import {POPUP_SETTINGS} from '@/models/POPUP_SETTINGS';
function findPopup(popup){
	for(let [rootKey, root] of Object.entries(POPUP_SETTINGS)){
		for(let [sectionKey, section] of Object.entries(root['SECTIONS'])){
			for(let [groupKey, group] of Object.entries(section)){
				if(popup === group.VALUE){
					return {section: root.VALUE, title: root.TITLE, popup: group.VALUE};
				}
			}
		}
	}
}

export default {
	namespaced: true,
	state: {
		opened: false,
		currentPopup: null,
		precededPopup: null,
		mediaChoosing: false,
		imageVideoMode: false,
	},
	getters: {
		isPopupOpened(state) {
			return state.opened;
		},
		isMediaChoosing(state){
			return state.mediaChoosing;
		},
		getCurrentPopup(state){
			return state.currentPopup;
		},
		getPrecededPopup(state){
			return state.precededPopup;
		},
		getImageVideoMode(state){
			return state.imageVideoMode;
		}
	},
	mutations: {
		OPEN_POPUP(state, popup){
			state.currentPopup = popup ? findPopup(popup) : null;
			state.opened = true;
		},
		CHANGE_POPUP(state, popup){
			state.currentPopup = findPopup(popup);
		},
		CLOSE_POPUP(state){
			state.opened = false;
			state.currentSection = null;
			state.currentPopup = null;
		},
		CHANGE_PRECEDED_POPUP(state, popup){
			state.precededPopup = popup;
		},
		CHANGE_MEDIA_CHOOSING(state, status){
			state.mediaChoosing = status;
		},
		SET_IMAGE_VIDEO_MODE(state, status){
			state.imageVideoMode = status;
		}
	},
	actions: {
		popupOpen({commit}, popup){
			commit("OPEN_POPUP", popup);
		},
		popupChange({commit}, popup){
			commit("CHANGE_POPUP", popup);
		},
		popupClose({commit}){
			commit("CLOSE_POPUP");
		},
		popupPrecededChange({commit}, popup){
			commit("CHANGE_PRECEDED_POPUP", popup);
		},
		mediaChoosingToggle({commit}, status){
			commit("CHANGE_MEDIA_CHOOSING", status);
		},
		imageVideoModeSet({commit}, status){
			commit("SET_IMAGE_VIDEO_MODE", status);
		}
	}
}
