import hidden from '@/store/modules/map/layers/modules/hidden';
import locked from '@/store/modules/map/layers/modules/locked';

import useI18nGlobal from '@/utils/i18n';
import ApiService from '@/utils/ApiService';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
const {i18n} = useI18nGlobal();
const {t} = i18n.global;
function layerPopulate(nodes){
	const layerNodes = [];
	for( let [nodeIndex, node] of Object.entries(nodes)){
		const layerNode = {
			name: `Node ${node.id}`,
			id: node.id,
			hiddenLocal: false,
			locked: node.locked,
			onLayer: nodes[nodeIndex].onLayer,
			seenFirst: node.seenFirst,
			visibilityMin: node.visibilityMin,
			visibilityMax: node.visibilityMax
		};
		layerNodes.push(layerNode);
	}
	return layerNodes;
}

export default {
	namespaced: true,
	modules: {
		hidden,
		locked
	},
	state: () => ({
		layerCurrentID: null,
		layers: null,
		levels: null
	}),
	getters: {
		getLayerCurrentID(state){
			return state.layerCurrentID
		},
		getLayers(state){
			return state.layers
		},
		getLevels(state){
			return state.levels
		},
		getLayersAmount(state, getters, rootState, rootGetters){
			return rootGetters['mapSettings/getLayersAmount'];
		},
		getNodesOriginal(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesOriginal'];
		},
		getNodes(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodes'];
		},
		getCurrentMapId(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getCurrentMapId']
		},
		getMapsURL(state, getters, rootState, rootGetters){
			return rootGetters['maps/getMapsURL']
		},
		getElementsURL(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getElementsURL']
		},
	},
	mutations: {
		SAVE_LEVELS(state, levels){
			state.levels = levels;
		},
		UPDATE_HIDDEN_LEVEL(state, hiddenData){
			for(let [id, status] of Object.entries(hiddenData)){
				const level = state.levels.find(level => level.id === parseInt(id));
				level.hidden = status;
			}
		},
		UPDATE_LOCKED_LEVEL(state, lockedData){
			for(let [id, status] of Object.entries(lockedData)){
				const level = state.levels.find(level => level.id === parseInt(id));
				level.locked = status;
			}
		},
		SET_CURRENT_LAYER(state, layerIndex){
			state.layerCurrentID = layerIndex;
		},
		DELETE_LAYER(state, layerID){
			// if active layer is the bottom one - make active to previous one
			if(state.layerCurrentID === Object.keys(state.layers).length) state.layerCurrentID--;
			delete state.layers[layerID];
			
			if(layerID <= Object.keys(state.layers).length){
				// change ids of layersData below deleted one
				for (let [id, layer] of Object.entries(state.layers)){
					if (parseInt(id) > layerID){
						layer.id = layer.id - 1;
						layer.name = `${t('layerOptions.layer')} ${layer.id}`;
						state.layers[id - 1] = layer; // add clone using index - 1
						delete state.layers[id]; // remove duplicate
					}
				}
			}
		},
		DELETE_LEVEL(state, levelID){
			const levelIndex = state.levels.findIndex(level => level.id === parseInt(levelID));
			state.levels.splice(levelIndex, 1);
			// levels amount more than deleted index
			// all levels ids below should be rearranged
			if(state.levels.length - 1 >= levelIndex){
				state.levels.forEach(level => {
					if(level.id > levelIndex) level.id = level.id - 1;
				})
			}
		},
		ADJUST_LEVELS(state, zoomLevels){
			const levelsLength = state.levels.length;
			if(levelsLength < zoomLevels){
				// if zoomLevels increased add levels accordingly
				for(let i = levelsLength + 1; i <= zoomLevels; i++){
					state.levels.push({hidden: false, locked: false, id: i})
				}
			} else {
				state.levels.splice(zoomLevels, levelsLength - zoomLevels);
			}
		},
		REORDER_NODES(state, reorderData){
			const {nodeIndex, toNodeIndex, layerID, SE = false} = reorderData;
			for(let layer of Object.values(state.layers)){
				if(layer.id !== layerID && !SE){
					const nodeToMove = layer.nodes.splice(nodeIndex, 1);
					layer.nodes.splice(toNodeIndex, 0, ...nodeToMove);
				} else if(SE){
					const nodeToMove = layer.nodes.splice(nodeIndex, 1);
					layer.nodes.splice(toNodeIndex, 0, ...nodeToMove);
				}
			}
		},
		INIT_LAYERS(state, layersData){
			const { zoomLevels, nodesOriginal } = layersData;
			const layersNodesOrderData = {};
			for (let currentLayer = 1; currentLayer <= zoomLevels; currentLayer++) {
				layersNodesOrderData[currentLayer] =
					{
						nodes:
							nodesOriginal.map(node => {
								const onLayer = node.visibility.min_level <= currentLayer &&
									node.visibility.max_level >= currentLayer;
								const seenFirst = node.visibility.min_level === currentLayer;
								const visibilityMin = node.visibility.min_level;
								const visibilityMax = node.visibility.max_level;
								const hiddenLocal = false;
								const locked = node.editing.locked;
								return {id: node.id, onLayer, seenFirst, visibilityMax, visibilityMin, hiddenLocal, locked}
							})
					}
			}
			
			state.layers = {}; // clear layers object
			for (let [layerId, layer] of Object.entries(layersNodesOrderData)){
				const level = state.levels.find(level => level.id === parseInt(layerId));
				state.layers[layerId] = {
					name: `Layer ${layerId}`,
					id: layerId,
					hidden: level?.hidden || false,
					locked: level?.locked || false,
					nodes: [...layerPopulate(layer.nodes)]
				};
			}
		},
		UPDATE_LAYERS_NODE_VISIBILITY(state, data){
			const {id, visibilityMin, visibilityMax} = data;
			for(let [layerId, layer] of Object.entries(state.layers)){
				const node = layer.nodes.find(node => node.id === parseInt(id));
				if(visibilityMin) node.visibilityMin = visibilityMin;
				if(visibilityMax) node.visibilityMax = visibilityMax;
				node.seenFirst = node.visibilityMin === parseInt(layerId);
				if(layerId < node.visibilityMin){
					node.onLayer = false;
				} else node.onLayer = layerId <= node.visibilityMax;
			}
		},
		ADD_CREATED_NODE_TO_LAYERS(state, node){
			const visibilityMin = node.visibility.min_level;
			const visibilityMax = node.visibility.max_level;
			
			for(let [layerId, layer] of Object.entries(state.layers)){
				const layerNode = {
					name: `${t('layerOptions.node')} ${node.id}`,
					id: node.id,
					hidden: false,
					locked: false,
					onLayer:
						parseInt(layerId) < visibilityMin ?
							false:
							parseInt(layerId) <= visibilityMax,
					seenFirst: parseInt(layerId) === visibilityMin,
					visibilityMin,
					visibilityMax
				};
				
				layer.nodes.push(layerNode);
			}
		},
		REMOVE_NODE_FROM_LAYERS(state, nodeId){
			for(let layer of Object.values(state.layers)){
				const nodeIndex = layer.nodes.findIndex(node => node.id === nodeId);
				layer.nodes.splice(nodeIndex, 1);
			}
		}
	},
	actions: {
		levelsSave({commit}, levels){
			commit('SAVE_LEVELS', levels);
		},
		levelHiddenUpdate({commit}, hiddenData){
			commit('UPDATE_HIDDEN_LEVEL', hiddenData)
		},
		levelLockedUpdate({commit}, lockedData){
			commit('UPDATE_LOCKED_LEVEL', lockedData)
		},
		layersInit({getters, commit}){
			const zoomLevels = getters.getLayersAmount;
			const nodesOriginal = getters.getNodesOriginal;
			
			commit('INIT_LAYERS', {zoomLevels, nodesOriginal});
			commit('SET_CURRENT_LAYER', '1');
		},
		layerCurrentSet({commit}, layerID){
			commit('SET_CURRENT_LAYER', layerID);
		},
		layerDeletedUpdate({getters, commit, dispatch}, layerID){
			const zoomLevels = getters.getLayersAmount;
			const nodesOriginal = getters.getNodesOriginal;
			const layerCurrentID = getters.getLayerCurrentID;
			const layers = getters.getLayers;
			const deletedLocked = layers[layerID].locked;
			const deletedHidden = layers[layerID].hidden;
			// check if deleting layer is current one and/or current is the latest layer
			if(layerCurrentID === layerID || layerCurrentID - 1 === zoomLevels) {
				commit('SET_CURRENT_LAYER', (layerCurrentID - 1 ? layerCurrentID - 1 : layerCurrentID).toString());
			}
			
			commit('DELETE_LAYER', layerID);
			commit('DELETE_LEVEL', layerID);
			
			if(layers[layerID]){
				const risenLocked = layers[layerID].locked;
				const risenHidden = layers[layerID].locked;
				
				if(deletedHidden !== risenHidden) {
					dispatch('layers/hidden/layerHiddenToggle', {[layerID]: risenHidden}, {root: true});
				}
				if(deletedLocked !== risenLocked) {
					dispatch('layers/locked/layerLockedToggle', {[layerID]: !locked}, {root: true});
				}
			}
		},
		async layerDeletedSave({getters, commit, dispatch}, {zoomData, layerID}){
			const mapsURL = getters.getMapsURL;
			const elementsURL = getters.getElementsURL;
			const mapId = getters.getCurrentMapId;
			
			const deletedData = {'levels': [{id: parseInt(layerID), delete: true}]}
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, deletedData);
				
				// send SE event
				const dataSE = {
					type: SE_SYNC_TYPES.LAYER_REMOVED,
					data: {...zoomData, layerID}
				}
				await dispatch('simultaneousEditing/syncEdits', dataSE, {root: true});
			} catch(err){
				console.log(err);
			}
		},
		layerDeleteSE({dispatch}, zoomData){
			const {zoomLevel, previous, layerID} = zoomData;
			dispatch('nodes/nodesChangeZoomLevel', {zoomLevel, previous}, {root: true});
			dispatch('edges/edgesChangeZoomLevel', {zoomLevel, previous}, {root: true});
			dispatch('mapSettings/mapZoomLevelsUpdateInStore', zoomLevel, {root:true});
			dispatch('layerDeletedUpdate', layerID);
		},
		layersAmountChange({getters, commit}, zoomLevels){
			const nodesOriginal = getters.getNodesOriginal;
			const currentLayerID = getters.getLayerCurrentID;
			commit('ADJUST_LEVELS', zoomLevels);
			commit('INIT_LAYERS', {zoomLevels, nodesOriginal});
			const layerID = parseInt(currentLayerID) <= parseInt(zoomLevels) ? currentLayerID : '1';
			commit('SET_CURRENT_LAYER', layerID);
		},
		layerNodesReorder({commit, dispatch}, reorderData){
			commit('REORDER_NODES', reorderData);
		},
		layersNodeVisibilityUpdate({commit}, nodeData){
			commit('UPDATE_LAYERS_NODE_VISIBILITY', nodeData);
		},
		nodeCreatedAddToLayers({commit}, node){
			commit('ADD_CREATED_NODE_TO_LAYERS', node);
		},
		nodeRemoveFromLayers({commit}, nodeId){
			commit('REMOVE_NODE_FROM_LAYERS', nodeId);
		}
	}
}

