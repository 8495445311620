import { createApp } from 'vue';
import App from '@/App.vue';
import {router, routes} from '@/router/router.js';
import store from '@/store/store';
import moment from "moment";
import '@/scss/styles.scss'; // import global styles
import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
import emitter from "@/utils/emitter";

import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
// TinyMCE plugins
// https://www.tiny.cloud/docs/tinymce/6/plugins/
import 'tinymce/plugins/image';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/help';
import 'tinymce/plugins/table';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/template';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/autosave';
import 'tinymce/models/dom/model'

const application = createApp(App);
application.provide('emitter', emitter);
application.provide('moment', moment);

application
	.use(i18n)
	.use(store)
	.use(router)
	.mount('#app');
