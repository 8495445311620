import { reactive, watch} from 'vue';
import {Scale} from '@/services/canvas/Scale';
import {BACKGROUND} from '@/models/BACKGROUND';
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";

// keep mapArea object in closure
const mapArea = reactive({
	x: 0,
	y: 0,
	width: 0,
	height: 0,
	borderColor: "#0125ea",
	bgColor: null,
	movement: false,
	update: false,
	updateElement: false,
	nodesToUpdate: new Set(),
	edgesToUpdate: new Set(),
	updateMap: (value = true) => mapArea.update = value,
	updateEl: (value = true, id = null, type = EDITING_ELEM_TYPES.NODE) => {
		if( !type || type === EDITING_ELEM_TYPES.NODE) {
			if(!mapArea.nodesToUpdate.add) mapArea.nodesToUpdate = new Set();
			if(id !== null) mapArea.nodesToUpdate.add(id);
		}
		if( type === EDITING_ELEM_TYPES.EDGE){
			if(!mapArea.edgesToUpdate.add) mapArea.edgesToUpdate = new Set();
			if(id !== null) mapArea.edgesToUpdate.add(id);
		}

		mapArea.updateElement = value;
	},
	clearUpdates: () => {
		mapArea.nodesToUpdate = new Set();
		mapArea.edgesToUpdate = new Set();
	}
})

export default function() {
	
	function mapBgWatcher(mapCanvasHTML, mapBgColor){
		watch(mapBgColor, (value, oldValue) => {
				if(!oldValue){
					mapCanvasHTML.style = value;
					return;
				}
				// because of glitch related to changing a gradient type in Chrome
				if(!oldValue.includes('linear-gradient') || !oldValue.includes('radial-gradient')){
					mapCanvasHTML.style = '';
					setTimeout(() => {mapCanvasHTML.style = value}, 10);
				} else {
					mapCanvasHTML.style = value;
				}
			},
			{immediate: true}
		);
	}
	
	function drawMapArea(ctx, zoomLevel, ignoreMapCoords = false, area = {width: null, height: null, shiftX: 0, shiftY: 0}){
		ctx.save();

		const {width, height, shiftX, shiftY} = area;
		const mapShiftX = ignoreMapCoords ? width / 2 + Math.abs(shiftX)  : mapArea.x;
		const mapShiftY = ignoreMapCoords ? height / 2 + Math.abs(shiftY) : mapArea.y;
		ctx.strokeStyle = mapArea.borderColor;
		ctx.setLineDash([2, 4]);
		ctx.lineDashOffset = 25;
		ctx.beginPath();
		ctx.strokeRect(
			Scale.x(mapShiftX, ctx.canvas.width / 2, zoomLevel),
			Scale.y(mapShiftY, ctx.canvas.height / 2, zoomLevel),
			mapArea.width * zoomLevel,
			mapArea.height * zoomLevel
		);
		ctx.stroke();
		ctx.rect(
			Scale.x(mapShiftX, ctx.canvas.width / 2, zoomLevel),
			Scale.y(mapShiftY, ctx.canvas.height / 2, zoomLevel),
			mapArea.width * zoomLevel,
			mapArea.height * zoomLevel
		);
		ctx.fillStyle = 'rgba(0,0,0,0)';
		ctx.fill();
		
		ctx.closePath();
		ctx.restore();
	}
	
	function drawMapImage(ctx, zoomLevel, mapData){
		if(mapData.image && mapData.image.width){
			
			ctx.save();
			ctx.beginPath();
			// create a non-primitive path to be able to clip image
			// clip if cover only
			ctx.rect(
				Scale.x(mapArea.x, ctx.canvas.width / 2, zoomLevel),
				Scale.y(mapArea.y, ctx.canvas.height / 2, zoomLevel),
				mapArea.width * zoomLevel,
				mapArea.height * zoomLevel);
			ctx.clip();
			
			/**
			 *
			 * @param imageDimensions: {{x: null, width: null, y: null, height: null}}
			 */
			const imageDimensions = getImageDimensions(mapData.image, mapData);
			setImagePosition(imageDimensions, mapData);
			
			ctx.globalAlpha = mapData.mediaOpacity / 100;
			
			ctx.drawImage(
				mapData.image,
				Scale.x(imageDimensions.x, ctx.canvas.width / 2, zoomLevel),
				Scale.y(imageDimensions.y, ctx.canvas.height / 2, zoomLevel),
				imageDimensions.width * zoomLevel,
				imageDimensions.height * zoomLevel
			)
			
			ctx.restore();
		}
	}
	
	function getImageDimensions(image, mapData){
		const imageWidth = image.width;
		const imageHeight = image.height;
		const imageRatio = imageWidth / imageHeight;
		const mapAreaWidth = mapData.width;
		const mapAreaHeight = mapData.height;
		
		const mapAreaWidthProportionalToImage = mapAreaWidth / imageRatio;
		
		const imageDimensions = {
			x: null,
			y: null,
			width: null,
			height: null
		}
		
		// all x and y are calc here to draw image at a center
		switch(mapData.mediaSize){
			case BACKGROUND.SIZE.FILL.VALUE:
				// stretch image to whole available mapArea ignoring an image ratio
				imageDimensions.x = mapArea.x;
				imageDimensions.y = mapArea.y;
				imageDimensions.width = mapArea.width;
				imageDimensions.height = mapArea.height;
				break;
			case BACKGROUND.SIZE.CONTAIN.VALUE:
				if(mapAreaWidthProportionalToImage < mapAreaHeight){
					imageDimensions.width = imageWidth * (mapAreaWidth / imageWidth);
					imageDimensions.height =  imageHeight * (mapAreaWidth / imageWidth);
					imageDimensions.x = mapArea.x
					imageDimensions.y = mapArea.y + (mapArea.height - imageDimensions.height) / 2;
				} else {
					imageDimensions.width = imageWidth * (mapAreaHeight / imageHeight);
					imageDimensions.height =  imageHeight * (mapAreaHeight / imageHeight);
					imageDimensions.x = mapArea.x
					imageDimensions.y = mapArea.y + (mapArea.height - imageDimensions.height) / 2;
				}
				break;
			case BACKGROUND.SIZE.COVER.VALUE:
				if(mapAreaWidthProportionalToImage > mapAreaHeight){
					imageDimensions.width = imageWidth * (mapAreaWidth / imageWidth);
					imageDimensions.height =  imageHeight * (mapAreaWidth / imageWidth);
					imageDimensions.x = mapArea.x;
					imageDimensions.y = mapArea.y + (mapArea.height - imageDimensions.height) / 2;
				} else {
					imageDimensions.width = imageWidth * (mapAreaHeight / imageHeight);
					imageDimensions.height =  imageHeight * (mapAreaHeight / imageHeight);
					imageDimensions.x = mapArea.x;
					imageDimensions.y = mapArea.y + (mapArea.height - imageDimensions.height) / 2;
				}
				break;
			case BACKGROUND.SIZE.NONE.VALUE:
				imageDimensions.x = mapArea.x + (mapArea.width - imageWidth) / 2;
				imageDimensions.y = mapArea.y + (mapArea.height - imageHeight) / 2;
				imageDimensions.width = imageWidth;
				imageDimensions.height = imageHeight;
				break;
		}
		
		return imageDimensions;
	}
	
	// todo add function to calc coords for repeat options
	
	function setImagePosition(imageDimensions, mapData){
		
		switch (mapData.mediaPosition){
			case BACKGROUND.POSITION.CENTER.VALUE:
				imageDimensions.x = mapArea.x + (mapArea.width - imageDimensions.width) / 2;
				break;
			case BACKGROUND.POSITION.LEFT.VALUE:
				imageDimensions.x = mapArea.x;
				break;
			case BACKGROUND.POSITION.RIGHT.VALUE:
				imageDimensions.x = mapArea.x + (mapArea.width - imageDimensions.width);
				break;
		}
		
		return imageDimensions;
	}
	
	let zoomFactorRatio = 1; // zoom factor multiplier
	function transferMapArea(zoomFactor, coords = null, dispatch){
		const pixelRatio = window.devicePixelRatio;
		const $windowWidth = window.innerWidth * pixelRatio;
		const $windowHeight = window.innerHeight * pixelRatio;
		
		const fps = 60;
		const time = 300;
		const millisecondsToFrame = 1000 / fps;
		const stepAmount = Math.floor(time / millisecondsToFrame);
		let cycleStep = 0;
		let centeringFrame;
		
		// get the overflow value related to window by x-axis | y-axis
		const shift = {
			x: coords ? coords.x : $windowWidth - (mapArea.x * 2 + mapArea.width),
			y: coords ? coords.y : $windowHeight - (mapArea.y * 2 + mapArea.height)
		}
		/*const shiftX = $windowWidth - (mapArea.x * 2 + mapArea.width);
		const shiftY = $windowHeight - (mapArea.y * 2 + mapArea.height);*/
		// divide this value to animation step amount to calc one-step value
		// and take half of it to make sure it's centered
		const dividerComputed = coords ? 1 : 2;
		let stepXValue = (shift.x / stepAmount + (shift.x / stepAmount) * cycleStep) / dividerComputed;
		let stepYValue = (shift.y / stepAmount + (shift.y / stepAmount) * cycleStep) / dividerComputed;

		// ratioShift by y-axis
		const oldZoomFactorRatio = zoomFactorRatio;
		// ratio of window height to map area height alongside it's vertical paddings
		zoomFactorRatio = $windowHeight /((mapArea.height) * pixelRatio);
		// map area height shouldn't be more than it's real size
		/*if ( mapArea.height * zoomFactorRatio * pixelRatio > mapArea.height){
			zoomFactorRatio = 1 / pixelRatio;
		}*/
		// increase / decrease zoomFactor to zoom factor ration changing subtraction
		const zoomFactorStep = (zoomFactorRatio - oldZoomFactorRatio) / stepAmount;

		// mapTransfer(true);
		dispatch('maps/mapTransfer', true)
		centering();
		mapArea.updateMap();
		
		function centering(){
			if (cycleStep === stepAmount){
				cancelAnimationFrame(centeringFrame);
				// mapTransfer(false);
				dispatch('maps/mapTransfer', false)
				mapArea.updateMap(false);
			} else {
				zoomFactor += zoomFactorStep;
				mapArea.x += stepXValue;
				mapArea.y += stepYValue;
				
				cycleStep++;
				centeringFrame = requestAnimationFrame(centering);
			}
		}
		
	}
	
	return {
		mapArea,
		mapBgWatcher,
		drawMapArea,
		drawMapImage,
		transferMapArea,
	}
}
