export default {
	namespaced: true,
	state: {},
	getters: {
		getLayers(state, getters, rootState, rootGetters){
			return rootGetters['layers/getLayers'];
		}
	},
	mutations: {
		TOGGLE_LAYER_LOCKED(state, {layersData, layers}){
			for(let [id, status] of Object.entries(layersData)){
				const toggledLayer = layers[id];
				toggledLayer.locked = status;
				
				for( let layer of Object.values(layers)){
					layer.nodes.forEach( (node, nodeIndex) => {
						if(toggledLayer.nodes[nodeIndex].onLayer) {
							node.locked = status;
						}
					})
				}
			}
		},
		UPDATE_LAYER_LOCKED(state, {layers, layerId, status}){
			layers[layerId].locked = status;
		},
		TOGGLE_NODE_LOCKED(state, {lockedData, layers}){
			for(let layer of Object.values(layers)){
				for(let [id, status] of Object.entries(lockedData)){
					const node = layer.nodes.find(node => node.id === parseInt(id));
					node.locked = status;
				}
			}
		},
	},
	actions: {
		layerLockedUpdate({getters, commit}, layersData){
			const layers = getters.getLayers;
			commit('TOGGLE_LAYER_LOCKED', {layersData, layers});
		},
		layerLockedToggle({getters, commit, dispatch}, lockedData){
			dispatch('nodes/locked/layerUpdateLocked', lockedData, {root: true});
			dispatch('nodes/locked/layerSaveLocked', lockedData, {root: true});
		},
		layersLockedEmptyFilter({getters, commit, dispatch}){
			const layers = getters.getLayers;
			const layersUpdated = [];
			for(let layer of Object.values(layers)){
				if(layer.locked){
					const lockedNode = layer.nodes.some(node => node.locked);
					if(!lockedNode) {
						commit('UPDATE_LAYER_LOCKED', {layers, layerId: layer.id, status: false});
						layersUpdated.push({[layer.id]: false});
					}
				}
			}
			// [{id, locked: false}]
			return Promise.resolve(layersUpdated);
		},
		nodeLockedUpdate({getters, commit}, lockedData){
			// hiddenData: {id: status, id: status, ...}
			const layers = getters.getLayers;
			commit('TOGGLE_NODE_LOCKED', {lockedData, layers});
		},
		nodeLockedToggle({getters, commit, dispatch}, lockedData){
			dispatch('nodes/locked/nodeUpdateLocked', lockedData, {root: true});
			dispatch('nodes/locked/nodeSaveLocked', lockedData, {root: true});
		},
	}
}
