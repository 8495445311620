import store from '@/store/store'
import {computed} from "vue";
import {DASHBOARD_OPTIONS} from '@/models/DASHBOARD_OPTIONS';
import {DASHBOARD_ACTIONS} from '@/models/DASHBOARD_ACTIONS';
import {NODE_SHAPES} from '@/models/NODE_SHAPES';
import {Roles} from '@/models/Roles';
import {EDITING_EXTENT_TYPES} from '@/models/EDITING_EXTENT_TYPES';
import {POPUP_SETTINGS} from "@/models/POPUP_SETTINGS";
import useMapArea from '@/services/canvas/map-area/MapArea';
const {mapArea} = useMapArea();
function checkOptionRelationToGroup(option, group){
	let related = false;
	for(let optionValue of Object.keys(group)){
		if(option.VALUE === optionValue) related = true;
	}
	return related;
}

import useI18nGlobal from '@/utils/i18n';
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";
const {i18n} = useI18nGlobal();
const { t } = i18n.global;
const NODE_DESIGN = DASHBOARD_OPTIONS.CREATE.GROUPS.NODE.NODE_DESIGN.VALUE;
const EDGE_DESIGN = DASHBOARD_OPTIONS.CREATE.GROUPS.EDGE.EDGE_DESIGN.VALUE;

// collect NODE_SHAPES values
const nodeShapes = [];
for(const value of Object.values(NODE_SHAPES)){
	nodeShapes.push(value);
}

// blocked
const blockedMap = computed(() => store.getters["simultaneousEditing/getBlockedMap"]);
const blockedGlobal = computed(() => store.getters["simultaneousEditing/getBlockedGlobal"]);
const blockedCookieConsent = computed(() => store.getters["simultaneousEditing/getBlockedCookieConsent"]);
const blockedEditorsNote = computed(() => store.getters["simultaneousEditing/getBlockedEditorsNote"]);
const blockedEmbed = computed(() => store.getters["simultaneousEditing/getBlockedEmbed"]);
const blockedGlossary = computed(() => store.getters["simultaneousEditing/getBlockedGlossary"]);
const blockedHeaderBar = computed(() => store.getters["simultaneousEditing/getBlockedHeaderBar"]);
const blockedMapAccess = computed(() => store.getters["simultaneousEditing/getBlockedMapAccess"]);
const blockedMapUrl = computed(() => store.getters["simultaneousEditing/getBlockedMapUrl"]);
const blockedMediaAssets = computed(() => store.getters["simultaneousEditing/getBlockedMediaAssets"]);
const blockedSeo = computed(() => store.getters["simultaneousEditing/getBlockedSeo"]);
const blockedSocialNetworks = computed(() => store.getters["simultaneousEditing/getBlockedSocialSharing"]);
const blockedSources = computed(() => store.getters["simultaneousEditing/getBlockedSources"]);
const blockedQuiz = computed(() => store.getters["simultaneousEditing/getBlockedQuiz"]);
const blockedTableOfContent = computed(() => store.getters["simultaneousEditing/getBlockedTableOfContent"]);

export default {
	namespaced: true,
	state: {
		sections: {
			create: {
				value: DASHBOARD_OPTIONS.CREATE.VALUE,
				icon: "fas fa-paint-brush",
				get tooltip() {return t('dashboardOptions.createMap')},
				groups: {
					"0": [
						{
							value: DASHBOARD_OPTIONS.CREATE.GROUPS.DEFAULT.ADD_NODE,
							// tooltip: t('dashboardOptions.drawShapes'),
							icon: "fas fa-pencil-alt",
							role: Roles.Student,
							shapes: nodeShapes
						},
						{
							value: DASHBOARD_OPTIONS.CREATE.GROUPS[1].MANAGE_TEMPLATES,
							get tooltip() {return t('dashboardOptions.objectTemplates')},
							icon: "fas fa-shapes",
							role: Roles.Student
						},
						{
							value: DASHBOARD_OPTIONS.CREATE.GROUPS.DEFAULT.TEXT_ON_MAP,
							get tooltip() {return t('dashboardOptions.drawText')},
							icon: "fas fa-paragraph",
							disabled: true,
						},
					],
					"1": [
						{
							value: DASHBOARD_OPTIONS.CREATE.GROUPS.DEFAULT.EDGE_CONNECTION,
							get tooltip() {return t('dashboardOptions.drawEdges')},
							icon: "fas fa-arrows-alt-h rotated--45-left",
							role: Roles.Student
						},
					],
					"2": [
						{
							value: DASHBOARD_OPTIONS.CREATE.GROUPS[1].GLOBAL_SETTINGS,
							get tooltip() {return t('dashboardOptions.globalDesign')},
							icon: "fas fa-palette",
							role: Roles.Student,
							get blockedClassname() {return blockedGlobal.value ? `${blockedGlobal.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.CREATE.GROUPS[2].MAP_SETTINGS,
							get tooltip() {return t('dashboardOptions.mapSettings')},
							icon: "fas fa-map",
							role: Roles.Student,
							get blockedClassname() {return blockedMap.value ? `${blockedMap.value.visitor.color} engaged` : ""},
						},
					],
				}
			},
			organize: {
				value: DASHBOARD_OPTIONS.ORGANIZE.VALUE,
				icon: "fas fa-folder-open",
				get tooltip() {return t('dashboardOptions.organizeContent')},
				groups: {
					"0": [
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].CONTENT_FORM,
							get tooltip() {return t('dashboardOptions.contentForm')},
							icon: "fas fa-align-justify",
							role: Roles.Student,
							disabled: false,
						},
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].EDITOR_NOTE,
							get tooltip() {return t('dashboardOptions.editorsNote')},
							icon: "fas fa-pen-nib",
							role: Roles.Student,
							get blockedClassname() {return blockedEditorsNote.value ? `${blockedEditorsNote.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].GLOSSARY,
							get tooltip() {return t('dashboardOptions.glossary')},
							icon: "fas fa-book",
							role: Roles.Student,
							get blockedClassname() {return blockedGlossary.value ? `${blockedGlossary.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].SOURCES,
							get tooltip() {return t('dashboardOptions.references')},
							icon: "fas fa-bookmark",
							role: Roles.Student,
							get blockedClassname() {return blockedSources.value ? `${blockedSources.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].HEADER_BAR,
							get tooltip() {return t('dashboardOptions.headerBar')},
							icon: "fas fa-window-maximize",
							role: Roles.Student,
							get blockedClassname() {return blockedHeaderBar.value ? `${blockedHeaderBar.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].QUIZ,
							tooltip: 'Quiz',
							icon: "fas fa-puzzle-piece",
							role: Roles.Editor,
							get blockedClassname() {return blockedQuiz.value ? `${blockedQuiz.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].TABLE_OF_CONTENT,
							get tooltip() {return t('dashboardOptions.tableOfContent')},
							icon: "fas fa-list-ul",
							role: Roles.Editor,
							get blockedClassname() {return blockedTableOfContent.value ? `${blockedTableOfContent.value.visitor.color} engaged` : ""},
						}
					],
					"1": [
						{
							value: DASHBOARD_OPTIONS.ORGANIZE.GROUPS[1].MANAGE_ASSETS,
							get tooltip() {return t('dashboardOptions.mediaAssets')},
							icon: "fas fa-photo-video",
							role: Roles.Student,
							get blockedClassname() {return blockedMediaAssets.value ? `${blockedMediaAssets.value.visitor.color} engaged` : ""},
						}
					]
				}
			},
			manage: {
				value: DASHBOARD_OPTIONS.MANAGE.VALUE,
				icon: "fas fa-globe-americas",
				get tooltip() {return t('dashboardOptions.webSettings')},
				groups: {
					"0": [
						{
							value: DASHBOARD_OPTIONS.MANAGE.GROUPS[0].SOCIAL_NETWORKS,
							get tooltip() {return t('dashboardOptions.socialNetworks')},
							icon: "fas fa-share-alt",
							role: Roles.Student,
							get blockedClassname() {return blockedSocialNetworks.value ? `${blockedSocialNetworks.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.MANAGE.GROUPS[0].COOKIE_CONSENT,
							get tooltip() {return t('dashboardOptions.cookieConsent')},
							icon: "fas fa-cookie-bite",
							role: Roles.Student,
							get blockedClassname() {return blockedCookieConsent.value ? `${blockedCookieConsent.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.MANAGE.GROUPS[0].SEO_SETTINGS,
							get tooltip() {return t('dashboardOptions.seo')},
							icon: "fas fa-magic",
							role: Roles.Student,
							get blockedClassname() {return blockedSeo.value ? `${blockedSeo.value.visitor.color} engaged` : ""},
						},
					],
					"1": [
						{
							value: DASHBOARD_OPTIONS.MANAGE.GROUPS[1].MAP_URL,
							get tooltip() {return t('dashboardOptions.mapUrl')},
							icon: "fas fa-link",
							role: Roles.Editor,
							get blockedClassname() {return blockedMapUrl.value ? `${blockedMapUrl.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.MANAGE.GROUPS[1].MAP_ACCESS,
							get tooltip() {return t('dashboardOptions.mapAccess')},
							icon: "fas fa-lock",
							role: Roles.Editor,
							get blockedClassname() {return blockedMapAccess.value ? `${blockedMapAccess.value.visitor.color} engaged` : ""},
						},
						{
							value: DASHBOARD_OPTIONS.MANAGE.GROUPS[1].EMBED_CODE,
							get tooltip() {return t('dashboardOptions.mapEmbed')},
							icon: "fas fa-code",
							role: Roles.Student,
							get blockedClassname() {return blockedEmbed.value ? `${blockedEmbed.value.visitor.color} engaged` : ""},
						}
					]
				}
			},
		},
		defaultGroup: [
			{
				value: DASHBOARD_OPTIONS.CREATE.GROUPS.DEFAULT.ADD_NODE,
				// tooltip: t('dashboardOptions.drawShapes'),
				icon: "fas fa-pencil-alt",
				role: Roles.Student,
				shapes: nodeShapes
			},
			{
				value: DASHBOARD_OPTIONS.CREATE.GROUPS[1].MANAGE_TEMPLATES,
				get tooltip() {return t('dashboardOptions.objectTemplates')},
				icon: "fas  fa-shapes",
				role: Roles.Student
			},
			{
				value: DASHBOARD_OPTIONS.CREATE.GROUPS.DEFAULT.TEXT_ON_MAP,
				get tooltip() {return t('dashboardOptions.drawText')},
				icon: "fas fa-paragraph",
				disabled: true
			},
		],
		nodeGroup: [
			{
				value: DASHBOARD_OPTIONS.CREATE.GROUPS.NODE.NODE_DESIGN,
				get tooltip() {return t('dashboardOptions.nodeDesign')},
				icon: "fas fa-pencil-ruler",
				role: Roles.Student
			},
			{
				value: DASHBOARD_OPTIONS.CREATE.GROUPS.NODE.CONTENT_FORM,
				get tooltip() {return t('dashboardOptions.nodeContent')},
				icon: "fas fa-align-left",
				role: Roles.Student,
				disabled: false
			},
		],
		edgeGroup: [
			{
				value: DASHBOARD_OPTIONS.CREATE.GROUPS.EDGE.EDGE_DESIGN,
				get tooltip() {return t('dashboardOptions.edgeDesign')},
				icon: "fas fa-pencil-ruler",
				role: Roles.Student
			},
		],
		sectionActive: DASHBOARD_OPTIONS.CREATE.VALUE,
		optionActive: null,
		nodeGroupActive: false,
		edgeGroupActive: false,
		mediaAssetsChoosing: false,
		nodeDrawingMode: false,
		edgeConnectionsDrawingMode: false,
		sectionLanguageEditing: false,
		subdomainEditing: false,
		publishOverviewEditing: null, // {String, Object: null}
		backToUserManagement: null
	},
	getters: {
		getSections(state){
			return state.sections;
		},
		getSectionActive(state){
			return state.sectionActive;
		},
		getOptionActive(state){
			return state.optionActive;
		},
		isMediaAssetsChoosing(state){
			return state.mediaAssetsChoosing;
		},
		isNodeGroupActive(state){
			return state.nodeGroupActive;
		},
		getNodeActiveID(state, getters, rootState){
			return rootState.nodes.nodeActiveID;
		},
		getEdgeActiveID(state, getters, rootState){
			return rootState.edges.edgeActiveID;
		},
		getEditingOption(state, getters, rootState){
			return rootState.simultaneousEditing.editingOption;
		},
		getEditedNode(state, getters, rootState, rootGetters){
			return rootGetters['simultaneousEditing/getEditedNode']
		},
		getNodeActive(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodeActive']
		},
		getCurrentLayerID(state, getters, rootState, rootGetters){
			return rootGetters['layers/getLayerCurrentID'];
		},
		getEditedEdge(state, getters, rootState, rootGetters){
			return rootGetters['simultaneousEditing/getEditedEdge']
		},
		isNodeDesignSettingsActive(state){
			return state.optionActive?.VALUE === NODE_DESIGN;
		},
		isEdgeDesignSettingsActive(state){
			return state.optionActive?.VALUE === EDGE_DESIGN;
		},
		isQEBContentTrackedChanges(state, getters, rootState, rootGetters){
			return rootGetters['nodes/content/getQEBContentTrackedChanges']
		},
		isNodeDrawingMode(state){
			return state.nodeDrawingMode;
		},
		isEdgeConnectionsDrawingMode(state){
			return state.edgeConnectionsDrawingMode
		},
		isSectionLanguageEditing(state){
			return state.sectionLanguageEditing;
		},
		isSubdomainEditing(state){
			return state.subdomainEditing;
		},
		isPublishOverviewEditing(state){
			return state.publishOverviewEditing;
		},
		getBackToUserManagementStatus(state){
			return state.backToUserManagement;
		}
	},
	mutations: {
		SECTION_TOGGLE(state, section){
			state.sectionActive = state.sectionActive === section ? null : section;
		},
		OPTION_CHOOSE(state, option){
			/**
			 * @param {option}
			 * {VALUE: 'value', TITLE: 'title', ACTION: 'action'}
			 * from '@/models/DASHBOARD_ACTIONS'
			 * */
			state.optionActive = option;
			// state.optionActive = state.optionActive?.VALUE === option?.VALUE ? null : option;

		},
		CHANGE_OPTION(state, option){
			for( const section of Object.values(DASHBOARD_OPTIONS)){
				for ( const group of Object.values(section['GROUPS'])){
					for (const item of Object.values(group)){
						if (item.VALUE === option){
							state.optionActive = item;
						}
					}
				}
			}
		},
		NODE_GROUP_TOGGLE(state, status){
			state.nodeGroupActive = status;
			state.sections.create.groups[0] =
				state.nodeGroupActive ?
					state.nodeGroup :
					state.defaultGroup;
			
		},
		EDGE_GROUP_TOGGLE(state, status){
			state.edgeGroupActive = status;
			state.sections.create.groups[0] =
				state.edgeGroupActive ?
					state.edgeGroup :
					state.defaultGroup;
			
		},
		SET_NODE_DRAWING_MODE(state, status){
			state.nodeDrawingMode = status;
		},
		SET_EDGE_CONNECTIONS_DRAWING_MODE(state, status){
			state.edgeConnectionsDrawingMode = status;
		},
		SET_SECTION_LANGUAGE_EDITING(state, status){
			state.sectionLanguageEditing = status;
		},
		SET_SUBDOMAIN_EDITING(state, status){
			state.subdomainEditing = status;
		},
		SET_PUBLISH_OVERVIEW_EDITING(state, step){
			state.publishOverviewEditing = step;
		},
		SET_BACK_TO_UM_STATUS(state, status){
			state.backToUserManagement = status;
		}
	},
	actions: {
		sectionToggle({commit}, section){
			commit("SECTION_TOGGLE", section);
		},
		optionChange({getters, commit, dispatch}, option){
			const optionActive = getters.getOptionActive;
			// if there is active option which is either popup or sidebar
			if(optionActive?.VALUE !== option){
				commit("CHANGE_OPTION", option);
			}
		},
		optionDrop({commit}) {
			commit('OPTION_CHOOSE', null);
		},

		async optionChoose({state, getters, commit, dispatch}, option){
			/**
			 * @description common function used either to open/close sidebar/popup
			 * or to call custom action
			 * @param {option.VALUE} - to detect what kind of action is calling
			 * described in model models/DASHBOARD_ACTIONS
			 */
			const nodeActive = getters.getNodeActive;
			const layerCurrentID = getters.getCurrentLayerID;

			if(nodeActive && option?.VALUE !== DASHBOARD_OPTIONS.CREATE.GROUPS.NODE.NODE_DESIGN.VALUE){
				// switch off selected state and draw it if node design settings has been closed
				nodeActive.setSelected(false);
				nodeActive.cacheUpdate(layerCurrentID);
				mapArea.updateEl(true, nodeActive.id);
			}

			// EDGES DRAWING MODE
			const isEdgeConnectionsDrawingMode = getters.isEdgeConnectionsDrawingMode;
			// if drawing edge mode was active cancel it
			if(isEdgeConnectionsDrawingMode){
				dispatch('edges/edgeConnectionCancel', null, {root: true});
				dispatch('edgeConnectionsDrawingModeSet', false);
				if(option?.ACTION === DASHBOARD_ACTIONS.EDGE_CONNECTION) option = null;
			} else if(option?.ACTION === DASHBOARD_ACTIONS.EDGE_CONNECTION){
				dispatch('edgeConnectionsDrawingModeSet', true);
			}

			// NODE DRAWING MODE
			const isNodeDrawingMode = getters.isNodeDrawingMode;
			if(isNodeDrawingMode){
				dispatch('nodeDrawingModeSet', false);
				if(option?.ACTION === DASHBOARD_ACTIONS.ADD_NODE) option = null;
			} else if(option?.ACTION === DASHBOARD_ACTIONS.ADD_NODE){
				dispatch('nodeDrawingModeSet', true);
			}

			// if QEB content not saved prevent farther action call
			if(getters.isQEBContentTrackedChanges){
				await dispatch('nodes/content/QEBContentWarningStatusUpdate', true, {root: true});
				return;
			}

			// NODE SETTINGS
			const NODE_DESIGN = DASHBOARD_OPTIONS.CREATE.GROUPS.NODE.NODE_DESIGN.VALUE;
			const NODE_CONTENT = DASHBOARD_OPTIONS.ORGANIZE.GROUPS[0].CONTENT_FORM.VALUE;
			let nodeActiveID = getters.getNodeActiveID;
			const edgeActiveID = getters.getEdgeActiveID;
			const editedNode = getters.getEditedNode;

			const editedEdge = getters.getEditedEdge;
			const ORGANIZE_SECTION = DASHBOARD_OPTIONS.ORGANIZE.VALUE;
			const CREATE_SECTION = DASHBOARD_OPTIONS.CREATE.VALUE;
			const sectionActive = getters.getSectionActive;
			// if content form opened from ORGANIZE section and there is active node element
			// reset it
			if(option?.VALUE === NODE_CONTENT && sectionActive === ORGANIZE_SECTION){
				dispatch('nodes/nodeSetActive', null, {root: true});
			}
			
			// if node editing - always send stop editing node event
			if(editedNode) dispatch('simultaneousEditing/stopEditingNode', null, {root: true});
			
			// if edit content || node settings opened send SE event accordingly
			if((option?.VALUE === NODE_CONTENT || option?.VALUE === NODE_DESIGN) &&	sectionActive === CREATE_SECTION){
				// SE emit call
				const nodeData = {
					nodeId: nodeActiveID,
					lockExtent: option.VALUE === NODE_CONTENT ?  EDITING_EXTENT_TYPES.CONTENT : EDITING_EXTENT_TYPES.APPEARANCE
				}
				dispatch('simultaneousEditing/startEditingNode', nodeData, {root: true})
			}
			
			// EDGE SETTINGS
			const EDGE_SETTINGS = DASHBOARD_OPTIONS.CREATE.GROUPS.EDGE.EDGE_DESIGN.VALUE;
			// if edge editing - always send stop editing edge event
			if(editedEdge) await dispatch('simultaneousEditing/stopEditingEdge', null, {root: true});
			
			// if editing edge design opened send SE event accordingly
			if(option?.VALUE === EDGE_SETTINGS){
				const edgeData = {
					edgeId: edgeActiveID,
					lockExtent: EDITING_EXTENT_TYPES.APPEARANCE
				}
				await dispatch('simultaneousEditing/startEditingEdge', edgeData, {root: true});
			}
			
			// check if opened option is part of node group to keep group opened
			if(option && nodeActiveID !== null){
				const nodeGroupOptions = DASHBOARD_OPTIONS.CREATE.GROUPS.NODE;
				if(checkOptionRelationToGroup(option, nodeGroupOptions)){
					// make unlinked option to avoid saving changed title
					// add id to title
					option = JSON.parse(JSON.stringify(option));
					option.TITLE = `${option.TITLE} - No. ${nodeActiveID}`;
				} else {
					// if option is not related to node group remove active nodeId
					// and close the group
					dispatch('nodes/nodeSetActive', null, {root: true});
					dispatch('groupNodeToggle', false);
				}
			}
			
			// process received option and store result
			const isEdgeDesignSettingsActive = getters.isEdgeDesignSettingsActive;
			const isNodeDesignSettingsActive = getters.isNodeDesignSettingsActive;
			const isSectionLanguageEditing = getters.isSectionLanguageEditing;
			const isSubdomainEditing = getters.isSubdomainEditing;
			const isPublishOverviewEditing = getters.isPublishOverviewEditing;

			if((isEdgeDesignSettingsActive || isNodeDesignSettingsActive) && option?.VALUE === state.optionActive?.VALUE){
				commit("OPTION_CHOOSE", option);
			} else {
				option = state.optionActive?.VALUE === option?.VALUE && !isSectionLanguageEditing && !isSubdomainEditing ?
					null :
					option;
				commit("OPTION_CHOOSE", option);
			}

			// proceed action for received option
			if(option?.ACTION === DASHBOARD_ACTIONS.SIDEBAR){
				if(state.optionActive){
					dispatch('settingsSidebar/sidebarOpen', null, { root: true});
				} else {
					dispatch('settingsSidebar/sidebarClose', null, { root: true});
				}
			}	else if (option?.ACTION === DASHBOARD_ACTIONS.POPUP){
				dispatch('settingsSidebar/sidebarClose', null, { root: true});
				dispatch('settingsPopup/popupOpen', option.VALUE , {root: true});
			}	else {
				// if the option value is null - close all popups and sidebars
				dispatch('settingsSidebar/sidebarClose', null, { root: true});
				dispatch('groupNodeToggle', false);

				if(nodeActive) {
					nodeActive.setSelected(false);
					nodeActive.cacheUpdate(layerCurrentID);
					mapArea.updateEl(true, nodeActive.id);
				}

				dispatch('settingsPopup/popupClose', null, {root: true});
				// remove node selected
				dispatch('nodes/nodeSetActive', null, {root: true});
				// remove edge selected
				dispatch('edges/edgeSetActive', null, {root: true});
				// set off the section language editing
				if(isSectionLanguageEditing){
					dispatch('sectionLanguageEditingSet', false);
					const blockedType = POPUP_SETTINGS.MAP.SECTIONS["0"].MAP_LANGUAGES.BLOCKED_TYPE;
					dispatch('simultaneousEditing/stopEditingOption', blockedType, {root: true});
				}
				if(isSubdomainEditing){
					dispatch('subdomainEditingSet', false);
					const blockedType = POPUP_SETTINGS.WEB.SECTIONS["1"].MAP_URL.BLOCKED_TYPE;
					dispatch('simultaneousEditing/stopEditingOption', blockedType, {root: true});
				}
				if(isPublishOverviewEditing){
					dispatch('publishOverviewEditingSet', null);
				}
			}
			mapArea.updateElement = true;
		},
		sidebarClose({commit, dispatch}){
			dispatch('settingsSidebar/sidebarClose', null, { root: true});
			// dispatch('groupNodeToggle', false);
			commit("OPTION_CHOOSE", null);
		},
		groupNodeToggle({commit}, status){
			commit("NODE_GROUP_TOGGLE", status);
		},
		groupEdgeToggle({commit, dispatch}, status){
			commit("EDGE_GROUP_TOGGLE", status);
		},
		nodeDrawingModeSet({commit}, status){
			commit("SET_NODE_DRAWING_MODE", status);
		},
		edgeConnectionsDrawingModeSet({commit}, status){
			commit("SET_EDGE_CONNECTIONS_DRAWING_MODE", status);
		},
		sectionLanguageEditingSet({commit}, status){
			commit("SET_SECTION_LANGUAGE_EDITING", status);
		},
		subdomainEditingSet({commit}, status){
			commit("SET_SUBDOMAIN_EDITING", status);
		},
		publishOverviewEditingSet({commit}, step){
			commit("SET_PUBLISH_OVERVIEW_EDITING", step);
		},
		backToUserManagementSet({commit}, status){
			commit("SET_BACK_TO_UM_STATUS", status);
		}
	}
}
