import ApiService from "@/utils/ApiService";
import {SE_SYNC_TYPES} from "@/models/SE_SYNC_TYPES";
import {TOASTS_TYPES} from "@/models/TOASTS_TYPES";
import ToastUtils from "@/utils/ToastUtils";
import EdgeUtils from '@/services/canvas/edges/EdgeUtils';
import useMapArea from "@/services/canvas/map-area/MapArea";
import useI18nGlobal from "@/utils/i18n";
import Fonts from "@/services/canvas/fonts/Fonts";
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";
const {createToastFromStore} = ToastUtils();
const {fontFamilyGetUrlByName} = Fonts();
const {
  EDGE_PROPS_MAPPING,
  convertToOriginalEdgeProperty,
  getEdgeDesignInitialComputedValue
} = EdgeUtils();
const {mapArea} = useMapArea();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
  namespaced: true,
  state: {
    designOrder: {},
    designTimeout: null,
  },
  getters: {
    getEdges(state, getters, rootState, rootGetters){
      return rootGetters["edges/getEdges"]
    },
    getEdgesOriginal(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesOriginal"];
    },
    getEdgesURL(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesURL"];
    },
    getCurrentMap(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getCurrentMap"]
    },
    getMapsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getMapsURL"]
    },
    getElementsDesign(state, getters, rootState, rootGetters) {
      return rootGetters["design/getElementsDesign"]
    },
    getClientFonts(state, getters, rootState, rootGetters){
      return rootGetters['nodes/getClientFonts'];
    },
  },
  mutations: {
    UPDATE_EDGE_DESIGN(state, {id, edgeData, edgesOriginal}) {
      const edge = edgesOriginal.find(edge => edge.id === id);
      Object.assign(edge.design, edgeData);
    },
  },
  actions: {
    edgeGetComputedData({getters}, {id, edgeData}){
      const activeEdge = getters.getEdgesOriginal.find(edge => edge.id === id);
      const templateId = activeEdge.design.object_template;
      const elementsDesign = getters.getElementsDesign;
      const [[property, value]] = Object.entries(edgeData);
      const edgeComputedData = {};
      const computedValue = getEdgeDesignInitialComputedValue(templateId, elementsDesign, EDGE_PROPS_MAPPING[property]);
      // if received value equal to computed value set edge value to null to avoid tracking changes
      value === computedValue ?
        Object.assign(edgeComputedData, {[property]: null}) :
        Object.assign(edgeComputedData, {[property]: value});
      return Promise.resolve(edgeComputedData);
    },
    async edgeDesignUpdate({getters, commit, dispatch}, {id, edgeData}) {
      const edgesOriginal = getters.getEdgesOriginal;
      const clientFonts = getters.getClientFonts;

      const edgeDesignOriginal = {};
      for(let [key, value] of Object.entries(edgeData)){
        if(key === 'fontFamily') value = fontFamilyGetUrlByName(value, clientFonts);
        const edgeComputedData = await dispatch('edgeGetComputedData', {id, edgeData: {[key]: value}})
        Object.assign(edgeDesignOriginal, convertToOriginalEdgeProperty(edgeComputedData))
      }

      commit('edges/UPDATE_EDITED_EDGE', {edgeId: id, edgeData}, {root: true});
      // properties width and height are parts of geometry object
      commit('UPDATE_EDGE_DESIGN', {id, edgesOriginal, edgeData: edgeDesignOriginal});

      // update canvas
      mapArea.updateEl(true, id, EDITING_ELEM_TYPES.EDGE);
    },
    edgeDesignSave({state, getters, dispatch}, {id, edgeData}) {
      // in case of settings values changed faster than debounce timeout reached
      // add changed property to design order object
      Object.assign(state.designOrder, edgeData);
      clearTimeout(state.edgeTimeout);
      state.edgeTimeout = setTimeout(async () => {
        const mapsURL = getters.getMapsURL;
        const edgesURL = getters.getEdgesURL;
        const mapId = getters.getCurrentMap.id;
        const clientFonts = getters.getClientFonts;

        const edgeDesignOriginal = {};
        for(let [key, value] of Object.entries(state.designOrder)){
          if(key === 'fontFamily') value = fontFamilyGetUrlByName(value, clientFonts);
          const edgeComputedData = await dispatch('edgeGetComputedData', {id, edgeData: {[key]: value}})
          Object.assign(edgeDesignOriginal, convertToOriginalEdgeProperty(edgeComputedData))
        }
        edgeData = Object.assign({}, state.designOrder);

        const data = {'design': edgeDesignOriginal};
        try {
          state.designOrder = {};
          await ApiService.patchRequest(`${mapsURL}${mapId}${edgesURL}${id}`, data);
          dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true}); // set updated date

          const editsData = {
            type: SE_SYNC_TYPES.EDGE_EDITED_DESIGN,
            data: {id, edgeData}
          }
          dispatch('simultaneousEditing/syncEdits', editsData, {root: true});

          await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgeDesignSuccess', {id}), dispatch);
        } catch (err) {
          state.designOrder = {};
          await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgeDesignError', {id}), dispatch);
        }

      }, 700)
    },
  }
}