import ApiService from "@/utils/ApiService";
import {SE_SYNC_TYPES} from "@/models/SE_SYNC_TYPES";
import {TOASTS_TYPES} from "@/models/TOASTS_TYPES";
import ToastUtils from "@/utils/ToastUtils";
const {createToastFromStore} = ToastUtils();
import EdgeUtils from '@/services/canvas/edges/EdgeUtils';
const {convertOriginalToEdgeProperty} = EdgeUtils();
const { getZoomLevelOptions } = MapUtils();
import VisibilityUtils from "@/services/canvas/utils/VisibilityUtils";
const {convertVisibilityToOriginalProperty} = VisibilityUtils();
import useMapArea from "@/services/canvas/map-area/MapArea";
import useI18nGlobal from "@/utils/i18n";
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";
import MapUtils from "@/services/canvas/map-area/MapUtils";
const {mapArea} = useMapArea();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
  namespaced: true,
  state:() => ({
    visibilityOrder: [],
    visibilityTimeout: null,
  }),
  getters: {
    getEdges(state, getters, rootState, rootGetters){
      return rootGetters["edges/getEdges"]
    },
    getEdgesOriginal(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesOriginal"];
    },
    getNodes(state, getters, rootState, rootGetters){
      return rootGetters["nodes/getNodes"];
    },
    getCurrentMap(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getCurrentMap"];
    },
    getMapsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getMapsURL"];
    },
    getElementsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getElementsURL"];
    },
  },
  mutations: {
    CHANGE_EDGE_VISIBILITY_BY_NODE(state, visibilityData) {
      const {edge, edgesOriginal, visibilityMin, visibilityMax} = visibilityData;
      edge.visibilityMin = visibilityMin;
      edge.visibilityMax = visibilityMax;
      mapArea.updateEl(true, edge.id, EDITING_ELEM_TYPES.EDGE);

      const edgeOriginal = edgesOriginal.find(edgeOriginal => edgeOriginal.id === edge.id);
      const visibilityOriginal = Object.assign(
        {},
        convertVisibilityToOriginalProperty({visibilityMin}),
        convertVisibilityToOriginalProperty({visibilityMax})
      )

      const result = {
        id: edge.id,
        visibility: {visibilityMin, visibilityMax}
      }
      Object.assign(edgeOriginal.visibility, visibilityOriginal);
      state.visibilityOrder.push(result);
    },
    UPDATE_EDGE_VISIBILITY(state, {id, visibility, edgesOriginal}) {
      const edge = edgesOriginal.find(edge => edge.id === id);
      Object.assign(edge.visibility, visibility);
    },
  },
  actions: {
    edgeChangeVisibilityByNode({state, getters, commit, dispatch}, data) {
      const edges = getters.getEdges;
      const edgesOriginal = getters.getEdgesOriginal;
      const nodes = getters.getNodes;
      const {id: nodeId, visibilityMin, visibilityMax} = data;
      const relatedEdges = edges.filter(edge => edge.fromNodeId === nodeId || edge.toNodeId === nodeId);
      const nodeVisibilityRange = getZoomLevelOptions(visibilityMax, visibilityMin);

      state.visibilityOrder = [];
      relatedEdges.forEach(edge => {
        const nodeRelated = nodes.filter(node => node.id !== nodeId && (edge.fromNodeId === node.id || edge.toNodeId === node.id))[0];
        const nodeRelatedVisibilityRange = getZoomLevelOptions(nodeRelated.visibilityMax, nodeRelated.visibilityMin);
        const levelsOverlapped = [];
        // to find common visibility levels of nodes which will be set to the edge
        nodeVisibilityRange.forEach(level => {
          if(nodeRelatedVisibilityRange.indexOf(level) > -1){
            levelsOverlapped.push(level);
          }
        });

        if(levelsOverlapped.length){
          commit('CHANGE_EDGE_VISIBILITY_BY_NODE',
            {
              edge, edgesOriginal,
              visibilityMin: levelsOverlapped[0],
              visibilityMax: levelsOverlapped[levelsOverlapped.length - 1]
            }
          );
        }
      })
    },

    edgeVisibilityUpdate({getters, commit, dispatch}, edgesData) {
      const edgesOriginal = getters.getEdgesOriginal;

      edgesData.forEach(edgeData => {
        const {id, visibility} = edgeData;
        const {visibilityMin, visibilityMax} = visibility;
        // convert properties to original ones
        const visibilityOriginal = {
          ...convertVisibilityToOriginalProperty({visibilityMin}),
          ...convertVisibilityToOriginalProperty({visibilityMax})
        };
        commit('UPDATE_EDGE_VISIBILITY', {id, visibility: visibilityOriginal, edgesOriginal});
        commit('edges/UPDATE_EDITED_EDGE', {edgeId: id, edgeData: visibility}, {root: true})
        mapArea.updateEl(true, id, EDITING_ELEM_TYPES.EDGE);
      })

      // set updated date of map changing
      dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});
    },

    edgeVisibilitySave({state, getters, dispatch}, edgesData) {
      if(!edgesData) edgesData = state.visibilityOrder;
      clearTimeout(state.visibilityTimeout);
      state.visibilityTimeout = setTimeout(async () => {
        const mapsURL = getters.getMapsURL;
        const elementsURL = getters.getElementsURL;
        const mapId = getters.getCurrentMap.id;
        const data = {edges: []};
        const syncData = {type: SE_SYNC_TYPES.EDGE_VISIBILITY_UPDATED, data: []}

        edgesData.forEach(edgeData => {
          const {id, visibility} = edgeData;
          const {visibilityMin, visibilityMax} = visibility;
          const visibilityOriginal = {
            ...convertVisibilityToOriginalProperty({visibilityMin}),
            ...convertVisibilityToOriginalProperty({visibilityMax})
          };
          data.edges.push({id, visibility: visibilityOriginal})
          syncData.data.push(edgeData);
        })

        // if(!Object.keys(visibility).length) return;
        if(!data.edges.length) return;

        try {
          state.visibilityOrder = [];
          await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, data);
          dispatch('simultaneousEditing/syncEdits', syncData, {root: true});

          await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgeVisibilitySuccess'), dispatch);
        } catch (err) {
          await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgeVisibilityError'), dispatch);
        }
      }, 700)
    },
  }
}