import ApiService from '@/utils/ApiService';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import ToastUtils from '@/utils/ToastUtils';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
const {createToastFromStore} = ToastUtils();
import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
const {t} = i18n.global;
import emitter from "@/utils/emitter";

// modules
import languages from '@/store/modules/map/language/languages';

export default {
	namespaced: true,
	modules: {
		languages
	},
	state: {
		mapsURL: '/maps/',
		tocURL: '/toc',
		mapPosterURL: '/poster',
		mapFaviconURL: '/favicon',
		mapsCloneURL: '/maps/clone',
		elementsURL: '/elements',
		publishURL: '/publish',
		resetURL: '/reset',
		changesURL: '/changes/',
		latestURL: 'latest',
		currentMap: null,
		mapTransfer: false,
		editorsNoteURL: '/editors_note',
		glossaryURL: '/glossary',
		referencesURL: '/references/',
		bibliographyURL: '/bibliography',
		socialSharingURL: '/social_sharing',
		cookieConsentURL: '/cookie_consent',
		seoDetailsURL: '/seo',
		urlDetailsURL: '/url',
		embedDetailsURL: '/embed',
		accessDetailsURL: '/access',
		headerDetailsURL: '/header',
		permissionsURL: '/permissions',
		editingPermissionsMapID: null,
		timezoneOn: false,
	},
	getters: {
		getMapsURL(state){
			return state.mapsURL
		},
		getTOCURL(state){
			return state.tocURL
		},
		getElementsURL(state){
			return state.elementsURL
		},
		getMapsCloneURL(state){
			return state.mapsCloneURL
		},
		getPublishURL(state){
			return state.publishURL;
		},
		getResetURL(state){
			return state.resetURL;
		},
		getChangesURL(state){
			return state.changesURL;
		},
		getLatestURL(state){
			return state.latestURL;
		},
		getCurrentMap(state){
			return state.currentMap
		},
		getCurrentMapId(state){
			return state.currentMap?.id
		},
		isMapTransfer(state){
			return state.mapTransfer;
		},
		getEditorsNoteURL(state){
			return state.editorsNoteURL
		},
		getGlossaryURL(state){
			return state.glossaryURL;
		},
		getReferencesURL(state){
			return state.referencesURL;
		},
		getBibliographyURL(state){
			return state.bibliographyURL;
		},
		getSocialSharingURL(state){
			return state.socialSharingURL
		},
		getCookieConsentURL(state){
			return state.cookieConsentURL
		},
		getSeoDetailsURL(state){
			return state.seoDetailsURL;
		},
		getUrlDetailsURL(state){
			return state.urlDetailsURL;
		},
		getEmbedDetailsURL(state){
			return state.embedDetailsURL;
		},
		getAccessDetailsURL(state){
			return state.accessDetailsURL;
		},
		getHeaderDetailsURL(state){
			return state.headerDetailsURL;
		},
		getPosterURL(state){
			return state.mapPosterURL;
		},
		getFaviconURL(state){
			return state.mapFaviconURL;
		},
		getPermissionsURL(state){
			return state.permissionsURL;
		},
		getEditingPermissionsMapID(state){
			return state.editingPermissionsMapID;
		},
		getTimezoneStatus(state){
			return state.timezoneOn
		}
	},
	mutations: {
		SAVE_MAP(state, map){
			state.currentMap = map;
		},
		UPDATE_MAP(state, mapData){
			Object.assign(state.currentMap, mapData);
		},
		TRANSFER_MAP_TOGGLE(state, status){
			state.mapTransfer = status;
		},
		SET_EDITING_PERMISSIONS_MAP_ID(state, mapID){
			state.editingPermissionsMapID = mapID;
		},
		SET_TIMEZONE_STATUS(state, status){
			state.timezoneOn = status;
		}
	},
	actions: {
		mapCurrentSave({commit}, map){
			commit("SAVE_MAP", map);
			commit("SET_TIMEZONE_STATUS", false);
		},
		mapCurrentSetUpdatedDate({commit}, date){
			const data = {updated_at: date}
			commit("UPDATE_MAP", data);
			commit("SET_TIMEZONE_STATUS", true);
		},
		async mapUpdate({getters, commit, dispatch}, mapData){
			const mapsURL = getters.getMapsURL;
			const mapId = getters.getCurrentMapId;
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}`, mapData);
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.mapSettingsSuccess'), dispatch);
				commit('UPDATE_MAP', mapData);
				
				const editsData = {
					type: SE_SYNC_TYPES.MAP_UPDATED,
					data: mapData
				}
				dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
				
				return Promise.resolve();
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.mapSettingsError'), dispatch);
				return Promise.reject(err);
			}
		},
		mapUpdateSE({commit}, mapData){
			commit('UPDATE_MAP', mapData);
		},
		mapResetSE({dispatch, commit}, data){
			dispatch("mapSettings/mapSettingsSave", data.map, {root: true});
			dispatch("design/elementsDesignSave", data.design, {root: true});
			dispatch("nodes/nodesOriginalSave", data.nodes, {root: true});
			dispatch("edges/edgesOriginalSave", data.edges, {root: true});
			dispatch("layers/layersInit", null, {root: true});
			
			dispatch('nodes/nodesPopulate', null, {root: true});
			dispatch('edges/edgesPopulate', null, {root: true});
			dispatch('nodes/nodesRelatedEdgesPopulate', null, {root: true});
			dispatch('templates/objectTemplatesClear', null, {root: true});
			dispatch("templates/objectTemplatesInit", null, {root: true});

			emitter.emit('mapReset');
		},
		mapTransfer({commit}, status){
			commit('TRANSFER_MAP_TOGGLE', status);
		},
		editingPermissionsMapIdSet({commit}, mapID){
			commit('SET_EDITING_PERMISSIONS_MAP_ID', mapID);
		}
	}
}
