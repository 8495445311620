export const Roles = {
	Superuser: "superuser",
	Admin: "admin",
	Editor: "editor",
	Student: "student",
	Guest: "guest",
	// Playgrounder: "playgrounder"
}
// Permissions are based on roles order
// so that it's important to place roles in this model in the right order
// from prior to minor
// this due to a fact that user can have multiple roles of different clients
