import useMapArea from '@/services/canvas/map-area/MapArea';
const {mapArea} = useMapArea();
export default function createImage(src, callback, anonymous, id){
	const image = new Image();
	image.onload = Event => {
		id = id !== null ? id : null;
		mapArea.updateEl(true, parseInt(id)); // TODO replace it
		if(callback) callback(id);
	}
	image.onerror = (error) => {
		console.log(error);
	}
	if(anonymous) image.crossOrigin = 'anonymous';
	// image.crossOrigin = 'anonymous';

	image.src = src;
	return image;
}
