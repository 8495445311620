import { createRouter, createWebHistory } from "vue-router";
import { computed } from "vue"
import store from "@/store/store";
import {Roles} from '@/models/Roles';
import PermissionService from '@/utils/PermissionService';
import useI18nGlobal from "@/utils/i18n";
import useUserLanguage from "@/utils/user/useUserLanguage";

export const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter: () => {
      const isAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
      const currentClientName = computed(() => store.getters["user/getClientName"]);
      const clientRoles = computed(() => store.getters["user/getUserRoles"]);
      
      if (isAuthenticated.value && currentClientName.value){
        Object.getOwnPropertyNames(clientRoles.value).length > 1
          ? router.push({ name: "clients-overview"})
          : router.push({name: 'map-overview', params: {clientName: currentClientName.value}})
      } else if(isAuthenticated.value && !currentClientName.value){
        router.push({ name: "clients-overview"})
      } else {
        router.push({name: 'login'})
      }
    }
  },
  {
    path: '/editor/:mapId',
    name: 'editor',
    props: true,
    meta: {permission: Roles.Student},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "entry" */ "@/pages/Editor")
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/Login")
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
      import(/* webpackChunkName: "reset-password" */ "@/pages/ResetPassword")
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "register-user" */ "@/pages/RegisterUser.vue")
  },
  {
    path: '/decline',
    name: 'decline',
    component: () =>
      import(/* webpackChunkName: "decline-invitation" */ "@/pages/DeclineInvitation.vue")
  },
  {
    path: '/clients',
    // meta: {permission: Roles.SuperUser},
    name: 'clients-overview',
    component: () =>
      import(/* webpackChunkName: "overview-clients" */ "@/pages/ClientsOverview")
  },
  {
    path: '/clients/:clientName',
    name: 'map-overview',
    props: true,
    component: () =>
      import(/* webpackChunkName: "map-overview" */ "@/pages/MapOverview")
  },
  { path: "/:pathMatch(.*)*", redirect: "login" }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const permission = to.meta?.permission || null;
  const isAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
  const userId = localStorage.getItem("KntxtUserId");
  // const userRole = localStorage.getItem("userRole");
  const userRole = computed(() => store.getters["user/getCurrentUserRole"]);
  const clientName = computed(() => store.getters["user/getClientName"]);
  
  // if not authenticated but has userId at storage
  if (!isAuthenticated.value && userId && to.name !== 'login' && to.name !== 'reset-password' && to.name !== 'register' && to.name !== 'decline') {
    // fetch user data
    try{
      const details = await store.dispatch("user/fetchUserDetail", userId);
      await store.dispatch("auth/setAuthentication", true);

      // get language settings
      const {getLanguageSetByCode} = useUserLanguage();

      if(!details.language) {
        const {getCurrentLang} = useI18nGlobal();
        await store.dispatch("user/userLanguageSet", getLanguageSetByCode(getCurrentLang()));
      }
      if(details.language) await store.dispatch("user/userLanguageSet", getLanguageSetByCode(details.language));

      // define user's roles if it's not
      if(!userRole.value){
        const clientRoles = details.client_roles;
        await store.dispatch("user/parseUserRoles", clientRoles);
        await store.dispatch("user/saveClientName", Object.getOwnPropertyNames(clientRoles)[0]);
        await store.dispatch("user/defineUserRole", clientName.value);
      }

      // check user's permission if page required it
      if(permission){
        const hasUserPermission = computed(() => PermissionService.permissionCheck({userRole: userRole.value, permission}))
        if(!hasUserPermission.value) {
          next({name: 'clients-overview'});
        } else {
          next();
        }
      } else next();
    } catch(err){
      await store.dispatch("auth/setAuthentication", false);
      next({name: 'login'});
    }
  } else if (!isAuthenticated.value && !userId && to.name !== 'login' && to.name !== 'reset-password' && to.name !== 'register' && to.name !== 'decline') {
    // otherwise, go to login page
    await store.dispatch("auth/setAuthentication", false);
    next({name: 'login'});
  } else {
    next()
  }
  
  // if not logged in && location to is not login or restore-password
  /*if (!isAuthenticated.value && permission) {
    if (PermissionService.permissionCheck({userRole, permission})) {
      console.log('Permission check');
      next();
    } else {
      console.log('Permission failed');
      next({ name: "login" });
    }
  }*/ /*else {
    next();
  }*/
});

export default {router, routes};
