export default {
  namespaced: true,
  state: {
    popupTourStatus: false,
  },
  getters: {
    isPopupTourActive(state) {
      return state.popupTourStatus;
    }
  },
  mutations: {
    TOGGLE_POPUP_TOUR(state, status){
      state.popupTourStatus = status;
    }
  },
  actions: {
    popupTourToggle({commit}, status){
      commit('TOGGLE_POPUP_TOUR', status);
    }
  },
}