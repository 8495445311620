import ApiService from '@/utils/ApiService';
// import RestrictionUtils from '@/utils/client/RestrictionUtils';
// const {getRestrictions} = RestrictionUtils();

// MODULES
import quotas from "@/store/modules/client/modules/quotas";

export default {
	namespaced: true,
	modules: {
		quotas
	},
	state: {
		clientsURL: '/clients/',
		clientMapsURL: '/maps',
		featuresURL: '/features',
		settingsURL: '/settings',
		logoURL: '/logo',
		analyticsURL: '/analytics',
		analyticsReportsURL: '/analytics/reports',
		invoicesURL: '/subscription/invoices',
		manageSubscriptionURL: '/subscription/portal',
		testingPeriodURL: '/testing_period',
		usersAccessURL: '/users_map_access',
		features: null,
		restrictions: null,
		testingPeriodDetails: null,
		testingBuyURL: null
	},
	getters: {
		getClientsURL(state){
			return state.clientsURL
		},
		getClientMapsURL(state){
			return state.clientMapsURL;
		},
		getClientFeaturesURL(state){
			return state.featuresURL;
		},
		getClientSettingsURL(state){
			return state.settingsURL
		},
		getClientLogoURL(state){
			return state.logoURL;
		},
		getClientAnalyticsURL(state){
			return state.analyticsURL;
		},
		getClientAnalyticsReportsURL(state){
			return state.analyticsReportsURL;
		},
		getInvoicesURL(state){
			return state.invoicesURL;
		},
		getManageSubscriptionURL(state){
			return state.manageSubscriptionURL;
		},
		getClientFeatures(state){
			return state.features;
		},
		getRestrictions(state){
			return state.restrictions;
		},
		getTestingPeriodDetails(state){
			return state.testingPeriodDetails;
		},
		getTestingPeriodURL(state){
			return state.testingPeriodURL;
		},
		getTestingBuyURL(state){
			return state.testingBuyURL;
		},
		getUsersAccessURL(state){
			return state.usersAccessURL;
		}
	},
	mutations: {
		SET_FEATURES(state, features){
			state.features = features;
		},
		SET_RESTRICTIONS(state, restrictions){
			state.restrictions = restrictions;
		},
		SET_TESTING_DETAILS(state, testingDetails){
			state.testingPeriodDetails = testingDetails;
		},
		SET_TESTING_BUY_URL(state, url){
			state.testingBuyURL = url;
		}
	},
	actions: {
		featuresSet({commit}, features){
			commit('SET_FEATURES', features);
		},
		restrictionsSet({commit}, data){
			commit('SET_RESTRICTIONS', data /*getRestrictions(data)*/);
		},
		testingBuyURLSet({commit}, url){
			commit('SET_TESTING_BUY_URL', url);
		},
		async fetchTestingDetails({getters, commit, dispatch}, clientSlug){
			const clientsURL = getters.getClientsURL;
			const testingPeriodURL = getters.getTestingPeriodURL;
			try{
				const {data} = await ApiService.getRequest(`${clientsURL}${clientSlug}${testingPeriodURL}`)
				commit('SET_TESTING_DETAILS', data);
				const {buy_urls, payment_interval} = data;
				const host = process.env.VUE_APP_ROOT_HOST;
				commit('SET_TESTING_BUY_URL', host + buy_urls[payment_interval])
			} catch(err){
				console.log(err);
			}
		},
		async fetchFeatures({getters, commit, dispatch}, clientSlug){
			const clientsURL = getters.getClientsURL;
			const featuresURL = getters.getClientFeaturesURL;
			try{
				const {data} = await ApiService.getRequest(`${clientsURL}${clientSlug}${featuresURL}?expand=fonts`)
				commit('SET_FEATURES', data);
				// dispatch('restrictionsSet', data);
				dispatch('fonts/fontsFetchedSave', data.fonts, {root: true})
				return Promise.resolve(data);
			} catch(err){
				return Promise.reject(null);
			}
		}
	}
}
