import ApiService from '@/utils/ApiService';
import ToastUtils from '@/utils/ToastUtils';
import {Roles} from '@/models/Roles';
import PermissionService from '@/utils/PermissionService';
import {ACCESS_LEVEL} from "@/models/user/ACCESS_LEVEL";
import {TOASTS_TYPES} from "@/models/TOASTS_TYPES";
import {checkDevice} from "@/utils/checkDevice";
const {isDeviceMobile} = checkDevice();
const {createToastFromStore} = ToastUtils();
import useI18nGlobal from '@/utils/i18n';
import {LANGUAGES} from "@/models/LANGUAGES";
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
	namespaced: true,
	state: {
		usersURL: '/users/',
		existURL: 'exists',
		userAvatarURL: '/avatar',
		loading: false,
		loadingError: false,
		id: null,
		email: null,
		name: null,
		avatar: null,
		role: null,
		userRoles: null,
		currentClientName: null,
		userDuplicated: false,
		deviceAccepted: true,
		deviceMobile: false,
		accessLevel: null,
		language: LANGUAGES.EN,
	},
	getters: {
		getUser(state){
			return {
				id: state.id,
				email: state.email,
				avatar: state.avatar,
				name: state.name,
				role: state.role,
			}
		},
		getUsersURL(state){
			return state.usersURL;
		},
		getUserExistURL(state){
			return state.existURL;
		},
		getUserAvatarURL(state){
			return state.userAvatarURL;
		},
		getClientName(state){
			return state.currentClientName;
		},
		getUserRoles(state){
			return state.userRoles;
		},
		getCurrentUserRole(state){
			return state.role
		},
		hasAccessToClients(state){
			return state.role === Roles.Superuser || Object.getOwnPropertyNames(state.userRoles || {})?.length > 1 || false
		},
		getUserDuplicated(state){
			return state.userDuplicated;
		},
		isDeviceAccepted(state){
			return state.deviceAccepted;
		},
		isDeviceMobile(state){
			return state.deviceMobile;
		},
		getMapURL(state, getters, rootState, rootGetters){
			return rootGetters["maps/getMapsURL"];
		},
		getPermissionsURL(state, getters, rootState, rootGetters) {
			return rootGetters["maps/getPermissionsURL"];
		},
		getUserAccessLevel(state){
			return state.accessLevel;
		},
		getUserLanguage(state){
			return state.language;
		}
	},
	mutations: {
		USER_DETAIL_LOADING(state, status){
			state.loading = status;
		},
		USER_DETAIL_LOADING_ERROR(state, status){
			state.loadingError = status;
		},
		USER_SAVE(state, {id, email, name, avatar}){
			state.id = id;
			state.email = email;
			state.name = name;
			state.avatar = avatar || null;
			localStorage.setItem("KntxtUserId", id);
		},
		USER_RESET(state){
			state.id = null;
			state.email = null;
			state.name = null;
			state.avatar = null;
			state.role = null;
			state.userRoles = null;
			localStorage.removeItem("KntxtUserId");
		},
		USER_GET_FROM_STORAGE(state){
			state.id = localStorage.getItem("KntxtUserId") || null;
		},
		SAVE_NAME(state, userName){
			state.name = userName;
		},
		SAVE_EMAIL(state, userEmail){
			state.email = userEmail;
		},
		SET_ROLE(state, role){
			state.role = role;
		},
		FIND_USER_ROLE(state, client){
			if(state.role !== Roles.Superuser){
				state.role = state.userRoles[client]
			}
		},
		PARSE_ROLES(state, clients){
			const userRoles = {};
			for( const [key, value] of Object.entries(clients)){
				const client = key.split('/')[key.split('/').length - 1];
				const roles = value.map(role => role.split('/')[role.split('/').length - 1]);
				Object.assign(userRoles, {[client]: PermissionService.getPriorityRole(roles)} )
			}
			state.userRoles = userRoles;
		},
		PARSE_CLIENT(state, client){
			// client format is "/api/v1/clients/clientName"
			state.currentClientName = client ? client.split('/')[client.split('/').length - 1] : client;
		},
		UPDATE_USER_AVATAR(state, avatar){
			state.avatar = avatar;
		},
		SET_USER_DUPLICATED(state, status){
			state.userDuplicated = status;
		},
		CHECK_USER_DEVICE(state){
			// state.deviceMobile = isDeviceMobile();
			state.deviceAccepted = window.matchMedia("only screen and (min-width: 900px)").matches;
		},
		SET_USER_ACCESS_LEVEL(state, status){
			state.accessLevel = status;
		},
		SET_USER_LANGUAGE(state, language){
			state.language = language;
		}
	},
	actions: {
		saveUser({commit}, user){
			commit("USER_SAVE", user);
		},
		saveUserName({commit}, userName){
			commit("SAVE_NAME", userName);
		},
		saveUserEmail({commit}, userEmail){
			commit("SAVE_EMAIL", userEmail);
		},
		removeUser({commit}){
			commit("USER_RESET");
		},
		setUserRole({commit}, role){
			commit("SET_ROLE", role);
		},
		defineUserRole({commit}, client){
			commit("FIND_USER_ROLE", client);
		},
		parseUserRoles({commit, dispatch}, roles){
			commit("PARSE_ROLES", roles);
		},
		saveClientName({commit}, client){
			commit("PARSE_CLIENT", client);
		},
		async fetchUserDetail({state, commit, dispatch}, userId){
			try{
				const { data } = await ApiService.getRequest(`${state.usersURL}${userId}`)
				const {id, email, name, avatar_url: avatar, client_roles: roles, is_superuser: superuser} = data;
				if(superuser){
					commit("SET_ROLE", Roles.Superuser)
				}
				commit("USER_SAVE", {id, email, name, avatar});
				commit("PARSE_ROLES", roles);
				return Promise.resolve(data);
			} catch (err) {
				return Promise.reject(err.response?.data?.detail || err.response);
			}
		},
		async fetchUserPermissions({getters, commit}, mapID){
			const mapURL = getters.getMapURL;
			const permissionsURL = getters.getPermissionsURL;
			try{
				const {data: {users}} = await ApiService.getRequest(`${mapURL}${mapID}${permissionsURL}`);
				return Promise.resolve(users);
			} catch(error){
				return Promise.reject(error);
			}

		},
		async saveUserAccessPermission({getters, commit, dispatch}, {mapID, userID}){
			const userCurrent = getters.getUser;
			if(userCurrent.role === Roles.Superuser) {
				commit('SET_USER_ACCESS_LEVEL', ACCESS_LEVEL.EDIT);
				return;
			}
			const permissions = await dispatch('fetchUserPermissions', mapID);

			for(let [id, permission] of Object.entries(permissions)){
				const parsedUserID = parseInt(id.split('/')[id.split('/').length - 1]);
				if(parsedUserID === userID){
					commit('SET_USER_ACCESS_LEVEL', permission);
				}
			}

		},
		checkDeletedMedia({getters, commit}, mediaKey){
			const user = getters.getUser;
			if(user.avatar?.includes(mediaKey)){
				commit('UPDATE_USER_AVATAR', null);
			}
		},
		userDuplicatedSet({commit}, status){
			commit('SET_USER_DUPLICATED', status);
		},
		userDeviceCheck({commit}){
			commit('CHECK_USER_DEVICE');
		},
		userLanguageSet({commit}, language){
			commit('SET_USER_LANGUAGE', language);
		},
		async userLanguageSetRequest({getters, dispatch}, code){
			const usersURL = getters.getUsersURL;
			const userID = getters.getUser.id;

			try{
				const {data} = await ApiService.patchRequest(`${usersURL}${userID}`, {language: code});
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.userLanguageSuccess'), dispatch);
				return Promise.resolve(data);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.userLanguageError'), dispatch);
				return Promise.reject(err.response?.data?.detail || err.response);
			}

		},
		userAccessLevelSet({commit}, status){
			commit('SET_USER_ACCESS_LEVEL', status);
		}
	}
}
