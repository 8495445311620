import ApiService from "@/utils/ApiService";
import {SE_SYNC_TYPES} from "@/models/SE_SYNC_TYPES";
import {TOASTS_TYPES} from "@/models/TOASTS_TYPES";
import ToastUtils from "@/utils/ToastUtils";
import useI18nGlobal from "@/utils/i18n";
import useMapArea from "@/services/canvas/map-area/MapArea";
const {createToastFromStore} = ToastUtils();
const {mapArea} = useMapArea();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
  namespaced: true,
  state: {
    savePositionTimeout: null,
    nodesPositionOrder: [],
  },
  getters: {
    getNodesOriginal(state, getters, rootState, rootGetters){
      return rootGetters['nodes/getNodesOriginal'];
    },
    getNodes(state, getters, rootState, rootGetters){
      return rootGetters['nodes/getNodes'];
    },
    getCurrentMap(state, getters, rootState, rootGetters) {
      return rootGetters['maps/getCurrentMap']
    },
    getMapsURL(state, getters, rootState, rootGetters){
      return rootGetters['maps/getMapsURL']
    },
    getElementsURL(state, getters, rootState, rootGetters) {
      return rootGetters['maps/getElementsURL']
    },
  },
  mutations: {
    UPDATE_NODE_ORIGINAL_GEOMETRY(state, nodeData){
      const {id, geometry, nodesOriginal} = nodeData;
      const nodeOriginal = nodesOriginal.find(node => node.id === parseInt(id));
      Object.assign(nodeOriginal.geometry, geometry);
    },
  },
  actions: {
    nodeSavePosition({state, getters, commit, dispatch}, nodeId){
      // pause saving coordinates in case of another node was being moved immediately
      if(nodeId === null) {
        clearTimeout(state.savePositionTimeout);
        return;
      }
      // add nodeId to saving data if it's not a trigger to save from edit node settings
      // otherwise that means this nodeId already included
      if(nodeId !== 'save' && !state.nodesPositionOrder.includes(nodeId)) state.nodesPositionOrder.push(nodeId);
      if(!state.nodesPositionOrder.length) return;
      // clearTimeout(state.savePositionTimeout);
      state.savePositionTimeout = setTimeout(async () => {
        const mapsURL = getters.getMapsURL;
        const elementsURL = getters.getElementsURL;
        const mapId = getters.getCurrentMap.id;
        const nodesOriginal = getters.getNodesOriginal;
        const nodes = getters.getNodes;
        const data = {'nodes': []};
        state.nodesPositionOrder.forEach(nodeIdToSave => {
          const node = nodes.find(node => node.id === nodeIdToSave);
          const model = {
            'id': nodeIdToSave,
            'geometry': {
              'center': {
                'x': node.x,
                'y': node.y
              }
            }
          };
          data.nodes.push(model);
        })

        if(!data.nodes.length) return;
        try{
          clearTimeout(state.savePositionTimeout);
          state.nodesPositionOrder = [];
          await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, data);
          dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true}); // set updated date
          data.nodes.forEach(nodeData => {
            commit('UPDATE_NODE_ORIGINAL_GEOMETRY', {...nodeData, nodesOriginal});
          })
          const editsData = {
            type: SE_SYNC_TYPES.NODE_POSITION,
            data: data.nodes
          }
          dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
          const message = data.nodes.length > 1 ? t('message.nodesPositionsSuccess') : t('message.nodePositionSuccess');
          await createToastFromStore(TOASTS_TYPES.SUCCESS, message, dispatch);

          // clearTimeout(state.savePositionTimeout);
          // state.nodesPositionOrder = [];
          data.nodes = [];
        } catch(err){
          clearTimeout(state.savePositionTimeout);
          const message = state.nodesPositionOrder.length > 1 ? t('message.nodesPositionsError') : t('message.nodePositionError');
          await createToastFromStore(TOASTS_TYPES.ERROR, message, dispatch);
          state.nodesPositionOrder = [];
          data.nodes = [];
        }

      }, 100) // 700
    },
    nodeUpdatePositionSE({getters, commit, dispatch}, nodesData){
      const nodesOriginal = getters.getNodesOriginal;
      const nodes = getters.getNodes;
      nodesData.forEach(data => {
        const id = data.id;
        const x = data.geometry.center.x;
        const y = data.geometry.center.y;

        const node = nodes.find(node => node.id === parseInt(id));
        node.x = x;
        node.y = y;

        commit('UPDATE_NODE_ORIGINAL_GEOMETRY', {...data, nodesOriginal});

        if(node.edgesFrom.length){
          node.edgesFrom.forEach(edge => {
            const updatedData = {
              edgeId: edge,
              startPosX: node.x,
              startPosY: node.y,
            }
            dispatch('edges/edgeUpdate', updatedData, {root: true});
          })
        }
        if(node.edgesTo.length){
          node.edgesTo.forEach(edge => {
            const updatedData = {
              edgeId: edge,
              endPosX: node.x,
              endPosY: node.y,
            }
            dispatch('edges/edgeUpdate', updatedData, {root: true});
          })
        }
      })
      mapArea.updateEl(); // update canvas
    },
  }
}