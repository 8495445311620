import ApiService from '@/utils/ApiService';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import ToastUtils from '@/utils/ToastUtils';
import useI18nGlobal from '@/utils/i18n';
import useMapArea from '@/services/canvas/map-area/MapArea';
const {mapArea} = useMapArea();
const {createToastFromStore} = ToastUtils();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;
function checkSiblingsIsoValue(langObj, languagesISO, key){
	return languagesISO.map(id => langObj[id][key]).some(value => value)
}

export default {
	namespaced: true,
	state: {
		saveContentTimeout: null,
		nodesContentOrder: {},
		QEBContentTrackChanges: false,
		QEBContentWarning: false,
	},
	getters: {
		getNodesOriginal(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesOriginal'];
		},
		getCurrentMap(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getCurrentMap']
		},
		getMapsURL(state, getters, rootState, rootGetters){
			return rootGetters['maps/getMapsURL']
		},
		getElementsURL(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getElementsURL']
		},
		getQEBContentTrackedChanges(state){
			return state.QEBContentTrackChanges
		},
		isQEBContentWarningActive(state){
			return state.QEBContentWarning;
		},
		getLanguage(state, getters, rootState, rootGetters) {
			return rootGetters["maps/languages/getLanguage"]
		},
		getLanguageLocal(state, getters, rootState, rootGetters){
			return rootGetters["maps/languages/getLanguageLocal"]
		}
	},
	mutations: {
		UPDATE_NODE_ORIGINAL_CONTENT(state, {id, content, nodesOriginal, dispatch}){
			const nodeOriginal = nodesOriginal.find(node => node.id === parseInt(id));
			if(!nodeOriginal) {
				createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeContentError', {id}), dispatch);
				return;
			}
			const dataCloned = JSON.parse(JSON.stringify(content));
			const result = {};
			// remove id property if exists since it's not a part of content object
			delete dataCloned.id;
			// merge objects deeply
			for(const key of Object.keys(content)){
				if(key === 'lang'){
					const languagesISO = Object.keys(nodeOriginal.content.lang);
					result.lang = {};
					languagesISO.forEach(langISO => {
						if(dataCloned.lang[langISO]) result.lang[langISO] = Object.assign({}, nodeOriginal.content.lang[langISO], dataCloned.lang[langISO]);
						if(!dataCloned.lang[langISO]) result.lang[langISO] = {...nodeOriginal.content.lang[langISO]};
					});
				} else {
					if(typeof dataCloned[key] === 'string') result[key] = dataCloned[key];
					if(typeof dataCloned[key] === 'object') result[key] = Object.assign({}, nodeOriginal.content[key], dataCloned[key]);
				}
			}
			Object.assign(nodeOriginal.content, result);
		},
		UPDATE_QEB_CONTENT_TRACK(state, status){
			state.QEBContentTrackChanges = status;
		},
		UPDATE_QEB_WARNING_STATUS(state, status){
			state.QEBContentWarning = status;
		}
	},
	actions: {
		nodeUpdateContent({getters, commit, dispatch}, {id, content}){
			const nodesOriginal = getters.getNodesOriginal;
			const currentLang = getters.getLanguage.id;
			const currentLocalLang = getters.getLanguageLocal.id;
			const LINK = 'node_link_to';
			const CONTENT = 'content';
			const MARGINAL1 = 'marginal_content_1';
			const MARGINAL2 = 'marginal_content_2';
			/**
			 * content can be changed in qeb where only default language is changing and
			 * in content form where all language sets are available for change;
			 * thus the `content` parameter contains lang object with changed languages
			 * but node class should be updated only with current language
			 * content destructuring:
			 * @param {object} lang - object with all parameters available for translation
			 * @param {object} navigation - two properties: prev and next, not impact on editor
			 * @param {string} popup_type - not impact on editor
			 * */
			for(const keyContent of Object.keys(content)){
				if(keyContent === 'lang'){
					// check if content contains title property
					if(content.lang[currentLocalLang] && Object.hasOwn(content.lang[currentLocalLang], 'title')){
						commit('nodes/UPDATE_NODE', {id, nodeData: {title: content.lang[currentLocalLang].title}}, {root: true});
					}
					/**
					 * LINK used as an anchor to detect there are more data than just a title
					 * */
					if(content.lang[currentLang] && Object.hasOwn(content.lang[currentLang], LINK)){
						const languagesISO = Object.keys(content.lang);
						let nodeData = null;
						const linkData = checkSiblingsIsoValue(content.lang, languagesISO, LINK);
						const contentData = checkSiblingsIsoValue(content.lang, languagesISO, CONTENT) ||
							checkSiblingsIsoValue(content.lang, languagesISO, MARGINAL1) ||
							checkSiblingsIsoValue(content.lang, languagesISO, MARGINAL2);
						if(linkData) nodeData = {contentBlocked: true, contentLinkBlocked: false};
						if(!linkData && contentData) nodeData = {contentBlocked: false, contentLinkBlocked: true};
						if(!linkData && !contentData) nodeData = {contentBlocked: false, contentLinkBlocked: false};
						commit('nodes/UPDATE_NODE', {id, nodeData}, {root: true});
					}
				}
			}
			commit('UPDATE_NODE_ORIGINAL_CONTENT', {id, content, nodesOriginal, dispatch});
			// set updated date to highlight unpublished changes
			dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});
			// update canvas
			mapArea.updateEl(true, parseInt(id));
		},
		async nodeSaveContent({state, getters, dispatch}, {id, content}){
			const mapsURL = getters.getMapsURL;
			const elementsURL = getters.getElementsURL;
			const mapId = getters.getCurrentMap?.id;

			Object.assign(state.nodesContentOrder, {[id]: content});
			clearTimeout(state.saveContentTimeout);
			return new Promise((resolve, reject) => {
				state.saveContentTimeout = setTimeout(async () => {
					const data = {nodes: []};
					for(const [id, contentData] of Object.entries(state.nodesContentOrder)){
						data.nodes.push({id, content: contentData})
					}
					try{
						const {data: elements} = await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, data);
						const updatedNode = elements.nodes.find(node => node.id === parseInt(id));

						state.nodesContentOrder = {}; // clear content order
						const editsData = {
							type: SE_SYNC_TYPES.NODE_EDITED_CONTENT,
							data: data.nodes[0]
						}

						dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
						resolve(updatedNode);
						mapArea.updateElement = true; // update canvas
						await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeContentSuccess', {id}), dispatch);
						clearTimeout(state.saveContentTimeout);
					} catch(err){
						reject();
						await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeContentError', {id}), dispatch);
						clearTimeout(state.saveContentTimeout);
					}
				}, 700)
			})
		},
		QEBContentTrackUpdate({commit}, status){
			commit('UPDATE_QEB_CONTENT_TRACK', status)
		},
		QEBContentWarningStatusUpdate({commit}, status){
			commit('UPDATE_QEB_WARNING_STATUS', status);
		}
	}
}
