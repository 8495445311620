export default {
	namespaced: true,
	state: {
		opened: false,
	},
	getters: {
		isOpened(state){
			return state.opened;
		}
	},
	mutations: {
		OPEN_SIDEBAR(state){
			state.opened = true;
		},
		CLOSE_SIDEBAR(state){
			state.opened = false;
		}
	},
	actions: {
		sidebarOpen({commit}){
			commit("OPEN_SIDEBAR");
		},
		sidebarClose({commit}){
			commit("CLOSE_SIDEBAR");
		}
	}
}
