import { DASHBOARD_ACTIONS} from '@/models/DASHBOARD_ACTIONS';
import {EDITING_ELEM_TYPES} from '@/models/EDITING_ELEM_TYPES';
import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
const { t } = i18n.global;

export const DASHBOARD_OPTIONS = {
	CREATE: {
		VALUE: "CREATE",
		GROUPS: {
			DEFAULT: {
				ADD_NODE: { VALUE: "ADD_NODE", get TITLE() {return t('sidebar.title.nodeDesign')}, ACTION: DASHBOARD_ACTIONS.ADD_NODE},
				TEXT_ON_MAP: { VALUE: "TEXT_ON_MAP", get TITLE() {return t('sidebar.title.textDesign')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR},
				EDGE_CONNECTION: { VALUE: "EDGE_CONNECTION", ACTION: DASHBOARD_ACTIONS.EDGE_CONNECTION},
			},
			NODE: {
				NODE_DESIGN: { VALUE: "NODE_DESIGN", get TITLE(){return t('sidebar.title.nodeDesign')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR},
				CONTENT_FORM: { VALUE: "CONTENT_FORM", ACTION: DASHBOARD_ACTIONS.POPUP},
				MANAGE_VISIBILITY_NODE: { VALUE: "MANAGE_VISIBILITY_NODE", get TITLE(){return t('sidebar.title.nodeVisibility')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR},
			},
			EDGE: {
				EDGE_DESIGN: { VALUE: "EDGE_DESIGN", get TITLE(){return t('sidebar.title.edgeDesign')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR},
				MANAGE_VISIBILITY_EDGE: { VALUE: "MANAGE_VISIBILITY_EDGE", get TITLE(){return t('sidebar.title.edgeVisibility')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR},
			},
			OBJECT_TEMPLATE: {
				SHAPE: {VALUE: "SHAPE_OBJECT_TEMPLATE", get TITLE() {return t('sidebar.title.objectTemplates')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR},
				EDGE: {VALUE: "EDGE_OBJECT_TEMPLATE", get TITLE() {return t('sidebar.title.objectTemplates')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR},
			},
			1: {
				GLOBAL_SETTINGS: { VALUE: "GLOBAL_SETTINGS", get TITLE() {return t('sidebar.title.globalDesign')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR, BLOCKED_TYPE: EDITING_ELEM_TYPES.GLOBAL},
				MANAGE_TEMPLATES: { VALUE: "MANAGE_TEMPLATES", get TITLE() {return t('sidebar.title.objectTemplates')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR}
			},
			2: {
				MAP_SETTINGS: { VALUE: "MAP_SETTINGS", get TITLE() {return t('sidebar.title.mapSettings')}, ACTION: DASHBOARD_ACTIONS.SIDEBAR, BLOCKED_TYPE: EDITING_ELEM_TYPES.MAP}
			}
		}
	},
	ORGANIZE: {
		VALUE: "ORGANIZE",
		GROUPS: {
			0: {
				CONTENT_FORM: { VALUE: "CONTENT_FORM", ACTION: DASHBOARD_ACTIONS.POPUP},
				EDITOR_NOTE: { VALUE: "EDITOR_NOTE", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.EDITORS_NOTE},
				GLOSSARY: { VALUE: "GLOSSARY", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.GLOSSARY},
				SOURCES: { VALUE: "SOURCES", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.SOURCES},
				HEADER_BAR: { VALUE: "HEADER_BAR", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.HEADER_BAR},
				QUIZ: { VALUE: 'QUIZ', ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.QUIZ},
				QUIZ_FORM: {VALUE: 'QUIZ_FORM', ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.QUIZ},
				TABLE_OF_CONTENT: { VALUE: "TABLE_OF_CONTENT", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.TABLE_OF_CONTENT},
			},
			1: {
				MEDIA_TEMPLATES: { VALUE: "MEDIA_TEMPLATES", ACTION: DASHBOARD_ACTIONS.POPUP},
				MANAGE_ASSETS: { VALUE: "MEDIA_ASSETS", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.MEDIA_ASSETS}
			}
		}
	},
	MANAGE: {
		VALUE: "MANAGE",
		GROUPS: {
			0: {
				SOCIAL_NETWORKS: { VALUE: "SOCIAL_NETWORKS", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.SOCIAL_SHARING},
				COOKIE_CONSENT: { VALUE: "COOKIE_CONSENT", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES. COOKIE_CONSENT},
				SEO_SETTINGS: { VALUE: "SEO", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.SEO}
			},
			1: {
				MAP_URL: { VALUE: "MAP_URL", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.MAP_URL},
				MAP_ACCESS: { VALUE: "MAP_ACCESS", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.MAP_ACCESS},
				EMBED_CODE: { VALUE: "EMBED_CODE", ACTION: DASHBOARD_ACTIONS.POPUP, BLOCKED_TYPE: EDITING_ELEM_TYPES.EMBED}
			}
		}
	},
}
