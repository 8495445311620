import {NODE_SHAPES} from "@/models/NODE_SHAPES";
import {EDGE_LINES} from "@/models/EDGE_LINES";
import {TEXT_POSITION} from "@/models/TEXT_POSITION";
import CanvasTxt from "@/services/canvas/text/CanvasText";

export default function (){

  function calculateBoundsForEdge(edge){
    const bounds = {width: null, height: null};

    const {width: edgeWidth, height: edgeHeight, x: edgeX, y: edgeY} = edge.name ? _getEdgeTemplateBounds(edge) : _getEdgeBounds(edge);
    const {startArrowWidth, startArrowHeight, endArrowWidth, endArrowHeight} = _getArrowsBounds(edge);
    const {x: shadowX, y: shadowY, blurWidth: shadowBlurWidth} = _getShadowBounds(edge);
    const {y: titleY, height: titleHeight, padding: titlePadding} = _getTitleBounds(edge);

    /**
     * because these calculations happens before canvas has width/height
     * edge top left point is always {0, 0}
     * features impacts on canvas dimensions like shadow, title or image
     * split up to the parts located before/above and after/below the edge itself
     * to find the biggest ones
     **/

      // SHADOW COMPUTED
    const shadowWidthLeft = shadowX < 0 ? shadowX : 0;
    const shadowWidthRight = shadowX < 0 ? shadowBlurWidth * 2 - Math.abs(shadowX) : shadowBlurWidth * 2 + shadowX;
    const shadowHeightTop = shadowY < 0 ? shadowY : 0;
    const shadowHeightBottom = shadowY < 0 ? shadowBlurWidth * 2 - Math.abs(shadowY) : shadowBlurWidth * 2 + shadowY;

    // TITLE COMPUTED
    const titleHeightTop = titleY < 0 ? titleY : 0;
    const titleHeightBottom =
      titleY > 0 ?
        titleHeight + titlePadding :
        Math.abs(titleY) === titleHeight / 2 ?
          titleHeight / 2 :
          0 ;

    // ARROWS COMPUTED
    const arrowHeightTopBottom = Math.max(startArrowHeight, endArrowHeight) / 2;

    // WIDTH && HEIGHT COMPUTED
    const leftPart = Math.abs(Math.min(0, shadowWidthLeft));
    const rightPart = Math.abs(Math.max(0, shadowWidthRight));
    const topPart = Math.abs(Math.min(0, shadowHeightTop, titleHeightTop, -arrowHeightTopBottom));
    const bottomPart = Math.abs(Math.max(0, shadowHeightBottom, titleHeightBottom, arrowHeightTopBottom));

    // RESULT
    bounds.width = edgeWidth + leftPart + rightPart;
    bounds.height = edgeHeight + topPart + bottomPart;

    /**
     * as a temporary solution until shadow position wouldn't be making dynamic
     * boundsHeight should be increased here depends on its height and position if
     * fromNodeX is bigger than toNodeX coords
     * getEdgeCoordinates should be changed in similar way
     * */

    return bounds;
  }

  function _getEdgeTemplateBounds(edge){
    /**
     * calculate edge bounds including arrows;
     * The edge bounds are considering as a "material" edge
     * that is a border (line) and the arrows, if existed. No shadows or title is included.
     * {x, y} of material edge is always {0, 0}
     * */
    return edge.edgeBounds = {width: edge._BASE_CANVAS_SIZE, height: edge.borderWidth, x: 0, y: 0};
  }

  function _getEdgeBounds(edge){
    const edgeComputedHeight = edge.editing ? edge.borderWidth + edge._HOVER_AREA : edge.borderWidth;
    const edgeBounds = {width: 0, height: edgeComputedHeight, x: 0, y: 0};

    const fromPoint = _getFromPoint(edge);
    const toPoint = _getToPoint(edge);

    edgeBounds.width = _getLineLength(fromPoint.x, toPoint.x, fromPoint.y, toPoint.y);

    return edge.edgeBounds = edgeBounds;
  }

  // POINT
  function _getFromPoint(edge){
    const {x, y} = _getPoint({edge, shape: edge.fromNodeShape, x: edge.startPosX, y: edge.startPosY, connected: 'from'});
    return edge.fromPoint = {x, y};
  }
  function _getToPoint(edge){
    const {x, y} = _getPoint({edge, shape: edge.toNodeShape, x: edge.endPosX, y: edge.endPosY, connected: 'to'});
    return edge.toPoint = {x, y};
  }
  function _getPoint({edge, shape, x, y, connected}){
    // connected either 'from' or 'to'
    // depends on the shape coordinates are different
    const point = {x: 0, y: 0};
    switch (shape){
      case(NODE_SHAPES.CIRCLE.VALUE): {
        const {
          fromNodeDistanceX,
          fromNodeDistanceY,
          toNodeDistanceX,
          toNodeDistanceY
        } = _getEdgeBorders(edge, edge.fromNodeHeight + edge.fromNodeBorderWidth * 2, edge.toNodeHeight + edge.toNodeBorderWidth * 2);
        if(connected === 'from'){
          point.x = x + fromNodeDistanceX;
          point.y = y + fromNodeDistanceY
        }
        if(connected === 'to'){
          point.x = x + toNodeDistanceX;
          point.y = y + toNodeDistanceY;
        }
        break;
      }
      case(NODE_SHAPES.RECTANGLE.VALUE): {
        // check if edge's line and any of the rectangle's sides are colliding
        // since it can be only one point of intersections let's parse rect sides
        // to be able to loop it
        const line1 = {x1: edge.startPosX, y1: edge.startPosY, x2: edge.endPosX, y2: edge.endPosY};
        const rectSides = [];

        const rectWidth =
          connected === 'from' ?
            edge.fromNodeWidth + edge.fromNodeBorderWidthValue * 2 :
            edge.toNodeWidth + edge.toNodeBorderWidthValue * 2;
        const rectHeight =
          connected === 'from' ?
            edge.fromNodeHeight + edge.fromNodeBorderWidthValue * 2 :
            edge.toNodeHeight + edge.toNodeBorderWidthValue * 2;
        const rectX =
          connected === 'from' ?
            edge.startPosX - edge.fromNodeWidth / 2 - edge.fromNodeBorderWidthValue :
            edge.endPosX - edge.toNodeWidth / 2 - edge.toNodeBorderWidthValue;
        const rectY =
          connected === 'from' ?
            edge.startPosY - edge.fromNodeHeight / 2 - edge.fromNodeBorderWidthValue :
            edge.endPosY - edge.toNodeHeight / 2 - edge.toNodeBorderWidthValue;

        const rectTop = {x3: rectX, y3: rectY, x4: rectX + rectWidth, y4: rectY};
        const rectRight = {x3: rectX + rectWidth, y3: rectY, x4: rectX + rectWidth, y4: rectY + rectHeight}
        const rectBottom = {x3: rectX + rectWidth, y3: rectY + rectHeight, x4: rectX, y4: rectY + rectHeight}
        const rectLeft = {x3: rectX, y3: rectY + rectHeight, x4: rectX, y4: rectY}


        let intersection = null;
        rectSides.push(...[rectTop, rectRight, rectBottom, rectLeft])
        for(const side of rectSides){
          intersection = _getLinesIntersectionCoords({line1, line2: side});
          if(intersection) {
            point.x = intersection.x;
            point.y = intersection.y;
            return point;
          }
        }

        break;
      }
    }
    return point;
  }
  function _getEdgeBorders(edge, fromNodeHeight, toNodeHeight){
    const edgeAngleFromNode =	Math.atan2((edge.endPosY - edge.startPosY), (edge.endPosX - edge.startPosX));
    const edgeAngleToNode = Math.atan2((edge.startPosY - edge.endPosY), (edge.startPosX - edge.endPosX));
    const fromNodeDistanceY = Math.sin(edgeAngleFromNode) * fromNodeHeight / 2;
    const fromNodeDistanceX = Math.cos(edgeAngleFromNode) * fromNodeHeight / 2;
    const toNodeDistanceX = Math.cos(edgeAngleToNode) * toNodeHeight / 2;
    const toNodeDistanceY = Math.sin(edgeAngleToNode) * toNodeHeight / 2;

    return {
      fromNodeDistanceX,
      fromNodeDistanceY,
      toNodeDistanceX,
      toNodeDistanceY
    }
  }
  function _getLinesIntersectionCoords({line1, line2}){
    const {x1, y1, x2, y2} = line1;
    const {x3, y3, x4, y4} = line2;
    // 1 - 2 belongs to line and 3 - 4 to rect

    // calculate the direction of the lines
    const uA = ((x4-x3)*(y1-y3) - (y4-y3)*(x1-x3)) / ((y4-y3)*(x2-x1) - (x4-x3)*(y2-y1));
    const uB = ((x2-x1)*(y1-y3) - (y2-y1)*(x1-x3)) / ((y4-y3)*(x2-x1) - (x4-x3)*(y2-y1));

    // if uA and uB are between 0-1, lines are colliding
    if (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1) {

      // optionally, draw a circle where the lines meet
      const intersectionX = x1 + (uA * (x2-x1));
      const intersectionY = y1 + (uA * (y2-y1));
      return {x: intersectionX, y: intersectionY};
    }
    return null;
  }

  function _getLineLength(x1, x2, y1, y2){
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }


  function _getArrowsBounds(edge){
    const arrowsBounds = {
      startArrowWidth: 0, startArrowHeight: 0, startLabelPadding: 0,
      endArrowWidth: 0, endArrowHeight: 0, endLabelPadding: 0
    };
    const _arrowHeight = edge._ARROW_HEIGHT * edge.borderWidth; // borderWidth - 1 add linear increasing of arrow size depends on edge width
    const _dotRadius = edge._ARROW_DOT_RADIUS * edge.borderWidth; // borderWidth / 2 doing the same for dot arrow
    const fromSizeMultiplier = parseInt(edge.lineStartSize) ? edge.lineStartSize / 100 : 1;
    const toSizeMultiplier =  parseInt(edge.lineEndSize) ? edge.lineEndSize / 100 : 1;

    if(edge.lineStart !== EDGE_LINES.NONE.VALUE){
      if(edge.lineStart === EDGE_LINES.DOT.VALUE){
        const _arrowWidthHeight = _dotRadius * fromSizeMultiplier;
        arrowsBounds.startArrowWidth = arrowsBounds.startArrowHeight = _arrowWidthHeight;
        arrowsBounds.startLabelPadding = _arrowWidthHeight /*+ this._LABEL_PADDING*/;
      } else {

        const _arrowRadius = _arrowHeight * fromSizeMultiplier / 2;

        if(edge.lineStart === EDGE_LINES.STRAIGHT.VALUE) {
          arrowsBounds.startArrowWidth = _arrowHeight * fromSizeMultiplier - _arrowHeight * fromSizeMultiplier / 4;
          arrowsBounds.startArrowHeight = _arrowRadius * 2 / 1.5;
          arrowsBounds.startLabelPadding = _arrowRadius + _arrowRadius / 2 /*+ this._LABEL_PADDING*/;
        }
        if(edge.lineStart === EDGE_LINES.SHARP.VALUE) {
          // arrowsBounds.startArrowHeight = _arrowHeight * fromSizeMultiplier - _arrowHeight * fromSizeMultiplier / 3;
          arrowsBounds.startArrowHeight = _arrowRadius * 2 / 1.5;
          arrowsBounds.startArrowWidth = _arrowHeight * fromSizeMultiplier - _arrowHeight * fromSizeMultiplier / 2;
          arrowsBounds.startLabelPadding = _arrowRadius + _arrowRadius / 3 /*+ this._LABEL_PADDING*/;
        }
        if(edge.lineStart === EDGE_LINES.WIDE.VALUE) {
          arrowsBounds.startArrowHeight = _arrowRadius * 2 / 1.7 + edge.borderWidth;
          arrowsBounds.startArrowWidth = edge.borderWidth * 5;
          arrowsBounds.startLabelPadding = _arrowRadius - _arrowRadius / 3 + (1 + edge.borderWidth);
        }
      }
    }

    if(edge.lineEnd !== EDGE_LINES.NONE.VALUE){
      if(edge.lineEnd === EDGE_LINES.DOT.VALUE){
        const _arrowWidthHeight = _dotRadius * toSizeMultiplier;
        arrowsBounds.endArrowWidth = arrowsBounds.endArrowHeight = _arrowWidthHeight;
        arrowsBounds.endLabelPadding = _arrowWidthHeight /*+ this._LABEL_PADDING*/;
      } else {

        const _arrowRadius = _arrowHeight * toSizeMultiplier / 2;

        if(edge.lineEnd === EDGE_LINES.STRAIGHT.VALUE) {
          arrowsBounds.endArrowWidth = _arrowHeight * toSizeMultiplier - _arrowHeight * toSizeMultiplier / 4;
          arrowsBounds.endArrowHeight = _arrowRadius * 2 / 1.5;
          arrowsBounds.endLabelPadding = _arrowRadius + _arrowRadius / 2 /*+ this._LABEL_PADDING*/;
        }
        if(edge.lineEnd === EDGE_LINES.SHARP.VALUE) {
          // arrowsBounds.endArrowHeight = _arrowHeight * toSizeMultiplier - _arrowHeight * toSizeMultiplier / 3;
          arrowsBounds.endArrowHeight = _arrowRadius * 2 / 1.5;
          arrowsBounds.endArrowWidth = _arrowHeight * toSizeMultiplier - _arrowHeight * toSizeMultiplier / 2
          arrowsBounds.endLabelPadding = _arrowRadius + _arrowRadius / 3 /*+ this._LABEL_PADDING*/;
        }
        if(edge.lineEnd === EDGE_LINES.WIDE.VALUE) {
          arrowsBounds.endArrowHeight = _arrowRadius * 2 / 1.7 + edge.borderWidth;
          arrowsBounds.endArrowWidth = edge.borderWidth * 5;
          arrowsBounds.endLabelPadding = _arrowRadius - _arrowRadius / 3 + (1 + edge.borderWidth);
        }
      }
    }

    return edge.arrowsBounds = arrowsBounds;
  }

  function _getShadowBounds(edge){
    let shadowBounds = {width: null, height: null, x: null, y: null, blurWidth: null, shadowShiftX: null, shadowShiftY: null};
    const {startArrowWidth, startArrowHeight, endArrowWidth, endArrowHeight} = edge.arrowsBounds;
    // this._SHADOW_BLUR_MULTIPLIER is a multiplier of shadowBlur needed to fit css shadow on preview map
    // TODO: find more accurate formula of getting shadowBlur dimensions using Gassing Blur 2D
    const arrowMaxHeight = Math.max(startArrowHeight, endArrowHeight);
    const reversedMultiplier = edge.endPosX - edge.startPosX < 0 ? -1 : 1;
    if(!edge.shadow){
      shadowBounds = Object.assign({}, edge.edgeBounds);
      // x and y of shadowBounds are top left point of the rect
      shadowBounds.x = 0;
      shadowBounds.y = 0;
      shadowBounds.blurWidth = 0;
      shadowBounds.shadowShiftX = 0;
      shadowBounds.shadowShiftY = 0;
    } else {
      const blurWidth = edge.shadowBlur * edge._SHADOW_BLUR_MULTIPLIER;
      shadowBounds.blurWidth = blurWidth;
      shadowBounds.shadowShiftX = edge.shadowX;
      shadowBounds.shadowShiftY = edge.shadowY;
      shadowBounds.width = edge.edgeBounds.width + blurWidth * 2;
      shadowBounds.height = edge.edgeBounds.height + arrowMaxHeight + blurWidth * 2;

      // calc x and y depend on shadowX and shadowY values
      // x and y are coordinates of top left point relative the center of the figure
      shadowBounds.x = edge.shadowX - blurWidth ;
      shadowBounds.y = edge.shadowY + arrowMaxHeight / 2 - blurWidth;
    }
    // was applied to this.shadowBounds
    return  edge.shadowBounds = shadowBounds;
  }

  function _getTitleBounds(edge){
    // to find the longest title
    const titleBounds = {y: null, height: null, vAlign: null, shiftReversed: 0};
    const {width: edgeWidth} = edge.edgeBounds;
    const {startLabelPadding, endLabelPadding} = edge.arrowsBounds;

    const contentParsed = edge.content ?
      _contentParsed(edge.content) :
      {
        amount: 1,
        content: {start: null, middle: edge.contentMiddle, end: null}
      };

    if(!contentParsed?.amount || !edge.fontSize){
      return edge.titleBounds = {x: 0, y: 0, height: 0, shiftReversed: 0}
    }

    const CanvasTextSettings = {
      debug: false,
      vAlign: TEXT_POSITION.CENTER.VALUE,
      font: edge.fontFamily,
      fontWeight: edge.fontWeight,
      fontStyle: edge.fontStyle,
      fontSize: edge.fontSize,
      lineHeight: edge.fontSize * 1.1,
    }
    const CanvasText = new CanvasTxt(null, CanvasTextSettings);

    const labelWidth = (edgeWidth - startLabelPadding - endLabelPadding) / contentParsed.amount;
    const labelsHeight = [];

    if(labelWidth < 30) return edge.titleBounds = {x: 0, y: 0, height: 0, shiftReversed: 0}

    for(const [label, labelText] of Object.entries(contentParsed.content)){
      if(labelText){
        const {textHeight} = CanvasText.getDrawnTextDimensions({ctx: edge.ctx, title: labelText, width: labelWidth});
        labelsHeight.push(textHeight);
      }
    }

    // get the height of the highest label
    titleBounds.height = Math.max(...labelsHeight);
    // get Y coordinate of the highest label
    const textPaddingFromEdge = 5 + (edge.borderWidth - 1) / 2;

    if(!edge.textPosition) edge.textPosition = TEXT_POSITION.TOP.VALUE;
    switch (edge.textPosition){
      case TEXT_POSITION.TOP.VALUE: {
        titleBounds.vAlign = TEXT_POSITION.BOTTOM.VALUE;
        titleBounds.y = -(titleBounds.height + textPaddingFromEdge);
        titleBounds.shiftReversed = textPaddingFromEdge;
        break;
      }
      case TEXT_POSITION.CENTER.VALUE: {
        titleBounds.vAlign = TEXT_POSITION.CENTER.VALUE;
        titleBounds.y = titleBounds.shiftReversed = -(titleBounds.height / 2);
        break;
      }
      case TEXT_POSITION.BOTTOM.VALUE: {
        titleBounds.vAlign = TEXT_POSITION.TOP.VALUE;
        titleBounds.y = textPaddingFromEdge;
        titleBounds.shiftReversed = -(titleBounds.height + textPaddingFromEdge);
        break;
      }
    }

    titleBounds.padding = 5 + (edge.borderWidth - 1) / 2;
    return edge.titleBounds = titleBounds;
  }

  function  _contentParsed(content){
    const parsedContent = {
      start: null,
      middle: null,
      end: null
    };
    let amount = 0;
    for (let [type, value] of Object.entries(content) ){
      if (value?.trim()){
        parsedContent[type] = value;
        amount++;
      }
    }
    return {content: parsedContent, amount: amount};
  }

  return{
    calculateBoundsForEdge
  }
}