import Fonts from "@/services/canvas/fonts/Fonts";

const hostURL = process.env.VUE_APP_ROOT_HOST;
import {TEMPLATE_TYPES} from "@/models/TEMPLATE_TYPES";
import NodeTemplate from "@/services/canvas/templates/NodeTemplate";
import useNodeTemplateUtils from "@/services/canvas/templates/NodeTemplateUtils";
import createImage from "@/services/canvas/utils/CreateImage";
import EdgeTemplate from "@/services/canvas/templates/EdgeTemplate";
import EdgeTemplateUtils from "@/services/canvas/templates/EdgeTemplateUtils";
const {createNodeTemplateObject} = useNodeTemplateUtils();
const {createEdgeTemplateObject} = EdgeTemplateUtils();
const {fontFamilyGetNameByUrl} = Fonts();

export default {
  namespaced: true,
  state: {
    objectTemplateURL: '/templates/objects/',
    objectTemplates: [],
    objectTemplateEdited: null,
    objectTemplateChosen: null,
  },
  getters: {
    getClientFonts(state, getters, rootState, rootGetters){
      return rootGetters["fonts/getClientFonts"]
    },
    getTemplates(state, getters, rootState, rootGetters){
      return rootGetters["design/getTemplates"]
    },
    getObjectTemplates(state){
      return state.objectTemplates;
    },
    getObjectTemplateEdited(state){
      return state.objectTemplateEdited;
    },
    getObjectTemplateChosen(state){
      return state.objectTemplateChosen;
    },
    getObjectTemplateURL(state){
      return state.objectTemplateURL;
    },
  },
  mutations: {
    POPULATE_OBJECT_TEMPLATES(state, {fonts, templates}){
      // state.objectTemplates = [];
      for(const template of Object.values(templates)){
        if(template.type === TEMPLATE_TYPES.NODE) {
          const nodeTemplate = new NodeTemplate(createNodeTemplateObject(template, fonts));
          nodeTemplate.init();
          const media = nodeTemplate.media;
          const mediaURL = nodeTemplate.mediaURL;
          if(media){
            nodeTemplate.image = createImage(media.includes(hostURL) ? media :hostURL + media, nodeTemplate.init.bind(nodeTemplate), true);
          }
          if(mediaURL){
            nodeTemplate.image = createImage(mediaURL, nodeTemplate.init.bind(nodeTemplate), true)
          }
          state.objectTemplates.push(nodeTemplate)
        }
        if(template.type === TEMPLATE_TYPES.EDGE){
          const edgeTemplate = new EdgeTemplate(createEdgeTemplateObject(template, fonts));
          edgeTemplate.cacheInstanceSetDimensions();
          edgeTemplate.cache();
          edgeTemplate.makeSnapshot();
          state.objectTemplates.push(edgeTemplate);
        }
      }
    },
    SET_OBJECT_TEMPLATE_EDITED(state, id){
      state.objectTemplateEdited = id;
    },
    SET_OBJECT_TEMPLATE_CHOSEN(state, templateChosen){
      state.objectTemplateChosen = templateChosen;
    },
    SET_OBJECT_TEMPLATE_OCCUPIED(state, {id, value}){
      const template = state.objectTemplates.find(template => template.id === id);
      if(template) template.occupied = value;
    },
    DELETE_OBJECT_TEMPLATE(state, id){
      const templateIndex = state.objectTemplates.findIndex(template => template.id === id);
      state.objectTemplates.splice(templateIndex, 1);
    },
    CLEAR_OBJECT_TEMPLATES(state){
      state.objectTemplates = [];
    }
  },
  actions: {
    objectTemplatesInit({getters, commit}){
      const fonts = getters.getClientFonts;
      const templates = getters.getTemplates;
      commit('POPULATE_OBJECT_TEMPLATES', {fonts, templates});
    },
    objectTemplateUpdateByData({getters, commit}, templateData){
      const {id, data} = templateData
      const objectTemplates = getters.getObjectTemplates;
      const objectTemplate = objectTemplates.find(template => template.id === parseInt(id));
      const fonts = getters.getClientFonts;

      if(data.type === TEMPLATE_TYPES.NODE){
        const dataConverted = createNodeTemplateObject(data, fonts);
        delete dataConverted.image;
        if(dataConverted.media !== objectTemplate.media){
          objectTemplate.media = dataConverted.media;
          if(objectTemplate.media) objectTemplate.image = createImage(objectTemplate.media, objectTemplate.init.bind(objectTemplate), true);
        }
        if(dataConverted.mediaURL !== objectTemplate.mediaURL){
          objectTemplate.mediaURL = dataConverted.mediaURL;
          if(objectTemplate.mediaURL) objectTemplate.image = createImage(objectTemplate.mediaURL, objectTemplate.init.bind(objectTemplate), true);
        }
        Object.assign(objectTemplate, dataConverted);
        objectTemplate.init();
      }

      if(data.type === TEMPLATE_TYPES.EDGE){
        const dataConverted = createEdgeTemplateObject(data, fonts);
        Object.assign(objectTemplate, dataConverted);
        objectTemplate.cacheInstanceSetDimensions();
        objectTemplate.cache();
        objectTemplate.makeSnapshot();
      }
    },
    objectTemplateUpdate({getters, commit, dispatch}, {id, data}){
      const objectTemplates = getters.getObjectTemplates;
      if(Object.keys(objectTemplates).length === 0){
        dispatch('objectTemplatesInit')
      }
      const objectTemplate = objectTemplates.find(template => template.id === id);
      const fonts = getters.getClientFonts;

      for(let [key, value] of Object.entries(data)){
        if(key === 'fontFamily') {
          objectTemplate.fontFamily = fontFamilyGetNameByUrl(value, fonts);
          break;
        }
        if((key === 'media' && !value) && !objectTemplate.mediaURL) {
          objectTemplate.image = null;
          objectTemplate.media = null;
          objectTemplate.mediaURL = null;
          break;
        }
        if(key === 'media' && value){
          objectTemplate.media = value;
          objectTemplate.mediaURL = null;
          // class instance's method should be bind to the instance to have this
          objectTemplate.image = createImage(value, objectTemplate.init.bind(objectTemplate), true);
          break;
        }
        if(key === 'mediaURL' && value){
          objectTemplate.image = createImage(value, objectTemplate.init.bind(objectTemplate), true);
          objectTemplate.media = null;
          objectTemplate.mediaURL = value;
          break;
        }
        objectTemplate[key] = value;
      }
      objectTemplate.cacheInstanceSetDimensions();
      objectTemplate.cache();
      objectTemplate.makeSnapshot();
    },
    objectTemplateCreate({getters, commit}, {id}){
      const fonts = getters.getClientFonts;
      const template = getters.getTemplates[id];
      commit('POPULATE_OBJECT_TEMPLATES', {fonts, templates: {template}});
    },
    objectTemplateDelete({commit}, templateId){
      commit('DELETE_OBJECT_TEMPLATE', templateId);
    },
    objectTemplateSetEdited({commit}, id){
      commit('SET_OBJECT_TEMPLATE_EDITED', id);
    },
    objectTemplateSetChosen({commit}, chosenTemplate){
      commit('SET_OBJECT_TEMPLATE_CHOSEN', chosenTemplate);
    },
    objectTemplateSetOccupied({commit}, {id, value}){
      commit('SET_OBJECT_TEMPLATE_OCCUPIED', {id, value});
    },
    objectTemplatesClear({commit}){
      commit('CLEAR_OBJECT_TEMPLATES');
    },
  }
}