import FontUtils from '@/services/canvas/fonts/Fonts';
import createImage from '@/services/canvas/utils/CreateImage';
import {NODE_SHAPES} from "@/models/NODE_SHAPES";
const {fontFamilyGetNameByUrl} = FontUtils();
const hostURL = process.env.VUE_APP_ROOT_HOST;
import store from '@/store/store'
import {computed} from "vue";
function checkSiblingsIsoValue(langObj, languagesISO, key){
	return languagesISO.map(id => langObj[id][key]).some(value => value)
}
// TODO move checkSiblingsIsoValue to utils[languages]

export default function (){
	function createNodeObject(nodeOriginal, elementsDesign, fonts){
		// translations
		const currentLang = computed(() => store.getters['maps/languages/getLanguage'].id);
		const languagesChosen = computed(() => store.getters['maps/languages/getLanguagesChosenComputed']);
		const gifAnimationStatus = computed(() => store.getters['nodes/design/getGifAnimationStatus']);
		const languagesISO = [currentLang.value, ...languagesChosen.value.map(item => item.id)];
		const LINK = 'node_link_to';
		const CONTENT = 'content';
		const MARGINAL1 = 'marginal_content_1';
		const MARGINAL2 = 'marginal_content_2';
		const contentBlocked = checkSiblingsIsoValue(nodeOriginal.content.lang, languagesISO, LINK);
		const contentLinkBlocked = checkSiblingsIsoValue(nodeOriginal.content.lang, languagesISO, CONTENT) ||
			checkSiblingsIsoValue(nodeOriginal.content.lang, languagesISO, MARGINAL1) ||
			checkSiblingsIsoValue(nodeOriginal.content.lang, languagesISO, MARGINAL2);

		const nodeDesign = getNodeOriginalDesign(nodeOriginal, elementsDesign);
		if(nodeDesign.shape === NODE_SHAPES.CIRCLE.VALUE && nodeDesign.width !== nodeDesign.height){
			nodeDesign.width = nodeDesign.height = Math.max(nodeDesign.width, nodeDesign.height);
		}

		const node = {
			id: nodeOriginal.id,
			type: nodeOriginal.geometry.shape || 'circle',
			template: nodeOriginal.design.object_template,
			shape: nodeDesign.shape,
			edgesFrom: [],
			edgesTo: [],
			width: nodeDesign.width,
			height: nodeDesign.height,
			rotate: nodeDesign.rotate,
			x: nodeOriginal.geometry.center.x,
			y: nodeOriginal.geometry.center.y,
			radius: nodeDesign.width / 2,
			surface: nodeDesign.bg_enabled,
			bgColor: nodeDesign.bg_color,
			borderColor: nodeDesign.border_color,
			borderStyle: nodeDesign.border_style,
			borderWidth: nodeDesign.border_width,
			border: nodeDesign.border_enabled,
			shadow: nodeDesign.shadow_enabled,
			shadowX: nodeDesign.shadow_x,
			shadowY: nodeDesign.shadow_y,
			shadowBlur: nodeDesign.shadow_blur,
			shadowColor: nodeDesign.shadow_color,
			title: nodeOriginal.content.lang[currentLang.value].title,
			titlePosition: nodeDesign.title_position,
			textAlign: nodeDesign.text_align,
			lineHeight: nodeDesign.line_height,
			fontFamily: fontFamilyGetNameByUrl(nodeDesign.font_url, fonts) || fonts[0].family_name,
			fontWeight: nodeDesign.font_weight,
			fontStyle: nodeDesign.font_style,
			fontSize: nodeDesign.font_size,
			fontColor: nodeDesign.font_color,
			media: nodeDesign.media,
			mediaURL: nodeDesign.media_url,
			mediaCropEnabled: nodeDesign.media_cropped,
			mediaSize: nodeDesign.media_size || 100,
			mediaOpacity: nodeDesign.media_opacity,
			image: null, // local instance of Image();
			gifAnimation: gifAnimationStatus.value,

			visibilityMin: nodeOriginal.visibility.min_level,
			visibilityMax: nodeOriginal.visibility.max_level,
			hidden: nodeOriginal.visibility.hidden,
			hiddenLocal: false,
			locked: nodeOriginal.editing.locked,
			connectSign: false,
			editingContent: null,
			editingAppearance: null,
			availableForConnection: true,
			contentBlocked,
			contentLinkBlocked,
		}
		// if media provided create image instance
		const media = node.media;
		const mediaURL = node.mediaURL;
		const computedMedia =  media?.includes(hostURL) ? media : `${hostURL}${media}`;
		if (media){
			node.image = createImage(computedMedia, null, null, nodeOriginal.id);
		}
		if (mediaURL){
			node.image = createImage(mediaURL, null, null, nodeOriginal.id);
		}
		
		return node;
	}
	
	function getNodeComputedDesign(nodeId, nodes, nodesOriginal, elementsDesign, fonts){
		/**
		 * @description
		 * this function purpose to populate computed fontFamily and image properties
		 * as converted nodeOriginalToNode object
		 */
		const nodeOriginal = nodesOriginal.find(node => node.id === nodeId);
		const node = nodes.find(node => node.id === nodeId);
		let nodeDesign = {};
		const nodeOriginalDesign = getNodeOriginalDesign(nodeOriginal, elementsDesign);
		// collect a node object from related node original
		for(let [key, value] of Object.entries(nodeOriginalDesign)){
			if(key === 'font_url' && value){
				value = fontFamilyGetNameByUrl(value, fonts)
			}
			if(key === 'media' && value && !node.image?.src.includes(value)){
				const imageUrl = value.includes(hostURL) ? value : `${hostURL}${value}`
				Object.assign(nodeDesign, {image: createImage(imageUrl, null, null, nodeId)})
			}
			if(key === 'media_url' && value && !node.image?.src.includes(value)){
				Object.assign(nodeDesign, {image: createImage(value, null, null, nodeId)});
			}
			if((key === 'media' && !value) && !nodeOriginalDesign.media_url) nodeDesign.image = null;
			Object.assign(nodeDesign, convertOriginalToNodeProperty({[key]: value}))
		}

		if(nodeDesign.shape === NODE_SHAPES.CIRCLE.VALUE && nodeDesign.width !== nodeDesign.height){
			nodeDesign.width = nodeDesign.height = Math.max(nodeDesign.width, nodeDesign.height);
		}
		
		return nodeDesign;
	}
	
	function getNodeOriginalDesign(node, elementsDesign){
		// check design properties of a node if null ->
		// check object template, if "0" -> use global design, if null -> use system defaults
		const {templates, nodes: global, defaults : {nodes: nodeDefaults}} = elementsDesign;
		let nodeOriginalDesign = {};
		let unlinkedDesign = JSON.parse(JSON.stringify(node.design));
		for(let [key, value] of Object.entries(unlinkedDesign)){
			if(value === null && key !== 'object_template'){
				const templateId = node.design.object_template;
				const propertyValue =
					templateId !== null &&
					getValueFromTemplate(templateId, templates, global, key) !== null &&
					getValueFromTemplate(templateId, templates, global, key) !== '' ?
						getValueFromTemplate(templateId, templates, global, key) :
						nodeDefaults[key];
				Object.assign(nodeOriginalDesign, {[key]: propertyValue});
			} else {
				Object.assign(nodeOriginalDesign, {[key]: value});
			}
		}
		// special check media fields
		// if node has media but media_url comes up from next levels templates
		if(unlinkedDesign.media && nodeOriginalDesign.media_url){
			nodeOriginalDesign.media_url = null;
		}
		if(unlinkedDesign.media_url && nodeOriginalDesign.media){
			nodeOriginalDesign.media = null;
		}
		
		return nodeOriginalDesign;
	}
	function getValueFromTemplate(templateId, templates, global, property){
		return templateId === "0" ? global[property] : templates[templateId][property];
	}
	function getNodeDesignFromTemplate(templateId, elementsDesign){
		const {templates, nodes: global, defaults : {nodes: nodeDefaults}} = elementsDesign;
		let nodeDesign = {};
		// if templateId look template[property] -> global[property] -> default[property]
		if(templateId !== null){
			let template = templateId !== '0' ? templates[templateId] : global;
			for(let [key, value] of Object.entries(template)){
				value === null ?
					// if value is null check global value, if still null get default value
					Object.assign(nodeDesign, {[key]: global[key] !== null ? global[key] : nodeDefaults[key]}) :
					// else get template value
					Object.assign(nodeDesign, {[key]: value});
			}
		} else {
			// if templateId is null get values from node default
			nodeDesign = nodeDefaults;
		}
		return nodeDesign;
	}
	
	function getNodeUpdatedData(node){
		let updatedValues = {};
		for(let [property, value] of Object.entries(node)){
			// avoid checking non-comparable values
			if(property !== 'object_template' && value !== null){
				updatedValues = Object.assign(updatedValues, {[property]: value})
			}
		}
		return updatedValues;
	}
	
	function getNodeDesignInitialComputedValue(templateId, elementDesign, property){
		const nodeDesign = getNodeDesignFromTemplate(templateId, elementDesign);
		return nodeDesign[property];
	}
	
	const NODE_DESIGN_MAPPING = {
		surface: 'bg_enabled',
		bgColor: 'bg_color',
		border: 'border_enabled',
		borderColor: 'border_color',
		borderStyle: 'border_style',
		borderWidth: 'border_width',
		fontColor: 'font_color',
		fontSize: 'font_size',
		fontWeight: 'font_weight',
		fontStyle: 'font_style',
		fontFamily: 'font_url',
		lineHeight: 'line_height',
		height: 'height',
		width: 'width',
		rotate: 'rotate',
		media: 'media',
		mediaOpacity: 'media_opacity',
		mediaURL: 'media_url',
		mediaSize: 'media_size',
		mediaCropEnabled: 'media_cropped',
		shadowBlur: 'shadow_blur',
		shadowColor: 'shadow_color',
		shadow: 'shadow_enabled',
		shadowX: 'shadow_x',
		shadowY: 'shadow_y',
		shape: 'shape',
		textAlign: 'text_align',
		textDecoration: 'text_decoration',
		titlePosition: 'title_position',
		template: 'object_template',
		
		// todo remove from design
		visibilityMin: 'min_level',
		visibilityMax: 'max_level'
	}
	function mappingInverted(mapping){
		const invertedMapping = {}
		for(let [key,value] of Object.entries(mapping)){
			Object.assign(invertedMapping, {[value]: key})
		}
		return invertedMapping;
	}
	
	function convertOriginalToNodeProperty(nodeProperty){
		const [[key, value]] = Object.entries(nodeProperty);
		const mapping = mappingInverted(NODE_DESIGN_MAPPING);
		if(mapping[key]) return {[mapping[key]] : value}
	}
	function convertToOriginalNodeProperty(nodeProperty){
		const [entries] = Object.entries(nodeProperty);
		const [key, value] = entries;
		
		if(NODE_DESIGN_MAPPING[key]) return {[NODE_DESIGN_MAPPING[key]] : value}
	}
	
	function adaptedNodeDesignProperties(node){
		const adaptedOriginal = {};
		for(let [key, value] of Object.entries(NODE_DESIGN_MAPPING)){
			if(node[value] !== undefined) Object.assign(adaptedOriginal, {[key]: node[value]})
		}
		return adaptedOriginal;
	}
	
	const NODE_CONTENT_MAPPING = {
		abstract: 'abstract',
		content: 'content',
		marginalContent1: 'marginal_content_1',
		marginalContent2: 'marginal_content_2',
		nodeLinkTo: 'node_link_to',
		nodeSearchTerms: 'node_search_terms',
		shortContent: 'short_content',
		title: 'title'
	}
	function convertContentToOriginalProperty(contentProperty){
		const [[key, value]] = Object.entries(contentProperty);
		return {[NODE_CONTENT_MAPPING[key]] : value}
	}
	function convertContentToAdaptedProperty(contentProperty){
		const [[key, value]] = Object.entries(contentProperty);
		const mapping = mappingInverted(NODE_CONTENT_MAPPING);
		return {[mapping[key]] : value}
	}
	
	function edgeUpdateRelated(currentNode, propertyFrom, propertyTo, value, dispatch){
		// if there are edges related -> update properties which impact on canvas drawing
		if(currentNode.edgesFrom){
			currentNode.edgesFrom.forEach((edge) => {
				const updatedData = {
					edgeId: edge,
					[propertyFrom]: value,
				}
				dispatch('edges/edgeUpdate', updatedData, {root: true});
			})
		}
		if(currentNode.edgesTo){
			currentNode.edgesTo.forEach((edge) => {
				const updatedData = {
					edgeId: edge,
					[propertyTo]: value,
				}
				dispatch('edges/edgeUpdate', updatedData, {root: true});
			})
		}
	}
	
	return {
		NODE_DESIGN_MAPPING,
		createNodeObject,
		adaptedNodeDesignProperties,
		getNodeOriginalDesign,
		getNodeDesignFromTemplate,
		getNodeDesignInitialComputedValue,

		getNodeComputedDesign,
		convertOriginalToNodeProperty,
		convertToOriginalNodeProperty,

		getNodeUpdatedData,
		
		convertContentToOriginalProperty,
		convertContentToAdaptedProperty,
		
		edgeUpdateRelated
	}
}
