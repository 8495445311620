import axios from "axios";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const baseURL = process.env.VUE_APP_ROOT_API;

const apiClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
  
  }
});

apiClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    /*if (error.response && error.response.status === 401) {
      store.dispatch("auth/logoutRequest");
    } else {
      return Promise.reject(error);
    }*/
    return Promise.reject(error);
  }
);

export default {
  getRequest(api, config = null) {
    return apiClient.get(api, config);
  },
  postRequest(api, payload, config = null) {
    return apiClient.post(api, payload, config);
  },
  deleteRequest(api, config = null) {
    return apiClient.delete(api, config);
  },
  putRequest(api, payload, config = null) {
    return apiClient.put(api, payload, config);
  },
  patchRequest(api, payload, config = null) {
    return apiClient.patch(api, payload, config);
  }
};
