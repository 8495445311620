import { createStore } from 'vuex'
import auth from '@/store/modules/auth';
import user from '@/store/modules/user';
import clients from '@/store/modules/client/clients';
import maps from '@/store/modules/map/maps';
import design from '@/store/modules/map/design';
import nodes from '@/store/modules/map/nodes/nodes';
import edges from '@/store/modules/map/edges/edges';
import templates from '@/store/modules/map/templates/templates';
import mapSettings from '@/store/modules/map/mapSettings';
import layers from '@/store/modules/map/layers/layers';

import dashboardOptions from '@/store/modules/editor/dashboard-options';
import settingsSidebar from '@/store/modules/editor/settings-sidebar';
import settingsPopup from '@/store/modules/editor/settings-popup';

import mediaAssets from '@/store/modules/editor/media-assets';
import mediaContent from '@/store/modules/editor/media-content';
import fonts from '@/store/modules/fonts';

import toastMessages from '@/store/modules/editor/toast-messages';

import simultaneousEditing from '@/store/modules/editor/SE/simultaneous-editing';
import invitation from '@/store/modules/editor/SE/invitation';

import tour from'@/store/modules/map/tour/tour';

import quizzes from '@/store/modules/editor/quizzes';

import translations from "@/store/modules/editor/translations";

export default createStore({
  modules: {
    auth,
    user,
    clients,
    maps,
    design,
    nodes,
    edges,
    layers,
    templates,
    mapSettings,
    dashboardOptions,
    settingsSidebar,
    settingsPopup,
    mediaAssets,
    mediaContent,
    fonts,
    toastMessages,
    simultaneousEditing,
    invitation,
    tour,
    quizzes,
    translations
  }
})
