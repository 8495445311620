import { Roles } from "@/models/Roles";
export default {
  permissionCheck({userRole, permission}) {
    if(!userRole) return false;
    if (userRole === Roles.Superuser.toLowerCase()) {
      // superuser has access to all the pages
      return true;
      // otherwise check particular permission
    } else return this.getRoleIndex(userRole) <= this.getRoleIndex(permission)
  },
  getPriorityRole(roles){
    for (let [key, role] of Object.entries(Roles)){
      if(roles.includes(role)) return role
    }
  },
  getRoleIndex(userRole){
    return Object.entries(Roles).findIndex(role => role[1] === userRole)
  }
};
