export default {
	namespaced: true,
	state: {},
	getters: {
		getLayers(state, getters, rootState, rootGetters){
			return rootGetters['layers/getLayers']
		}
	},
	mutations: {
		TOGGLE_LAYER_HIDDEN(state, {layersData, layers}){
			for(let [id, status] of Object.entries(layersData)){
				const toggledLayer = layers[id];
				toggledLayer.hidden = status;
				
				for( let layer of Object.values(layers)){
					layer.nodes.forEach( (node, nodeIndex) => {
						if(toggledLayer.nodes[nodeIndex].onLayer) {
							node.hiddenLocal = status;
						}
					})
				}
			}
		},
		TOGGLE_NODE_HIDDEN(state, {hiddenData, layers}){
			for(let layer of Object.values(layers)){
				for(let [id, status] of Object.entries(hiddenData)){
					const node = layer.nodes.find(node => node.id === parseInt(id));
					node.hiddenLocal = status;
				}
			}
		},
	},
	actions: {
		layerHiddenUpdate({getters, commit}, layersData){
			const layers = getters.getLayers;
			commit('TOGGLE_LAYER_HIDDEN', {layersData, layers});
		},
		layerHiddenToggle({getters, commit, dispatch}, hiddenData){
			dispatch('layerHiddenUpdate', hiddenData);
			dispatch('nodes/hidden/layerUpdateHidden', hiddenData, {root: true});
			// dispatch('nodes/hidden/layerSaveHidden', hiddenData, {root: true});
		},
		nodeHiddenUpdate({getters, commit}, hiddenData){
			// hiddenData: {id: status, id: status, ...}
			const layers = getters.getLayers;
			commit('TOGGLE_NODE_HIDDEN', {hiddenData, layers});
		},
		nodeHiddenToggle({state, getters, commit, dispatch}, hiddenData){
			dispatch('nodeHiddenUpdate', hiddenData);
			dispatch('nodes/hidden/nodeUpdateHiddenLocal', hiddenData, {root: true});
			// dispatch('nodes/hidden/nodeSaveHidden', hiddenData, {root: true});
		},
	}
}
