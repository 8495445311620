export default {
  namespaced: true,
  state: {
    translations: {

    },
  },
  getters: {
    getTranslations(state){
      return state.translations
    }
  },
  mutations: {
    SET_TRANSLATIONS(state, translations){
      state.translations = translations
    }
  },
  actions: {
    setTranslations({commit}, translations){
      commit('SET_TRANSLATIONS', translations)
    }
  }
}