import MapUtils from '@/services/canvas/map-area/MapUtils';
import ToastUtils from '@/utils/ToastUtils';
import createImage from '@/services/canvas/utils/CreateImage';
import ApiService from '@/utils/ApiService';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import useMapArea from '@/services/canvas/map-area/MapArea';
const {createToastFromStore} = ToastUtils();
const {
	createMapDataObject,
	convertOriginalMapProperty,
	convertAdoptedMapProperty
} = MapUtils();
const {mapArea} = useMapArea();
import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
const {t} = i18n.global;


export default {
	namespaced: true,
	state: {
		mapDataOriginal: null,
		mapData: null,
		MAX_ZOOM_LEVELS: 12,
		downloadMedia: false,
	},
	getters: {
		getMapDataOriginal(state){
			return state.mapDataOriginal;
		},
		getMapData(state){
			return state.mapData;
		},
		getLayersAmount(state){
			return state.mapData.zoomLevels;
		},
		getMaxZoomLevels(state){
			return state.MAX_ZOOM_LEVELS;
		},
		getMapsURL(state, getters, rootState){
			return rootState.maps.mapsURL
		},
		getCurrentMap(state, getters, rootState) {
			return rootState.maps.currentMap;
		},
		getElementsURL(state, getters, rootState) {
			return rootState.maps.elementsURL;
		},
		getDownloadMediaStatus(state){
			return state.downloadMedia;
		}
	},
	mutations: {
		SAVE_MAP_SETTINGS(state, settings){
			state.mapDataOriginal = settings;
			state.mapData = createMapDataObject(settings);
			mapArea.width = state.mapData.width;
			mapArea.height = state.mapData.height;
			mapArea.bgColor = `background: ${state.mapData.bgColor}`
		},
		UPDATE_MAP_DATA(state, data){
			Object.assign(state.mapData, data);
		},
		UPDATE_MAP_DATA_ORIGINAL(state, data){
			Object.assign(state.mapDataOriginal, data);
		},
		SET_DOWNLOAD_MEDIA_STATUS(state, status){
			state.downloadMedia = status;
		}
	},
	actions: {
		mapSettingsSave({commit, dispatch}, settings){
			commit('SAVE_MAP_SETTINGS', settings);
		},
		mapDataUpdateSE({commit, dispatch}, editedData){
			const {mapData, zoomData} = editedData;
			/**
			 * @param zoomData.previous - initial zoomLevel
			 * @param zoomData.zoomLevel - updated zoomLevel
			 */
			
			const mapSettings = {
				design: {},
				measurement: {width: mapData.width, height: mapData.height},
				visibility_levels: mapData.zoomLevels
			}
			for(let [key, value] of Object.entries(mapData)){
				Object.assign(mapSettings.design, convertAdoptedMapProperty({[key]: value}))
			}
			
			if(mapData.media){
				const hostURL = process.env.VUE_APP_ROOT_HOST;
				const src = mapData.media.includes(hostURL) ? mapData.media : `${hostURL}${mapData.media}`
				mapData.image = createImage(src)
			}
			
			if(mapData.mediaURL){
				mapData.image = createImage(mapData.mediaURL);
			}
			
			commit('UPDATE_MAP_DATA', mapData);
			if(mapData.width) mapArea.width = mapData.width;
			if(mapData.height) mapArea.height = mapData.height;
			if(mapData.bgColor) mapArea.bgColor = `background: ${mapData.bgColor}`
			commit('UPDATE_MAP_DATA_ORIGINAL', mapSettings);
			
			if(zoomData.previous !== zoomData.zoomLevel){
				dispatch('nodes/nodesChangeZoomLevel', zoomData, {root: true});
				dispatch('edges/edgesChangeZoomLevel', zoomData, {root: true});
				dispatch('layers/layersAmountChange', zoomData.zoomLevel, {root: true});
			}
			mapArea.updateElement = true;
		},
		async mapDataUpdate({getters, commit, dispatch}, mapData){
			const mapsURL = getters.getMapsURL;
			const elementsURL = getters.getElementsURL;
			const mapId = getters.getCurrentMap.id;
			// convert mapData properties to original to make request
			const mapSettings = {
				design: {},
				measurement: {width: mapData.width, height: mapData.height},
				visibility_levels: mapData.zoomLevels,
				behavior: {
					mark_visited_nodes: mapData.markVisitedNodes
				}
			}
			for(let [key, value] of Object.entries(mapData)){
				Object.assign(mapSettings.design, convertAdoptedMapProperty({[key]: value}))
			}
			const request = {'map': mapSettings};
			
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, request);
				
				commit('UPDATE_MAP_DATA', mapData);
				if(mapData.width) mapArea.width = mapData.width;
				if(mapData.height) mapArea.height = mapData.height;
				if(mapData.bgColor) mapArea.bgColor = `background: ${mapData.bgColor}`
				commit('UPDATE_MAP_DATA_ORIGINAL', mapSettings);
				mapArea.updateElement = true;
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.mapSettingsSuccess'), dispatch);
				return Promise.resolve('resolve');
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.mapSettingsError'), dispatch);
				return Promise.reject('reject');
			}
		},
		async mapZoomLevelsUpdate({getters, commit, dispatch}, value){
			const mapsURL = getters.getMapsURL;
			const elementsURL = getters.getElementsURL;
			const mapId = getters.getCurrentMap.id;
			
			const zoomLevelsOriginal = {visibility_levels: value};
			const request = {'map': zoomLevelsOriginal}
			
			/*commit('UPDATE_MAP_DATA', zoomLevels);
			commit('UPDATE_MAP_DATA_ORIGINAL', zoomLevelsOriginal);*/
			
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, request);
				dispatch('mapZoomLevelsUpdateInStore', value);
				mapArea.updateElement = true;
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.mapSettingsSuccess'), dispatch);
				return Promise.resolve();
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.mapSettingsError'), dispatch);
				return Promise.reject();
			}
		},
		mapZoomLevelsUpdateInStore({commit}, zoomLevels){
			commit('UPDATE_MAP_DATA', {zoomLevels});
			commit('UPDATE_MAP_DATA_ORIGINAL', {visibility_levels: zoomLevels});
		},
		checkDeletedMedia({getters, commit}, mediaKey){
			const mapData = getters.getMapData;
			const mapDataOriginal = getters.getMapDataOriginal;
			
			if(mapData.media?.includes(mediaKey)){
				commit('UPDATE_MAP_DATA', {media: null, image: null});
				mapArea.updateElement = true;
				const mapOriginalDesign = Object.assign({}, mapDataOriginal.design, {media: null})
				console.log(mapOriginalDesign);
				commit('UPDATE_MAP_DATA_ORIGINAL', mapOriginalDesign);
			}
		},
		async mapMediaDownload({commit, dispatch}, {url, fileName}){
			commit('SET_DOWNLOAD_MEDIA_STATUS', true);
			let response = await fetch(url, {
				method: 'GET',
				credentials: 'include'
			});
			if(!response.ok) {
				commit('SET_DOWNLOAD_MEDIA_STATUS', false);
				createToastFromStore(TOASTS_TYPES.ERROR, t('message.downloadMediaError'), dispatch);
				return Promise.reject(response.status);
			}
			let data = await response.blob();
			commit('SET_DOWNLOAD_MEDIA_STATUS', false);

			// If the file is ready, download it
			const link = document.createElement('a');
			link.href = URL.createObjectURL(data);
			link.download = fileName;
			link.style.display = 'none';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.downloadMediaSuccess'), dispatch);
			return Promise.resolve();

			/*try{
				let response = await fetch(url, {
					method: 'GET',
					credentials: 'include'
				});
				if(!response.ok) {
					commit('SET_DOWNLOAD_MEDIA_STATUS', false);
					createToastFromStore(TOASTS_TYPES.ERROR, t('message.downloadMediaError'), dispatch);
					return Promise.reject(response.status);
				}
				let data = await response.blob();
				commit('SET_DOWNLOAD_MEDIA_STATUS', false);

				// If the file is ready, download it
				const link = document.createElement('a');
				link.href = URL.createObjectURL(data);
				link.download = fileName;
				link.style.display = 'none';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.downloadMediaSuccess'), dispatch);
				return Promise.resolve();
			} catch(err){
				commit('SET_DOWNLOAD_MEDIA_STATUS', false);
				createToastFromStore(TOASTS_TYPES.ERROR, t('message.downloadMediaError'), dispatch);
				return Promise.reject('reject');
			}*/
		}
	}
}
