export default {
  namespaced: true,
  state: {
    languagesURL: '/languages/',
    language: null,
    languageComputed: null,
    languageLocal: null,
    languagesChosen: [],
    languagesChosenComputed: [],
  },
  getters: {
    getLanguagesURL(state){
      return state.languagesURL;
    },
    getLanguage(state){
      return state.language;
    },
    getUserLanguage(state, getters, rootState, rootGetters) {
      return rootGetters["user/getUserLanguage"];
    },
    getLanguageComputed(state, getters){
      return {
        id: state.language.id,
        name: state.language.lang[getters.getUserLanguage.code].name,
        _link: state.language._links.self
      };
    },
    getLanguagesChosenComputed(state, getters){
      return state.languagesChosen.filter(item => item.id !== state.language.id).map(item => ({
        id: item.id,
        name: item.lang[getters.getUserLanguage.code].name,
        _link: item._links.self
      }));
    },
    getLanguageLocal(state){
      return state.languageLocal
    },
    getLanguageChosenLocal(state){
      return state.languagesChosen.filter(item => item.id !== state.languageLocal.id).map(item => ({
        id: item.id,
        subId: item.id.length > 2 ? item.id.substring(3, 5) : null,
      }))
    }
  },
  mutations: {
    SET_LANGUAGE(state, language){
      state.language = language;
    },
    SET_LANGUAGES_CHOSEN(state, languagesChosen){
      state.languagesChosen = languagesChosen;
    },
    SET_LANGUAGE_LOCAL(state, id) {
      state.languageLocal = {
        id,
        subId: id.length > 2 ? id.substring(3, 5) : null
      };
    }
  },
  actions: {
    languageSet({commit}, language){
      commit('SET_LANGUAGE', language);
    },
    languagesChosenSet({commit}, languagesChosen){
      commit('SET_LANGUAGES_CHOSEN', languagesChosen);
    },
    languageLocalSet({commit}, id){
      commit('SET_LANGUAGE_LOCAL', id);
    },
    languagesReset({dispatch}){
      dispatch('languageSet', null);
      dispatch('languagesChosenSet', []);
    }
  }
}