import {Scale} from '@/services/canvas/Scale';
const {mapArea} = useMapArea();
import useMapArea from "@/services/canvas/map-area/MapArea";
import useColorUtils from '@/utils/colorUtils';
const {hexToRGB, RGB_to_hex} = useColorUtils();
import EdgeTemplate from "@/services/canvas/templates/EdgeTemplate";
import collidesCalculator from "@/services/canvas/edges/modules/collidesCalculator";
const {calculateNodesCollidingForEdge} = collidesCalculator()

export default class Edge extends EdgeTemplate{
	constructor({
    id, template, startPosX, startPosY, endPosX, endPosY,
    fromNodeId, fromNodeHeight, fromNodeWidth, fromNodeBorderWidth, fromNodeBorder, fromNodeShape, lineStart, lineStartSize,
    toNodeId, toNodeHeight,  toNodeWidth, toNodeBorderWidth, toNodeBorder, toNodeShape, lineEnd, lineEndSize,
    border, borderColor, borderStyle, borderWidth, fontColor, fontSize, fontFamily, fontWeight, fontStyle,
		textPosition, textDecoration,
		shadow, shadowColor, shadowX, shadowY, shadowBlur,
    contentStart, contentMiddle, contentEnd, content,
		visibilityMin, visibilityMax,
		editing, hidden
	}) {
		super({
			id, lineStart, lineStartSize, lineEnd, lineEndSize,
			border, borderColor, borderWidth, borderStyle,
			fontColor, fontSize, fontFamily, fontWeight, fontStyle, textPosition,
			shadow, shadowColor, shadowX, shadowY, shadowBlur,
			contentMiddle
		})
		this.template = template;

		this.fromNodeId = fromNodeId;
		this.fromNodeWidth = fromNodeWidth;
		this.fromNodeHeight = fromNodeHeight;
		this.fromNodeBorderWidthValue = fromNodeBorder ? fromNodeBorderWidth : 0;
		this.fromNodeBorder = fromNodeBorder;
		this.fromNodeShape = fromNodeShape;
		this.fromPoint = {x: 0, y: 0};

		this.toNodeId = toNodeId;
		this.toNodeWidth = toNodeWidth;
		this.toNodeHeight = toNodeHeight;
		this.toNodeBorderWidthValue = toNodeBorder ? toNodeBorderWidth : 0;
		this.toNodeBorder = toNodeBorder;
		this.toNodeShape = toNodeShape;
		this.toPoint = {x: 0, y:0};

		this.startPosX = startPosX;
		this.endPosX = endPosX;
		this.startPosY = startPosY;
		this.endPosY = endPosY;
		this.middlePoint = {x: null, y: null};

		this.textDecoration = textDecoration;

		this.contentStart = contentStart;
		this.contentEnd = contentEnd;
		this.content = content;
		
		this.visibilityMin = visibilityMin;
		this.visibilityMax = visibilityMax;
		
		this.hidden = hidden;
		this.editing = editing;

		this.ZOOM_MAX = 1.62854;
		this._HOVER_COLOR = '#B9E0E3';
		this._LOCKED_COLOR = '#ED6F78';
		this._LABEL_PADDING = 10;

	}

	set fromNodeBorderWidth(val){
		this.fromNodeBorderWidthValue =
			this.fromNodeBorder ?
			val :
			0
	}
	get fromNodeBorderWidth(){
		return this.fromNodeBorderWidthValue
	}
	set toNodeBorderWidth(val){
		this.toNodeBorderWidthValue =
			this.toNodeBorder ?
				val :
				0
	}
	get toNodeBorderWidth(){
		return this.toNodeBorderWidthValue
	}

	cache(layerID, ctxPath, selectedID){
		this.ctxPath = ctxPath || this.ctxPath || null;
		this.currentLayerID = layerID || this.currentLayerID || 1;
		this.globalAlpha = super._getVisibility(layerID);
		// set common alpha and calc color
		this._setCurrentColor(selectedID);

		// get coords of the computed edge's start point which includes shadow and arrow shifts if any...
		({x: this.xCache, y: this.yCache} = super._getEdgeCoordinates());

		// if nodes colliding, edge shouldn't be drawn
		const isNodesColliding = calculateNodesCollidingForEdge(
			this,
			{x: this.startPosX, y: this.startPosY, radius: this.fromNodeWidth /2 + this.fromNodeBorderWidth},
			{x: this.endPosX, y: this.endPosY, radius: this.toNodeWidth /2 + this.toNodeBorderWidth}
		)
		if(isNodesColliding) return;

		// draw path
		super._drawEdge();
		super._drawArrows();
		super._drawText();
	}

	cacheUpdate(layerID, ctxPath, selectedID){
		this.cacheClear();
		this.cache(layerID, ctxPath, selectedID);
	}

	draw(contexts, zoomLevel, selectedID, ignoreMapArea = false, area = {width: null, height: null, shiftX: 0, shiftY: 0}){

		if(this.hidden) return;

		const {ctxBuffered: ctx, ctxHighlights} = contexts;
		if(selectedID > -1 && selectedID === this.id){
			this.setSelected(ctxHighlights, selectedID, zoomLevel);
		}

		ctx.save();
		/**
		 * to draw cacheInstance to ctx of the mapArea by computed coordinates
		 * */
		const edgeAngle =	Math.atan2((this.endPosY - this.startPosY), (this.endPosX - this.startPosX));

		const {width, height, shiftX, shiftY} = area;
		const shiftMapX = ignoreMapArea ? width / 2 + Math.abs(shiftX)  : mapArea.x;
		const shiftMapY = ignoreMapArea ? height / 2 + Math.abs(shiftY) : mapArea.y;
		// coords on the main canvas to rotate it
		const computedRotateX = Scale.x(this.fromPoint.x + shiftMapX, ctx.canvas.width / 2, zoomLevel);
		const computedRotateY = Scale.y(this.fromPoint.y + shiftMapY, ctx.canvas.height / 2, zoomLevel);

		// coords on the main canvas to draw cached edge
		const computedX = Scale.x(this.fromPoint.x - this.xCache + shiftMapX, ctx.canvas.width / 2, zoomLevel);
		const computedY = Scale.y(this.fromPoint.y - this.yCache + shiftMapY, ctx.canvas.height / 2, zoomLevel);
		const computedInteractX = Scale.x(this.fromPoint.x + mapArea.x, ctx.canvas.width / 2, zoomLevel);
		const computedInteractY = Scale.y(this.fromPoint.y - this._HOVER_AREA / 2 - this.borderWidth / 2 + mapArea.y, ctx.canvas.height / 2, zoomLevel);

		const ZOOM_FACTOR = this.ZOOM_MAX * this.pixelRatio;

		if(!this.cacheInstance.width || !this.cacheInstance.height) return;

		super._ctxRotate(edgeAngle, computedRotateX, computedRotateY, ctx);

		/**
		 * transformation and drawing coords are different
		 * since actual line connected centers of the nodes but should be drawn on the node's border
		 * rotate point is center of related nodes
		 * draw point is the point on the border
		 * */

		ctx.drawImage(this.cacheInstance, computedX, computedY, (this.cacheInstance.width / ZOOM_FACTOR) * zoomLevel, (this.cacheInstance.height / ZOOM_FACTOR) * zoomLevel);

		this.pathInteraction = new Path2D();
		this.pathInteraction.rect(
			computedInteractX,
			computedInteractY,
			this.edgeBounds.width * zoomLevel,
			(this.borderWidth + this._HOVER_AREA) * zoomLevel
		)

		ctx.restore();
	}


	setSelected(ctx, selectedId, zoomLevel){
		this._getMiddlePoint(); // get coords of edge's center
		if(this.id === selectedId) this._selectedMark(ctx, zoomLevel);
	}

	checkHovered(ctx, mouse, zoomLevel){
		if(!this.pathInteraction) return;
		const edgeAngle =	Math.atan2((this.endPosY - this.startPosY), (this.endPosX - this.startPosX));
		const computedRotateX = Scale.x(this.fromPoint.x + mapArea.x, ctx.canvas.width / 2, zoomLevel);
		const computedRotateY = Scale.y(this.fromPoint.y + mapArea.y, ctx.canvas.height / 2, zoomLevel);

		ctx.save();
		this._ctxRotate(edgeAngle, computedRotateX, computedRotateY, ctx);
		ctx.lineWidth = this._HOVER_AREA + this.borderWidth;
		this.hover = !this.hidden ? ctx.isPointInPath(this.pathInteraction, mouse.x /*+ mapArea.x*/, mouse.y /*+ mapArea.y*/) : null;
		ctx.restore();

		return this.hover;
	}

	_setCurrentColor(hoveredId){
		if(hoveredId === -1) this.hover = false;
		// convert color to rgba
		const lockedRGB = hexToRGB(this._LOCKED_COLOR);
		const lockedComputedColor = `rgba(${lockedRGB.r}, ${lockedRGB.g}, ${lockedRGB.b}, ${this.ctx.globalAlpha})`;
		const hoverRGB = hexToRGB(this._HOVER_COLOR);
		const hoverComputedColor = `rgba(${hoverRGB.r}, ${hoverRGB.g}, ${hoverRGB.b}, ${this.ctx.globalAlpha})`;

		// TODO move settings ctx.strokeStyle to locked part

		if(this.borderColor?.includes('rgba')){
			this._currentColor = this.borderColor;
			this.ctx.strokeStyle = this.locked ? lockedComputedColor : this.borderColor;
		} else if(this.borderColor?.includes('rgb')){
			const colorRGB = hexToRGB(RGB_to_hex(this.borderColor));
			this._currentColor = `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 1)`;
		} else {
			const colorRGB = hexToRGB(this.borderColor);
			this._currentColor = `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 1)`;
		}

		if(this.locked) this._currentColor = lockedComputedColor;
		if(this.hover) this._currentColor = '#940000'//hoverComputedColor;
		if(!this.hover && !this.locked) this._currentColor = this.borderColor;

		// this.ctx.strokeStyle = this._currentColor;
	}

	_getMiddlePoint(){
		this.middlePoint.x = (this.fromPoint.x + this.toPoint.x) / 2;
		this.middlePoint.y = (this.fromPoint.y + this.toPoint.y) / 2;
	}
	
	_polygon(ctx, n, x, y, r, angle = 0, counterclockwise = false){
		ctx.moveTo(x + r * Math.sin(angle), y - r * Math.cos(angle));
		let delta = 2 * Math.PI / n; // angular distance between vertices
		for(let i = 1; i < n; i++){
			angle += counterclockwise ? -delta : delta;
			ctx.lineTo(x + r * Math.sin(angle), y - r * Math.cos(angle));
		}
	}

	_selectedMark(ctx, zoomLevel){
		const dotRadius = 4;
		
		ctx.save();
		// draw middle point
		const middlePoint = {
			ctx,
			x: Scale.x(this.middlePoint.x + mapArea.x, ctx.canvas.width / 2, zoomLevel),
			y: Scale.y(this.middlePoint.y + mapArea.y, ctx.canvas.height / 2, zoomLevel),
			radius: dotRadius * zoomLevel,
			startAngle: 0,
			endAngle: Math.PI * 2,
			counterclockwise: false,
			fillStyle: '#0125ea',
			strokeStyle: '#ffffff',
			lineWidth: 1 * zoomLevel
		}
		const fromPoint = {
			ctx,
			x: Scale.x(this.fromPoint.x + mapArea.x, ctx.canvas.width / 2, zoomLevel),
			y: Scale.y(this.fromPoint.y + mapArea.y, ctx.canvas.height / 2, zoomLevel),
			radius: dotRadius * zoomLevel,
			startAngle: 0,
			endAngle: Math.PI * 2,
			counterclockwise: false,
			fillStyle: '#ffffff',
			strokeStyle: '#0125ea',
			lineWidth: 1 * zoomLevel
		}
		const toPoint = {
			ctx,
			x: Scale.x(this.toPoint.x + mapArea.x, ctx.canvas.width / 2, zoomLevel),
			y: Scale.y(this.toPoint.y + mapArea.y, ctx.canvas.height / 2, zoomLevel),
			radius: dotRadius * zoomLevel,
			startAngle: 0,
			endAngle: Math.PI * 2,
			counterclockwise: false,
			fillStyle: '#ffffff',
			strokeStyle: '#0125ea',
			lineWidth: 1 * zoomLevel
		}


		this._drawCircle(middlePoint);
		this._drawCircle(fromPoint);
		this._drawCircle(toPoint);

		ctx.closePath();
		ctx.restore();
	}

}
