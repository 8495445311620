export default {
  namespaced: true,
  state: {
    quizzesURL: '/quizzes/',
    quizChoosing: false,
    quizChosen: null,
    quizEdited: null,
  },
  getters: {
    isQuizChoosing(state){
      return state.quizChoosing
    },
    getChosenQuiz(state){
      return state.quizChosen;
    },
    getEditedQuiz(state){
      return state.quizEdited;
    },
    getQuizzesURL(state){
      return state.quizzesURL;
    }
  },
  mutations: {
    SET_CHOOSING_QUIZ(state, status){
      state.quizChoosing = status;
    },
    SET_EDITED_QUIZ(state, quiz){
      state.quizEdited = quiz;
    }
  },
  actions: {
    quizChoosingSet({commit}, status){
      commit('SET_CHOOSING_QUIZ', status);
    },
    quizEditedSet({commit}, quiz){
      commit('SET_EDITED_QUIZ', quiz);
    }
  }
}