import FontUtils from '@/services/canvas/fonts/Fonts';
const {fontFamilyGetNameByUrl} = FontUtils();

export default function (){

  function createEdgeTemplateObject(templateData, fonts){
    return {
      id: templateData.id,
      name: templateData.name,
      type: templateData.type,
      lineStart: templateData.line_start,
      lineStartSize: templateData.line_start_size,
      lineEnd: templateData.line_end,
      lineEndSize: templateData.line_end_size,
      border: true,
      borderColor: templateData.border_color,
      borderStyle: templateData.border_style,
      borderWidth: templateData.border_width,
      shadow: templateData.shadow_enabled,
      shadowX: templateData.shadow_x,
      shadowY: templateData.shadow_y,
      shadowBlur: templateData.shadow_blur,
      shadowColor: templateData.shadow_color,
      fontColor: templateData.font_color,
      fontSize: templateData.font_size,
      fontFamily: fontFamilyGetNameByUrl(templateData.font_url, fonts),
      fontWeight: templateData.font_weight,
      fontStyle: templateData.font_style,
      textPosition: templateData.text_position,
      textDecoration: templateData.text_decoration,
      contentStart: 'START',
      contentMiddle: 'Label',
      contentEnd: 'END',
    }
  }

  const EDGE_TEMPLATE_MAPPING = {
    border: 'border_enabled', // todo deprecated -> remove
    borderColor: 'border_color',
    borderStyle: 'border_style',
    borderWidth: 'border_width',
    fontColor: 'font_color',
    fontSize: 'font_size',
    fontWeight: 'font_weight',
    fontStyle: 'font_style',
    fontFamily: 'font_url',
    lineHeight: 'line_height',
    shadowBlur: 'shadow_blur',
    shadowColor: 'shadow_color',
    shadow: 'shadow_enabled',
    shadowX: 'shadow_x',
    shadowY: 'shadow_y',
    textDecoration: 'text_decoration',
    textPosition: 'text_position',
    textAlign: 'text_align',
    lineStart: 'line_start',
    lineStartSize: 'line_start_size',
    lineEnd: 'line_end',
    lineEndSize: 'line_end_size',
  }

  function mappingInverted(mapping){
    const invertedMapping = {}
    for(let [key,value] of Object.entries(mapping)){
      Object.assign(invertedMapping, {[value]: key})
    }
    return invertedMapping;
  }

  function convertOriginalProperty(originalProperty){
    const [[key, value]] = Object.entries(originalProperty);
    const mapping = mappingInverted(EDGE_TEMPLATE_MAPPING);
    if(mapping[key]) return {[mapping[key]] : value}
  }
  function convertToOriginalProperty(templateProperty){
    const [[key, value]] = Object.entries(templateProperty);
    if(EDGE_TEMPLATE_MAPPING[key]) return {[EDGE_TEMPLATE_MAPPING[key]] : value}
  }

  return {
    createEdgeTemplateObject,
    convertOriginalProperty,
    convertToOriginalProperty,
  }
}