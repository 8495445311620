import {NODE_SHAPES} from "@/models/NODE_SHAPES";

export default function() {

  function calculateNodesCollidingForEdge(edge, nodeFrom, nodeTo){
    if(edge.fromNodeShape === NODE_SHAPES.CIRCLE.VALUE && edge.toNodeShape === NODE_SHAPES.CIRCLE.VALUE) {
      return isCirclesCollides(nodeFrom, nodeTo);
    }

    if(edge.fromNodeShape === NODE_SHAPES.RECTANGLE.VALUE && edge.toNodeShape === NODE_SHAPES.RECTANGLE.VALUE) {
      const rectangle1 = {
        r1x: edge.startPosX - edge.fromNodeWidth / 2 - edge.fromNodeBorderWidthValue,
        r1y: edge.startPosY - edge.fromNodeHeight / 2 - edge.fromNodeBorderWidthValue,
        r1w: edge.fromNodeWidth + edge.fromNodeBorderWidthValue * 2,
        r1h: edge.fromNodeHeight + edge.fromNodeBorderWidthValue * 2
      };
      const rectangle2 = {
        r2x: edge.endPosX - edge.toNodeWidth / 2 - edge.toNodeBorderWidthValue,
        r2y: edge.endPosY - edge.toNodeHeight / 2 - edge.toNodeBorderWidthValue,
        r2w: edge.toNodeWidth + edge.toNodeBorderWidthValue * 2,
        r2h: edge.toNodeHeight + edge.toNodeBorderWidthValue * 2
      }
      return isRectanglesCollides({rectangle1, rectangle2});
    }

    if(
      (edge.fromNodeShape === NODE_SHAPES.CIRCLE.VALUE && edge.toNodeShape === NODE_SHAPES.RECTANGLE.VALUE) ||
      (edge.fromNodeShape === NODE_SHAPES.RECTANGLE.VALUE && edge.toNodeShape === NODE_SHAPES.CIRCLE.VALUE)
    ) {
      const computedCX = edge.fromNodeShape === NODE_SHAPES.CIRCLE.VALUE ? edge.startPosX : edge.endPosX;
      const computedCY = edge.fromNodeShape === NODE_SHAPES.CIRCLE.VALUE ? edge.startPosY : edge.endPosY;
      const computedRadius =
        edge.fromNodeShape === NODE_SHAPES.CIRCLE.VALUE ?
          edge.fromNodeWidth / 2 + edge.fromNodeBorderWidthValue :
          edge.toNodeWidth / 2 + edge.toNodeBorderWidthValue;
      const circle = {
        cx: computedCX,
        cy: computedCY,
        radius: computedRadius
      };

      const computedRX =
        edge.fromNodeShape === NODE_SHAPES.RECTANGLE.VALUE ?
          edge.startPosX - edge.fromNodeWidth / 2 - edge.fromNodeBorderWidthValue :
          edge.endPosX - edge.toNodeWidth / 2 - edge.toNodeBorderWidthValue;
      const computedRY =
        edge.fromNodeShape === NODE_SHAPES.RECTANGLE.VALUE ?
          edge.startPosY - edge.fromNodeHeight / 2 - edge.fromNodeBorderWidthValue :
          edge.endPosY - edge.toNodeHeight / 2 - edge.toNodeBorderWidthValue
      const computedRH =
        edge.fromNodeShape === NODE_SHAPES.RECTANGLE.VALUE ?
          edge.fromNodeHeight + edge.fromNodeBorderWidthValue * 2 :
          edge.toNodeHeight + edge.toNodeBorderWidthValue * 2
      const computedRW =
        edge.fromNodeShape === NODE_SHAPES.RECTANGLE.VALUE ?
          edge.fromNodeWidth + edge.fromNodeBorderWidthValue * 2 :
          edge.toNodeWidth + edge.toNodeBorderWidthValue * 2;
      const rectangle = {
        rx: computedRX,
        ry: computedRY,
        rh: computedRH,
        rw: computedRW
      };

      return isCircleRectangleCollides({circle, rectangle});
    }
  }

  // COLLIDES DETECTION METHODS
  function isCirclesCollides(nodeFrom, nodeTo){
    const dx = nodeTo.x - nodeFrom.x;
    const dy = nodeTo.y - nodeFrom.y;
    const rSum = nodeFrom.radius + nodeTo.radius;
    return(dx * dx + dy * dy <= rSum * rSum);
  }

  function isRectanglesCollides({rectangle1, rectangle2}){
    return _testRectanglesHit({rectangle1, rectangle2});
  }

  function isCircleRectangleCollides({circle, rectangle}){
    return _testRectangleCircleHit({circle, rectangle})
  }

  function _testRectangleCircleHit({circle, rectangle}){
    const {cx, cy, radius} = circle;
    const {rx, ry, rw, rh} = rectangle;

    // temporary variables to set edges for testing
    let testX = cx;
    let testY = cy;

    // which edge is closest?
    if (cx < rx)         testX = rx;      // test left edge
    else if (cx > rx+rw) testX = rx+rw;   // right edge

    if (cy < ry)         testY = ry;      // top edge
    else if (cy > ry+rh) testY = ry+rh;   // bottom edge

    // get distance from the closest edges
    const distX = cx - testX;
    const distY = cy - testY;
    const distance = Math.sqrt( (distX*distX) + (distY*distY) );

    // if the distance is less than the radius, collision!
    return distance <= radius;
  }

  function _testRectanglesHit({rectangle1, rectangle2}){
    const {r1x, r1y, r1w, r1h} = rectangle1;
    const {r2x, r2y, r2w, r2h} = rectangle2;

    return  r1x + r1w >= r2x &&    // r1 right edge past r2 left
      r1x <= r2x + r2w &&    // r1 left edge past r2 right
      r1y + r1h >= r2y &&    // r1 top edge past r2 bottom
      r1y <= r2y + r2h;
  }

  return {
    calculateNodesCollidingForEdge
  }
}