export default function(){
	
	async function createToastFromStore(type, message, dispatch){
		await dispatch('toastMessages/toastAdd', {type, message}, {root: true})
	}
	
	async function createToastFromComponent(type, message, store){
		await store.dispatch('toastMessages/toastAdd', {type, message})
	}
	
	return {
		createToastFromStore,
		createToastFromComponent
	}
}
