import ApiService from "@/utils/ApiService";
import {SE_SYNC_TYPES} from "@/models/SE_SYNC_TYPES";
import {TOASTS_TYPES} from "@/models/TOASTS_TYPES";
import ToastUtils from "@/utils/ToastUtils";
import useMapArea from "@/services/canvas/map-area/MapArea";
import useI18nGlobal from "@/utils/i18n";
const {createToastFromStore} = ToastUtils();
const {mapArea} = useMapArea();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
  namespaced: true,
  state: () => ({}),
  getters: {
    getEdges(state, getters, rootState, rootGetters){
      return rootGetters["edges/getEdges"]
    },
    getEdgesOriginal(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesOriginal"];
    },
    getEdgesURL(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesURL"];
    },
    getCurrentMap(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getCurrentMap"]
    },
    getMapsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getMapsURL"]
    },
    getElementsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getElementsURL"]
    },
  },
  mutations: {
    REMOVE_EDGE(state, {edgeId, edges}) {
      const edgeIndex = edges.findIndex(edge => edge.id === edgeId);
      edges.splice(edgeIndex, 1);
    },
    REMOVE_EDGE_ORIGINAL(state, {edgeId, edgesOriginal}) {
      const edgeIndex = edgesOriginal.findIndex(edge => edge.id === edgeId);
      edgesOriginal.splice(edgeIndex, 1);
    },
    REMOVE_ALL_EDGES(state, edges) {
      edges = [];
    },
    REMOVE_ALL_EDGES_ORIGINAL(state, edgesOriginal) {
      edgesOriginal = []
    },
  },
  actions: {
    async edgeRemoveRequest({getters, commit, dispatch}, edgeId) {
      const mapsURL = getters.getMapsURL;
      const mapId = getters.getCurrentMap.id;
      const edgesURL = getters.getEdgesURL;

      try {
        await ApiService.deleteRequest(`${mapsURL}${mapId}${edgesURL}${edgeId}`)
        dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true}); // set updated date
        dispatch('dashboardOptions/optionChoose', null, {root: true}); // close settings sidebar
        dispatch("dashboardOptions/groupEdgeToggle", false, {root: true}); // close toggle options
        await dispatch('edges/edgeSetActive', null, {root: true});

        dispatch('edgeRemove', edgeId);

        const editsData = {
          type: SE_SYNC_TYPES.EDGE_REMOVED,
          data: [edgeId]
        }
        dispatch('simultaneousEditing/syncEdits', editsData, {root: true});

        await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgeRemoveSuccess', {id: edgeId}), dispatch);
      } catch (err) {
        await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgeRemoveError'), dispatch);
      }
    },
    edgeRemove({getters, commit, dispatch}, edgeId) {
      const edges = getters.getEdges;
      const edgesOriginal = getters.getEdgesOriginal;
      const edgeRemoved = edges.find(edge => edge.id === edgeId);
      const edgeFromRemovedData = {edgeFromId: edgeRemoved.id, nodeId: edgeRemoved.fromNodeId};
      const edgeToRemovedData = {edgeToId: edgeRemoved.id, nodeId: edgeRemoved.toNodeId};

      dispatch('nodes/nodeRemoveRelatedEdge', edgeFromRemovedData, {root: true}); // remove fromId of node related
      dispatch('nodes/nodeRemoveRelatedEdge', edgeToRemovedData, {root: true}); // remove toId of node related
      commit('REMOVE_EDGE', {edgeId, edges});
      commit('REMOVE_EDGE_ORIGINAL', {edgeId, edgesOriginal});
      mapArea.updateEl();
    },

    async edgesRemoveAllRequest({getters, dispatch}) {
      const mapsURL = getters.getMapsURL;
      const mapId = getters.getCurrentMap.id;
      const elementsURL = getters.getElementsURL;
      const edges = getters.getEdges;

      if (edges.length) {
        const request = {'edges': []};
        edges.forEach(edge => {
          request.edges.push({id: edge.id, delete: true})
        })

        try {
          await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, request);
          dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true}); // set updated date
          await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgesRemoveSuccess'), dispatch);

          dispatch('edgesRemoveAll');
        } catch (err) {
          await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgesRemoveError'), dispatch);
        }
      }
    },
    edgesRemoveAll({getters, commit, dispatch}) {
      const edges = getters.getEdges;
      const edgesOriginal = getters.getEdgesOriginal;
      commit('REMOVE_ALL_EDGES', edges);
      commit('REMOVE_ALL_EDGES_ORIGINAL', edgesOriginal);
      dispatch('nodes/nodeRemoveAllRelatedEdges', null, {root: true}); // remove all related edges ids from node object
      mapArea.updateEl();
    },

    edgesRemove({getters, commit, dispatch}, edgesIds) {
      edgesIds.forEach(edgeId => {
        dispatch('edgeRemove', edgeId);
      })
    },
    async edgesRemoveRequest({getters, dispatch}, edgesIds) {
      const mapsURL = getters.getMapsURL;
      const mapId = getters.getCurrentMap.id;
      const elementsURL = getters.getElementsURL;

      const request = {'edges': edgesIds.map(edgeId => ({id: edgeId, delete: true}))};

      try {
        await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, request);
        const editsData = {
          type: SE_SYNC_TYPES.EDGE_REMOVED,
          data: edgesIds
        }
        dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
        await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgesRelatedRemoveSuccess'), dispatch);
      } catch (err) {
        await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgesRelatedRemoveError'), dispatch);
      }
    },

    async edgesRemoveRelatedToDeletedNode({getters, commit}, nodeId) {
      const edges = getters.getEdges;
      const edgesOriginal = getters.getEdgesOriginal;
      const relatedEdges = edges.filter(edge => edge.fromNodeId === nodeId || edge.toNodeId === nodeId);
      relatedEdges.forEach(edge => {
        commit('REMOVE_EDGE', {edgeId: edge.id, edges});
        commit('REMOVE_EDGE_ORIGINAL', {edgeId: edge.id, edgesOriginal});
      })
    },
    async edgesRemoveRelatedToDeletedNodeRequest({getters, dispatch}, nodeId) {
      const edges = getters.getEdges;
      const relatedEdges = edges.filter(edge => edge.fromNodeId === nodeId || edge.toNodeId === nodeId);

      const mapsURL = getters.getMapsURL;
      const mapId = getters.getCurrentMap.id;
      const elementsURL = getters.getElementsURL;

      if (relatedEdges.length) {
        const request = {'edges': []};
        relatedEdges.forEach(edge => {
          request.edges.push({id: edge.id, delete: true})
        })

        try {
          await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, request);
          await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgesRelatedRemoveSuccess'), dispatch);
        } catch (err) {
          await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgesRelatedRemoveError'), dispatch);
        }
      }
    },
  }
}