import ApiService from "@/utils/ApiService";
import {SE_SYNC_TYPES} from "@/models/SE_SYNC_TYPES";
import {TOASTS_TYPES} from "@/models/TOASTS_TYPES";
import useMapArea from "@/services/canvas/map-area/MapArea";
import useI18nGlobal from "@/utils/i18n";
import ToastUtils from "@/utils/ToastUtils";
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";
const {createToastFromStore} = ToastUtils();
const {mapArea} = useMapArea();
const {i18n, getCurrentLang} = useI18nGlobal();
const {t} = i18n.global;

export default {
  namespaced: true,
  state: () => ({
    contentTimeout: null,
    contentOrder: {},
  }),
  getters: {
    getEdgesOriginal(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesOriginal"];
    },
    getCurrentMap(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getCurrentMap"]
    },
    getMapsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getMapsURL"]
    },
    getElementsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getElementsURL"]
    },
    getLanguage(state, getters, rootState, rootGetters) {
      return rootGetters["maps/languages/getLanguage"]
    },
    getLanguageLocal(state, getters, rootState, rootGetters) {
      return rootGetters["maps/languages/getLanguageLocal"]
    }
  },
  mutations: {
    UPDATE_EDGE_CONTENT(state, {id, edgeData, edgesOriginal, dispatch}) {
      const edge = edgesOriginal.find(edge => edge.id === id);
      if(!edge) {
        createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgeContentError', {id}), dispatch);
        return;
      }
      const result = {lang: {}};

      for(const isoKey of Object.keys(edge.content.lang)){
        if(edgeData.lang[isoKey]){
          result.lang[isoKey] = Object.assign({}, edge.content.lang[isoKey], edgeData.lang[isoKey]);
        } else {
          result.lang[isoKey] = {...edge.content.lang[isoKey]};
        }
      }

      Object.assign(edge.content, result);
    },
  },
  actions: {
    edgeContentUpdate({getters, commit, dispatch}, {id, edgeData}) {
      const currentLang = getters.getLanguage.id;
      const localLang = getters.getLanguageLocal.id;
      const edgesOriginal = getters.getEdgesOriginal;
      // original edges should contain lang object
      commit('UPDATE_EDGE_CONTENT', {id, edgeData, edgesOriginal, dispatch}); // update edge original
      // edge (not original) shouldn't contain lang object
      // and should be updated only if received data contains current language
      if(edgeData.lang[localLang]){
        const content = JSON.parse(JSON.stringify(edgeData.lang[localLang]));
        delete content.translated;
        commit('edges/UPDATE_EDITED_EDGE', {edgeId: id, edgeData: {content}}, {root: true}); // update edge
      }
      /*const content = JSON.parse(JSON.stringify(edgeData.lang[currentLang]));
      if(content){
        delete content.translated;
        commit('edges/UPDATE_EDITED_EDGE', {edgeId: id, edgeData: {content}}, {root: true}); // update edge
      }*/
      mapArea.updateEl(true, id, EDITING_ELEM_TYPES.EDGE);
    },
    edgeContentSave({state, getters, dispatch}, {id, edgeData}) {
      Object.assign(state.contentOrder, edgeData);
      clearTimeout(state.contentTimeout);
      state.contentTimeout = setTimeout(async () => {
        const mapsURL = getters.getMapsURL;
        const elementsURL = getters.getElementsURL;
        const mapId = getters.getCurrentMap.id;
        // content must contain lang object
        const data = {
          'edges': [
            {
              id,
              'content': state.contentOrder
            }
          ]
        };
        edgeData = Object.assign({}, state.contentOrder);

        try {
          state.contentOrder = {};
          await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, data);
          dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true}); // set updated date

          const editsData = {
            type: SE_SYNC_TYPES.EDGE_EDITED_CONTENT,
            data: {id, edgeData}
          }
          dispatch('simultaneousEditing/syncEdits', editsData, {root: true});

          await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgeContentSuccess', {id}), dispatch);
        } catch (err) {
          state.contentOrder = {};
          await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgeContentError', {id}), dispatch);
        }
      }, 700)
    },
  }
}