export default {
	namespaced: true,
	state: {
		assetsURL: '/media/',
		assetsStatsURL: 'stats',
		assetsStatTemplateURL: '/media/stats?templates=only',
		assetsParamTemplates: 'templates=only',
		assetsParamMap: 'map='
	},
	getters: {
		getAssetsURL(state){
			return state.assetsURL
		},
		getAssetsStatsURL(state){
			return state.assetsStatsURL
		},
		getAssetsStatTemplateURL(state){
			return state.assetsStatTemplateURL
		},
		getAssetsParamTemplates(state){
			return state.assetsParamTemplates
		},
		getAssetsParamMap(state){
			return state.assetsParamMap
		}
	},
	mutations: {
	
	},
	actions: {
	
	}
}
