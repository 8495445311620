import QuotaUtils from "@/utils/client/QuotaUtils";
const {checkAvailableQuotas} = QuotaUtils();
export default {
  namespaced: true,
  state: {
    quota: null,
    quotaCount: null
  },
  getters: {
    getQuota(state){
      return state.quota;
    },
    getQuotaCount(state){
      return state.quotaCount;
    },
    isLiveMapAvailable(state){
      return checkAvailableQuotas(state.quota?.liveMapQuota.value, state.quotaCount?.liveMapCount.value);
    }
  },
  mutations: {
    SET_QUOTA(state, quota){
      state.quota = quota;
    },
    SET_QUOTA_COUNT(state, quota){
      state.quotaCount = quota;
    },
    UPDATE_QUOTA_COUNT(state, prop){
      // prop: {name: value}
      const name = Object.keys(prop)[0];
      Object.assign(state.quotaCount[name], {value: prop[name]});
    }
  },
  actions: {
    quotaSet({commit}, data){
      commit('SET_QUOTA', data);
    },
    quotaCountSet({commit}, data){
      commit('SET_QUOTA_COUNT', data);
    },
    quotaCountUpdate({commit}, data){
      commit('UPDATE_QUOTA_COUNT', data);
    }
  }
}