export default {
	namespaced: true,
	state: {
		isActive: false,
		contentMedia: null,
		editorActive: null,
	},
	getters: {
		isMediaContentActive(state){
			return state.isActive;
		},
		getContentMedia(state){
			return state.contentMedia;
		},
		getEditorActive(state){
			return state.editorActive;
		}
	},
	mutations: {
		SET_MEDIA_CONTENT(state, status){
			state.isActive = status;
		},
		SET_CONTENT_ACTIVE(state, editorId){
			state.editorActive = editorId;
		}
	},
	actions: {
		mediaContentToggle({commit}, status){
			commit('SET_MEDIA_CONTENT', status);
		},
		setEditorActive({commit}, editorId){
			commit('SET_CONTENT_ACTIVE', editorId);
		},
		/*async appendMediaContent({dispatch}){
			return await dispatch('getChosenMedia');
		}*/
	}
}
