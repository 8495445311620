import FontUtils from '@/services/canvas/fonts/Fonts';
import useI18nGlobal from "@/utils/i18n";
const {fontFamilyGetNameByUrl} = FontUtils();
import store from '@/store/store'
import {computed} from "vue";

export default function() {
	function createEdgeObject(edge, nodes, elementsDesign, fonts){
		const startNode = nodes.find(node => node.id === edge.node.from_id);
		const endNode = nodes.find(node => node.id === edge.node.to_id);
		const edgeDesign = getEdgeDesign(edge, elementsDesign);
		// get content for current language because only main language displayed on the map
		const currentLang = computed(() => store.getters['maps/languages/getLanguage'].id);
		const contentComputed = edge.content.lang[currentLang.value];
		delete contentComputed.translated;

		return {
			id: edge.id,
			template: edge.design.object_template,
			fromNodeId: edge.node.from_id,
			fromNodeHeight: startNode.height,
			fromNodeWidth: startNode.width,
			fromNodeBorderWidth: startNode.border ?  startNode.borderWidth : 0,
			fromNodeBorder: startNode.border,
			fromNodeShape: startNode.shape,
			lineStart: edgeDesign.line_start,
			lineStartSize: edgeDesign.line_start_size,
			toNodeId: edge.node.to_id,
			toNodeHeight: endNode.height,
			toNodeWidth: endNode.width,
			toNodeBorderWidth: endNode.border ? endNode.borderWidth : 0,
			toNodeBorder: endNode.border,
			toNodeShape: endNode.shape,
			lineEnd: edgeDesign.line_end,
			lineEndSize: edgeDesign.line_end_size,
			startPosX: startNode.x,
			endPosX: endNode.x,
			startPosY: startNode.y,
			endPosY: endNode.y,
			border: true,
			borderColor: edgeDesign.border_color,
			borderStyle: edgeDesign.border_style,
			borderWidth: edgeDesign.border_width,
			shadow: edgeDesign.shadow_enabled,
			shadowX: edgeDesign.shadow_x,
			shadowY: edgeDesign.shadow_y,
			shadowBlur: edgeDesign.shadow_blur,
			shadowColor: edgeDesign.shadow_color,
			fontColor: edgeDesign.font_color,
			fontSize: edgeDesign.font_size,
			fontFamily: fontFamilyGetNameByUrl(edgeDesign.font_url, fonts),
			fontWeight: edgeDesign.font_weight,
			fontStyle: edgeDesign.font_style,
			textPosition: edgeDesign.text_position,
			textDecoration: edgeDesign.text_decoration,
			content: contentComputed,
			visibilityMin: edge.visibility.min_level,
			visibilityMax: edge.visibility.max_level,
			editing: null,
			hidden: startNode.hidden || endNode.hidden || false,
		}
	}
	
	function getEdgeDesign(edge, elementsDesign){
		// check design properties of a node if null ->
		// check object template, if "0" -> use global design, if null -> use system defaults
		const {templates, edges: global, defaults : {edges: edgeDefaults}} = elementsDesign;
		let edgeDesign = {};
		let unlinkedDesign = JSON.parse(JSON.stringify(edge.design));
		for(let [key, value] of Object.entries(unlinkedDesign)){
			if(value === null){
				const templateId = edge.design.object_template;
				const propertyValue =
					templateId && getValueFromTemplate(templateId, templates, global, key) !== null &&
					getValueFromTemplate(templateId, templates, global, key) !== '' ?
						getValueFromTemplate(templateId, templates, global, key) :
						edgeDefaults[key];
				edgeDesign = Object.assign(edgeDesign, {[key]: propertyValue});
			} else {
				edgeDesign = Object.assign(edgeDesign, {[key]: value});
			}
		}
		return edgeDesign;
	}
	function getEdgeDesignFromTemplate(templateId, elementsDesign){
		const {templates, edges: global, defaults : {edges: edgeDefaults}} = elementsDesign;
		let edgeDesign = {};
		// if templateId look template[property] -> global[property] -> default[property]
		if(templateId){
			let template = templateId !== '0' ? templates[templateId] : global;
			for(let [key, value] of Object.entries(template)){
				value === null ?
					// if value is null check global value, if still null get default value
					Object.assign(edgeDesign, {[key]: global[key] !== null ? global[key] : edgeDefaults[key]}) :
					// else get template value
					Object.assign(edgeDesign, {[key]: value});
			}
		} else {
			// if templateId is null get values from node default
			edgeDesign = edgeDefaults;
		}
		
		return edgeDesign;
	}
	function getEdgeDesignSyncedToOriginal(edgeId, edgesOriginal, elementsDesign, fonts){
		const edge = edgesOriginal.find(edge => edge.id === edgeId);
		let edgeDesign = {};
		const edgeOriginalDesign = getEdgeDesign(edge, elementsDesign);
		// collect edge object from related edge
		for(let [key, value] of Object.entries(edgeOriginalDesign)){
			// convert font_url value ( which is url with id) to fontFamily value;
			if(key === 'font_url' && value){
				value = fontFamilyGetNameByUrl(value, fonts)
			}
			Object.assign(edgeDesign, convertOriginalToEdgeProperty({[key]: value}))
		}
		return edgeDesign;
	}
	function getValueFromTemplate(templateId, templates, global, property){
		return templateId === "0" ? global[property] : templates[templateId][property];
	}
	
	function getEdgeDesignInitialComputedValue(templateId, elementDesign, property){
		const edgeDesign = getEdgeDesignFromTemplate(templateId, elementDesign);
		return edgeDesign[property];
	}
	
	function getEdgeChangedValues(edge){
		let changedValues = {};
		for(let [property, value] of Object.entries(edge)){
			// avoid checking non-comparable values
			if(property !== 'object_template' && value !== null){
				changedValues = Object.assign(changedValues, {[property]: value})
			}
		}
		return changedValues;
	}
	
	const EDGE_PROPS_MAPPING = {
		border: 'border_enabled', // todo deprecated -> remove
		borderColor: 'border_color',
		borderStyle: 'border_style',
		borderWidth: 'border_width',
		fontColor: 'font_color',
		fontSize: 'font_size',
		fontWeight: 'font_weight',
		fontStyle: 'font_style',
		fontFamily: 'font_url',
		lineHeight: 'line_height',
		shadowBlur: 'shadow_blur',
		shadowColor: 'shadow_color',
		shadow: 'shadow_enabled',
		shadowX: 'shadow_x',
		shadowY: 'shadow_y',
		textDecoration: 'text_decoration',
		textPosition: 'text_position',
		textAlign: 'text_align',
		lineStart: 'line_start',
		lineStartSize: 'line_start_size',
		lineEnd: 'line_end',
		lineEndSize: 'line_end_size',
		
		visibilityMin: 'min_level',
		visibilityMax: 'max_level'
	}
	function mappingInverted(){
		const invertedMapping = {}
		for(let [key,value] of Object.entries(EDGE_PROPS_MAPPING)){
			Object.assign(invertedMapping, {[value]: key})
		}
		return invertedMapping;
	}
	function convertToOriginalEdgeProperty(edgeProperty){
		const [[key, value]] = Object.entries(edgeProperty);
		if(EDGE_PROPS_MAPPING[key]){
			return {[EDGE_PROPS_MAPPING[key]] : value}
		}
	}
	function convertOriginalToEdgeProperty(edgeProperty){
		const [entries] = Object.entries(edgeProperty);
		const [key, value] = entries;
		const mapping = mappingInverted();
		
		return {[mapping[key]] : value}
	}
	
	function adaptedOriginalDesignPropertiesToEdge(edgeOriginal){
		const adaptedOriginal = {};
		for(let [key, value] of Object.entries(EDGE_PROPS_MAPPING)){
			if(edgeOriginal[value] !== undefined) Object.assign(adaptedOriginal, {[key]: edgeOriginal[value]})
		}
		return adaptedOriginal;
	}
	
	return {
		EDGE_PROPS_MAPPING,
		createEdgeObject,
		getEdgeDesignInitialComputedValue,
		getEdgeDesignFromTemplate,
		getEdgeChangedValues,
		getEdgeDesignSyncedToOriginal,
		
		convertToOriginalEdgeProperty,
		convertOriginalToEdgeProperty,
		adaptedOriginalDesignPropertiesToEdge
	}
}

