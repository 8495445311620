import {LANGUAGES} from "@/models/LANGUAGES";
export default function useUserLanguage(){
  function getLanguageSetByName(name){
    for(const value of Object.values(LANGUAGES)){
      if(value.name === name)	return value;
    }
  }
  function getLanguageSetByCode(code){
    for(const value of Object.values(LANGUAGES)){
      if(value.code === code)	return value;
    }
  }

  return {
    getLanguageSetByName,
    getLanguageSetByCode,
  }
}