import CanvasTxt from '@/services/canvas/text/CanvasText';
import {Scale} from '@/services/canvas/Scale';
import useColorUtils from '@/utils/colorUtils';
import useMapArea from '@/services/canvas/map-area/MapArea';
import useShapeUtils from "@/services/canvas/nodes/ShapeUtils";
import NodeTemplate from "@/services/canvas/templates/NodeTemplate";
const {mapArea} = useMapArea();
const {hexToRGB} = useColorUtils();
const {addShapeToContext} = useShapeUtils();
import {SIZER_POINTS} from "@/models/node/SIZER_POINTS";

export default class Node extends NodeTemplate{
	constructor(
		{
			x, y, template, shape, edgesFrom, edgesTo,
			radius, width, height, rotate,
			border, borderColor, borderStyle, borderWidth,
			surface, bgColor, shadow, shadowColor, shadowX, shadowY, shadowBlur,
			title, titlePosition, textAlign, fontSize, fontColor, fontFamily, fontWeight, fontStyle, lineHeight,
			mediaCropEnabled, mediaSize, mediaOpacity, media, mediaURL, image, gifAnimation,
			id,
			visibilityMin, visibilityMax, hidden, hiddenLocal, locked, connectSign,
			editingContent, editingAppearance, availableForConnection,
			contentBlocked, contentLinkBlocked
		})
	{
		super({
			shape, id,
			radius, width, height, rotate,
			border, borderColor, borderStyle, borderWidth,
			surface, bgColor, shadow, shadowColor, shadowX, shadowY, shadowBlur,
			title, titlePosition, textAlign, fontSize, fontColor, fontFamily, fontWeight, fontStyle, lineHeight,
			mediaCropEnabled, mediaSize, mediaOpacity, media, mediaURL, image, gifAnimation
		});
		this.x = x;
		this.y = y;

		this.template = template;
		this.visibilityMin = visibilityMin;
		this.visibilityMax = visibilityMax;

		this.hidden = hidden;
		this.hiddenLocal = hiddenLocal;
		this.locked = locked;
		this.connectSign = connectSign;

		this.edgesFrom = edgesFrom;
		this.edgesTo = edgesTo;

		this.contentBlocked = contentBlocked;
		this.contentLinkBlocked = contentLinkBlocked;
		
		this.editingContent = editingContent;
		this.editingAppearance = editingAppearance;
		this.availableForConnection = availableForConnection;

		this.pathInteraction = null;

		this.currentLayerID = 1;
		this.ZOOM_MAX = 1.62854;

		this.selectedPoints = [];
		this.selectedPoint = null;
	}

	// CACHE
	cache(layerID, ignoreMedia = false){
		this.currentLayerID = parseInt(layerID);
		this.globalAlpha = this.getVisibility(layerID);

		// get coords of shape's center
		({x: this.xCache, y: this.yCache} = super._getShapeCoordinates());

		if(this.availableForConnection){
			if(this.editingContent || this.editingAppearance) this._drawEditing();
			super._drawShape();
			if(!ignoreMedia) super._drawMedia();
			super._drawStroke();

			this._drawVisibilityMask();
			if(this.hidden) this._drawHiddenMask();
		}

		this._drawId();
		if(this.fontSize !== null && this.fontSize > 0 && this.availableForConnection) super._drawText();

		if(!this.availableForConnection) this._drawConnectionReadyStatus();

	}

	cacheUpdate(layerID, ignoreMedia = false){
		super.cacheClear();
		this.cache(layerID, ignoreMedia);
	}

	// DRAWS
	draw(contexts, zoomLevel, ignoreMapArea = false, area = {width: null, height: null, shiftX: 0, shiftY: 0}){
		if(this.hiddenLocal) return;

		const {ctxBuffered: ctx, ctxHighlights} = contexts
		/**
		 * to draw cacheInstance to ctx of the mapArea by computed coordinates
		 * */
		// ignore map area used for getting snapshot of the map
		const {width, height, shiftX, shiftY} = area;
		const shiftMapX = ignoreMapArea ? width / 2 + Math.abs(shiftX)  : mapArea.x;
		const shiftMapY = ignoreMapArea ? height / 2 + Math.abs(shiftY) : mapArea.y;

		const computedX = Scale.x(this.x - this.xCache + shiftMapX, ctx.canvas.width / 2, zoomLevel);
		const computedY = Scale.y(this.y - this.yCache + shiftMapY, ctx.canvas.height / 2, zoomLevel);
		const ZOOM_FACTOR = this.ZOOM_MAX * this.pixelRatio;

		if(!this.cacheInstance.width || !this.cacheInstance.height) return;
		ctx.drawImage(this.cacheInstance, computedX, computedY, (this.cacheInstance.width / ZOOM_FACTOR) * zoomLevel, (this.cacheInstance.height / ZOOM_FACTOR) * zoomLevel);

		// change path for interaction accordingly
		this.pathInteraction = new Path2D();
		addShapeToContext({
			ctx: this.pathInteraction,
			node: this,
			center: {
				x: Scale.x(this.x + mapArea.x, ctx.canvas.width / 2, zoomLevel),
				y: Scale.y(this.y + mapArea.y, ctx.canvas.height / 2, zoomLevel)
			},
			zoomLevel
		})

		// draw selected
		if(this.selected) this._drawSelected(ctxHighlights, zoomLevel);
	}

	_drawSelected(ctx, zoomLevel){
		const points = [];
		const computedBorder = this.border ? this.borderWidth * 2 : 0;
		const rectWidth = this.width + computedBorder;
		const rectHeight = this.height + computedBorder;

		// start from top left point
		const point1 = {x: this.x - rectWidth / 2, y: this.y - rectHeight / 2,
			position: SIZER_POINTS.TOP_LEFT, pointer: 'nw-resize'};
		const point2 = {x: this.x + rectWidth / 2, y: this.y - rectHeight / 2, position: SIZER_POINTS.TOP_RIGHT, pointer: 'ne-resize'};
		const point3 = {x: this.x + rectWidth / 2, y: this.y + rectHeight / 2, position: SIZER_POINTS.BOTTOM_RIGHT, pointer: 'nw-resize'};
		const point4 = {x: this.x - rectWidth / 2, y: this.y + rectHeight / 2, position: SIZER_POINTS.BOTTOM_LEFT, pointer: 'ne-resize'};
		points.push(...[point1, point2, point3, point4]);

		const DOT_RADIUS = 3;
		const dot = {
			ctx,
			x: null,
			y: null,
			radius: DOT_RADIUS * zoomLevel,
			startAngle: 0,
			endAngle: Math.PI * 2,
			counterclockwise: false,
			fillStyle: '#ffffff',
			strokeStyle: '#2750E2',
			lineWidth: 1 * zoomLevel
		}

		ctx.beginPath();
		ctx.rect(
			Scale.x(point1.x + mapArea.x, ctx.canvas.width / 2, zoomLevel),
			Scale.y(point1.y + mapArea.y, ctx.canvas.height / 2, zoomLevel),
			rectWidth * zoomLevel,
			rectHeight * zoomLevel
		)
		ctx.closePath();
		ctx.strokeStyle = '#2750E2';
		ctx.lineWidth = 1 * zoomLevel;
		ctx.stroke();

		this.selectedPoints = [];
		points.forEach(point => {
			point.x = Scale.x(point.x + mapArea.x, ctx.canvas.width / 2, zoomLevel);
			point.y = Scale.y(point.y + mapArea.y, ctx.canvas.height / 2, zoomLevel);
			const settings = Object.assign({}, dot, point);
			this.selectedPoints.push(settings);
			this._drawCircle(settings);
		})
	}

	_drawId(){
		const CONNECTION_ICON = '\uf337';
		const ICON_FONT_SIZE = 10;
		const LOCK_ICON = '\uf023';
		const HIDDEN_ICON = '\uf070';
		const defaultColor = '#2750e2';
		const defaultInRGB = hexToRGB(defaultColor);
		const defaultIdWidth = 20;// 14
		const defaultIdHeight = 12;
		const connectSign = this.connectSign ? ' +' : '';
		const computedBorder = this.border ? this.borderWidth : 0;
		const drawData = {
			ctx: this.ctx,
			title: `${String(this.id)}${connectSign}`,
			x: this.xCache + this.width / 4,
			y: this.yCache - (this.height / 2 + computedBorder + defaultIdHeight),
			width: defaultIdWidth,
			height: defaultIdHeight
		}
		const CanvasText = new CanvasTxt(drawData, {fontSize: 10, align: 'left'});

		this.ctx.beginPath();
		this.ctx.save();

		this.ctx.fillStyle = `rgba(${defaultInRGB.r}, ${defaultInRGB.g}, ${defaultInRGB.b}, ${this.globalAlpha})`;

		if(!this.hiddenLocal){
			// draw id
			CanvasText.drawText(drawData, {align: 'right'});
			this.ctx.beginPath();
			if(this.locked || this.hidden){
				// draw locked symbol
				this.ctx.font = `900 ${ICON_FONT_SIZE}px "Font Awesome 5 Free"`;
				const lockIcon = this.locked ? LOCK_ICON : '';
				const hiddenIcon = this.hidden ? HIDDEN_ICON : '';
				const LOCK_ICON_WIDTH = 12;
				const HIDDEN_ICON_WIDTH = 15;
				const ICONS_DIFF = 3;
				// if both icon to show need additional padding from id
				// if only hidden icon ->
				// hidden icon's width is more than locked icon -> need additional padding
				const iconComputedWidth = this.hidden && this.locked ?
					this.width / 4 - LOCK_ICON_WIDTH - HIDDEN_ICON_WIDTH :
					this.hidden ?
						this.width / 4 - ICONS_DIFF - HIDDEN_ICON_WIDTH :
						this.width / 4 - LOCK_ICON_WIDTH;

				this.ctx.fillText(
					`${lockIcon} ${hiddenIcon}`,
					this.xCache + iconComputedWidth,
					this.yCache + ICON_FONT_SIZE - (this.height / 2 + computedBorder + defaultIdHeight),
				)
			}
		}
		this.ctx.restore();
		this.ctx.closePath();
	}

	_drawEditing(){
		this.ctx.beginPath();
		this.ctx.save();
		
		const MIN_NODE_WIDTH = 60;
		const STATUS_LAYER_HEIGHT = 29;
		const STATUS_LAYER_PADDING = 2;
		const STATUS_LAYER_COLOR = 'rgba(60, 60, 60, .54)';
		const STATUS_FONT_SIZE = 15;
		const STATUS_FONT_COLOR_DEFAULT = 'rgba(247, 251, 251, 0.42)';
		const STATUS_APPEARANCE_ICON = '\uf1fc';
		const STATUS_CONTENT_ICON = '\uf03a';
		
		// draw layer under shape
		const borderWidth = this.border ? this.borderWidth : 0;
		this.ctx.font = `900 ${STATUS_FONT_SIZE}px "Font Awesome 5 Free"`;
		
		// if node's width more than min width for drawing vertical layer
		if(this.width >= MIN_NODE_WIDTH){
			// console.log('MIN_NODE_WIDTH ' + this.id);
			this.ctx.beginPath();
			this.ctx.fillStyle = STATUS_LAYER_COLOR;
			this.ctx.rect(
				this.xCache - this.width / 2 - borderWidth,
				this.yCache - this.height / 2 - borderWidth,
				this.width + borderWidth * 2,
				this.height + borderWidth * 2
			)
			// draw editing status layer
			this.ctx.rect(
				this.xCache - this.width / 2 - borderWidth,
				this.yCache + this.height / 2 + borderWidth + STATUS_LAYER_PADDING,
				this.width + borderWidth * 2,
				STATUS_LAYER_HEIGHT
			)
			if(!this.hiddenLocal) {
				this.ctx.fill();
			}

			// draw icons
			this.ctx.beginPath();
			this.ctx.fillStyle = this.editingAppearance ? this.editingAppearance.color : STATUS_FONT_COLOR_DEFAULT;
			this.ctx.fillText(
				STATUS_APPEARANCE_ICON,
				this.xCache - this.width / 4 - borderWidth - STATUS_FONT_SIZE / 2,
				this.yCache + this.height / 2 + borderWidth + STATUS_LAYER_HEIGHT / 2 + STATUS_FONT_SIZE / 2,
			)
			
			this.ctx.fillStyle = this.editingContent ? this.editingContent.color : STATUS_FONT_COLOR_DEFAULT;
			this.ctx.fillText(
				STATUS_CONTENT_ICON,
				this.xCache + this.width / 4 + borderWidth - STATUS_FONT_SIZE / 2,
				this.yCache + this.height / 2 + borderWidth + STATUS_LAYER_HEIGHT / 2 + STATUS_FONT_SIZE / 2,
			)
			if(!this.hiddenLocal) {
				this.ctx.fill();
			}
		} else if(this.height >= STATUS_LAYER_HEIGHT) {
			// if node's width less than min width for drawing vertical layer
			this.ctx.beginPath();
			this.ctx.fillStyle = STATUS_LAYER_COLOR;
			this.ctx.rect(
				this.xCache - this.width / 2 - borderWidth,
				this.yCache - this.height / 2 - borderWidth,
				this.width + borderWidth * 2,
				this.height + borderWidth * 2
			)
			// draw editing status layer
			this.ctx.rect(
				this.xCache + this.width / 2 + borderWidth + STATUS_LAYER_PADDING,
				this.yCache - this.height / 2 - borderWidth,
				MIN_NODE_WIDTH,
				this.height + borderWidth * 2
			)
			if(!this.hiddenLocal) {
				this.ctx.fill();
			}
			
			// draw icons
			this.ctx.beginPath();
			this.ctx.fillStyle = this.editingAppearance ? this.editingAppearance.color : STATUS_FONT_COLOR_DEFAULT;
			this.ctx.fillText(
				STATUS_APPEARANCE_ICON,
				this.xCache + this.width / 2 + borderWidth + MIN_NODE_WIDTH / 2 - STATUS_FONT_SIZE * 1.2,
				this.yCache + STATUS_FONT_SIZE / 2
			)
			
			this.ctx.fillStyle = this.editingContent ? this.editingContent.color : STATUS_FONT_COLOR_DEFAULT;
			this.ctx.fillText(
				STATUS_CONTENT_ICON,
				this.xCache + this.width / 2 + borderWidth + MIN_NODE_WIDTH / 2 + (STATUS_FONT_SIZE * 1.2) / 2,
				this.yCache + STATUS_FONT_SIZE / 2,
			)
			if(!this.hiddenLocal) {
				this.ctx.fill();
			}
		} else {
			// console.log('EVERYTHING_ELSE ' + this.id);

			this.ctx.beginPath();
			this.ctx.fillStyle = STATUS_LAYER_COLOR;
			this.ctx.rect(
				this.xCache - STATUS_LAYER_HEIGHT / 2,
				this.yCache - STATUS_LAYER_HEIGHT / 2,
				STATUS_LAYER_HEIGHT,
				STATUS_LAYER_HEIGHT
			)
			// draw editing status layer
			this.ctx.rect(
				this.xCache + STATUS_LAYER_HEIGHT / 2 + STATUS_LAYER_PADDING,
				this.yCache - STATUS_LAYER_HEIGHT / 2,
				MIN_NODE_WIDTH,
				STATUS_LAYER_HEIGHT
			)
			if(!this.hiddenLocal) {
				this.ctx.fill();
			}
			
			// draw icons
			this.ctx.beginPath();
			this.ctx.fillStyle = this.editingAppearance ? this.editingAppearance.color : STATUS_FONT_COLOR_DEFAULT;
			this.ctx.fillText(
				STATUS_APPEARANCE_ICON,
				this.xCache + STATUS_LAYER_HEIGHT / 2 + MIN_NODE_WIDTH / 2 - STATUS_FONT_SIZE * 1.2,
				this.yCache - STATUS_LAYER_HEIGHT / 2 + (STATUS_LAYER_HEIGHT - STATUS_FONT_SIZE) / 2 + STATUS_FONT_SIZE * 0.9
			)
			// this.y + mapArea.y + STATUS_FONT_SIZE / 2.4
			
			this.ctx.fillStyle = this.editingContent ? this.editingContent.color : STATUS_FONT_COLOR_DEFAULT;
			this.ctx.fillText(
				STATUS_CONTENT_ICON,
				this.xCache + STATUS_LAYER_HEIGHT / 2 + MIN_NODE_WIDTH / 2 + (STATUS_FONT_SIZE * 1.2) / 2,
				this.yCache - STATUS_LAYER_HEIGHT / 2 + (STATUS_LAYER_HEIGHT - STATUS_FONT_SIZE) / 2 + STATUS_FONT_SIZE * 0.9
			)
			if(!this.hiddenLocal) {
				this.ctx.fill();
			}
		}
		
		this.ctx.restore();
		this.ctx.closePath();
	}

	_drawConnectionReadyStatus(){
		this.ctx.beginPath();
		this.ctx.save();
		const radius = this.border ? this.width / 2 + this.borderWidth : this.width / 2;
		addShapeToContext({
			ctx: this.ctx,
			node: this,
			center: {
				x: this.xCache,
				y: this.yCache
			}
		})
		this.ctx.clip();

		this._ctxRotate(
			this.ctx,
			45,
			this.xCache,
			this.yCache
		)
		this.ctx.moveTo(
			this.xCache,
			this.yCache - radius,
		);
		this.ctx.lineTo(
			this.xCache,
			this.yCache + radius,
		)

		this.ctx.fillStyle = "#ffffff";
		this.ctx.fill();

		this.ctx.strokeStyle = '#BFBFBF'
		this.ctx.lineWidth = Math.floor(this.width / 6);
		this.ctx.stroke();

		this.ctx.restore();
		this.ctx.closePath();
	}

	_drawVisibilityMask(){
		if(this.visibilityMin > this.currentLayerID || this.visibilityMax < this.currentLayerID){
			this.ctx.save();
			const radius = this.border ? this.width / 2 + this.borderWidth : this.width / 2;
			addShapeToContext({
				ctx: this.ctx,
				node: this,
				center: {
					x: this.xCache,
					y: this.yCache
				}
			})
			this.ctx.clip();
			this._createLinePattern();
			this.ctx.restore();
		}
	}

	_drawHiddenMask(){
		this.ctx.save();
		const radius = this.border ? this.width / 2 + this.borderWidth : this.width / 2;
		addShapeToContext({
			ctx: this.ctx,
			node: this,
			center: {
				x: this.xCache,
				y: this.yCache
			}
		})
		this.ctx.clip();
		const time = new Date();
		this._createSquaredPattern();
		this.ctx.restore();
	}

	_drawCircle(settings){
		const {ctx, x, y, radius, startAngle, endAngle, couterclockwise, fillStyle, strokeStyle, lineWidth} = settings;

		ctx.fillStyle = fillStyle;
		ctx.strokeStyle = strokeStyle;
		ctx.lineWidth = lineWidth;

		ctx.beginPath();
		ctx.arc(
			x,
			y,
			radius,
			startAngle,
			endAngle,
			couterclockwise
		)
		ctx.closePath();

		ctx.fill();
		ctx.stroke();
	}
	
	_createLinePattern(){
		const radiusX = this.border ? this.width / 2 + this.borderWidth : this.width / 2;
		const radiusY = this.border ? this.height / 2 + this.borderWidth : this.height / 2;
		this.ctx.beginPath();
		const initialX = this.xCache - radiusX;
		const initialY = this.yCache - radiusY;
		const lineWidth = 18;
		const patternWidth = 30;
		const patternHeight = 20;

		// using pattern
		const patternCanvas = document.createElement('canvas');
		const patternCtx = patternCanvas.getContext('2d');

		// give the pattern a width and height
		patternCanvas.width = patternWidth;
		patternCanvas.height = patternHeight;

		patternCtx.lineWidth = lineWidth / 2;
		patternCtx.lineCap = 'round';
		patternCtx.lineJoin = 'bevel';
		patternCtx.strokeStyle = 'rgba(0, 0, 0, .1)';

		// create pattern
		patternCtx.moveTo(-patternWidth, patternHeight);
		patternCtx.lineTo(patternWidth, -patternHeight);
		patternCtx.moveTo(0, patternHeight);
		patternCtx.lineTo(patternWidth, 0);
		patternCtx.moveTo(0, patternHeight * 2);
		patternCtx.lineTo(patternWidth * 2, 0);
		patternCtx.stroke();

		const pattern = this.ctx.createPattern(patternCanvas, 'repeat');
		this.ctx.fillStyle = pattern;
		this.ctx.fillRect(initialX, initialY, radiusX * 2, radiusY * 2);

		this.ctx.closePath();
	}

	_createSquaredPattern(){
		const radiusX = this.border ? this.width / 2 + this.borderWidth : this.width / 2;
		const radiusY = this.border ? this.height / 2 + this.borderWidth : this.height / 2;
		this.ctx.beginPath();
		const initialX = this.xCache - radiusX;
		const initialY = this.yCache - radiusY;

		// using pattern
		const patternCanvas = document.createElement('canvas');
		const patternContext = patternCanvas.getContext('2d');

		// Give the pattern a width and height
		patternCanvas.width = 20;
		patternCanvas.height = 20;

		// Give the pattern a background color and draw an arc
		patternContext.fillStyle = '#2750E2';
		patternContext.globalAlpha = .2;
		patternContext.fillRect(0, 0, 10, 10);
		patternContext.fillRect(10, 10, 10, 10);

		this.ctx.fillStyle = this.ctx.createPattern(patternCanvas, 'repeat');
		this.ctx.fillRect(initialX, initialY, radiusX * 2, radiusY * 2);

		this.ctx.closePath();
	}

	setSelected(status){
		this.selected = status;
	}
	checkHovered(ctxPath, mouse){
		if(!this.pathInteraction) return;
		return this.hover = !this.hiddenLocal ? ctxPath.isPointInPath(this.pathInteraction, mouse.x, mouse.y) : null;
	}

	_isPointInCircle(x, y, circle){
		const dx = x - circle.x;
		const dy = y - circle.y;
		return (dx * dx + dy * dy < circle.radius * circle.radius);
	}
	checkSizerPointHovered(mouse, isMobile = false){
		// check if point from this.selectedPoints is hovered
		this.selectedPoint = null;
		for(let i = 0; i < this.selectedPoints.length; i++){
			const point = this.selectedPoints[i];
			if(isMobile) point.radius = 20;
			if(this._isPointInCircle(mouse.x, mouse.y, point)){
				return this.selectedPoint = point;
			}
		}
	}
	
	getVisibility(layerID){
		layerID = layerID || this.currentLayerID;
		return layerID < this.visibilityMin || layerID > this.visibilityMax ? 0.5 : 1;
	}

	_ctxRotate(ctx, angle, x, y){
		ctx.translate(x, y);
		ctx.rotate(angle);
		ctx.translate(-x, -y);
	}

}
