import useMapArea from '@/services/canvas/map-area/MapArea';
import ToastUtils from '@/utils/ToastUtils';
import useI18nGlobal from '@/utils/i18n';
import ApiService from '@/utils/ApiService';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import {layer} from "@/components/deprecated/Layer";

const {mapArea} = useMapArea();
const {createToastFromStore} = ToastUtils();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
	namespaced: true,
	state: {
		saveLockedTimeout: null,
		nodesLockedOrder: {},
		saveLayerLockedTimeout: null,
		layerLockedOrder: {}
	},
	getters: {
		getNodesOriginal(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesOriginal'];
		},
		getNodes(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodes'];
		},
		getLayers(state, getters, rootState, rootGetters){
			return rootGetters['layers/getLayers']
		},
		getCurrentMapId(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getCurrentMapId']
		},
		getNodesURL(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesURL']
		},
		getMapsURL(state, getters, rootState, rootGetters){
			return rootGetters['maps/getMapsURL']
		},
		getElementsURL(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getElementsURL']
		},
	},
	mutations: {
		UPDATE_NODE_ORIGINAL_LOCKED(state, {nodesOriginal, lockedData}){
			for(let [id, status] of Object.entries(lockedData)){
				const nodeOriginal = nodesOriginal.find(node => node.id === parseInt(id));
				nodeOriginal.editing.locked = status;
			}
		},
		UPDATE_NODE_LOCKED(state, {nodes, lockedData}){
			for(let [id, status] of Object.entries(lockedData)){
				const node = nodes.find(node => node.id === parseInt(id));
				node.locked = status;
			}
		},
	},
	actions: {
		nodeUpdateLocked({state, getters, commit, dispatch}, lockedData){
			// lockedData: {[id]: locked, [id]: locked, ...}
			const nodes = getters.getNodes;
			const nodesOriginal = getters.getNodesOriginal;
			commit('UPDATE_NODE_LOCKED', {nodes, lockedData});
			commit('UPDATE_NODE_ORIGINAL_LOCKED', {nodesOriginal, lockedData});
			// update locked state on layer
			dispatch('layers/locked/nodeLockedUpdate', lockedData, {root: true})
			// set updated date of map changing
			dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});
			// update canvas
			for(let id of Object.keys(lockedData)){
				mapArea.updateEl(true, +id);
			}
		},
		nodeSaveLocked({state, getters, commit, dispatch}, lockedData){
			// save order as {id: status}
			Object.assign(state.nodesLockedOrder, lockedData);
			clearTimeout(state.saveLockedTimeout);
			state.saveLockedTimeout = setTimeout( async () => {
				const mapsURL = getters.getMapsURL;
				const elementsURL = getters.getElementsURL;
				const mapId = getters.getCurrentMapId;
				const nodesData = [];
				for(let [id, status] of Object.entries(state.nodesLockedOrder)){
					const nodeLocked = {
						'id': id,
						'editing': {'locked': status}
					}
					nodesData.push(nodeLocked)
				}
				const data = {'nodes': nodesData}

				// check if layers become empty to add them to request
				const nodesOrder = state.nodesLockedOrder;

				const layerOrder = {};
				const layersLockedEmpty = await dispatch('layers/locked/layersLockedEmptyFilter', null, {root: true});
				if(layersLockedEmpty.length) {
					const layerData = [];
					layersLockedEmpty.forEach(layer => {
						dispatch('layerUpdateLocked', layer);
						const [[id, status]] = Object.entries(layer);
						layerData.push({id, locked: status});
						Object.assign(layerOrder, layer);
					});

					data.levels = layerData;
				}

				try{
					state.nodesLockedOrder = {};
					await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, data);
					const editsData = !data.levels ? {
						type: SE_SYNC_TYPES.NODE_LOCKED_UPDATED,
						data: nodesOrder
					} : {
						type: SE_SYNC_TYPES.LAYER_LOCKED_UPDATED,
						data: layerOrder
					}
					dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
					state.nodesLockedOrder = {};
					
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeLockedSuccess'), dispatch);
					clearTimeout(state.saveLockedTimeout);
				} catch(err){
					await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeLockedError'), dispatch);
					clearTimeout(state.saveLockedTimeout);
				}
				

			}, 700);
		},
		layerUpdateLocked({getters, commit, dispatch}, data){
			const layers = getters.getLayers;
			const nodes = getters.getNodes;
			const nodesOriginal = getters.getNodesOriginal;
			
			// check if node on layer to change its locked state
			const lockedData = {}; // {id: status, id2: status2, ... }
			for(let [layerID, status] of Object.entries(data)){
				layers[layerID].nodes.forEach((node, nodeIndex) => {
					if (node.onLayer) {
						Object.assign(lockedData, {[nodes[nodeIndex].id]: status})
					}
				})
			}
			
			commit('UPDATE_NODE_ORIGINAL_LOCKED', {nodesOriginal, lockedData});
			commit('UPDATE_NODE_LOCKED', {nodes, lockedData});
			// call from here instead layers module to be able to handle it form simultaneous editing
			// update levels
			dispatch('layers/levelLockedUpdate', data, {root: true});
			// update layers
			dispatch('layers/locked/layerLockedUpdate', data, {root: true});
			// set updated date of map changing
			dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});
			// update canvas
			for(let id of Object.keys(lockedData)){
				mapArea.updateEl(true, +id);
			}
		},
		layerSaveLocked({state, getters, commit, dispatch}, lockedData){
			Object.assign(state.layerLockedOrder, lockedData);
			clearTimeout(state.saveLayerLockedTimeout);
			state.saveLayerLockedTimeout = setTimeout(async () => {
				const mapsURL = getters.getMapsURL;
				const elementsURL = getters.getElementsURL;
				const mapId = getters.getCurrentMapId;
				const layers = getters.getLayers;
				const nodes = getters.getNodes;
				
				const layerData = [];
				const nodesData = [];

				for(let [id, status] of Object.entries(state.layerLockedOrder)){
					layerData.push({id,	locked: status});
					// collect nodes from current layer
					layers[id].nodes.forEach((node, nodeIndex) => {
						if(node.onLayer){
							nodesData.push({
								id: nodes[nodeIndex].id,
								editing: {locked: status}
							})
						}
					})
				}

				// check if other layers become empty to add them to request
				const order = state.layerLockedOrder;
				const layersLockedEmpty = await dispatch('layers/locked/layersLockedEmptyFilter', null, {root: true});
				// [{id: status}, {id: status}...]
				if(layersLockedEmpty.length) {
					layersLockedEmpty.forEach(layer => {
						const [[id, status]] = Object.entries(layer);
						layerData.push({id, locked: status});
					});

					Object.assign(order, ...layersLockedEmpty);
				}
				const levelsData = {'levels': layerData, 'nodes': nodesData}

				try{
					state.layerLockedOrder = {};
					await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, levelsData);

					const editsData = {
						type: SE_SYNC_TYPES.LAYER_LOCKED_UPDATED,
						data: order
					}
					dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
					
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.layerLockedSuccess'), dispatch);
					clearTimeout(state.saveLockedTimeout);
				} catch(err){
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.layerLockedError'), dispatch);
					clearTimeout(state.saveLockedTimeout);
				}
			}, 700)
		}
	}
}
