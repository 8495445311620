import FontUtils from '@/services/canvas/fonts/Fonts';
const { fontLoading, fontsCollector} = FontUtils();
export default {
	namespaced: true,
	state: {
		fonts: null,
		fontsCollected: null,
		fontsURL: '/fonts/'
	},
	getters: {
		getFontsURL(state){
			return state.fontsURL;
		},
		getClientFonts(state){
			return state.fonts;
		},
		getFontsCollected(state){
			return state.fontsCollected;
		},
		getFontFamilies(state){
			return state.fonts.map(font => ({value: font.family_name, name: font.name}));
		}
	},
	mutations: {
		SAVE_FONTS(state, fonts){
			state.fonts = fonts;
		},
		SAVE_COLLECTED_FONTS(state, fonts){
			state.fontsCollected = fontsCollector(fonts);
		},
	},
	actions: {
		async fontsFetchedSave({commit, dispatch}, fonts){
			commit('SAVE_FONTS', fonts);
			commit('SAVE_COLLECTED_FONTS', fonts);
		},
		async fontsLoading({getters, dispatch}){
			const fonts = getters.getClientFonts;
			for(const font of fonts){
				const filteredFaces = [];
				font.faces.forEach(face => {
					const existingSameFace = filteredFaces.find(fFace => fFace.style === face.style && fFace.weight === face.weight);
					// check if face with same style and weight are in filteredFaces array
					if(existingSameFace){
						if(face.format === 'woff2' || (face.format === 'woff' && existingSameFace.format !== 'woff2'))
							filteredFaces.splice(filteredFaces.indexOf(existingSameFace), 1, face);
					} else {
						filteredFaces.push(face);
					}
				});
				await fontLoading({fontFamily: font.family_name, faces: filteredFaces});
				dispatch('nodes/nodeUpdateOnFontLoaded', {fontFamily: font.family_name}, {root: true});
				dispatch('edges/edgeUpdateOnFontLoaded', {fontFamily: font.family_name}, {root: true});
			}
		}
	}
}
