import NodeUtils from '@/services/canvas/nodes/NodeUtils';
import ToastUtils from '@/utils/ToastUtils';
import MapUtils from "@/services/canvas/map-area/MapUtils";
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import Fonts from '@/services/canvas/fonts/Fonts';
import Node from '@/services/canvas/nodes/Node';
const {
	NODE_DESIGN_MAPPING,
	createNodeObject,
	adaptedNodeDesignProperties,
	getNodeComputedDesign,
	getNodeDesignFromTemplate,
	getNodeDesignInitialComputedValue,
	convertOriginalToNodeProperty,
	convertToOriginalNodeProperty,
	edgeUpdateRelated
} = NodeUtils();
const {mapArea} = useMapArea();
const {getZoomLevelOptions} = MapUtils();
const {fontFamilyGetNameByUrl, fontFamilyGetUrlByName} = Fonts();
const {createToastFromStore} = ToastUtils();
import createImage from '@/services/canvas/utils/CreateImage';
import { DASHBOARD_OPTIONS} from '@/models/DASHBOARD_OPTIONS';
import ApiService from '@/utils/ApiService';
import {EDITING_EXTENT_TYPES} from '@/models/EDITING_EXTENT_TYPES';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import useMapArea from '@/services/canvas/map-area/MapArea';
import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
const {t} = i18n.global;
// MODULES
import visibility from '@/store/modules/map/nodes/modules/visibility';
import design from '@/store/modules/map/nodes/modules/design';
import content from '@/store/modules/map/nodes/modules/content';
import hidden from '@/store/modules/map/nodes/modules/hidden';
import locked from '@/store/modules/map/nodes/modules/locked';
// import templates from '@/store/modules/map/nodes/modules/templates';
import position from '@/store/modules/map/nodes/modules/position';
import {TEMPLATE_TYPES} from "@/models/TEMPLATE_TYPES";
import {NODE_SHAPES} from "@/models/NODE_SHAPES";

export default {
	namespaced: true,
	modules: {
		visibility,
		design,
		content,
		hidden,
		locked,
		position,
		// templates,
	},
	state: () => ({
		nodesOriginal: null,
		nodesURL: '/nodes/',
		nodeCloneURL: '/nodes/clone',
		nodeActiveID: null,
		nodes: [],
		saveTimeout: null,
		saveContentTimeout: null,
		nodeDesignOrder: {},
		nodesContentOrder: {},
		isTemplateApplying: false,
		shapeGlobal: NODE_SHAPES.CIRCLE.VALUE
	}),
	getters: {
		getNodesOriginal(state) {
			return state.nodesOriginal;
		},
		getNodes(state){
			return state.nodes
		},
		getEdges(state, getters, rootState){
			return rootState.edges.edges;
		},
		getLayers(state, getters, rootState){
			return rootState.layers.layers
		},
		getActiveNodeID(state){
			return state.nodeActiveID
		},
		getNodeActive(state){
			return state.nodes.find(node => node.id === state.nodeActiveID)
		},
		getNodeOriginalActive(state){
			return state.nodesOriginal.find(node => node.id === state.nodeActiveID)
		},
		getDashboardActiveOption(state, getters, rootState){
			return rootState.dashboardOptions.optionActive;
		},
		getTemplates(state, getters, rootState){
			return rootState.design.design.templates
		},
		getElementsDesign(state, getters, rootState) {
			return rootState.design.design
		},
		getClientFonts(state, getters, rootState){
			return rootState.fonts.fonts
		},
		getMapsURL(state, getters, rootState){
			return rootState.maps.mapsURL
		},
		getCurrentMap(state, getters, rootState) {
			return rootState.maps.currentMap;
		},
		getElementsURL(state, getters, rootState) {
			return rootState.maps.elementsURL;
		},
		getNodesURL(state){
			return state.nodesURL;
		},
		getNodeCloneURL(state){
			return state.nodeCloneURL;
		},
		getLayersAmount(state, getters, rootState){
			return rootState.mapSettings.mapData.zoomLevels
		},
		getCurrentLayerID(state, getters, rootState, rootGetters){
			return rootGetters['layers/getLayerCurrentID'];
		},
		getShapeGlobal(state){
			return state.shapeGlobal;
		},
		isTemplateChangesTracked(state, getters, rootState){
			const activeNode = getters.getNodeOriginalActive;/*state.nodesOriginal.find(node => node.id === state.nodeActiveID);*/
			const templateId = activeNode.design.object_template;
			
			for(let [key, value] of Object.entries(activeNode.design)){
				// avoid checking non-comparable values and null values
				if(key !== 'object_template' && value !== null){
					// if node's value isn't equal to computed template value
					if(value !== getNodeDesignInitialComputedValue(templateId, rootState.design.design, key)){
						return true
					}
				}
			}
			// if no differences found
			return false
		},
		isTemplateApplying(state){
			return state.isTemplateApplying;
		},
		getChosenTemplate(state, getters, rootState, rootGetters){
			return rootGetters["templates/getObjectTemplateChosen"]
		},
	},
	mutations: {
		// NODES ORIGINAL
		NODES_ORIGINAL_SAVE(state, nodesOriginal){
			state.nodesOriginal = nodesOriginal;
		},
		// NODES (instances)
		POPULATE_NODES(state, nodeData){
			const {elementsDesign, fonts} = nodeData;
			state.nodes = [];
			const unlinkedNodes = JSON.parse(JSON.stringify(state.nodesOriginal));
			for( let node of Object.values(unlinkedNodes)){
				state.nodes.push(new Node(createNodeObject(node, elementsDesign, fonts)));
			}
		},
		// RELATED EDGES
		POPULATE_RELATED_EDGES(state, edges){
			state.nodes.forEach(node => {
				const edgesFrom = edges.filter(edge => edge.fromNodeId === node.id).map(edge => edge.id);
				const edgesTo = edges.filter(edge => edge.toNodeId === node.id).map(edge => edge.id);
				node.edgesFrom = edgesFrom;
				node.edgesTo = edgesTo;
			})
		},
		REMOVE_ALL_RELATED_EDGES(state){
			state.nodes.forEach(node => {
				node.edgesFrom = [];
				node.edgesTo = [];
			})
		},
		ADD_RELATED_EDGE(state, edgeData){
			const {from, to, edgeId} = edgeData;
			const nodeFrom = state.nodes.find(node => node.id === from);
			const nodeTo = state.nodes.find(node => node.id === to);
			nodeFrom.edgesFrom.push(edgeId);
			nodeTo.edgesTo.push(edgeId);
		},
		REMOVE_RELATED_EDGE(state, edgeData){
			const {nodeId, edgeFromId, edgeToId} = edgeData;
			const currentNode = state.nodes.find(node => node.id === nodeId);
			if(edgeFromId) {
				const index = currentNode.edgesFrom.findIndex(id => id === edgeFromId);
				currentNode.edgesFrom.splice(index, 1);
			}
			if(edgeToId) {
				const index = currentNode.edgesTo.findIndex(id => id === edgeToId);
				currentNode.edgesTo.splice(index, 1);
			}
		},
		// applying changes from Object templates settings
		APPLY_OBJECT_TEMPLATE_TO_NODES(state, {templateId, elementsDesign, fonts}){
			// get nodes which uses a template
			const nodesToUpdate = state.nodes.filter(node => node.template === templateId.toString());
			// since node.design could have direct data not stored in template, yet
			// it should be synchronized with following priority
			// node.design -> template -> global -> defaults
			nodesToUpdate.forEach(node => {
				Object.assign(node, getNodeComputedDesign(node.id, state.nodes, state.nodesOriginal, elementsDesign, fonts));
			})
		},
		APPLY_OBJECT_TEMPLATES_DELETED(state, templateData){
			const {templateId, elementsDesign, fonts} = templateData;
			const nodesOriginalToUpdate = state.nodesOriginal.filter(node => node.design.object_template === templateId.toString());
			// first set to null node template value
			nodesOriginalToUpdate.forEach(nodeOriginal => {
				nodeOriginal.design.object_template = '0';
			})
			// update nodes accordingly
			const nodesToUpdate = state.nodes.filter(node => node.template === templateId.toString());
			nodesToUpdate.forEach(node => {
				node.template = '0';
				node.image = null;
				Object.assign(node, getNodeComputedDesign(node.id, state.nodes, state.nodesOriginal, elementsDesign, fonts));
			})
		},

		UPDATE_NODE_ORIGINAL_TEMPLATE_ID(state, {nodeId, templateId}){
			const nodeOriginal = state.nodesOriginal.find(node => node.id === nodeId);
			nodeOriginal.design.object_template = templateId !== null ? templateId.toString() : templateId;
		},
		UPDATE_NODE_TEMPLATE_ID(state, {nodeId, templateId}){
			const node = state.nodes.find(node => node.id === nodeId);
			node.template = templateId !== null ? templateId.toString() : templateId;
		},
		
		CHANGE_ZOOM_LEVEL(state, zoomData){
			const {zoomLevel, previous} = zoomData;
			state.nodes.forEach(node => {
				if(node.visibilityMax >= zoomLevel){
					node.visibilityMax = zoomLevel;
					// check if visibilityMax more than previous zoomLevel which would means it has changed
					// and should be increased to same as zoomLevel
				} else if(node.visibilityMax >= previous && zoomLevel > previous){
					node.visibilityMax = zoomLevel;
				}
				
				if(node.visibilityMin > zoomLevel){
					node.visibilityMin = zoomLevel;
				}
			})
			state.nodesOriginal.forEach(nodeOriginal => {
				if(nodeOriginal.visibility.max_level >= zoomLevel){
					nodeOriginal.visibility.max_level = zoomLevel;
					// check if visibilityMax more than previous zoomLevel which would means it has changed
					// and should be increased to same as zoomLevel
				} else if(nodeOriginal.visibility.max_level >= previous && zoomLevel > previous){
					nodeOriginal.visibility.max_level = zoomLevel;
				}
				
				if(nodeOriginal.visibility.min_level > zoomLevel){
					nodeOriginal.visibility.min_level = zoomLevel;
				}
			})
		},
		UPDATE_NODE_ORDERING(state, orderData){
			const {nodeIndex, toNodeIndex} = orderData;
			
			const nodeOriginalToMove = state.nodesOriginal.splice(nodeIndex, 1);
			const nodeToMove = state.nodes.splice(nodeIndex, 1);
			
			state.nodesOriginal.splice(toNodeIndex, 0, ...nodeOriginalToMove);
			state.nodes.splice(toNodeIndex, 0, ...nodeToMove);
		},
		
		SET_ACTIVE_NODE_ID(state, nodeID){
			state.nodeActiveID = nodeID;
		},
		UPDATE_NODE(state, editedData){
			const {id, nodeData} = editedData;

			const node = state.nodes.find(node => node.id === parseInt(id));
			if(!node) return;
			// create image if exist
			if(nodeData.media){
				const hostURL = process.env.VUE_APP_ROOT_HOST;
				nodeData.image = nodeData.media?.includes(hostURL) ? createImage(nodeData.media, null, null, id) : createImage(`${hostURL}${nodeData.media}`, null, null, id);
			}
			if(nodeData.mediaURL){
				nodeData.image = createImage(nodeData.mediaURL, null, null, id);
			}
			// dimensionImpacted[width, height, imageSize, shadowBlur, shadowX, shadowY, borderColor, titlePosition, title, ].indexOf(editedData) > -1
			Object.assign(node, nodeData);
		},
		ADD_NODE_ORIGINAL(state, createdNode){
			state.nodesOriginal.push(createdNode);
		},
		ADD_NODE(state, node){
			state.nodes.push(node);
		},
		APPLY_TEMPLATE_RESTORE_TO_NODES(state, data){
			const {nodeId, elementDesign, clientFonts} = data;
			const currentNodeId = nodeId || state.nodeActiveID;
			const currentNodeOriginal = state.nodesOriginal.find(node => node.id === currentNodeId);
			let currentNode = state.nodes.find(node => node.id === currentNodeId);
			const templateId = currentNode.template;
			// set to null all node's values and collect changed properties to restoredProperties object
			let restoredProperties = {}
			for(let [key, value] of Object.entries(currentNodeOriginal.design)){
				if(key !== 'object_template' && value !== null){
					Object.assign(restoredProperties, {[key]: value})
					currentNodeOriginal.design[key] = null;
				}
			}
			let convertedData = {};
			// convert node to node properties and set computed properties to node to redraw it on the canvas
			Object.entries(restoredProperties).forEach(([property, value]) => {
				let computedValue = getNodeDesignInitialComputedValue(templateId, elementDesign, property);
				if(property === NODE_DESIGN_MAPPING.fontFamily) computedValue = fontFamilyGetNameByUrl(value, clientFonts)
				Object.assign(
					convertedData,
					convertOriginalToNodeProperty({[property]: computedValue}))
			});
			
			Object.assign(currentNode, convertedData)
		},
		APPLY_GLOBAL_UPDATE_TO_NODES(state, nodeData){
			const {elementsDesign, fonts} = nodeData
			state.nodes.forEach(node => {
				const design = getNodeComputedDesign(node.id, state.nodes, state.nodesOriginal, elementsDesign, fonts);
				Object.assign(node, design);
			})
		},
		NULLIFY_NODE_ORIGINAL_VALUES(state, nodeId){
			const currentNodeId = nodeId !== undefined ? nodeId : state.nodeActiveID;
			const currentNodeOriginal = state.nodesOriginal.find(node => node.id === currentNodeId);
			for(let [key, value] of Object.entries(currentNodeOriginal.design)){
				if(key !== 'object_template' && value !== null){
					currentNodeOriginal.design[key] = null;
				}
			}
		},
		SET_TEMPLATE_APPLYING_STATUS(state, status){
			state.isTemplateApplying = status;
		},
		DELETE_NODE_ORIGINAL(state, nodeId){
			const nodeIndex = state.nodesOriginal.findIndex(node => node.id === nodeId);
			state.nodesOriginal.splice(nodeIndex, 1);
		},
		DELETE_NODE(state, nodeId){
			const nodeIndex = state.nodes.findIndex(node => node.id === nodeId);
			state.nodes.splice(nodeIndex, 1);
		},
		MARK_NODE_CONNECT(state, nodeId){
			const node = state.nodes.find(node => node.id === nodeId);
			node.connectSign = true;
		},
		UNMARK_NODE_CONNECT(state, nodeId){
			const node = state.nodes.find(node => node.id === nodeId);
			node.connectSign = false;
		},
		SET_NODE_EDITING(state, nodeEditing){
			const node = state.nodes.find(node => node.id === nodeEditing.elem_id);
			if(!node) return;
			nodeEditing.lock_extent === EDITING_EXTENT_TYPES.APPEARANCE ?
				node.editingAppearance = nodeEditing :
				node.editingContent = nodeEditing
		},
		REMOVE_NODE_EDITING(state, nodeEditing){
			const node = state.nodes.find(node => node.id === nodeEditing.elem_id);
			if(!node) return;
			nodeEditing.lock_extent === EDITING_EXTENT_TYPES.APPEARANCE ?
				node.editingAppearance = null :
				node.editingContent = null
			mapArea.updateEl(true, node.id);
		},
		SET_NODE_ORIGINAL_OCCUPIED(state, nodeEditing){
			const nodeIndex = state.nodesOriginal.findIndex(node => node.id === nodeEditing.elem_id);
			if(nodeIndex === -1) return;
			state.nodesOriginal[nodeIndex].occupied = nodeEditing;
		},
		REMOVE_NODE_ORIGINAL_OCCUPIED(state, nodeEditing){
			const nodeIndex = state.nodesOriginal.findIndex(node => node.id === nodeEditing.elem_id);
			if(nodeIndex === -1) return;
			delete state.nodesOriginal[nodeIndex].occupied;
		},
		//MEDIA
		REMOVE_DELETED_MEDIA_FROM_NODES(state, url){
			const nodes = state.nodesOriginal;
			nodes.forEach(node => {
				if(node.design.media?.includes(url)){
					node.design.media = null;
				}
			});
		},
		// SHAPE
		SET_SHAPE_GLOBAL(state, shape){
			if(shape) state.shapeGlobal = shape;
		}
	},
	actions: {
		nodesOriginalSave({commit}, nodes){
			commit('NODES_ORIGINAL_SAVE', nodes);
		},
		nodeSetActive({state, commit, dispatch}, nodeID){
			commit('SET_ACTIVE_NODE_ID', nodeID);
		},
		nodeUpdate({commit}, node){
			commit('UPDATE_NODE', node);
		},
		nodesPopulate({getters,commit}){
			const elementsDesign = getters.getElementsDesign;
			const fonts = getters.getClientFonts;
			commit('POPULATE_NODES', {elementsDesign, fonts});
		},
		nodesRelatedEdgesPopulate({getters, commit}){
			const edges = getters.getEdges;
			commit('POPULATE_RELATED_EDGES', edges);
		},
		nodeConnectMark({commit, dispatch}, nodeId){
			commit('MARK_NODE_CONNECT', nodeId);
			dispatch('nodesUnavailableForConnectMark', nodeId);
			mapArea.updateEl(true, nodeId);
		},
		nodeConnectUnmark({commit}, nodeId){
			commit('UNMARK_NODE_CONNECT', nodeId);
			mapArea.updateEl(true, nodeId);
		},
		nodesUnavailableForConnectMark({getters, commit}, nodeFromId){
			const nodes = getters.getNodes;
			if(nodeFromId !== null){
				const nodeFrom = getters.getNodes.find(node => node.id === nodeFromId);
				const nodeFromVisibilityRange = getZoomLevelOptions(nodeFrom.visibilityMax, nodeFrom.visibilityMin);

				nodes.forEach(node => {
					if(node.id !== nodeFromId){
						const nodeVisibilityRange = getZoomLevelOptions(node.visibilityMax, node.visibilityMin);
						const result = []

						nodeFromVisibilityRange.forEach(level => {
							if(nodeVisibilityRange.indexOf(level) > -1) {
								result.push(level);
							}
						})

						if(!result.length) node.availableForConnection = false;
						mapArea.updateEl(true, node.id);
					}
				});
			}

			if(nodeFromId === null){
				nodes.forEach(node => {
					if(!node.availableForConnection){
						node.availableForConnection = true;
						mapArea.updateEl(true, node.id);
					}
				})
			}

		},
		nodeAddRelatedEdge({commit}, edgeData) {
			commit('ADD_RELATED_EDGE', edgeData);
			mapArea.updateEl();
		},
		nodeRemoveRelatedEdge({commit}, edgeData){
			commit('REMOVE_RELATED_EDGE', edgeData);
			mapArea.updateEl();
		},
		nodeRemoveAllRelatedEdges({commit}){
			commit('REMOVE_ALL_RELATED_EDGES');
			mapArea.updateEl();
		},
		async nodeCreate({getters, commit, dispatch}, nodeOriginalCreated){
			const elementsDesign = getters.getElementsDesign;
			const fonts = getters.getClientFonts;
			const layerCurrentID = getters.getCurrentLayerID;

			if(nodeOriginalCreated.content.popup_type === null){
				nodeOriginalCreated.content.popup_type = 'popup-small';
			}
			commit('ADD_NODE_ORIGINAL', nodeOriginalCreated);
			dispatch('layers/nodeCreatedAddToLayers', nodeOriginalCreated, {root: true});
			
			const node = new Node(createNodeObject(nodeOriginalCreated, elementsDesign, fonts));
			commit('ADD_NODE', node);

			node.cacheInstanceSetDimensions();
			node.cache(layerCurrentID.value);

			mapArea.updateEl(true, node.id); // update canvas
			
			return Promise.resolve(node);
		},
		async nodeCreateRequest({getters, commit, dispatch}, geometry){
			const {x, y} = geometry;
			const layersAmount = parseInt(getters.getLayersAmount);
			const layerCurrentID = parseInt(getters.getCurrentLayerID);
			const mapsURL = getters.getMapsURL;
			const nodesURL = getters.getNodesURL;
			const mapId = getters.getCurrentMap.id;
			const shapeGlobal = getters.getShapeGlobal;

			const request = {
				design: {shape: shapeGlobal},
				geometry: {center: {x, y}},
				visibility: {min_level: layerCurrentID, max_level: layersAmount, hidden: false}
			}

			// check chosen template
			const objectTemplateChosen = getters.getChosenTemplate;
			if(objectTemplateChosen !== null && objectTemplateChosen.type === TEMPLATE_TYPES.NODE){
				Object.assign(request, {design: {object_template: objectTemplateChosen.id.toString()}})
			}

			try{
				const {data: nodeOriginal} = await ApiService.postRequest(`${mapsURL}${mapId}${nodesURL}`, request);
				// prevent adding node with the same id
				if(getters.getNodes.find(node => node.id === nodeOriginal.id)) {
					return Promise.resolve(null);
				}
				// send syncSE
				const editsData = {
					type: SE_SYNC_TYPES.NODE_CREATED,
					data: nodeOriginal
				}
				dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
				// show toaster
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeCreatedSuccess', {id: nodeOriginal.id}), dispatch);
				return Promise.resolve(nodeOriginal);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeCreatedError'), dispatch);
				return Promise.reject(err);
			}
		},
		async nodeClone({getters, commit, dispatch}, nodeCloned){
			const elementsDesign = getters.getElementsDesign;
			const fonts = getters.getClientFonts;
			const layerCurrentID = getters.getCurrentLayerID;

			commit('ADD_NODE_ORIGINAL', nodeCloned);
			dispatch('layers/nodeCreatedAddToLayers', nodeCloned, {root: true});
			const node = new Node(createNodeObject(nodeCloned, elementsDesign, fonts));
			commit('ADD_NODE', node);

			node.cacheInstanceSetDimensions();
			node.cache(layerCurrentID);

			mapArea.updateEl(true, node.id); // update canvas
		},
		async nodeCloneRequest({getters, dispatch}, nodeId){
			const mapsURL = getters.getMapsURL;
			const cloneURL = getters.getNodeCloneURL;
			const mapId = getters.getCurrentMap.id;
			const dashboardActiveOption = getters.getDashboardActiveOption;
			const NODE_DESIGN = DASHBOARD_OPTIONS.CREATE.GROUPS.NODE.NODE_DESIGN;

			const nodes = getters.getNodes;
			const layerCurrentID = getters.getCurrentLayerID;
			const nodeActive = getters.getNodeActive;

			const request = {
				id: nodeId,
				offset: {x: 30,	y: 30}
			}
			
			try{
				const {data: nodeCloned} = await ApiService.postRequest(`${mapsURL}${mapId}${cloneURL}`, request);
				const editsData = {
					type: SE_SYNC_TYPES.NODE_CLONED,
					data: nodeCloned
				}

				dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
				nodeActive.setSelected(false);
				await dispatch('nodeSetActive', null); // reset active node
				dispatch('simultaneousEditing/stopEditingNode', null, {root: true})

				dispatch('nodeClone', nodeCloned);
				await dispatch('nodeSetActive', nodeCloned.id); // set active node id

				const node = nodes.find(node => node.id === nodeCloned.id);
				node.setSelected(true);
				node.cacheInstanceSetDimensions();
				node.cache(layerCurrentID);
				mapArea.updateEl(true, node.id);
				
				if(dashboardActiveOption?.VALUE === NODE_DESIGN.VALUE){
					const nodeData = {
						nodeId: nodeCloned.id,
						lockExtent: EDITING_EXTENT_TYPES.APPEARANCE
					}
					dispatch('simultaneousEditing/startEditingNode', nodeData, {root: true})
					// call the option choose to change active node in settings sidebar
					dispatch('dashboardOptions/optionChoose', NODE_DESIGN, {root: true});
				}
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeCloneSuccess', {id: nodeCloned.id}), dispatch);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeCloneError'), dispatch);
			}
		},
		
		async nodeRemove({getters, commit, dispatch}, nodeId){
			const nodes = getters.getNodes;
			const edges = getters.getEdges;

			// remove edgeId from edgesFrom/edgesTo of relatedNode
			const node = nodes.find(node => node.id === nodeId);
			[...node.edgesFrom, ...node.edgesTo].forEach(edgeId => {
				const edge = edges.find(edge => edge.id === edgeId);
				if(edge){
					[edge.fromNodeId, edge.toNodeId].forEach(relatedNodeId => {
						if(relatedNodeId !== nodeId){
							const relatedNode = nodes.find(relatedNode => relatedNode.id === relatedNodeId);
							const edgeFromId = relatedNode.edgesFrom.find(edgeFromId => edgeFromId === edgeId);
							const edgeToId = relatedNode.edgesTo.find(edgeToId => edgeToId === edgeId);
							commit('REMOVE_RELATED_EDGE', {nodeId: relatedNode.id, edgeFromId, edgeToId})
						}
					})
				}
			})
			await dispatch('edges/remove/edgesRemoveRelatedToDeletedNode', nodeId, {root: true});
			commit('DELETE_NODE_ORIGINAL', nodeId);
			commit('DELETE_NODE', nodeId);
			commit('REMOVE_RELATED_EDGE', {nodeId})
			dispatch('layers/nodeRemoveFromLayers', nodeId, {root: true});
			mapArea.updateEl(); // update canvas

			return Promise.resolve();
		},
		async nodeRemoveRequest({getters, dispatch}, nodeId){
			const mapsURL = getters.getMapsURL;
			const nodesURL = getters.getNodesURL;
			const mapId = getters.getCurrentMap.id;
			
			try{
				await ApiService.deleteRequest(`${mapsURL}${mapId}${nodesURL}${nodeId}`);
				// await dispatch('edges/remove/edgesRemoveRelatedToDeletedNodeRequest', nodeId, {root: true}); // should be removed?
				const editsData = {
					type: SE_SYNC_TYPES.NODE_REMOVED,
					data: nodeId
				}
				dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
				
				dispatch('dashboardOptions/optionChoose', null, {root: true}); // close settings sidebar
				dispatch("dashboardOptions/groupNodeToggle", false, {root: true}); // close toggle options

				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeRemovedSuccess', {id: nodeId}), dispatch);
				await dispatch('nodeSetActive', null);

				return Promise.resolve();
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeRemovedError'), dispatch);
				return Promise.reject();
			}
		},
		
		async nodeSaveDesignByTemplate({getters, dispatch}, saveData){
			const {nodeId, nodeData, type} = saveData;
			const mapsURL = getters.getMapsURL;
			const nodesURL = getters.getNodesURL;
			const mapId = getters.getCurrentMap.id;
			const data = {'design': nodeData};
			const message = {success: '', error: ''};
			
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${nodesURL}${nodeId}`, data);
				if(!type) return;
				const dataSE = {type,	data: {nodeId, nodeData}};
				dispatch('simultaneousEditing/syncEdits', dataSE, {root: true});
				
				mapArea.updateEl(true, nodeId); // update canvas
				
				switch (type){
					case SE_SYNC_TYPES.NODE_TEMPLATE_APPLIED: {
						message.success = t('message.templateApplySuccess');
						message.error = t('message.templateApplyError');
						break;
					}
					case SE_SYNC_TYPES.NODE_TEMPLATE_RESTORED: {
						message.success = t('message.templateRestoreSuccess');
						message.error = t('message.templateRestoreError');
						break;
					}
					case SE_SYNC_TYPES.NODE_TEMPLATE_CREATED: {
						message.success = t('message.templateCreateSuccess');
						message.error = t('message.templateCreateError');
						break;
					}
				}
				await createToastFromStore(TOASTS_TYPES.SUCCESS, message.success, dispatch);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, message.error, dispatch);
			}
		},
		async nodeUpdateTemplate({getters, dispatch}, saveData){
			const {nodeId, nodeData, type, template} = saveData;
			const mapsURL = getters.getMapsURL;
			const nodesURL = getters.getNodesURL;
			const mapId = getters.getCurrentMap.id;
			const data = {'design': nodeData};
			
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${nodesURL}${nodeId}`, data);
				const dataSE = {type,	data: {...template, nodeId}};
				dispatch('simultaneousEditing/syncEdits', dataSE, {root: true});
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.templateUpdateSuccess'), dispatch);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.templateUpdateError'), dispatch);
			}
		},
		
		nodeTemplateCreatedPopulate({getters, commit, dispatch}, {nodeId, template}){
			const fonts = getters.getClientFonts;
			const nodes = getters.getNodes;
			const currentNode = nodes.find(node => node.id === nodeId);

			commit('UPDATE_NODE_TEMPLATE_ID', {nodeId: currentNode.id, templateId: template.id}); // change template id value of the node accordingly
			commit('UPDATE_NODE_ORIGINAL_TEMPLATE_ID', {nodeId: currentNode.id, templateId: template.id}); // change template id value of the original node accordingly

			let templateObject = {};
			// copy node's values to template
			for(let [key, value] of Object.entries(currentNode)){
				if(key === 'fontFamily') value = fontFamilyGetUrlByName(value, fonts);
				Object.assign(templateObject, convertToOriginalNodeProperty({[key]: value}))
			}
			
			// assign template
			dispatch('design/templateUpdate', {id: template.id, data: templateObject}, {root: true});

			commit('NULLIFY_NODE_ORIGINAL_VALUES', nodeId);
		},
		
		nodesApplyTemplateRestore({getters, commit}, nodeId){
			const elementDesign = getters.getElementsDesign;
			const clientFonts = getters.getClientFonts;
			commit('APPLY_TEMPLATE_RESTORE_TO_NODES', {elementDesign, nodeId, clientFonts});
			mapArea.updateEl(true, nodeId); // update canvas
		},

		nodeOriginalNullify({getters, commit}, nodeId){
			commit('NULLIFY_NODE_ORIGINAL_VALUES', nodeId);
			const nodesOriginal = getters.getNodesOriginal;
			const currentNodeOriginal = nodesOriginal.find(node => node.id === nodeId);

			return currentNodeOriginal.design;
		},

		templateApply({getters, commit}, {templateId, nodeId}){
			const nodeOriginalDesign = getNodeDesignFromTemplate(templateId, getters.getElementsDesign);
			const fonts = getters.getClientFonts;
			const nodes = getters.getNodes;
			
			// convert font_url to fontName
			if(nodeOriginalDesign.font_url) nodeOriginalDesign.font_url = fontFamilyGetNameByUrl(nodeOriginalDesign.font_url, fonts);
			commit('UPDATE_NODE_ORIGINAL_TEMPLATE_ID', {nodeId, templateId}); // change template id accordingly
			commit('UPDATE_NODE_TEMPLATE_ID', {nodeId, templateId})

			commit('UPDATE_NODE', {id: nodeId, nodeData: {image: null}}) // remove image before update node related to templated

			delete nodeOriginalDesign.min_level;
			delete nodeOriginalDesign.max_level;
			commit('UPDATE_NODE', {id: nodeId, nodeData: adaptedNodeDesignProperties(nodeOriginalDesign)}); // apply template properties to node
			commit('NULLIFY_NODE_ORIGINAL_VALUES', nodeId);

			mapArea.updateEl(true, nodeId); // update canvas

			return nodes.find(node => node.id === nodeId);
		},

		// Globals changes
		async nodesApplyGlobalsUpdate({getters, commit, dispatch}){
			const elementsDesign = getters.getElementsDesign;
			const fonts = getters.getClientFonts;
			const nodes = getters.getNodes;
			// set applying template status or node's dimensions locked status
			commit('SET_TEMPLATE_APPLYING_STATUS', true);
			commit('APPLY_GLOBAL_UPDATE_TO_NODES', {elementsDesign, fonts});
			
			const nodesToUpdate = nodes.filter(node => parseInt(node.template) === 0);
			await dispatch('nodesUpdateConvertedEdgesRelated', nodesToUpdate);

			nodesToUpdate.forEach(node => {mapArea.updateEl(true, node.id)});
			commit('SET_TEMPLATE_APPLYING_STATUS', false);
		},

		nodesLockedFieldIgnoringSet({state, commit}, status){
			commit('SET_TEMPLATE_APPLYING_STATUS', status)
		},

		nodesUpdateConvertedEdgesRelated({dispatch}, nodesToUpdate){
			nodesToUpdate.forEach(node => {
				for(const [key, value] of Object.entries(node)){
					if(key === 'width'){
						edgeUpdateRelated(node,'fromNodeWidth', 'toNodeWidth', value, dispatch);
					}
					if(key === 'height'){
						edgeUpdateRelated(node,'fromNodeHeight', 'toNodeHeight', value, dispatch);
					}
					if(key === 'borderWidth'){
						edgeUpdateRelated(node,'fromNodeBorderWidth', 'toNodeBorderWidth', value, dispatch);
					}
					if(key === 'border'){
						// if border disabled avoid using border width in computed edge distance value
						edgeUpdateRelated(node,'fromNodeBorder', 'toNodeBorder', value, dispatch);
					}
					if(key === 'shape'){
						edgeUpdateRelated(node, 'fromNodeShape', 'toNodeShape', value, dispatch);
					}
				}
			});
		},

		// shape global
		shapeGlobalSet({commit}, shape) {
			commit('SET_SHAPE_GLOBAL', shape);
		},

		// MEDIA
		checkDeletedMedia({getters, commit, dispatch}, url){
			const nodes = getters.getNodes;
			const nodesOriginal = getters.getNodesOriginal;
			const elementsDesign = getters.getElementsDesign;
			const fonts = getters.getClientFonts;
			
			// clear node media field
			commit('REMOVE_DELETED_MEDIA_FROM_NODES', url);
			const nodesToUpdate = nodes.filter(node => node.media?.includes(url));
			nodesToUpdate.forEach(node => {
				// clear media && image
				node.media = null;
				node.image = null;
				// check if template or global design linked to this node has media/mediaURL
				const rebuiltNode = getNodeComputedDesign(node.id, nodes,nodesOriginal, elementsDesign, fonts);
				console.log('checkDeletedMedia')
				commit('UPDATE_NODE',
					{
						id: node.id,
						nodeData: {
							media: rebuiltNode.media,
							mediaURL: rebuiltNode.mediaURL,
							image: rebuiltNode.image
						}
					})
			})

			nodesToUpdate.forEach(node => {
				mapArea.updateEl(true, node.id);
			})
		},
		
		// Object templates
		objectTemplatesUpdatedApplyToNodes({getters, commit, dispatch}, {templateId}){
			const elementsDesign = getters.getElementsDesign
			const fonts = getters.getClientFonts;
			commit('APPLY_OBJECT_TEMPLATE_TO_NODES', {templateId, elementsDesign, fonts});
			const nodes = getters.getNodes;
			const nodesToUpdate = nodes.filter(node => node.template === templateId.toString());
			dispatch('nodesUpdateConvertedEdgesRelated', nodesToUpdate);
			nodesToUpdate.forEach(node => {
				mapArea.updateEl(true, node.id);
			})
		},

		objectTemplatesDeletedApply({getters, commit, dispatch}, templateId){
			const elementsDesign = getters.getElementsDesign
			const fonts = getters.getClientFonts;
			const templateData = {elementsDesign, templateId, fonts}
			const nodes = getters.getNodes;
			const nodesToUpdate = nodes.filter(node => node.template === templateId.toString());
			// nodesOriginal/nodes template set to null && update node design properties
			commit('APPLY_OBJECT_TEMPLATES_DELETED', templateData);
			// update edges related lengths
			dispatch('nodesUpdateConvertedEdgesRelated', nodesToUpdate);
			nodesToUpdate.forEach(node => {
				mapArea.updateEl(true, node.id);
			})
		},
		// zoom / locked / visibility
		nodesChangeZoomLevel({commit}, zoomLevel){
			commit('CHANGE_ZOOM_LEVEL', zoomLevel);
		},
		
		nodesUpdateOrder({commit, dispatch}, reorderData){
			const {nodeIndex, toNodeIndex} = reorderData;
			commit('UPDATE_NODE_ORDERING', {nodeIndex, toNodeIndex});
			dispatch('layers/layerNodesReorder', reorderData, {root: true});
			mapArea.updateEl();
		},
		async nodesOriginalSaveOrder({getters, dispatch}, {nodeIndex, toNodeIndex, layerID}){
			const mapsURL = getters.getMapsURL;
			const mapId = getters.getCurrentMap.id;
			const elementsURL = getters.getElementsURL;
			const nodesOriginalOrder = getters.getNodesOriginal.map(node => ({id: node.id}));
			
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, {nodes: nodesOriginalOrder});
				
				const editsData = {
					type: SE_SYNC_TYPES.NODES_ORDER_UPDATED,
					data: {nodeIndex, toNodeIndex, layerID, SE: true}
				}
				dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
				
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodesOrderSuccess'), dispatch);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodesOrderError'), dispatch);
			}
		},

		nodeUpdateOnFontLoaded({getters, commit}, fontData){
			const {fontFamily} = fontData;
			const nodes = getters.getNodes;
			const layerCurrentID = getters.getCurrentLayerID;
			nodes.forEach(node => {
				if(node.fontFamily === fontFamily){
					node.cacheInstanceSetDimensions();
					node.cache(layerCurrentID);
					mapArea.updateEl(true, node.id)
				}
			})
		},

		// SE actions
		nodeSetEditing({commit}, node){
			commit('SET_NODE_EDITING', node);
			mapArea.updateEl(true, node.elem_id);
		},
		nodeRemoveEditing({commit}, node){
			commit('REMOVE_NODE_EDITING', node);
			mapArea.updateEl(true, node.elem_id);
		},
		nodeSetOccupied({commit}, node){
			commit('SET_NODE_ORIGINAL_OCCUPIED', node);
		},
		nodeRemoveOccupied({commit}, node){
			commit('REMOVE_NODE_ORIGINAL_OCCUPIED', node);
		}
	}
}
