import FontUtils from "@/services/canvas/fonts/Fonts";
const {fontFamilyGetNameByUrl} = FontUtils();
import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default function useNodeTemplateUtils(){

  function createNodeTemplateObject(templateOriginal, fonts){
    return {
      id: templateOriginal.id,
      type: templateOriginal.type,
      shape: templateOriginal.shape,
      radius: templateOriginal.width / 2,
      width: templateOriginal.width,
      height: templateOriginal.height,
      rotate: templateOriginal.rotate,
      border: templateOriginal.border_enabled,
      borderColor: templateOriginal.border_color,
      borderStyle: templateOriginal.border_style,
      borderWidth: templateOriginal.border_width,
      surface: templateOriginal.bg_enabled,
      bgColor: templateOriginal.bg_color,
      shadow: templateOriginal.shadow_enabled,
      shadowColor: templateOriginal.shadow_color,
      shadowX: templateOriginal.shadow_x,
      shadowY: templateOriginal.shadow_y,
      shadowBlur: templateOriginal.shadow_blur,
      name: templateOriginal.name,
      title: t('objectTemplate.title'),
      titlePosition: templateOriginal.title_position,
      textAlign: templateOriginal.text_align,
      fontSize: templateOriginal.font_size,
      fontColor: templateOriginal.font_color,
      fontFamily: fontFamilyGetNameByUrl(templateOriginal.font_url, fonts),
      fontWeight: templateOriginal.font_weight,
      fontStyle: templateOriginal.font_style,
      lineHeight: templateOriginal.line_height,
      media: templateOriginal.media,
      mediaURL: templateOriginal.media_url,
      mediaCropEnabled: templateOriginal.media_cropped,
      mediaSize: templateOriginal.media_size || 100,
      mediaOpacity: templateOriginal.media_opacity || 100,
      image: null, // local instance of Image()
    }
  }

  const NODE_TEMPLATE_MAPPING = {
    surface: 'bg_enabled',
    bgColor: 'bg_color',
    border: 'border_enabled',
    borderColor: 'border_color',
    borderStyle: 'border_style',
    borderWidth: 'border_width',
    fontColor: 'font_color',
    fontSize: 'font_size',
    fontWeight: 'font_weight',
    fontFamily: 'font_url',
    fontStyle: 'font_style',
    lineHeight: 'line_height',
    height: 'height',
    width: 'width',
    rotate: 'rotate',
    media: 'media',
    mediaOpacity: 'media_opacity',
    mediaURL: 'media_url',
    mediaSize: 'media_size',
    mediaCropEnabled: 'media_cropped',
    shadowBlur: 'shadow_blur',
    shadowColor: 'shadow_color',
    shadow: 'shadow_enabled',
    shadowX: 'shadow_x',
    shadowY: 'shadow_y',
    shape: 'shape',
    textAlign: 'text_align',
    textDecoration: 'text_decoration',
    titlePosition: 'title_position',
  }

  function mappingInverted(mapping){
    const invertedMapping = {}
    for(let [key,value] of Object.entries(mapping)){
      Object.assign(invertedMapping, {[value]: key})
    }
    return invertedMapping;
  }

  function convertOriginalProperty(originalProperty){
    const [[key, value]] = Object.entries(originalProperty);
    const mapping = mappingInverted(NODE_TEMPLATE_MAPPING);
    if(mapping[key]) return {[mapping[key]] : value}
  }
  function convertToOriginalProperty(templateProperty){
    const [[key, value]] = Object.entries(templateProperty);
    if(NODE_TEMPLATE_MAPPING[key]) return {[NODE_TEMPLATE_MAPPING[key]] : value}
  }

  return{
    createNodeTemplateObject,
    convertOriginalProperty,
    convertToOriginalProperty,
  }
}