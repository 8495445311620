import CanvasTxt from '@/services/canvas/text/CanvasText';
import useColorUtils from '@/utils/colorUtils';
import {TEXT_POSITION} from '@/models/TEXT_POSITION';
import {TEXT_ALIGN} from '@/models/TEXT_ALIGN';
import useShapeUtils from "@/services/canvas/nodes/ShapeUtils";
const {hexToRGB, RGB_to_hex} = useColorUtils();
const {addShapeToContext} = useShapeUtils();
import {parseGIF, decompressFrames} from "gifuct-js";
import useMapArea from "@/services/canvas/map-area/MapArea";
const {mapArea} = useMapArea();

export default class NodeTemplate {
  constructor(
    {
      shape, type, name, id,
      radius, width, height, rotate,
      border, borderColor, borderStyle, borderWidth,
      surface, bgColor, shadow, shadowColor, shadowX, shadowY, shadowBlur,
      title, titlePosition, textAlign, fontSize, fontColor, fontFamily, fontWeight, fontStyle, lineHeight,
      mediaCropEnabled, mediaSize, mediaOpacity, media, mediaURL, image, gifAnimation
    })
  {
    this.shiftX = null;
    this.shiftY = null;

    this.id = id;
    this.type = type;
    this.name = name;
    this.occupied = false;
    this.snapshot = null; // image of the canvas

    // design
    this.shape = shape;
    this.radius = radius;
    this.width = width;
    this.height = height;
    this.rotate = rotate;
    this.border = border;
    this.borderColor = borderColor;
    this.borderStyle = borderStyle;
    this.borderWidth = borderWidth;
    this.surface = surface;
    this.bgColor = bgColor;

    this.shadow = shadow;
    this.shadowColor = shadowColor;
    this.shadowX = shadowX;
    this.shadowY = shadowY;
    this.shadowBlur = shadowBlur;

    this.title = title;
    this.titlePosition = titlePosition;
    this.textAlign = textAlign;
    this.fontSize = parseInt(fontSize);
    this.fontColor = fontColor;
    this.fontFamily = fontFamily;
    this.fontWeight = fontWeight;
    this.fontStyle = fontStyle;
    this.lineHeight = lineHeight;

    this.media = media;
    this.mediaURL = mediaURL;
    this.image = image;
    this._imageFetching = false;
    this.mediaCropEnabled = mediaCropEnabled;
    this.mediaSize = mediaSize;
    this.mediaOpacity = mediaOpacity;
    // -------

    // node states && features
    this.globalAlpha = 1;
    this.hover = false;
    this.selected = false;
    this.editingContent = false;
    this.editingAppearance = false;

    this.path = null;

    this.ctx = null;
    this.cacheInstance = null;
    this.pixelRatio = null;

    // properties {width, height, x, y} of objects which impacts to canvasCache dimensions
    // to precisely detect canvas area
    this.shapeBounds = null;
    this.imageBounds = null;
    this.shadowBounds = null;
    this.titleBounds = null;

    // gif animation data
    this.animation = false;
    this.gifAnimation = gifAnimation;
    this.frames = [];
    this.frameCanvas = null;
    this.frameCtx = null;

    this.frameCommonCanvas = null;
    this.frameCommonCtx = null;

    this.framesSource = null;
    this.frameIndex = 0;
    this.frameTimeout = null;

    // coords of the figure itself used to draw on the map
    this.xCache = null;
    this.yCache = null;

    this.ZOOM_MAX = 1; // 1.62854;
    this.SHADOW_BLUR_MULTIPLIER = 2;
  }

  cacheInstanceSetDimensions(){
    /**
     * must be called first on initialization or if changes impacts on canvas size e.g. width, height, borderWidth, titlePosition, blurShadow
     * cache() function creates offscreen canvas with computed size multiplied to maximum available zoomFactor
     *
     * */

    if(!this.cacheInstance){
      this.cacheInstance = document.createElement('canvas');
      this.ctx = this.cacheInstance.getContext('2d');
    }

    const {width, height} = this.getBounds();

    this.getPixelRatio();

    this.cacheInstance.width = Math.ceil(width * this.ZOOM_MAX * this.pixelRatio);
    this.cacheInstance.height = Math.ceil(height * this.ZOOM_MAX * this.pixelRatio);

    this.cacheInstance.style.width = `${width * this.ZOOM_MAX}px`;
    this.cacheInstance.style.height = `${height * this.ZOOM_MAX}px`;

    this.ctx.scale(this.pixelRatio * this.ZOOM_MAX, this.pixelRatio * this.ZOOM_MAX);
  }

  init(){
    this.cacheInstanceSetDimensions();
    this.cache();
    this.makeSnapshot();
  }

  cache(){
    this.getPixelRatio();
    // get coords of shape's center
    ({x: this.xCache, y: this.yCache} = this._getShapeCoordinates());

    this._drawShape();
    this._drawMedia(); // or animate media
    this._drawStroke();
    this._drawText();
  }

  devDrawBorders(){
    const multiplier = this.ZOOM_MAX * this.pixelRatio;
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(this.ctx.canvas.width / multiplier, 0);
    this.ctx.lineTo(this.ctx.canvas.width / multiplier, this.ctx.canvas.height / multiplier);
    this.ctx.lineTo(0, this.ctx.canvas.height / multiplier);
    this.ctx.lineTo(0, 0);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = 'rgba(255,244,255,.6)';
    this.ctx.stroke();
  }

  cacheClear(){
    this.ctx.clearRect(0, 0, this.cacheInstance.width, this.cacheInstance.height);
  }

  makeSnapshot(canvas){
    if(!canvas) canvas = this.cacheInstance;
    this.snapshot = this.toDataURL(canvas);
  }

  toDataURL(canvas){
    if(!canvas) canvas = this.cacheInstance;
    return canvas.toDataURL();
  }

  getPixelRatio(){
    return this.pixelRatio = window.devicePixelRatio;
  }

  _drawStroke(){
    if(!this.border || !this.borderWidth) return;
    this.ctx.beginPath();
    this.ctx.save();

    addShapeToContext({
      ctx: this.ctx,
      node: this,
      center: {x: this.xCache, y: this.yCache},
      border: true
    })

    this.ctx.lineWidth = this.borderWidth;
    this._setBorderStyle();

    // this.ctx.strokeStyle = this.hover && !this.editingContent && !this.editingAppearance && this.id === hoveredId ? hoverColor : this.borderColor;
    this.ctx.strokeStyle = this.borderColor;
    this.ctx.globalAlpha = this.globalAlpha;

    this.ctx.stroke();

    this.ctx.restore();
    this.ctx.closePath();
  }

  _drawShape(){
    this.ctx.beginPath();
    this.ctx.save();

    // to use isPointInPath
    this.path = new Path2D();
    const zoomLevel = this.pixelRatio * this.ZOOM_MAX;

    addShapeToContext({
      ctx: this.path,
      node: this,
      center: {x: this.xCache, y: this.yCache},
    })

    const selectedColor = '#ED6F78';
    const bgColor = this.surface ? this.bgColor : 'rgba(0, 0, 0, 0)'
    // this.ctx.fillStyle = this.hover && !this.selected ? selectedColor : bgColor;
    this.ctx.fillStyle = bgColor;

    // shadow options
    if(this.shadow){
      /*const RGB = hexToRGB(this.shadowColor);
      ctx.shadowColor = RGB ? `rgba(${RGB.r}, ${RGB.g}, ${RGB.b}, 0.2)` : 'rgba(0, 0, 0, 0)';*/
      this.ctx.shadowColor = this.shadowColor;
      this.ctx.shadowOffsetX = this.shadowX * zoomLevel;
      this.ctx.shadowOffsetY = this.shadowY * zoomLevel;
      this.ctx.shadowBlur = this.shadowBlur * zoomLevel * 2; // 2 - multiply to align it with css view
    }

    this.ctx.globalAlpha = this.globalAlpha;

    this.ctx.fill(this.path);

    this.ctx.restore();
    this.ctx.closePath();
  }

  async _drawMedia(){
    if (this.image && this.image.width){
      const {x, y, width, height} = this.imageBounds;
      const hostURL = process.env.VUE_APP_ROOT_HOST;
      const media = this.media && !this.media.includes(hostURL) ? `${hostURL}${this.media}` : this.media;
      const url =  media || this.mediaURL;
      const isGifURL = url?.includes('.gif');

      if(!this._imageFetching && this.id !== undefined && isGifURL && ( !this.animation || (this.framesSource && url !== this.framesSource))){
        this._imageFetching = true;
        await fetch(url, {
          method: 'GET',
          credentials: 'include',
        })
          .then(resp => resp.arrayBuffer())
          .then(buff => {``
            if(buff.byteLength > 0) {
              const gif = parseGIF(buff)
              this.frameIndex = 0;
              this.frames = decompressFrames(gif, true);
              this.framesSource = url;
              this.animation = true;

              this.frameCommonCanvas = null;
              this.frameCanvas = null;
              this.frameCommonCtx = null;
              this.frameCtx = null;

              this._imageFetching = false;
              return gif;
            }
            return true;
          })
          .catch(err => {
            this._imageFetching = false;
          })
      }
      if(!isGifURL && this.animation) {
        this.animation = false;
      }

      this.ctx.save();
      this.ctx.globalAlpha = this.globalAlpha < 1 ? this.globalAlpha : (this.mediaOpacity / 100);
      if(this.mediaCropEnabled){
        addShapeToContext({
          ctx: this.ctx,
          node: this,
          center: {x: this.xCache, y: this.yCache},
        })
        this.ctx.clip();
      }

      let image = this.image;

      if(this.animation && this.id !== undefined && this.gifAnimation) {

        const frame = this.frames[this.frameIndex];

        if(frame){
          const start = new Date().getTime();

          if(!this.frameCommonCtx){
            this.frameCommonCanvas = document.createElement('canvas');
            this.frameCommonCtx = this.frameCommonCanvas.getContext('2d');
            this.frameCommonCanvas.width = frame.dims.width;
            this.frameCommonCanvas.height = frame.dims.height;
          }
          if(!this.frameCtx){
            this.frameCanvas = document.createElement('canvas');
            this.frameCtx = this.frameCanvas.getContext('2d');
            this.frameCanvas.width = frame.dims.width;
            this.frameCanvas.height = frame.dims.height;
          }

          const imageData = new ImageData(frame.patch, frame.dims.width, frame.dims.height);
          this.frameCtx.putImageData(imageData, frame.dims.left, frame.dims.top);

          const end = new Date().getTime();
          const diff = end - start;

          if(this.frameIndex === 0 || frame.disposalType === 2){
            this.frameCommonCtx.clearRect(
              0, 0,
              this.frameCanvas.width,
              this.frameCanvas.height,
            )
          }

          this.frameIndex++;
          if(this.frameIndex >= this.frames.length) {
            this.frameIndex = 0;
          }

          this.frameCommonCtx.drawImage(this.frameCanvas, 0, 0);
          image = this.frameCommonCanvas;

          if(this.frameTimeout) clearTimeout(this.frameTimeout);
          this.frameTimeout = setTimeout(() => {
            if(this.id !== undefined) mapArea.updateEl(true, this.id);
            // if(id === undefined) this.cache();
          }, Math.max(0, Math.floor(frame.delay - diff)))
        }

      }

      this.ctx.drawImage(
        image,
        x + this.shiftX,
        y + this.shiftY,
        width,
        height
      );

      this.ctx.restore();
    }
  }

  _drawText(){
    if(this.fontSize === 0) return;
    if(!this.title) return;

    const computedBorder = this.border ? this.borderWidth : 0;
    const {x: titleX, y: titleY, width: titleW, height: titleH, align: tAlign} = this.titleBounds;

    const CanvasTextSettings = {
      debug: false,
      font: this.fontFamily,
      fontWeight: this.fontWeight,
      fontStyle: this.fontStyle,
      fontSize: this.fontSize,
      lineHeight: this.fontSize * this.lineHeight,
      align: tAlign,
      vAlign: 'center'
    }

    const CanvasText = new CanvasTxt(null, CanvasTextSettings);

    const textData = {
      ctx: this.ctx,
      title: this.title,
      x: titleX + this.xCache - this.width / 2  - computedBorder,
      y: titleY + this.yCache - this.height / 2 - computedBorder,
      width: titleW,
      height: titleH,
    }

    this.ctx.save();

    if(this.fontColor === null) this.fontColor = 'rgba(0, 0, 0, 1)';
    // todo add converter rgb to rgb object
    if(this.fontColor.includes('rgba')){
      this.ctx.fillStyle = this.fontColor;
    } else {
      const fontInRGB = this.fontColor.includes('rgb') ? hexToRGB(RGB_to_hex(this.fontColor)) : hexToRGB(this.fontColor);
      this.ctx.fillStyle = `rgba(${fontInRGB.r}, ${fontInRGB.g}, ${fontInRGB.b}, ${this.globalAlpha})`;
    }

    CanvasText.drawText(textData);

    this.ctx.restore();

    return CanvasText;
  }

  _titleDataComputed(){
    /**
     * return computed x and y depends on title position
     * and settings for CanvasText class to draw the title like
     * vAlign and align which are responsible for
     * vertical and horizontal positioning of text
     * inside box area which specified by width and height
     * */
    this.ctx.font = `${this.fontWeight} ${this.fontStyle} ${this.fontSize}px ${this.fontFamily || 'Arial'}`;

    const CanvasTextSettings = {
      debug: false,
      font: this.fontFamily,
      fontWeight: this.fontWeight,
      fontStyle: this.fontStyle,
      fontSize: this.fontSize,
      lineHeight: this.fontSize * this.lineHeight,
    }

    const CanvasText = new CanvasTxt(null, CanvasTextSettings);
    const {width: shapeWidth, height: shapeHeight} = this.shapeBounds;

    let titleWidth = null;
    if(this.titlePosition === TEXT_POSITION.CENTER.VALUE) titleWidth = shapeWidth;

    const {textWidth, textHeight} = CanvasText.getDrawnTextDimensions({ctx: this.ctx, title: this.title, width: titleWidth});
    const {textWidth: textWidthBorderless, textHeight: textHeightBorderless} = CanvasText.getDrawnTextDimensions({ctx: this.ctx, title: this.title});

    const isTitleOverflow = textHeight > shapeHeight;
    const computedBorder = this.border ? this.borderWidth : 0;
    const padding = 5;

    const computedPosition = {
      x: null,
      y: null,
      initialX: null,
      initialY: null,
      width: textWidth,
      height: textHeight,
      isTextOverflow: false,
      vAlign: TEXT_POSITION.CENTER.VALUE,
      align: null,
      // padding: computedBorder + padding
    }
    /**
     * initialX/initialY coordinates of the rect contains a text
     * top/left corner of the shape assume to be {0, 0} coordinates
     * */

    switch (this.titlePosition){
      case TEXT_POSITION.TOP_LEFT.VALUE:
        // computedPosition.x = Scale.x(this.x + mapArea.x - (this.width * 3) / 2, ctx.canvas.width / 2, zoomLevel);
        computedPosition.x = this.xCache - this.width / 2 - computedBorder - padding - textWidth;
        computedPosition.y = this.yCache - this.height / 2 - textHeight - computedBorder - padding;
        computedPosition.initialX = /*- computedBorder*/ - padding - textWidth;
        computedPosition.initialY = - textHeight /*- computedBorder*/ - padding;
        computedPosition.vAlign = TEXT_POSITION.BOTTOM.VALUE;
        computedPosition.align = TEXT_ALIGN.RIGHT.VALUE;
        break;
      case TEXT_POSITION.TOP.VALUE:
        computedPosition.x = this.xCache - textWidth / 2;
        computedPosition.y = this.yCache - this.height / 2 - padding - computedBorder - textHeight;
        computedPosition.initialX = shapeWidth / 2 - textWidth / 2;
        computedPosition.initialY = - padding /*- computedBorder*/ - textHeight;
        computedPosition.vAlign = TEXT_POSITION.BOTTOM.VALUE;
        computedPosition.align = TEXT_ALIGN.CENTER.VALUE;
        break;
      case TEXT_POSITION.TOP_RIGHT.VALUE:
        computedPosition.x = this.xCache + this.width / 2 + computedBorder + padding;
        computedPosition.y = this.yCache - this.height / 2 - textHeight - computedBorder - padding;
        computedPosition.initialX = shapeWidth + padding;
        computedPosition.initialY = - textHeight /*- computedBorder*/ - padding;
        computedPosition.vAlign = TEXT_POSITION.BOTTOM.VALUE;
        computedPosition.align = TEXT_ALIGN.LEFT.VALUE;
        break;
      case TEXT_POSITION.LEFT.VALUE:
        computedPosition.x = this.xCache - this.width / 2 - computedBorder - padding - textWidth;
        computedPosition.y = this.yCache - textHeight / 2;
        computedPosition.initialX = /*- computedBorder*/ - padding - textWidth;
        computedPosition.initialY = shapeHeight / 2 - textHeight / 2;
        computedPosition.align = TEXT_ALIGN.RIGHT.VALUE;
        break;
      case TEXT_POSITION.CENTER.VALUE:
        computedPosition.x = this.xCache - this.width / 2;
        computedPosition.y = this.yCache - this.height / 2;
        computedPosition.initialX = isTitleOverflow? (shapeWidth - textWidthBorderless) / 2 : 0;
        computedPosition.initialY = isTitleOverflow ? (shapeHeight - textHeightBorderless) / 2 : 0;
        computedPosition.width = isTitleOverflow ? textWidthBorderless : shapeWidth;
        computedPosition.height = isTitleOverflow ? textHeightBorderless : shapeHeight;
        computedPosition.isTextOverflow = true;
        computedPosition.align = this.textAlign;
        break;
      case TEXT_POSITION.RIGHT.VALUE:
        computedPosition.x = this.xCache + this.width / 2 + computedBorder + padding;
        computedPosition.y = this.yCache - textHeight / 2;
        computedPosition.initialX = shapeWidth + padding;
        computedPosition.initialY = shapeHeight / 2 - textHeight / 2;
        computedPosition.align = TEXT_ALIGN.LEFT.VALUE;
        break;
      case TEXT_POSITION.BOTTOM_LEFT.VALUE:
        computedPosition.x = this.xCache - this.width / 2 - computedBorder - padding - textWidth;
        computedPosition.y = this.yCache + this.height / 2 + computedBorder + padding;
        computedPosition.initialX = /*- computedBorder*/ - padding - textWidth;
        computedPosition.initialY = shapeHeight + padding;
        computedPosition.vAlign = TEXT_POSITION.TOP.VALUE;
        computedPosition.align = TEXT_ALIGN.RIGHT.VALUE;
        break;
      case TEXT_POSITION.BOTTOM.VALUE:
        computedPosition.x = this.xCache - textWidth / 2;
        computedPosition.y = this.yCache + this.height / 2 + computedBorder + padding;
        computedPosition.initialX = shapeWidth / 2 - textWidth / 2;
        computedPosition.initialY = shapeHeight + padding;
        computedPosition.vAlign = TEXT_POSITION.TOP.VALUE;
        computedPosition.align = TEXT_ALIGN.CENTER.VALUE;
        break;
      case TEXT_POSITION.BOTTOM_RIGHT.VALUE:
        computedPosition.x = this.xCache + this.width / 2 + computedBorder + padding;
        computedPosition.y = this.yCache + this.height / 2 + computedBorder + padding;
        computedPosition.initialX = shapeWidth + padding;
        computedPosition.initialY = shapeHeight + padding;
        computedPosition.vAlign = TEXT_POSITION.TOP.VALUE;
        computedPosition.align = TEXT_ALIGN.LEFT.VALUE;
        break;
      default:
        //
        break;
    }

    return computedPosition;
  }

  // BOUNDS
  getBounds(){
    let width = null;
    let height = null;

    this._getShapeBounds();
    this._getShadowBounds();
    this._getMediaBounds();
    this._getTitleBounds();

    const {width: shapeWidth, height: shapeHeight} = this.shapeBounds;
    const {x: shadowX, y: shadowY, blurWidth: shadowBlurWidth} = this.shadowBounds;
    const {offsetX: mediaOffsetX, offsetY: mediaOffsetY} = this.imageBounds;
    const {x: titleX, y: titleY, width: titleWidth, height: titleHeight, isTextOverflow} = this.titleBounds;

    /**
     * because these calculations happens before canvas has width/height
     * shape top left point is always {0, 0}
     * features impacts on canvas dimensions like shadow, title or image
     * split up to a parts located before/above and after/below the shape itself
     * to find the biggest ones which are the cached shape dimensions
     * */

      // SHADOW COMPUTED
    const shadowWidthLeft = shadowX < 0 ? shadowX : 0;
    const shadowWidthRight = shadowX < 0 ? shadowBlurWidth * 2 - Math.abs(shadowX) : shadowBlurWidth * 2 + shadowX;
    const shadowHeightTop = shadowY < 0 ? shadowY : 0;
    const shadowHeightBottom = shadowY < 0 ? shadowBlurWidth * 2 - Math.abs(shadowY) : shadowBlurWidth * 2 + shadowY;

    // TITLE COMPUTED
    const titleWidthLeft = titleX < 0 ? titleX : 0;
    const isTitlePositionBottom = this.titlePosition === TEXT_POSITION.BOTTOM.VALUE;
    const isTitlePositionTop = this.titlePosition === TEXT_POSITION.TOP.VALUE;

    let titleWidthRight = titleX > shapeWidth ? titleX - shapeWidth + titleWidth : 0;
    if(isTextOverflow || isTitlePositionBottom || isTitlePositionTop) titleWidthRight = (titleWidth - shapeWidth) / 2;

    const titleHeightTop = titleY < 0 ? titleY : 0;
    let titleHeightBottom = (titleY > shapeHeight || titleHeight > shapeHeight) ? titleY - shapeHeight + titleHeight : 0;
    if(isTextOverflow) titleHeightBottom = (titleHeight - shapeHeight) / 2;

    // MEDIA COMPUTED
    const mediaWidthLeft = mediaOffsetX;
    const mediaWidthRight = Math.abs(mediaOffsetX);
    const mediaHeightTop = mediaOffsetY;
    const mediaHeightBottom = Math.abs(mediaOffsetY);

    // ID
    const SHAPE_ID_HEIGHT = 12;
    const STATUS_LAYER_HEIGHT = 29 + 2; // 2 is padding
    const NODE_MIN_WIDTH = 60 + 2; // 2 is padding
    const computedEditedLayerWidth = this.editingAppearance || this.editingContent ? NODE_MIN_WIDTH : 0;
    const computedEditedLayerHeight = this.editingAppearance || this.editingContent ? STATUS_LAYER_HEIGHT : 0;

    // WIDTH && HEIGHT COMPUTED
    const leftPart = Math.abs(Math.min(0, titleWidthLeft, shadowWidthLeft, mediaWidthLeft));
    const rightPart = Math.abs(Math.max(shadowWidthRight, titleWidthRight, mediaWidthRight, computedEditedLayerWidth));
    const topPart = Math.abs(Math.min(-SHAPE_ID_HEIGHT, shadowHeightTop, titleHeightTop, mediaHeightTop));
    const bottomPart = Math.abs(Math.max(shadowHeightBottom, titleHeightBottom, mediaHeightBottom, computedEditedLayerHeight));

    // RESULT
    const resultWidth = shapeWidth + leftPart + rightPart;
    const resultHeight = shapeHeight + topPart + bottomPart;

    width = resultWidth;
    height = resultHeight;

    return {width, height}
  }

  _getShapeBounds(){
    let width = this.width;
    let height = this.height;

    if(this.border){
      width += this.borderWidth * 2;
      height += this.borderWidth * 2;
    }

    const shapeBounds = {width, height, x: width / 2, y: height / 2};

    this.shapeBounds = shapeBounds;

    return shapeBounds;
  }

  _getShadowBounds(){
    let shadowBounds = {width: null, height: null, x: null, y: null, blurWidth: null, shadowShiftX: null, shadowShiftY: null};

    // 2 is a multiplier of shadowBlur needed to fit css shadow on preview map
    // TODO: find more accurate formula of getting shadowBlur dimensions using Gassing Blur 2D

    if(!this.shadow){
      shadowBounds = Object.assign({}, this.shapeBounds);
      // x and y of shadowBounds are top left point of the rect
      shadowBounds.x -= shadowBounds.width / 2;
      shadowBounds.y -= shadowBounds.height / 2;
      shadowBounds.blurWidth = 0;
      shadowBounds.shadowShiftX = 0;
      shadowBounds.shadowShiftY = 0;
    } else {
      const blurWidth = this.shadowBlur * this.SHADOW_BLUR_MULTIPLIER;
      shadowBounds.blurWidth = blurWidth;
      shadowBounds.shadowShiftX = this.shadowX;
      shadowBounds.shadowShiftY = this.shadowY;
      shadowBounds.width = this.shapeBounds.width + blurWidth * 2/* * this.pixelRatio*/;
      shadowBounds.height = this.shapeBounds.height + blurWidth * 2/* * this.pixelRatio*/;

      // calc x and y depends on shadowX and shadowY values
      // x and y are coordinates of top left point relative the center of the figure
      shadowBounds.x = this.shadowX - blurWidth ;
      shadowBounds.y = this.shadowY - blurWidth;
    }

    this.shadowBounds = shadowBounds;

    return shadowBounds;
  }

  _getMediaBounds(){
    const {width: shapeWidth, height: shapeHeight} = this.shapeBounds;
    const imageBounds = {width: null, height: null, x: null, y: null};

    if(this.image && this.image.width){
      const imageRatio = this.image.width / this.image.height;
      const shapeRatio = shapeWidth / shapeHeight;
      const imageSizeMultiplier =  this.mediaSize / 100;
      const scaleFactor = imageRatio > shapeRatio ? this.width / this.image.width : this.height / this.image.height;

      imageBounds.width = this.image.width * scaleFactor * imageSizeMultiplier;
      imageBounds.height = this.image.height * scaleFactor * imageSizeMultiplier;
      imageBounds.x = shapeWidth /2 - imageBounds.width / 2;
      imageBounds.y = shapeHeight / 2 - imageBounds.height / 2;
      imageBounds.offsetX = !this.mediaCropEnabled && imageBounds.x < 0 ? imageBounds.x : 0;
      imageBounds.offsetY = !this.mediaCropEnabled && imageBounds.y < 0 ? imageBounds.y : 0;
    } else {
      imageBounds.width = shapeWidth;
      imageBounds.height = shapeHeight;
      imageBounds.x = 0;
      imageBounds.y = 0;
      imageBounds.offsetX = 0;
      imageBounds.offsetY = 0;
    }

    return this.imageBounds = imageBounds;
  }

  _getTitleBounds(){
    this.title = this.title === null ? '' : this.title;
    const {initialX: tX, initialY: tY, width: tW, height: tH, align: tAlign, isTextOverflow} = this._titleDataComputed();
    const titleBounds = {x: tX, y: tY, width: tW, height: tH, isTextOverflow , align: tAlign};

    if(!this.title || this.fontSize === 0){
      titleBounds.x = 0;
      titleBounds.y = 0;
      titleBounds.width = 0;
      titleBounds.height = 0;
      return this.titleBounds = titleBounds;
    }

    return this.titleBounds = titleBounds;
  }

  _getShapeCoordinates(){
    let x = this.shapeBounds.x;
    let y = this.shapeBounds.y;
    const SHAPE_ID_HEIGHT = 12;

    const {shadowShiftX, shadowShiftY, blurWidth: shadowBlurWidth} = this.shadowBounds;
    const {offsetX: mediaOffsetX, offsetY: mediaOffsetY} = this.imageBounds;
    const {x: titleX, y: titleY} = this.titleBounds;

    // shadow computed
    const shadowShiftXComputed = shadowShiftX - shadowBlurWidth;
    const shadowShiftYComputed = shadowShiftY - shadowBlurWidth;

    // 0 is beginning of coordinates
    this.shiftX = Math.abs(Math.min(0, shadowShiftXComputed, titleX, mediaOffsetX));
    this.shiftY = Math.abs(Math.min(-SHAPE_ID_HEIGHT, shadowShiftYComputed, titleY, mediaOffsetY));

    x += this.shiftX;
    y += this.shiftY;

    return {x, y}
  }

  // UTILS

  _setBorderStyle(){
    const BORDER_STYLES = {
      SOLID: 'solid',
      DASHED: 'dashed',
      DOTTED: 'dotted'
    }

    if (this.borderStyle === BORDER_STYLES.DASHED){
      this.ctx.setLineDash([this.borderWidth * 4, this.borderWidth * 2])
    } else if (this.borderStyle === BORDER_STYLES.DOTTED){
      this.ctx.lineCap = 'round';
      const multiplier = this.borderWidth === 1 ? 2 : 1;
      this.ctx.lineWidth = this.borderWidth + 1;
      this.ctx.setLineDash([this.borderWidth / 100, this.borderWidth * multiplier * 3]);
    }
  }

}
