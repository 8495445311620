export const COLORS = [
	{NAME: 'orange', VALUE: '#ff5421'},
	{NAME: 'blue-grey', VALUE: '#b5eaea'},
	{NAME: 'golden', VALUE: '#e8c200'},
	{NAME: 'dusky-rose', VALUE: '#bc6490'},
	{NAME: 'sea', VALUE: '#359a83'},
	{NAME: 'pink', VALUE: '#ed6f78'},
	{NAME: 'greeny-blue', VALUE: '#42b7b7'},
	{NAME: 'pale-teal', VALUE: '#82c2a0'}
]
