import EdgeUtils from '@/services/canvas/edges/EdgeUtils';
import ToastUtils from '@/utils/ToastUtils';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import Fonts from '@/services/canvas/fonts/Fonts';

const {
  createEdgeObject,
  getEdgeDesignInitialComputedValue,
  getEdgeDesignFromTemplate,
  getEdgeDesignSyncedToOriginal,
  convertOriginalToEdgeProperty,
  convertToOriginalEdgeProperty,
  adaptedOriginalDesignPropertiesToEdge
} = EdgeUtils();
const {fontFamilyGetNameByUrl, fontFamilyGetUrlByName} = Fonts();
const {createToastFromStore} = ToastUtils();
const {mapArea} = useMapArea();

import Edge from '@/services/canvas/edges/Edge';
import ApiService from '@/utils/ApiService';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import useMapArea from '@/services/canvas/map-area/MapArea';
import useI18nGlobal from '@/utils/i18n';
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";

const {i18n} = useI18nGlobal();
const {t} = i18n.global;
// MODULES
import create from '@/store/modules/map/edges/modules/create';
import remove from '@/store/modules/map/edges/modules/remove';
import visibility from "@/store/modules/map/edges/modules/visibility";
import design from "@/store/modules/map/edges/modules/design";
import content from "@/store/modules/map/edges/modules/content";

export default {
  namespaced: true,
  modules: {
    create,
    remove,
    design,
    visibility,
    content,
  },
  state: () => ({
    edgesOriginal: null,
    edges: null,
    edgesURL: '/edges/',
    edgeActiveID: null,
    edgesHidden: false,
    edgesLocked: false,
    contentOrder: {},
    visibilityOrder: [],
    contentTimeout: null,
    visibilityTimeout: null,
    edgeConnections: {
      from: null,
      to: null
    }
  }),
  getters: {
    getEdges(state) {
      return state.edges;
    },
    getEdgesOriginal(state) {
      return state.edgesOriginal;
    },
    getEdgesHidden(state) {
      return state.edgesHidden
    },
    getEdgesLocked(state) {
      return state.edgesLocked
    },
    getActiveEdgeID(state) {
      return state.edgeActiveID
    },
    getActiveEdge(state) {
      return state.edges.find(edge => edge.id === state.edgeActiveID)
    },
    getActiveEdgeOriginal(state) {
      return state.edgesOriginal.find(edge => edge.id === state.edgeActiveID)
    },
    getNodes(state, getters, rootState, rootGetters) {
      return rootGetters['nodes/getNodes'];
    },
    getEdgesURL(state) {
      return state.edgesURL;
    },
    getMapsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getMapsURL"]
    },
    getCurrentMap(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getCurrentMap"]
    },
    getElementsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getElementsURL"]
    },
    getElementsDesign(state, getters, rootState, rootGetters) {
      return rootGetters["design/getElementsDesign"]
    },
    getClientFonts(state, getters, rootState, rootGetters) {
      return rootGetters["fonts/getClientFonts"]
    },
    getLayersAmount(state, getters, rootState) {
      return rootState.mapSettings.mapData.zoomLevels
    },
    getCurrentLayerID(state, getters, rootState) {
      return rootState.layers.layerCurrentID
    },
    getEdgeConnections(state) {
      return state.edgeConnections;
    },
    isTemplateChangesTracked(state, getters, rootState) {
      const activeEdge = state.edgesOriginal.find(edge => edge.id === state.edgeActiveID);
      const templateId = activeEdge.design.object_template;

      for (let [key, value] of Object.entries(activeEdge.design)) {
        // avoid checking non-comparable values and null values
        if (key !== 'object_template' && value !== null) {
          // if node's value isn't equal to computed template value
          if (value !== getEdgeDesignInitialComputedValue(templateId, rootState.design.design, key)) {
            return true
          }
        }
      }
      // if no differences found
      return false
    },
    getChosenTemplate(state, getters, rootState, rootGetters){
      return rootGetters["templates/getObjectTemplateChosen"]
    }
  },
  mutations: {
    EDGES_ORIGINAL_SAVE(state, edges) {
      state.edgesOriginal = edges;
    },
    POPULATE_EDGES(state, edgesData) {
      const {nodes, elementsDesign, fonts} = edgesData;
      state.edges = [];
      const unlinkedEdges = JSON.parse(JSON.stringify(state.edgesOriginal));

      unlinkedEdges.forEach(edge => {
        state.edges.push(new Edge(createEdgeObject(edge, nodes, elementsDesign, fonts)))
      })
    },
    TOGGLE_EDGES_HIDDEN(state) {
      state.edgesHidden = !state.edgesHidden;
      state.edges.forEach(edge => {
        edge.hidden = !edge.hidden;
      })
    },
    TOGGLE_EDGES_LOCKED(state) {
      state.edgesLocked = !state.edgesLocked;
      state.edges.forEach(edge => {
        edge.locked = !edge.locked;
      })
    },
    UPDATE_EDGE(state, data) {
      // const {edgeId, startPosX, endPosX, startPosY, endPosY } = updateData;
      const updateData = {...data};
      const {edgeId} = updateData;
      const edge = state.edges.find(edge => edge.id === parseInt(edgeId));
      if (!edge) {
        console.log(updateData);
        return;
      }

      mapArea.updateEl(true, edgeId, EDITING_ELEM_TYPES.EDGE)
      // get rid of temporary data before put it to the store
      delete updateData.edgeId;
      Object.assign(edge, updateData);
    },
    UPDATE_EDITED_EDGE(state, {edgeData, edgeId}) {
      const edge = state.edges.find(edge => edge.id === edgeId);
      Object.assign(edge, edgeData);
    },
    SET_ACTIVE_EDGE(state, edgeID) {
      state.edgeActiveID = edgeID;
      /*const edgeIndex = state.edges.findIndex(edge => edge.id === edgeID);
      edgeID ? state.edges[edgeIndex].selected = true : state.edges[edgeIndex].selected = false;*/
    },
    CHANGE_ZOOM_LEVEL(state, zoomData) {
      const {zoomLevel, previous} = zoomData;
      state.edges.forEach(edge => {
        if (edge.visibilityMax >= zoomLevel) {
          edge.visibilityMax = zoomLevel;
          // check if visibilityMax more than previous zoomLevel which would means it has changed
          // and should be increased to same as zoomLevel
        } else if (edge.visibilityMax >= previous && zoomLevel > previous) {
          edge.visibilityMax = zoomLevel;
        }

        if (edge.visibilityMin > zoomLevel) {
          edge.visibilityMin = zoomLevel;
        }
      })
    },

    APPLY_TEMPLATE_RESTORE_TO_EDGES(state, data) {
      const {elementsDesign, fonts, edgeId} = data;
      const currentEdgeOriginal = state.edgesOriginal.find(edge => edge.id === edgeId);
      let currentEdge = state.edges.find(edge => edge.id === edgeId);
      const templateId = currentEdge.template;
      // set to null all node's values and collect changed properties to restoredProperties object
      let restoredProperties = {}
      for (let [key, value] of Object.entries(currentEdgeOriginal.design)) {
        if (key !== 'object_template' && value !== null) {
          currentEdgeOriginal.design[key] = null;
          Object.assign(restoredProperties, {[key]: value})
        }
      }
      let convertedData = {};
      // convert edgeOriginal to edge properties and set computed properties to edge to redraw it on the canvas
      Object.entries(restoredProperties).forEach(([property, value]) => {

        Object.assign(
          convertedData,
          convertOriginalToEdgeProperty({[property]: getEdgeDesignInitialComputedValue(templateId, elementsDesign, property)}))
      });
      convertedData.fontFamily = fontFamilyGetNameByUrl(convertedData.fontFamily, fonts);
      Object.assign(currentEdge, convertedData)
    },
    NULLIFY_EDGE_ORIGINAL_VALUES(state, edgeId) {
      const currentEdgeId = edgeId !== null && edgeId !== undefined ? edgeId : state.edgeActiveID;
      // const currentEdgeId = edgeId || state.edgeActiveID;
      const currentEdgeOriginal = state.edgesOriginal.find(edge => edge.id === currentEdgeId);
      for (let [key, value] of Object.entries(currentEdgeOriginal?.design)) {
        if (key !== 'object_template' && value !== null) {
          currentEdgeOriginal.design[key] = null;
        }
      }
    },
    UPDATE_EDGE_TEMPLATE_ID(state, {edgeId, templateId}) {
      const edgeIndex = state.edges.findIndex(edge => edge.id === edgeId);
      state.edges[edgeIndex].template = templateId !== null ? templateId.toString() : templateId;
    },
    UPDATE_EDGE_ORIGINAL_TEMPLATE_ID(state, {templateId, edgeId}) {
      const edgeIndex = state.edgesOriginal.findIndex(edge => edge.id === edgeId);
      state.edgesOriginal[edgeIndex].design.object_template = templateId !== null ? templateId.toString() : templateId;
    },
    APPLY_OBJECT_TEMPLATES_UPDATED_TO_EDGES(state, {templateId, elementsDesign, fonts}) {
      // get nodes which uses updated template
      const edgesToUpdate = state.edges.filter(edge => edge.template === templateId.toString());
      // since edge.design could have direct data not stored in templates, yet
      // it should be synchronized with following priority
      // edge.design -> template -> global -> defaults
      edgesToUpdate.forEach(edge => {
        Object.assign(edge, getEdgeDesignSyncedToOriginal(edge.id, state.edgesOriginal, elementsDesign, fonts));
        mapArea.updateEl(true, edge.id, EDITING_ELEM_TYPES.EDGE);
      })
    },
    APPLY_OBJECT_TEMPLATES_DELETED(state, templateData) {
      const {templateId, elementsDesign, fonts} = templateData;
      const edgesOriginalToUpdate = state.edgesOriginal.filter(edge => edge.design.object_template === templateId.toString());
      // first set to null edgeOriginal template value
      edgesOriginalToUpdate.forEach(edge => {
        edge.design.object_template = "0";
      })
      // update edges accordingly
      const edgesToUpdate = state.edges.filter(edge => edge.template === templateId.toString());
      edgesToUpdate.forEach(edge => {
        edge.template = "0";
        Object.assign(edge, getEdgeDesignSyncedToOriginal(edge.id, state.edgesOriginal, elementsDesign, fonts));
      })
    },
    APPLY_GLOBAL_UPDATE_TO_EDGES(state, edgeData) {
      const {elementsDesign, fonts} = edgeData;
      state.edges.forEach(edge => {
        Object.assign(edge, getEdgeDesignSyncedToOriginal(edge.id, state.edgesOriginal, elementsDesign, fonts));
      })
    },
    CONNECT_EDGE(state, nodeId) {
      state.edgeConnections.from === null ?
        state.edgeConnections.from = nodeId :
        state.edgeConnections.to = nodeId;
    },
    CONNECT_CLEAR(state) {
      state.edgeConnections.from = null;
      state.edgeConnections.to = null;
    },
    SET_EDGE_EDITING(state, edgeEditing) {
      const edgeIndex = state.edges.findIndex(edge => edge.id === edgeEditing.elem_id);
      state.edges[edgeIndex].editing = edgeEditing;
    },
    REMOVE_EDGE_EDITING(state, edgeEditing) {
      const edgeIndex = state.edges.findIndex(edge => edge.id === edgeEditing.elem_id);
      state.edges[edgeIndex].editing = null;
    },
    UPDATE_EDGE_HIDDEN_BY_NODE(state, {nodes, hiddenData}){
      // hiddenData: {[id]: hidden, [id]: hidden, ...}
      const edges = state.edges;

      // find edges related to updated node
      for(let [id, status] of Object.entries(hiddenData)){
        const relatedEdges = edges.filter(edge => edge.fromNodeId === parseInt(id) ||
          edge.toNodeId === parseInt(id)
        )
        const node = nodes.find(node => node.id === parseInt(id));
        if(!status && node.hidden) return;

        relatedEdges.forEach(edge => edge.hidden = status);
      }
    }
  },
  actions: {
    edgesOriginalSave({commit}, edges) {
      commit('EDGES_ORIGINAL_SAVE', edges);
    },
    edgesPopulate({getters, commit}) {
      const nodes = getters.getNodes;
      const elementsDesign = getters.getElementsDesign;
      const fonts = getters.getClientFonts;
      commit('POPULATE_EDGES', {nodes, elementsDesign, fonts});
    },
    edgesHiddenToggle({commit}) {
      commit('TOGGLE_EDGES_HIDDEN');
      mapArea.updateEl();
    },
    edgesLockedToggle({commit}) {
      commit('TOGGLE_EDGES_LOCKED');
      mapArea.updateEl();
    },
    edgeUpdate({getters, commit}, updateData) {
      commit('UPDATE_EDGE', updateData);
    },
    edgeSetActive({state, commit, dispatch}, edgeID) {
      commit('SET_ACTIVE_EDGE', edgeID);
    },
    edgeEditedSave({getters, commit}, {edgeId, edgeData}) {
      // const edgeId = getters.getActiveEdgeID;
      commit('UPDATE_EDITED_EDGE', {edgeId, edgeData});
    },
    edgesChangeZoomLevel({commit}, zoomData) {
      commit('CHANGE_ZOOM_LEVEL', zoomData);
    },
    edgeConnect({getters, commit, dispatch}, nodeId) {
      commit('CONNECT_EDGE', nodeId);
    },
    edgeConnectionCancel({getters, commit, dispatch}) {
      const nodeId = getters.getEdgeConnections.from;
      if (nodeId !== null) dispatch('nodes/nodeConnectUnmark', nodeId, {root: true})
      dispatch('nodes/nodesUnavailableForConnectMark', null, {root: true});
      commit('CONNECT_CLEAR');
    },

    edgeUpdateOnFontLoaded({getters, commit}, fontData){
      const {fontFamily} = fontData;
      const edges = getters.getEdges;
      const layerCurrentID = getters.getCurrentLayerID;
      edges.forEach(edge => {
        if(edge.fontFamily === fontFamily){
          edge.cacheInstanceSetDimensions();
          edge.cache(layerCurrentID);
          mapArea.updateEl(true, edge.id, EDITING_ELEM_TYPES.EDGE);
        }
      })
    },

    // hidden
    edgeUpdateHiddenByNode({getters, commit, dispatch}, hiddenData) {
      const nodes = getters.getNodes;
      commit('UPDATE_EDGE_HIDDEN_BY_NODE', {nodes, hiddenData});
      mapArea.updateEl(true, hiddenData.id, EDITING_ELEM_TYPES.EDGE);
    },

    //template related actions
    async edgeSaveDesignByTemplate({getters, dispatch}, {edgeData, edgeId, type}) {
      const mapsURL = getters.getMapsURL;
      const edgesURL = getters.getEdgesURL;
      const mapId = getters.getCurrentMap.id;
      const data = {'design': edgeData};
      const message = {success: '', error: ''};

      try {
        await ApiService.patchRequest(`${mapsURL}${mapId}${edgesURL}${edgeId}`, data);
        // if no type avoid call syncEdits SE
        if (!type) return;
        const dataSE = {type, data: {edgeId, edgeData}};
        dispatch('simultaneousEditing/syncEdits', dataSE, {root: true});

        mapArea.updateEl(true, edgeId, EDITING_ELEM_TYPES.EDGE);

        switch (type) {
          case SE_SYNC_TYPES.EDGE_TEMPLATE_APPLIED: {
            message.success = t('message.templateApplySuccess');
            message.error = t('message.templateApplyError');
            break;
          }
          case SE_SYNC_TYPES.EDGE_TEMPLATE_RESTORED: {
            message.success = t('message.templateRestoreSuccess');
            message.error = t('message.templateRestoreError');
            break;
          }
          case SE_SYNC_TYPES.EDGE_TEMPLATE_CREATED: {
            message.success = t('message.templateCreateSuccess');
            message.error = t('message.templateCreateError');
            break;
          }
        }
        await createToastFromStore(TOASTS_TYPES.SUCCESS, message.success, dispatch);
      } catch (err) {
        await createToastFromStore(TOASTS_TYPES.ERROR, message.error, dispatch);
      }
    },

    edgeNullify({getters, commit}, edgeId){
      commit('NULLIFY_EDGE_ORIGINAL_VALUES', edgeId);
      const edgesOriginal = getters.getEdgesOriginal;
      const currentEdge = edgesOriginal.find(edge => edge.id === edgeId);
      return currentEdge.design;
    },

    async edgeUpdateTemplate({getters, dispatch}, templateData) {
      const {edgeData, edgeId, type, template} = templateData;
      const mapsURL = getters.getMapsURL;
      const edgesURL = getters.getEdgesURL;
      const mapId = getters.getCurrentMap.id;
      const data = {'design': edgeData};

      try {
        await ApiService.patchRequest(`${mapsURL}${mapId}${edgesURL}${edgeId}`, data);
        const dataSE = {type, data: {...template, edgeId}};
        dispatch('simultaneousEditing/syncEdits', dataSE, {root: true});
        await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.templateUpdateSuccess'), dispatch);
      } catch (err) {
        await createToastFromStore(TOASTS_TYPES.ERROR, t('message.templateUpdateError'), dispatch);
      }
    },

    edgesApplyTemplateRestore({getters, commit}, edgeId) {
      const elementsDesign = getters.getElementsDesign;
      const fonts = getters.getClientFonts;
      commit('APPLY_TEMPLATE_RESTORE_TO_EDGES', {elementsDesign, fonts, edgeId});
      mapArea.updateEl(true, edgeId, EDITING_ELEM_TYPES.EDGE);
    },
    edgeTemplateCreatedPopulate({getters, commit, dispatch}, {edgeId, template}) {
      const fonts = getters.getClientFonts;
      const edges = getters.getEdges;
      const currentEdge = edges.find(edge => edge.id === edgeId);

      commit('UPDATE_EDGE_TEMPLATE_ID', {templateId: template.id, edgeId: currentEdge.id}); // change template id of edge accordingly
      commit('UPDATE_EDGE_ORIGINAL_TEMPLATE_ID', {templateId: template.id, edgeId: currentEdge.id}); // change template id of edgeOriginal accordingly

      let templateObject = {};
      // copy edge's not null values to template
      for (let [key, value] of Object.entries(currentEdge)) {
        if (key === 'fontFamily' && value) value = fontFamilyGetUrlByName(value, fonts);
        Object.assign(templateObject, convertToOriginalEdgeProperty({[key]: value}))
      }
      // assign template
      dispatch('design/templateUpdate', {id: template.id, data: templateObject}, {root: true});
      // Object.assign(templates[templateCreatedId], templateObject);

      commit('NULLIFY_EDGE_ORIGINAL_VALUES', edgeId);
    },
    templateApply({getters, commit, dispatch}, {templateId, edgeId}) {
      const edgeDesign = getEdgeDesignFromTemplate(templateId, getters.getElementsDesign);
      const fonts = getters.getClientFonts;

      // convert font_url to fontName
      if (edgeDesign.font_url) edgeDesign.font_url = fontFamilyGetNameByUrl(edgeDesign.font_url, fonts);
      commit('UPDATE_EDGE_TEMPLATE_ID', {templateId, edgeId});
      commit('UPDATE_EDGE_ORIGINAL_TEMPLATE_ID', {templateId, edgeId}); // update template id accordingly

      commit('UPDATE_EDITED_EDGE', {edgeData: adaptedOriginalDesignPropertiesToEdge(edgeDesign), edgeId}); // apply template properties to node
      commit('NULLIFY_EDGE_ORIGINAL_VALUES', edgeId);
      mapArea.updateEl(true, edgeId, EDITING_ELEM_TYPES.EDGE);
    },

    // object templates
    objectTemplatesUpdatedApplyToEdges({getters, commit, dispatch}, {templateId}) {
      const elementsDesign = getters.getElementsDesign;
      const fonts = getters.getClientFonts;
      commit('APPLY_OBJECT_TEMPLATES_UPDATED_TO_EDGES', {templateId, elementsDesign, fonts});
      // mapArea.updateEl(); // --> call in commit
    },

    objectTemplatesDeletedApply({getters, commit, dispatch}, templateId) {
      const elementsDesign = getters.getElementsDesign
      const fonts = getters.getClientFonts;
      const templateData = {elementsDesign, templateId, fonts}
      commit('APPLY_OBJECT_TEMPLATES_DELETED', templateData);
      mapArea.updateEl();
    },

    // global settings
    edgesApplyGlobalsUpdate({getters, commit}) {
      const elementsDesign = getters.getElementsDesign;
      const fonts = getters.getClientFonts;
      const edges = getters.getEdges;
      commit('APPLY_GLOBAL_UPDATE_TO_EDGES', {elementsDesign, fonts});

      const edgesToUpdate = edges.filter(edge => parseInt(edge.template) === 0);
      edgesToUpdate.forEach(edge => {mapArea.updateEl(true, edge.id, EDITING_ELEM_TYPES.EDGE)})
    },
    // SE actions
    edgeSetEditing({commit}, edge) {
      commit('SET_EDGE_EDITING', edge);
      mapArea.updateEl(true, edge.elem_id, EDITING_ELEM_TYPES.EDGE);
    },
    edgeRemoveEditing({commit}, edge) {
      commit('REMOVE_EDGE_EDITING', edge);
      mapArea.updateEl(true, edge.elem_id, EDITING_ELEM_TYPES.EDGE);
    }
  }
}
