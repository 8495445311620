import en from '@/locales/en.json';
import de from '@/locales/de.json';
import {LANG} from '@/models/LANG';
import {createI18n} from 'vue-i18n';

function getCurrentLang() {
	let currentLang = window.navigator.language;
	currentLang = currentLang.split('-'); // if "de-CH" or similar --> use only "de"
	currentLang = currentLang[0];
	
	if(currentLang !== LANG.DE) {
		currentLang = LANG.EN;
	}
	return currentLang;
}

export const i18n = createI18n({
	legacy: false, // you must set `false`, to use Composition API
	locale: getCurrentLang(), // set locale
	fallbackLocale: LANG.EN, // set fallback locale
	warnHtmlMessage: false, // disable warning HTML in a message
	messages: {
		en: en,
		de: de
	}
});

export default function useI18nGlobal(){
	return {i18n, getCurrentLang};
}
