import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
const {t} = i18n.global;
export const TEXT_POSITION = {
	TOP_LEFT: {VALUE: "top-left", get NAME() {return t('options.textPosition.topLeft')}},
	TOP: {VALUE: "top", get NAME() {return t('options.textPosition.top')}},
	TOP_RIGHT: {VALUE: "top-right", get NAME() {return t('options.textPosition.topRight')}},
	LEFT: {VALUE: "left", get NAME() {return t('options.textPosition.left')}},
	CENTER: {VALUE: "center", get NAME() {return t('options.textPosition.center')}},
	RIGHT: {VALUE: "right", get NAME() {return t('options.textPosition.right')}},
	BOTTOM_LEFT: {VALUE: "bottom-left", get NAME() {return t('options.textPosition.bottomLeft')}},
	BOTTOM: {VALUE: "bottom", get NAME() {return t('options.textPosition.bottom')}},
	BOTTOM_RIGHT: {VALUE: "bottom-right", get NAME() {return t('options.textPosition.bottomRight')}},
}
