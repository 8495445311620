import {TEMPLATE_TYPES} from "@/models/TEMPLATE_TYPES";
import ApiService from "@/utils/ApiService";
import Edge from "@/services/canvas/edges/Edge";
import {SE_SYNC_TYPES} from "@/models/SE_SYNC_TYPES";
import {TOASTS_TYPES} from "@/models/TOASTS_TYPES";
import ToastUtils from "@/utils/ToastUtils";
import EdgeUtils from '@/services/canvas/edges/EdgeUtils';
import useMapArea from "@/services/canvas/map-area/MapArea";
import useI18nGlobal from "@/utils/i18n";
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";
const {createToastFromStore} = ToastUtils();
const {mapArea} = useMapArea();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;
const {createEdgeObject} = EdgeUtils();

export default {
  namespaced: true,
  state: () => ({}),
  getters: {
    getEdges(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdges"];
    },
    getEdgesOriginal(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesOriginal"];
    },
    getNodes(state, getters, rootState, rootGetters) {
      return rootGetters['nodes/getNodes'];
    },
    getClientFonts(state, getters, rootState, rootGetters) {
      return rootGetters["fonts/getClientFonts"]
    },
    getElementsDesign(state, getters, rootState, rootGetters) {
      return rootGetters["design/getElementsDesign"]
    },
    getCurrentMap(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getCurrentMap"]
    },
    getEdgeConnections(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgeConnections"]
    },
    getChosenTemplate(state, getters, rootState, rootGetters){
      return rootGetters["templates/getObjectTemplateChosen"]
    },
    getCurrentLayerID(state, getters, rootState) {
      return rootState.layers.layerCurrentID
    },
    getEdgesURL(state, getters, rootState, rootGetters) {
      return rootGetters["edges/getEdgesURL"];
    },
    getMapsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getMapsURL"]
    },
    getElementsURL(state, getters, rootState, rootGetters) {
      return rootGetters["maps/getElementsURL"]
    },
  },
  mutations: {
    ADD_EDGE_ORIGINAL(state, {edgeCreated, edgesOriginal}) {
      edgesOriginal.push(edgeCreated);
    },
    ADD_EDGE(state, {edge, edges}) {
      edges.push(edge);
    },
  },
  actions: {
    async edgeCreateRequest({getters, dispatch}) {
      const {from, to} = getters.getEdgeConnections;
      const nodes = getters.getNodes;
      const mapsURL = getters.getMapsURL;
      const edgesURL = getters.getEdgesURL;
      const mapId = getters.getCurrentMap.id;

      const nodeFrom = nodes.find(node => node.id === from);
      const nodeTo = nodes.find(node => node.id === to);
      const visibilityMinFrom = nodeFrom.visibilityMin;
      const visibilityMaxFrom = nodeFrom.visibilityMax;
      const visibilityMinTo = nodeTo.visibilityMin;
      const visibilityMaxTo = nodeTo.visibilityMax;

      const edgeCreatedVisibilityMin = Math.max(visibilityMinFrom, visibilityMinTo);
      const edgeCreatedVisibilityMax = Math.min(visibilityMaxFrom, visibilityMaxTo);

      const request = {
        node: {
          from_id: from,
          to_id: to
        },
        visibility: {
          min_level: edgeCreatedVisibilityMin,
          max_level: edgeCreatedVisibilityMax,
          hidden: false
        }
      }

      // check chosen template
      const objectTemplateChosen = getters.getChosenTemplate;
      if(objectTemplateChosen !== null && objectTemplateChosen.type === TEMPLATE_TYPES.EDGE){
        Object.assign(request, {design: {object_template: objectTemplateChosen.id.toString()}})
      }

      try {
        const {data: edgeCreated} = await ApiService.postRequest(`${mapsURL}${mapId}${edgesURL}`, request);
        dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true}); // set updated date

        dispatch('edgeCreate', {from, to, edgeCreated});

        const editsData = {
          type: SE_SYNC_TYPES.EDGE_CREATED,
          data: {from, to, edgeCreated}
        }
        dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
        await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.edgeCreateSuccess', {id: edgeCreated.id}), dispatch);
      } catch (err) {
        await createToastFromStore(TOASTS_TYPES.ERROR, t('message.edgeCreateError'), dispatch);
      }
    },
    edgeCreate({getters, commit, dispatch}, {from, to, edgeCreated}) {
      const nodes = getters.getNodes;
      const elementsDesign = getters.getElementsDesign;
      const fonts = getters.getClientFonts;
      const edges = getters.getEdges;
      const edgesOriginal = getters.getEdgesOriginal;
      const layerCurrentID = getters.getCurrentLayerID;

      commit('ADD_EDGE_ORIGINAL', {edgeCreated, edgesOriginal});
      const edge = new Edge(createEdgeObject(edgeCreated, nodes, elementsDesign, fonts))
      commit('ADD_EDGE', {edge, edges});
      dispatch('nodes/nodeAddRelatedEdge', {from, to, edgeId: edge.id}, {root: true}); // add edge id to node related [from, to] properties

      edge.cacheInstanceSetDimensions();
      edge.cache(layerCurrentID.value, null, -1);
    },
  }
}