import {NODE_SHAPES} from "@/models/NODE_SHAPES";

export default function useShapeUtils(){

  function circleDraw(settings){
    const {ctx, node, center: {x, y}, zoomLevel, border} = settings;
    const computedBorder = node.border ? node.borderWidth : 0;
    const radius = border ? node.width / 2 + computedBorder / 2 : node.width / 2 + computedBorder;
    ctx.arc(x, y, radius * zoomLevel, 0 , Math.PI * 2, false);
  }

  function rectangleDraw(settings){
    const {ctx, node, center: {x, y}, border, zoomLevel} = settings;
    const width = border ? node.width + node.borderWidth : node.width + node.borderWidth * 2;
    const height = border ? node.height + node.borderWidth : node.height + node.borderWidth * 2;
    const computedWidth = node.border ? width : node.width;
    const computedHeight = node.border ? height : node.height;
    const computedX = x - computedWidth / 2 * zoomLevel;
    const computedY = y - computedHeight / 2 * zoomLevel;
    ctx.rect(computedX, computedY, computedWidth * zoomLevel, computedHeight * zoomLevel);
  }

  function addShapeToContext({ctx, node, center, zoomLevel = 1, border= false}){
    const {x, y} = center;
    const shape = node.shape;

    switch (shape) {
      case NODE_SHAPES.CIRCLE.VALUE: {
        circleDraw({ctx, node, center, zoomLevel, border});
        break;
      }
      case NODE_SHAPES.RECTANGLE.VALUE: {
        rectangleDraw({ctx, node, center, zoomLevel, border})
      }
    }
  }

  return {
    addShapeToContext
  }
}