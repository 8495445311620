import ApiService from '@/utils/ApiService';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import useMapArea from '@/services/canvas/map-area/MapArea';
import Fonts from '@/services/canvas/fonts/Fonts';
import NodeUtils from '@/services/canvas/nodes/NodeUtils';
import ToastUtils from '@/utils/ToastUtils';
import useI18nGlobal from '@/utils/i18n';
import createImage from '@/services/canvas/utils/CreateImage';
const {mapArea} = useMapArea();
const {fontFamilyGetUrlByName} = Fonts();
const {	getNodeComputedDesign, convertToOriginalNodeProperty} = NodeUtils();
const {createToastFromStore} = ToastUtils();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
	namespaced: true,
	state: {
		saveTimeout: null,
		nodeDesignOrder: {},
		gifAnimation: true,
	},
	getters: {
		getNodesOriginal(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesOriginal'];
		},
		getNodes(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodes'];
		},
		getCurrentMap(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getCurrentMap']
		},
		getElementsDesign(state, getters, rootState, rootGetters){
			return rootGetters['design/getElementsDesign']
		},
		getClientFonts(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getClientFonts'];
		},
		getNodesURL(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesURL']
		},
		getMapsURL(state, getters, rootState, rootGetters){
			return rootGetters['maps/getMapsURL']
		},
		getElementsURL(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getElementsURL']
		},
		getGifAnimationStatus(state){
			return state.gifAnimation;
		}
	},
	mutations: {
		UPDATE_NODE_DESIGN(state, {nodeOriginal, designOriginal}){
			Object.assign(nodeOriginal.design, designOriginal);
		},
		TOGGLE_GIF_ANIMATION(state, {nodes, status}){
			// set global flag
			state.gifAnimation = status;
			// update nodes with gif files
			nodes.forEach(node => {
				if(node.media && node.media.includes('.gif')){
					if(status) node.gifAnimation = true;
					if(!status) node.gifAnimation = false;
					mapArea.updateEl(true, parseInt(node.id));
				}
			})
		},
		SET_GIF_ANIMATION_STATUS(state, status){
			state.gifAnimation = status;
		}
	},
	actions: {
		gifAnimationToggle({getters, commit}, status){
			const nodes = getters.getNodes;
			commit('TOGGLE_GIF_ANIMATION', {nodes, status});
		},
		setGifAnimationStatus({commit}, status){
			commit('SET_GIF_ANIMATION_STATUS', status);
		},
		nodeDesignUpdate({getters, commit, dispatch}, {id, designData}){
			const nodesOriginal = getters.getNodesOriginal;
			const nodes = getters.getNodes;
			const clientFonts = getters.getClientFonts;
			const elementsDesign = getters.getElementsDesign;
			const nodeOriginal = getters.getNodesOriginal.find(node => node.id === parseInt(id));
			const node = getters.getNodes.find(node => node.id === parseInt(id));

			if(!node) {
				 createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeDesignError', {id}), dispatch);
				 return;
			}
			// UPDATE NODE
			const nodeData = {};
			const designOriginal = {};
			// convert received node properties to node original
			for(let [key, value] of Object.entries(designData)){
				if(key === 'fontFamily') value = fontFamilyGetUrlByName(value, clientFonts);
				if((key === 'media' && !value) && !designData.mediaURL) {
					nodeData.image = null;
					nodeData.media = null;
					nodeData.mediaURL = null;
				}
				if(key === 'media' && value){
					nodeData.image = createImage(value);
					nodeData.media = value;
					nodeData.mediaURL = null;
				}
				if(key === 'mediaURL' && value){
					nodeData.image = createImage(value);
					nodeData.media = null;
					nodeData.mediaURL = value;
				}
				Object.assign(designOriginal, convertToOriginalNodeProperty({[key]: value}));
			}
			commit('UPDATE_NODE_DESIGN', {nodeOriginal, designOriginal});
			// *don't change the order*
			
			// UPDATE NODE
			// if image generated from template media values needs to be updated
			const computedNode = getNodeComputedDesign(id, nodes, nodesOriginal, elementsDesign, clientFonts);
			if(computedNode.image && !nodeData.image){
				nodeData.media = computedNode.media;
				nodeData.mediaURL = computedNode.mediaURL;
				nodeData.image = computedNode.image;
			}

			Object.assign(designData, nodeData);
			commit('nodes/UPDATE_NODE', {id, nodeData: designData}, {root: true}); // update node
			dispatch('nodes/nodesUpdateConvertedEdgesRelated', [node], {root: true});
			
			// set updated date of map changing
			dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});
			// update canvas
			mapArea.updateEl(true, parseInt(id));
		},
		
		async nodeDesignSave({state, getters, dispatch}, {id, designData}){
			// In case when settings values changed faster than debounce timeout is reached,
			// assign updated property with nodeDesignOrder object
			const clientFonts = getters.getClientFonts;
			const mapsURL = getters.getMapsURL;
			const nodesURL = getters.getNodesURL;
			const mapId = getters.getCurrentMap.id;
			Object.assign(state.nodeDesignOrder, designData);
			clearTimeout(state.saveTimeout);
			return new Promise((resolve, reject) => {
				state.saveTimeout = setTimeout(async () => {
					const designOriginal = {};
					// convert node properties to node original before saving
					for(let [key, value] of Object.entries(state.nodeDesignOrder)){
						if(key === 'fontFamily') value = fontFamilyGetUrlByName(value, clientFonts);
						Object.assign(designOriginal, convertToOriginalNodeProperty({[key]: value}));
					}
					const data = {
						'design': designOriginal
					};
					try{
						await ApiService.patchRequest(`${mapsURL}${mapId}${nodesURL}${id}`, data);
						// send SE data
						const dataSE = {
							type: SE_SYNC_TYPES.NODE_EDITED_DESIGN,
							data: {id, designData: state.nodeDesignOrder}
						}
						dispatch('simultaneousEditing/syncEdits', dataSE, {root: true});
						resolve();
						clearTimeout(state.saveTimeout);
						state.nodeDesignOrder = {};
						await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeDesignSuccess', {id}), dispatch);
					} catch(err){
						clearTimeout(state.saveTimeout);
						state.nodeDesignOrder = {};
						reject();
						await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeDesignError', {id}), dispatch);
					}
				}, 700)
			})
		},
	}
}
