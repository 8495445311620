import {router} from '@/router/router';
export default {
	namespaced: true,
	state: {
		loginURL: '/auth/login',
		logoutURL: '/auth/logout',
		resetPasswordURL: '/auth/password_reset/tokens',
		authentication: false,
	},
	getters: {
		isAuthenticated(state){
			return state.authentication;
		},
		getLoginURL(state){
			return state.loginURL;
		},
		getLogoutURL(state){
			return state.logoutURL;
		},
		getResetPasswordURL(state){
			return state.resetPasswordURL;
		}
	},
	mutations: {
		AUTH_SET(state, status){
			state.authentication = status;
		},
	},
	actions: {
		setAuthentication({commit}, status){
			commit("AUTH_SET", status);
		},
		logoutRequest({commit, dispatch}){
			if (router.currentRoute.value.name !== 'login'){
				commit("AUTH_SET", false);
				dispatch("user/removeUser", null, {root: true});
				router.push({name: 'login'});
			}
		},
	}
}
