export const EDITING_ELEM_TYPES = {
	EDGE: 'edge',
	NODE: 'node',
	TEMPLATE: 'object_template',
	
	MAP: 'map',
	GLOBAL: 'global_design',
	COOKIE_CONSENT: 'cookie_consent',
	EDITORS_NOTE: 'editors_note',
	EMBED: 'embed',
	GLOSSARY: 'glossary',
	HEADER_BAR: 'header_bar',
	TABLE_OF_CONTENT: 'table_of_contents',
	MAP_ACCESS: 'map_access',
	MAP_URL: 'map_url',
	MEDIA_ASSETS: 'media_asset',
	SEO: 'seo',
	SOCIAL_SHARING: 'social_sharing',
	SOURCES: 'source',
	QUIZ: 'quiz',
	MAP_LANGUAGES: 'map_languages',
}
