export default function (){

  const QUOTA_COUNT_MAPPING = {
    activeReadersCount: {name: 'active_readers_count'},
    editingUserCount: {name: 'editing_user_count'},
    liveMapCount: {name: 'live_map_count'},
    mapCount: {name: 'map_count'},
    templateCount: {name: 'template_count'},
    userCount: {name: 'user_count'}
  }
  const QUOTA_MAPPING = {
    activeReadersQuota: {name: 'active_readers_quota'},
    editingUserQuota: {name: 'editing_user_quota'},
    liveMapQuota: {name: 'live_map_quota'},
    mapQuota: {name: 'map_quota'},
    templateQuota: {name: 'template_quota'},
    userQuota: {name: 'user_quota'}
  }

  function _convertFromOriginal(prop, value, mapping){
    for(const [key, specs] of Object.entries(mapping)){
      if(specs.name === prop) {
        const result = Object.assign({}, specs, {value})
        return {[key]: result}
      }
    }
  }

  function _getEntries(data, mapping){
    const entries = {};
    for( const [key, value] of Object.entries(data)){
      const result = _convertFromOriginal(key, value, mapping);
      if(result) {
        Object.assign(entries, result);
      }
    }
    return entries;
  }

  function getQuotaCounts(features){
    return _getEntries(features, QUOTA_COUNT_MAPPING)
  }

  function getQuotas(features){
    return _getEntries(features, QUOTA_MAPPING)
  }

  function checkAvailableQuotas(quota, count){
    if(quota === null) return true;
    return quota - count > 0;

  }

  return {
    getQuotaCounts,
    getQuotas,
    checkAvailableQuotas
  }
}