export default function(){
	function RGBAToHexA(rgba) {
		let sep = rgba.indexOf(",") > -1 ? "," : " ";
		rgba = rgba.substr(5).split(")")[0].split(sep);
		
		// Strip the slash if using space-separated syntax
		if (rgba.indexOf("/") > -1)
			rgba.splice(3,1);
		
		for (let R in rgba) {
			let r = rgba[R];
			if (r.indexOf("%") > -1) {
				let p = r.substr(0,r.length - 1) / 100;
				
				if (R < 3) {
					rgba[R] = Math.round(p * 255);
				} else {
					rgba[R] = p;
				}
			}
		}
		
		let r = (+rgba[0]).toString(16),
			g = (+rgba[1]).toString(16),
			b = (+rgba[2]).toString(16),
			a = Math.round(+rgba[3] * 255).toString(16);
		
		if (r.length === 1)
			r = "0" + r;
		if (g.length === 1)
			g = "0" + g;
		if (b.length === 1)
			b = "0" + b;
		if (a.length === 1)
			a = "0" + a;
		
		return "#" + r + g + b + a;
	}
	
	function replaceColorsInArray(rawData, find, replace) {
		let replaceString = rawData;
		
		for (let i = 0; i < find.length; i++) {
			const regex = new RegExp(find[i], "g");
			replaceString = (typeof(replace) == 'object') ? replaceString.replace(regex, replace[i]) : replaceString.replace(regex, replace);
		}
		return replaceString;
	}
	
	function RGB_to_hex(rgb) {
		rgb = replaceColorsInArray(rgb,['rgb', '\\(', '\\)'], '');
		const rgb_arr = rgb.split(',');
		
		let r = parseInt(rgb_arr[0].trim(), 10).toString(16),
			g = parseInt(rgb_arr[1].trim(), 10).toString(16),
			b = parseInt(rgb_arr[2].trim(), 10).toString(16);
		
		if (r.length === 1) {r = "0" + r;}
		if (g.length === 1) {g = "0" + g;}
		if (b.length === 1) {b = "0" + b;}
		
		return "#" + r + g + b;
	}
	
	function hexToRGB(hex) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}
	
	return {
		RGBAToHexA,
		hexToRGB,
		RGB_to_hex
	}
}
