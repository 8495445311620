import {computed} from "vue";
import store from '@/store/store'

import useI18nGlobal from '@/utils/i18n';
import {EDITING_ELEM_TYPES} from "@/models/EDITING_ELEM_TYPES";

const {i18n} = useI18nGlobal();
const {t} = i18n.global;

// blocked
const blockedCookieConsent = computed(() => store.getters["simultaneousEditing/getBlockedCookieConsent"]);
const blockedEditorsNote = computed(() => store.getters["simultaneousEditing/getBlockedEditorsNote"]);
const blockedEmbed = computed(() => store.getters["simultaneousEditing/getBlockedEmbed"]);
const blockedGlossary = computed(() => store.getters["simultaneousEditing/getBlockedGlossary"]);
const blockedHeaderBar = computed(() => store.getters["simultaneousEditing/getBlockedHeaderBar"]);
const blockedTableOfContent = computed(() => store.getters["simultaneousEditing/getBlockedTableOfContent"]);
const blockedMapAccess = computed(() => store.getters["simultaneousEditing/getBlockedMapAccess"]);
const blockedMapUrl = computed(() => store.getters["simultaneousEditing/getBlockedMapUrl"]);
const blockedMediaAssets = computed(() => store.getters["simultaneousEditing/getBlockedMediaAssets"]);
const blockedSeo = computed(() => store.getters["simultaneousEditing/getBlockedSeo"]);
const blockedSocialSharing = computed(() => store.getters["simultaneousEditing/getBlockedSocialSharing"]);
const blockedSources = computed(() => store.getters["simultaneousEditing/getBlockedSources"]);
const blockedQuiz = computed(() => store.getters["simultaneousEditing/getBlockedQuiz"]);
const blockedLanguageSettings = computed(() => store.getters["simultaneousEditing/getBlockedLanguageSettings"]);

// blocked by permission
import PermissionService from '@/utils/PermissionService';
import {Roles} from '@/models/Roles';

const userRole = computed(() => store.getters["user/getCurrentUserRole"]);

export const POPUP_SETTINGS = {
  CONTENT: {
    VALUE: "CONTENT",
    get TITLE() {
      return t('popup.sidebar.title.content')
    },
    SECTIONS: {
      0: {
        CONTENT_FORM: {
          VALUE: "CONTENT_FORM", get TITLE() {
            return t('dashboardOptions.contentForm')
          }
        },
        EDITOR_NOTE: {
          VALUE: "EDITOR_NOTE", get TITLE() {
            return t('dashboardOptions.editorsNote')
          }, get blockedClassname() {
            return blockedEditorsNote.value ? `color--${blockedEditorsNote.value.visitor.color} engaged` : ''
          }
        },
        GLOSSARY: {
          VALUE: "GLOSSARY", get TITLE() {
            return t('dashboardOptions.glossary')
          }, get blockedClassname() {
            return blockedGlossary.value ? `color--${blockedGlossary.value.visitor.color} engaged` : ''
          }
        },
        LIST_REFERENCES: {
          VALUE: "SOURCES", get TITLE() {
            return t('dashboardOptions.references')
          }, get blockedClassname() {
            return blockedSources.value ? `color--${blockedSources.value.visitor.color} engaged` : ''
          }
        },
        HEADER_BAR: {
          VALUE: "HEADER_BAR", get TITLE() {
            return t('dashboardOptions.headerBar')
          }, get blockedClassname() {
            return blockedHeaderBar.value ? `color--${blockedHeaderBar.value.visitor.color} engaged` : ''
          }
        },
        TABLE_OF_CONTENT: {
          VALUE: "TABLE_OF_CONTENT", get TITLE() {
            return t('dashboardOptions.tableOfContent')
          }, get blockedClassname() {
            return blockedTableOfContent.value ? `color--${blockedTableOfContent.value.visitor.color} engaged` : ''
          }
        },
        QUIZ: {
          VALUE: "QUIZ", get TITLE() {
            return t('dashboardOptions.quizTool')
          }, get blockedClassname() {
            return blockedQuiz.value ? `color--${blockedQuiz.value.visitor.color} engaged` : ''
          }
        },
        QUIZ_FORM: {VALUE: "QUIZ_FORM"}
      },
      1: {
        MANAGE_ASSETS: {
          VALUE: "MEDIA_ASSETS", get TITLE() {
            return t('popup.sidebar.section.content.mediaAssets')
          }, get blockedClassname() {
            return blockedMediaAssets.value ? `color--${blockedMediaAssets.value.visitor.color} engaged` : ''
          }
        },
        MEDIA_TEMPLATES: {
          VALUE: "MEDIA_TEMPLATES", get TITLE() {
            return t('dashboardOptions.mediaTemplates')
          }
        },
      }
    }
  },
  WEB: {
    VALUE: "WEB",
    get TITLE() {
      return t('popup.sidebar.title.web')
    },
    SECTIONS: {
      0: {
        SOCIAL_NETWORKS: {
          VALUE: "SOCIAL_NETWORKS", get TITLE() {
            return t('popup.sidebar.section.web.social')
          }, get blockedClassname() {
            return blockedSocialSharing.value ? `color--${blockedSocialSharing.value.visitor.color} engaged` : ''
          }
        },
        COOKIE_CONSENT: {
          VALUE: "COOKIE_CONSENT", get TITLE() {
            return t('popup.sidebar.section.web.cookie')
          }, get blockedClassname() {
            return blockedCookieConsent.value ? `color--${blockedCookieConsent.value.visitor.color} engaged` : ''
          }
        },
        SEO_SETTINGS: {
          VALUE: "SEO", get TITLE() {
            return t('popup.sidebar.section.web.seo')
          }, get blockedClassname() {
            return blockedSeo.value ? `color--${blockedSeo.value.visitor.color} engaged` : ''
          }
        }
      },
      1: {
        MAP_URL: {
          VALUE: "MAP_URL", get TITLE() {
            return t('popup.sidebar.section.web.url')
          },
          get blockedClassname() {
            return blockedMapUrl.value ? `color--${blockedMapUrl.value.visitor.color} engaged` : ''
          },
          /*TOOLTIP: {
            get value() {return !PermissionService.permissionCheck({userRole: userRole.value, permission: Roles.Admin})},
            get text() {return t('tooltip.adminPermission')}
          },*/
          BLOCKED_TYPE: EDITING_ELEM_TYPES.MAP_URL
        },
        MAP_ACCESS: {
          VALUE: "MAP_ACCESS", get TITLE() {
            return t('popup.sidebar.section.web.access')
          },
          get blockedClassname() {
            return blockedMapAccess.value ? `color--${blockedMapAccess.value.visitor.color} engaged` : ''
          }
        },
        EMBED_CODE: {
          VALUE: "EMBED_CODE", get TITLE() {
            return t('popup.sidebar.section.web.embed')
          }, get blockedClassname() {
            return blockedEmbed.value ? `color--${blockedEmbed.value.visitor.color} engaged` : ''
          }
        }
      }
    }
  },
  /*PUBLISHING: {
    VALUE: "PUBLISHING",
    TITLE: "Publish Your Map Project",
    SECTIONS: {
      0: {
        MAP_URL: { VALUE: "MAP_URL", TITLE: "Map specific URL"},
        MAP_ACCESS: { VALUE: "MAP_ACCESS", TITLE: "Access restriction"},
        EMBED_CODE: { VALUE: "EMBED_CODE", TITLE: "Embed Code"}
      }
    }
  },*/
  CLIENT: {
    VALUE: "CLIENT",
    get TITLE() {
      return t('popup.sidebar.title.client')
    },
    SECTIONS: {
      0: {
        GENERAL: {
          VALUE: "GENERAL", get TITLE() {
            return t('popup.sidebar.section.client.general')
          }
        },
        USER_MANAGEMENT: {
          VALUE: "USER_MANAGEMENT",
          get TITLE() {
            return t('popup.sidebar.section.client.user')
          },
          TOOLTIP: {
            get value() {return !PermissionService.permissionCheck({userRole: userRole.value, permission: Roles.Admin})},
            get text() {return t('tooltip.adminPermission')}
					}
        },
        ANALYTICS: {
          VALUE: "ANALYTICS", get TITLE() {
            return t('popup.sidebar.section.client.analytics')
          }
        },
        REPORTS: {
          VALUE: "REPORTS", get TITLE() {
            return t('popup.reports.title')
          }
        },
      },
      1: {
        INVOICES: {
          VALUE: "INVOICES", get TITLE() {
            return t('popup.sidebar.section.client.invoices')
          },
          TOOLTIP: {
            get value() {return !PermissionService.permissionCheck({userRole: userRole.value, permission: Roles.Admin})},
            get text() {return t('tooltip.adminPermission')}
          }
        },
        // BILLING: { VALUE: "BILLING", get TITLE() {return t('popup.sidebar.section.client.billing')}, DISABLED: true},
        SUBSCRIPTION: {
          VALUE: "SUBSCRIPTION", get TITLE() {
            return t('popup.sidebar.section.client.subscription')
          },
          TOOLTIP: {
            get value() {return !PermissionService.permissionCheck({userRole: userRole.value, permission: Roles.Admin})},
            get text() {return t('tooltip.adminPermission')}
          }
        },
        // PAYMENT: { VALUE: "PAYMENT", get TITLE() {return t('popup.sidebar.section.client.payment')}, DISABLED: true}
      }
    }
  },
  USER: {
    VALUE: "USER",
    get TITLE() {
      return t('popup.sidebar.title.user')
    },
    SECTIONS: {
      0: {
        PROFILE: {
          VALUE: "PROFILE", get TITLE() {
            return t('popup.sidebar.section.user.profile')
          }
        },
        PASSWORD: {
          VALUE: "PASSWORD", get TITLE() {
            return t('popup.sidebar.section.user.password')
          }
        }
      }
    }
  },
  MAP: {
    VALUE: "MAP",
    get TITLE() {
      return t('popup.sidebar.title.map')
    },
    SECTIONS: {
      0: {
        MAP_SETTINGS: {
          VALUE: "MAP_SETTINGS", get TITLE() {
            return t('popup.sidebar.section.map.settings')
          }
        },
        MAP_LANGUAGES: {
          VALUE: "MAP_LANGUAGES", get TITLE() {
            return t('dashboardOptions.mapLanguages')
          }, get blockedClassname() {
            return blockedLanguageSettings.value ? `color--${blockedLanguageSettings.value.visitor.color} engaged` : ''
          }, BLOCKED_TYPE: EDITING_ELEM_TYPES.MAP_LANGUAGES
        },
        MAP_SPECIFIC_ACCESS: {
          VALUE: "MAP_SPECIFIC_ACCESS", get TITLE() {
            return t('dashboardOptions.mapAccess')
          }
        }
      },
      1: {
        MAP_VERSIONS: {
          VALUE: "MAP_VERSIONS", get TITLE() {
            return t('popup.sidebar.section.map.versions')
          }
        },
        MAP_PROTOCOL: {
          VALUE: "MAP_PROTOCOL", get TITLE() {
            return 'Protocol'
          },
          TOOLTIP: {
            get value() {return !PermissionService.permissionCheck({userRole: userRole.value, permission: Roles.Admin})},
            get text() {return t('tooltip.adminPermission')}
          }
        },
      },
      2: {
        PUBLISHING: {
          VALUE: "PUBLISHING",
          get TITLE() {
            return t('popup.publishing.title')
          },
        }
      }
    }
  },
  TEMPLATES: {
    VALUE: "TEMPLATES",
    TITLE: null,
    SECTIONS: {
      0: {
        ALL_TEMPLATES: {
          VALUE: "ALL_TEMPLATES", get TITLE() {
            return t('popup.mapTemplates.allTemplates')
          }
        }
      },
      1: {
        BASIC: {
          VALUE: "BASIC", get TITLE() {
            return t('popup.mapTemplates.themes.basic')
          }
        },
        STRATEGY: {
          VALUE: "STRATEGY", get TITLE() {
            return t('popup.mapTemplates.themes.strategy')
          }
        },
        BUSINESS: {
          VALUE: "BUSINESS", get TITLE() {
            return t('popup.mapTemplates.themes.business')
          }
        },
        MARKETING: {
          VALUE: "MARKETING", get TITLE() {
            return t('popup.mapTemplates.themes.marketing')
          }
        }
      },
      2: {
        OWN_TEMPLATES: {
          VALUE: "OWN_TEMPLATES", get TITLE() {
            return t('popup.mapTemplates.myTemplates')
          }
        }
      }
    }
  }
}

