import {BACKGROUND} from '@/models/BACKGROUND';
import createImage from '@/services/canvas/utils/CreateImage';

export default function() {
	const hostURL = process.env.VUE_APP_ROOT_HOST;
	
	function createMapDataObject(mapSettings){
		const settings = {
			width: mapSettings.measurement.width,
			height: mapSettings.measurement.height,
			expansion: mapSettings.design.expansion,
			zoomLevels: mapSettings.visibility_levels,
			bgColor: mapSettings.design.bg_color,
			media: mapSettings.design.media,
			mediaURL: mapSettings.design.media_url,
			image: null,
			mediaPosition: mapSettings.design.media_position,
			mediaRepeat: mapSettings.design.media_repeat,
			mediaSize: mapSettings.design.media_size,
			mediaOpacity: mapSettings.design.media_opacity,
			markVisitedNodes: mapSettings.behavior?.mark_visited_nodes || false,
		}
		if(settings.media){
			const image =
				settings.media.includes(hostURL) ?
					createImage(settings.media) :
					createImage(`${hostURL}${settings.media}`)
			settings.image = image;
		}
		if(settings.mediaURL){
			settings.image = createImage(settings.mediaURL);
		}
		
		return settings;
	}
	function getZoomLevelOptions(levels, from = 1){
		const zoomLevels = [];
		
		for (let i = from; i <= levels; i++){
			zoomLevels.push(i);
		}
		return zoomLevels;
	}
	
	const MAP_DESIGN_MAPPING = {
		bgColor: 'bg_color',
		expansion: 'expansion',
		media: 'media',
		mediaURL: 'media_url',
		mediaOpacity: 'media_opacity',
		mediaPosition: 'media_position',
		mediaRepeat: 'media_repeat',
		mediaSize: 'media_size',
	}
	const MAP_MEASUREMENT_MAPPING = {
		width: 'width',
		height: 'height'
	}
	
	function mappingInverted(mapping){
		const invertedMapping = {}
		for(let [key,value] of Object.entries(mapping)){
			Object.assign(invertedMapping, {[value]: key})
		}
		return invertedMapping;
	}
	function convertOriginalMapProperty(property){
		const [entries] = Object.entries(property);
		const [key, value] = entries;
		const mapping = mappingInverted(MAP_DESIGN_MAPPING);
		if(mapping[key]) return {[mapping[key]] : value}
	}
	function convertAdoptedMapProperty(property){
		const [entries] = Object.entries(property);
		const [key, value] = entries;
		
		if(MAP_DESIGN_MAPPING[key]) return {[MAP_DESIGN_MAPPING[key]] : value}
	}
	
	return {
		createMapDataObject,
		convertOriginalMapProperty,
		convertAdoptedMapProperty,
		getZoomLevelOptions,
	}
}
