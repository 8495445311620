import ApiService from '@/utils/ApiService';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import ToastUtils from '@/utils/ToastUtils';
const {createToastFromStore} = ToastUtils();
import useI18nGlobal from '@/utils/i18n';
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
	namespaced: true,
	state: {
		design: null,
		globalURL: '/templates/global/',
		saveList: [],
	},
	getters: {
		getElementsDesign(state){
			return state.design
		},
		getTemplates(state){
			return state.design.templates;
		},
		getNodesOriginalGlobalDesign(state){
			return state.design.nodes
		},
		getEdgesGlobalDesign(state){
			return state.design.edges
		},
		getContentGlobalDesign(state){
			return state.design.popups
		},
		getTooltipsGlobalDesign(state){
			return state.design.tooltips
		},
		getTableGlobalDesign(state){
			return state.design.table_of_contents;
		},
		getNodesTemplates(state){
			let suitableTemplates = {};
			for( let [id, template] of Object.entries(state.design.templates)){
				if(template.type === 'node'){
					Object.assign(suitableTemplates, {[id]: template});
				}
			}
			return suitableTemplates;
		},
		getEdgesTemplates(state){
			let suitableTemplates = {};
			for( let [id, template] of Object.entries(state.design.templates)){
				if(template.type === 'edge'){
					suitableTemplates = Object.assign(suitableTemplates, {[id]: template});
				}
			}
			return suitableTemplates;
		},
		getMapsURL(state, getters, rootState, rootGetters){
			return rootGetters["maps/getMapsURL"]
		},
		getCurrentMap(state, getters, rootState, rootGetters) {
			return rootGetters["maps/getCurrentMap"]
		},
		getGlobalURL(state){
			return state.globalURL;
		},
		getClientFonts(state, getters, rootState, rootGetters){
			return rootGetters["fonts/getClientFonts"]
		},
		getObjectTemplateURL(state, getters, rootState, rootGetters){
			return rootGetters["templates/getObjectTemplateURL"]
		},
		getSaveList(state){
			return state.saveList
		}
	},
	mutations: {
		SAVE_ELEMENTS_DESIGN(state, design){
			state.design = design;
		},
		UPDATED_ELEMENTS_DESIGN(state, elements){
			Object.assign(state.design, elements);
		},
		UPDATE_TEMPLATE(state, templateData){
			const {id, data} = templateData
			let currentTemplate = state.design.templates[id];
			Object.assign(currentTemplate, data);
		},
		CREATE_TEMPLATE(state, templateObject){
			const {id} = templateObject;
			const template = {[id]: {...templateObject}}
			Object.assign(state.design.templates,	template);
		},
		DELETE_TEMPLATE(state, templateId){
			delete state.design.templates[templateId];
		},
		UPDATE_GLOBAL_NODES(state, design){
			Object.assign(state.design.nodes, design);
			// state.design.nodes = design;
		},
		UPDATE_GLOBAL_EDGES(state, design){
			Object.assign(state.design.edges, design);
			// state.design.edges = design;
		},
		UPDATE_GLOBAL_CONTENT(state, design){
			Object.assign(state.design.popups, design);
			// state.design.popups = design;
		},
		UPDATE_GLOBAL_TOOLTIPS(state, design){
			Object.assign(state.design.tooltips, design);
			// state.design.tooltips = design;
		},
		UPDATE_GLOBAL_TABLE(state, design){
			Object.assign(state.design.table_of_contents, design);
			// state.design.table_of_contents = design;
		},
		ADD_TO_SAVE_LIST(state, value){
			state.saveList.push(value);
		},
		CLEAR_SAVE_LIST(state){
			state.saveList = [];
		},
		SET_TEMPLATE_OCCUPIED(state, templateEditing){
			const templates = state.design.templates;
			const templateId = templateEditing.elem_id;
			for(const [key, value] of Object.entries(templates)){
				if(parseInt(key) === templateId) value.occupied = templateEditing;
			}
		},
		REMOVE_TEMPLATE_OCCUPIED(state, templateEditing){
			const templates = state.design.templates;
			const templateId = templateEditing.elem_id;
			for(const [key, value] of Object.entries(templates)){
				if(parseInt(key) === templateId) delete value.occupied;
			}
		},
	},
	actions: {
		elementsDesignSave({state, getters, commit}, design){
			commit('SAVE_ELEMENTS_DESIGN', design);
		},

		templateUpdate({state, getters, commit, dispatch}, templateData){
			commit('UPDATE_TEMPLATE', templateData);
		},

		async templateSave({state, getters, dispatch}, templateData){
			const {id: templateId, data} = templateData;
			const mapId = getters.getCurrentMap.id;
			const objectTemplateURL = getters.getObjectTemplateURL;
			const mapsURL = getters.getMapsURL;
			const currentTemplate = state.design.templates[templateId];
			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${objectTemplateURL}${templateId}`, currentTemplate)
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.templateNamedUpdateSuccess', {name: currentTemplate.name}), dispatch);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.templateUpdateError'), dispatch);
			}
		},

		async templateCreate({getters, commit, dispatch}, templateData){
			const {templateObject} = templateData;
			const {name, type} = templateObject;
			const mapsURL = getters.getMapsURL;
			const objectTemplateURL = getters.getObjectTemplateURL
			const mapId = getters.getCurrentMap.id;
			try{
				const {data: template} = await ApiService.postRequest(`${mapsURL}${mapId}${objectTemplateURL}`,{name, type})
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.templateNamedCreateSuccess', {name}), dispatch);
				commit('CREATE_TEMPLATE', template);
				// dispatch('templates/objectTemplateCreate', {[template.id]: template}, {root: true);
				return template;
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.templateNamedCreateError', {name}), dispatch);
			}
		},
		templateAddCreated({commit, dispatch}, template){
			commit('CREATE_TEMPLATE', template);
		},

		templateDelete({commit, dispatch}, templateId){
			commit('DELETE_TEMPLATE', templateId);
			dispatch('templates/objectTemplateDelete', templateId, {root: true});
		},
		async templateDeleteRequest({getters, dispatch}, templateId){
			const mapsURL = getters.getMapsURL;
			const objectTemplateURL = getters.getObjectTemplateURL
			const mapId = getters.getCurrentMap.id;
			try{
				await ApiService.deleteRequest(`${mapsURL}${mapId}${objectTemplateURL}${templateId}`)
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.templateRemoveSuccess'), dispatch);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.templateRemoveError'), dispatch);
			}
		},

		templateSetOccupied({commit, dispatch}, editingTemplate){
			commit('SET_TEMPLATE_OCCUPIED', editingTemplate);
			dispatch('templates/objectTemplateSetOccupied', {id: editingTemplate.elem_id, value: editingTemplate}, {root: true})
		},
		templateRemoveOccupied({commit, dispatch}, editingTemplate){
			commit('REMOVE_TEMPLATE_OCCUPIED', editingTemplate);
			dispatch('templates/objectTemplateSetOccupied', {id: editingTemplate.elem_id, value: null}, {root: true});
		},

		// global updates
		async globalSave({getters, commit, dispatch}){
			const mapsURL = getters.getMapsURL;
			const globalURL = getters.getGlobalURL;
			const mapId = getters.getCurrentMap.id;
			const elementsDesign = getters.getElementsDesign;
			const saveList = getters.getSaveList;
			const request = {};

			saveList.forEach(element => {
				Object.assign(request, {[element]: elementsDesign[element]})
			});
			commit("CLEAR_SAVE_LIST");

			try{
				await ApiService.patchRequest(`${mapsURL}${mapId}${globalURL}`, request);
				// SE send event
				const editsData = {
					type: SE_SYNC_TYPES.GLOBAL_DESIGN_UPDATED,
					data: request
				}
				dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
				
				await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.globalUpdateSuccess'), dispatch);
			} catch(err){
				await createToastFromStore(TOASTS_TYPES.ERROR, t('message.globalUpdateError'), dispatch);
			}
		},
		globalUpdateSE({commit, dispatch}, globalData){
			commit('UPDATED_ELEMENTS_DESIGN', globalData);
			
			for(const key of Object.keys(globalData)){
				switch (key){
					case 'edges': {
						dispatch('edges/edgesApplyGlobalsUpdate', null, {root: true});
						break;
					}
					case 'nodes': {
						dispatch('nodes/nodesApplyGlobalsUpdate', null, {root: true});
						break;
					}
				}
			}
		},

		async globalNodesUpdate({getters, commit}, design){
			commit('UPDATE_GLOBAL_NODES', design);
		},
		async globalEdgesUpdate({getters, commit}, design){
			commit('UPDATE_GLOBAL_EDGES', design);
		},
		async globalContentUpdate({getters, commit}, design){
			commit('UPDATE_GLOBAL_CONTENT', design)
		},
		async globalTooltipsUpdate({getters, commit}, design){
			commit('UPDATE_GLOBAL_TOOLTIPS', design)
		},
		async globalTableUpdate({getters, commit}, design){
			commit('UPDATE_GLOBAL_TABLE', design)
		},

		addToSaveList({commit}, value){
			commit('ADD_TO_SAVE_LIST', value);
		},

		// MEDIA
		checkDeletedMedia({getters, commit, dispatch}, url){
			const nodesTemplates = getters.getNodesTemplates;
			const nodesOriginalGlobal = getters.getNodesOriginalGlobalDesign;
			
			if(nodesOriginalGlobal.media?.includes(url)) commit('UPDATE_GLOBAL_NODES', {media: null});
			
			for(const template of Object.values(nodesTemplates)){
				if(template.media?.includes(url)) commit('UPDATE_TEMPLATE', {id: template.id, data: {media: null}})
			}
			
		}
	}
}
