export const SE_SYNC_TYPES = {
	NODE_POSITION: 'nodePosition',
	NODE_REMOVED: 'nodeRemoved',
	NODE_CLONED: 'nodeCloned',
	NODE_CREATED: 'nodeCreated',
	NODE_EDITED_DESIGN: 'nodeEditedDesign',
	NODE_EDITED_CONTENT: 'nodeEditedContent',
	NODE_TEMPLATE_APPLIED: 'nodeTemplateApplied',
	NODE_TEMPLATE_UPDATED: 'nodeTemplateUpdated',
	NODE_TEMPLATE_RESTORED: 'nodeTemplateRestored',
	NODE_TEMPLATE_CREATED: 'nodeTemplateCreated',
	NODE_TEMPLATE_REMOVED: 'nodeTemplateRemoved',
	NODE_VISIBILITY_UPDATED: 'nodeVisibilityUpdate',
	NODE_HIDDEN_UPDATED: 'nodeHiddenUpdate',
	NODE_LOCKED_UPDATED: 'nodeLockedUpdate',
	NODES_ORDER_UPDATED: 'nodesOrderUpdate',
	EDGE_CREATED: 'edgeCreated',
	EDGE_REMOVED: 'edgeRemoved',
	EDGE_EDITED_DESIGN: 'edgeEditedDesign',
	EDGE_EDITED_CONTENT: 'edgeEditedContent',
	EDGE_ALL_REMOVED: 'edgeAllRemoved',
	EDGE_VISIBILITY_UPDATED: 'edgeVisibilityUpdate',
	EDGE_TEMPLATE_APPLIED: 'edgeTemplateApplied',
	EDGE_TEMPLATE_UPDATED: 'edgeTemplateUpdated',
	EDGE_TEMPLATE_RESTORED: 'edgeTemplateRestored',
	EDGE_TEMPLATE_CREATED: 'edgeTemplateCreated',
	GLOBAL_DESIGN_UPDATED: 'globalDesignUpdated',
	OBJECT_TEMPLATES_NODE_UPDATED: 'objectTemplatesNodeUpdated',
	OBJECT_TEMPLATES_NODE_REMOVED: 'objectTemplatesNodeRemoved',
	OBJECT_TEMPLATES_EDGE_UPDATED: 'objectTemplatesEdgeUpdated',
	OBJECT_TEMPLATES_EDGE_REMOVED: 'objectTemplatesEdgeRemoved',
	LAYER_REMOVED: 'layerRemoved',
	LAYER_HIDDEN_UPDATED: 'layerHiddenUpdated',
	LAYER_LOCKED_UPDATED: 'layerLockedUpdated',
	MEDIA_ASSET_REMOVED: 'mediaAssetRemoved',
	MAP_UPDATED: 'mapUpdated',
	MAP_RESET: 'mapReset',
	MAP_PUBLISHED: 'mapPublished',
	TRANSLATED_STATUS: 'translatedStatus',
	LANGUAGE_UPDATED: 'languageUpdated',
	MAP_STATE_RESTORED: 'mapStateRestored',
}
