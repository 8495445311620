import CanvasTxt from '@/services/canvas/text/CanvasText';
import useColorUtils from '@/utils/colorUtils';
const {hexToRGB, RGB_to_hex} = useColorUtils();
import {BORDER_STYLES} from '@/models/BORDER_STYLES';
import {TEXT_POSITION} from '@/models/TEXT_POSITION';
import {EDGE_LINES} from '@/models/EDGE_LINES';
import boundsCalculator from '@/services/canvas/edges/modules/boundsCalculator';
const {calculateBoundsForEdge} = boundsCalculator();

export default class EdgeTemplate {
  constructor({
      id, type, name,
      lineStart, lineStartSize, lineEnd, lineEndSize,
      border, borderColor, borderStyle, borderWidth,
      fontColor, fontSize, fontFamily, fontWeight, fontStyle, textPosition,
      shadow, shadowColor, shadowX, shadowY, shadowBlur,
      contentMiddle
    }) {
    this.id = id;
    this.type = type;
    this.name = name;

    this.lineStart = lineStart;
    this.lineStartSize = lineStartSize;
    this.lineEnd = lineEnd;
    this.lineEndSize = lineEndSize;

    this.startPosX = 0;
    this.endPosX = 112;
    this.startPosY = 56;
    this.endPosY = 56;

    this.xCache = null;
    this.yCache = null;

    this.shadow = shadow;
    this.shadowColor = shadowColor;
    this.shadowX = shadowX;
    this.shadowY = shadowY;
    this.shadowBlur = shadowBlur;

    this.border = border;
    this.borderColor = borderColor;
    this.borderStyle = borderStyle;
    this.borderWidth = borderWidth;
    this.fontColor = fontColor || '#000000';
    this.fontSize = fontSize;
    this.fontFamily = fontFamily;
    this.fontWeight = fontWeight;
    this.fontStyle = fontStyle;
    this.textPosition = textPosition;

    this.contentStart = "";
    this.contentMiddle = contentMiddle;
    this.contentEnd = "";

    this._HOVER_AREA = 20;
    this.hover = false;
    this.selected = false;
    this.locked = false;

    this.path = null;
    this.pathInteraction = null;

    this._ARROW_HEIGHT = 12;
    this._ARROW_DOT_RADIUS = 6;

    this._currentColor = this.borderColor;

    this.ctx = null;
    // this.ctxPath = null; // ctx of the layer for interaction purpose
    this.pixelRatio = null;
    this.cacheInstance = null;
    this.snapshot = null;

    // properties {width, height, x, y  } of objects which impacts to canvasCache dimensions
    // to precisely detect canvas area
    this.edgeBounds = null;
    this.arrowsBounds = null;
    this.shadowBounds = null;
    this.titleBounds = null;

    this.currentLayerID = 1;
    this.visibilityMin = 1;
    this.visibilityMax = 1;

    this.ZOOM_MAX = 1;
    this._SHADOW_BLUR_MULTIPLIER = 2;
    this._LABEL_PADDING = 10;
    this._BASE_CANVAS_SIZE = 112;

    this.hidden = false;
    this.editing = false;
  }

  cacheInstanceSetDimensions(){
    /**
     * must be called first on initialization or if changing settings impacts on canvas size e.g. width, height, borderWidth, titlePosition, blurShadow
     * cache() function creates offscreen canvas with computed size multiplied to maximum available zoomFactor
     *
     * */

    if(!this.cacheInstance){
      this.cacheInstance = document.createElement('canvas');
      this.ctx = this.cacheInstance.getContext('2d');
    }

    const {width, height} = calculateBoundsForEdge(this);

    this.getPixelRatio();

    this.cacheInstance.width = Math.floor(width * this.ZOOM_MAX * this.pixelRatio);
    this.cacheInstance.height = Math.floor(height * this.ZOOM_MAX * this.pixelRatio);

    this.cacheInstance.style.width = `${width * this.ZOOM_MAX}px`;
    this.cacheInstance.style.height = `${height * this.ZOOM_MAX}px`;

    this.ctx.scale(this.pixelRatio * this.ZOOM_MAX, this.pixelRatio * this.ZOOM_MAX);
  }

  cache(){
    this.getPixelRatio();
    // get coords of the computed edge's start point which includes shadow and arrow shifts if any...
    ({x: this.xCache, y: this.yCache} = this._getEdgeCoordinates());

    // draw path
    this._drawEdge();
    this._drawArrows();
    this._drawText();
  }

  cacheClear(){
    this.ctx.clearRect(0, 0, this.cacheInstance.width, this.cacheInstance.height);
  }

  getPixelRatio(){
    return this.pixelRatio = window.devicePixelRatio;
  }

  makeSnapshot(canvas){
    if(!canvas) canvas = this.cacheInstance;
    this.snapshot = this.toDataURL(canvas);
  }

  toDataURL(canvas){
    if(!canvas) canvas = this.cacheInstance;
    return canvas.toDataURL();
  }

  // DRAWING
  _drawEdge(){
    if(this.hidden) return;
    this.ctx.beginPath();
    this.ctx.save();

    this._createLine();

    // shadow options
    this._drawShadow();

    this.ctx.stroke();
    this.ctx.restore();

    if(this.editing){
      this._drawEditing();
    }
    this.ctx.closePath();
  }

  _drawEditing(){
    const {width: edgeWidth} = this.edgeBounds;
    const {startArrowWidth, endArrowWidth} = this.arrowsBounds;

    this.ctx.save();

    const STATUS_LAYER_COLOR = 'rgba(60, 60, 60, .54)';
    this.ctx.lineWidth = this.borderWidth + this._HOVER_AREA;
    this.ctx.strokeStyle = STATUS_LAYER_COLOR;
    this.ctx.globalAlpha = 1;

    this.ctx.moveTo(this.xCache + startArrowWidth, this.yCache);
    this.ctx.lineTo(this.xCache + edgeWidth - endArrowWidth, this.yCache);

    this.ctx.stroke();
    this.ctx.restore();
  }

  _createLine(){
    // draw edge on a borders of nodes
    // arrow is a circumscribed equilateral triangle to the circle which center at fromNodeHeight/toNodeHeight
    // to find extra: h = 3R / 2, extra = 2R - h = 0.5R

    const {width: edgeWidth, height: edgeHeight, x: edgeX, y: edgeY} = this.edgeBounds;
    const {startArrowWidth, startArrowHeight, endArrowWidth, endArrowHeight} = this.arrowsBounds;

    if(this.borderStyle === BORDER_STYLES.DOTTED){
      this.ctx.lineCap = 'round';
      const multiplier = this.borderWidth === 1 ? 2 : 1;
      this.ctx.lineWidth = this.borderWidth + 1;
      this.ctx.setLineDash([this.borderWidth / 100, this.borderWidth * multiplier * 2.4]);
    }

    if (this.borderStyle === BORDER_STYLES.DASHED){
      // set pattern of the line
      this.ctx.setLineDash([this.borderWidth * 4, this.borderWidth * 2])
    }

    if(this.name){
      this.ctx.lineWidth = this.borderWidth > 4 ? 4 : this.borderWidth;
    } else this.ctx.lineWidth = this.borderWidth;

    this.ctx.strokeStyle = this._currentColor;
    this.ctx.globalAlpha = this._getVisibility(this.currentLayerID);

    this.ctx.moveTo(this.xCache + startArrowWidth, this.yCache);
    this.ctx.lineTo(this.xCache + edgeWidth - endArrowWidth, this.yCache);
  }

  _ctxRotate(angle, x, y, ctx){
    ctx = ctx ? ctx : this.ctx;
    ctx.translate(x, y);
    ctx.rotate(angle);
    ctx.translate(-x, -y);
  }

  _drawShadow(){
    // TODO add shadow shift here depend on edge angle
    const reversedMultiplier = this.endPosX - this.startPosX < 0 ? -1 : 1;
    if(this.shadow){
      this.ctx.shadowColor = this.shadowColor;
      this.ctx.shadowOffsetX = this.shadowX;
      this.ctx.shadowOffsetY = this.shadowY * reversedMultiplier;
      this.ctx.shadowBlur = this.shadowBlur * this._SHADOW_BLUR_MULTIPLIER; // _SHADOW_BLUR_MULTIPLIER - multiply to align it with css view
    }
  }

  _drawCircle(settings){
    const {ctx, x, y, radius, startAngle, endAngle, couterclockwise, fillStyle, strokeStyle, lineWidth} = settings;

    ctx.fillStyle = fillStyle;
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;

    ctx.beginPath();
    ctx.arc(
      x,
      y,
      radius,
      startAngle,
      endAngle,
      couterclockwise
    )
    ctx.closePath();

    ctx.fill();
    ctx.stroke();
  }

  _drawArrow(arrow, x, y, r){
    switch (arrow){
      case EDGE_LINES.SHARP.VALUE: {
        this.ctx.save();
        this.ctx.beginPath();
        // start drawing from left top corner of non-rotated figure
        // arrow direction to the right
        this.ctx.moveTo(x - r / 3, y - r / 1.5);
        this.ctx.lineTo(x + r, y);
        this.ctx.lineTo(x - r / 3, y + r / 1.5);
        this.ctx.lineTo(x, y);
        this.ctx.globalAlpha = this._getVisibility(this.currentLayerID);
        this.ctx.fillStyle = this._currentColor;
        this._drawShadow();
        this.ctx.fill();
        this.ctx.closePath();
        this.ctx.moveTo(x, y);
        this.ctx.restore();
        break;
      }
      case EDGE_LINES.STRAIGHT.VALUE: {
        this.ctx.save();
        this.ctx.beginPath();
        // start drawing from left bottom corner
        this.ctx.moveTo(x - r / 2, y - r / 1.5);
        this.ctx.lineTo(x + r, y);
        this.ctx.lineTo(x - r / 2, y + r / 1.5);
        this.ctx.globalAlpha = this._getVisibility(this.currentLayerID);
        this.ctx.fillStyle = this._currentColor;
        this._drawShadow();
        this.ctx.fill();
        this.ctx.closePath();
        this.ctx.restore();
        break;
      }
      case EDGE_LINES.WIDE.VALUE: {
        const computedRadius = r /*+ Math.ceil(this.borderWidth / 4) * zoomLevel*/;
        this.ctx.save();
        this.ctx.beginPath();
        // ctx.lineWidth = (1 + Math.ceil(this.borderWidth / 2)) * zoomLevel;
        this.ctx.lineWidth = (1 + this.borderWidth) /* * zoomLevel*/;
        // to stick arrow right to the border it needs to be compensated
        this.ctx.moveTo(x + r / 3 - this.ctx.lineWidth / 2, y - r / 1.7);
        this.ctx.lineTo(x + r  - this.ctx.lineWidth / 2, y);
        this.ctx.lineTo(x + r / 3 - this.ctx.lineWidth / 2, y + r / 1.7)

        this.ctx.lineCap = 'square';
        this.ctx.lineJoin = 'miter';

        this.ctx.globalAlpha = this._getVisibility(this.currentLayerID);
        this.ctx.strokeStyle = this._currentColor;
        this._drawShadow();

        this.ctx.stroke();
        this.ctx.closePath();
        this.ctx.restore();
        break;
      }
    }
  }
  _drawDot(x, y, radius){
    this.ctx.beginPath();
    this.ctx.arc(
      x, y,
      radius/* * zoomLevel*/,
      0,
      Math.PI * 2,
      false
    )
    this.ctx.fillStyle = this._currentColor;
    this.ctx.globalAlpha = this._getVisibility(this.currentLayerID);
    this._drawShadow();
    this.ctx.fill();
    this.ctx.closePath();
  }
  _drawArrows(){
    // define coords and angles of drawing arrows
    // const _arrowHeight = this._ARROW_HEIGHT + (this.borderWidth - 1); // borderWidth - 1 add linear increasing of arrow size depends on edge width
    let borderWidth = this.borderWidth;
    if(this.name) borderWidth = this.borderWidth > 4 ? 4 : this.borderWidth;
    const _arrowHeight = this._ARROW_HEIGHT * borderWidth; // borderWidth - 1 add linear increasing of arrow size depends on edge width
    const dotRadius = this._ARROW_DOT_RADIUS * borderWidth; // borderWidth / 2 doing the same for dot arrow
    const fromSizeMultiplier = parseInt(this.lineStartSize) ? this.lineStartSize / 100 : 1;
    const toSizeMultiplier =  parseInt(this.lineEndSize) ? this.lineEndSize / 100 : 1;

    const {width: edgeWidth, height: edgeHeight, x: edgeX, y: edgeY} = this.edgeBounds;

    this.ctx.save();
    this.ctx.imageSmoothingEnabled = true;
    // draw arrows depends on its type
    if(this.lineStart !== EDGE_LINES.NONE.VALUE){
      if(this.lineStart === EDGE_LINES.DOT.VALUE) {
        // since dot has different radius from other arrows
        // get its own computed distances from node
        this._drawDot(
          this.xCache + dotRadius * fromSizeMultiplier / 2,
          this.yCache,
          dotRadius * fromSizeMultiplier / 2,
        )
      } else {
        this.ctx.save();
        // set arrow width depends on its type to calc label padding
        // _arrowHeight * fromSizeMultiplier - diameter of circle where circumscribed an equilateral triangle which is the arrow
        // to draw label appropriately diameter of the circle needs to be subtract the part 1/2, 1/3, 1/4 accordingly
        // rotate ctx
        this._ctxRotate(
          Math.PI,
          this.xCache + _arrowHeight * fromSizeMultiplier / 2,
          this.yCache
        );
        // draw arrow
        this._drawArrow(
          this.lineStart,
          this.xCache + _arrowHeight * fromSizeMultiplier / 2,
          this.yCache,
          _arrowHeight * fromSizeMultiplier / 2,
        )
        this.ctx.restore();
      }
    }

    if(this.lineEnd !== EDGE_LINES.NONE.VALUE){
      if(this.lineEnd === EDGE_LINES.DOT.VALUE){
        this._drawDot(
          this.xCache + edgeWidth - dotRadius * toSizeMultiplier / 2,
          this.yCache,
          dotRadius * toSizeMultiplier / 2,
        )
      } else {
        this.ctx.save();
        /**
         * edgeWidth === shadowLeftPart + edgeDistance so that
         * */
        this._drawArrow(
          this.lineEnd,
          this.xCache + edgeWidth - _arrowHeight * toSizeMultiplier / 2,
          this.yCache,
          _arrowHeight * toSizeMultiplier / 2,
        )

        this.ctx.restore();
      }
    }

    this.ctx.restore();
  }

  getVisibility(layerID){
    this._getVisibility(layerID);
  }
  _getVisibility(layerID){
    layerID = layerID || this.currentLayerID;
    return layerID < this.visibilityMin || layerID > this.visibilityMax ? 0.5 : 1;
  }

  _drawText(){
    let contentParsed =
      this.content ?
        this._contentParsed(this.content) :
        {
          amount: 1,
          content: {start: null, middle: this.contentMiddle, end: null}
        };

    // const contentParsed = this._contentParsed(this.content);
    const labelsTotal = contentParsed.amount; // edge's labels
    let content = contentParsed.content;
    const labelMinWidth = 30;
    const {startLabelPadding, endLabelPadding} = this.arrowsBounds;

    let fontSize = this.fontSize;
    if(!this.name) fontSize = this.fontSize > 20 ? 20 : this.fontSize;

    if(!this.hidden && labelsTotal){
      // text settings
      const textLineHeight = fontSize/* * 1.1*/;

      const CanvasTextSettings = {
        debug: false,
        vAlign: TEXT_POSITION.CENTER.VALUE,
        font: this.fontFamily,
        fontWeight: this.fontWeight,
        fontStyle: this.fontStyle,
        fontSize,
        lineHeight: textLineHeight,
      }
      const CanvasText = new CanvasTxt(null, CanvasTextSettings);

      const {x: edgeX, y: edgeY, width: edgeWidth, height: edgeHeight} = this.edgeBounds;
      const {y: titleY, height: titleHeight, vAlign: titleVAlign} = this.titleBounds;

      if(this.fontColor.includes('rgba')){
        this.ctx.fillStyle = this.fontColor;
      } else {
        const fontInRGB = this.fontColor.includes('rgb') ? hexToRGB(RGB_to_hex(this.fontColor)) : hexToRGB(this.fontColor);
        this.ctx.fillStyle = `rgba(${fontInRGB.r}, ${fontInRGB.g}, ${fontInRGB.b}, 1)`;
      }

      this.ctx.globalAlpha = this._getVisibility(this.currentLayerID);

      const LABEL_ALIGN = {
        START: 'left',
        MIDDLE: 'center',
        END: 'right'
      }
      // split available edge distance to an equal segments for drawing text
      let step = 0;

      // if edgeStart located behind edgeEnd labels should be reverted
      if(this.endPosX - this.startPosX < 0){
        let properties = {};
        Object.entries(content).reverse().forEach(([key, value]) => Object.assign(properties, {[key]: value}));
        content = properties;
        LABEL_ALIGN.START = 'right';
        LABEL_ALIGN.END = 'left';
      }

      this.ctx.save();

      if(this.endPosX - this.startPosX  < 0){
        this._ctxRotate(
          180 * Math.PI / 180,
          edgeWidth / 2,
          edgeY
        )
      }

      // to compensate shadow width it should be subtracted from edgeWidth
      const labelWidth = (edgeWidth - startLabelPadding - endLabelPadding) / contentParsed.amount;
      const computedArrowWidth = this.endPosX - this.startPosX < 0 ? endLabelPadding : startLabelPadding;
      const computedEdgeX = this.endPosX - this.startPosX < 0 ? -this.xCache : this.xCache;
      const computedTitleY = this.endPosX - this.startPosX < 0 ? titleY - this.yCache : titleY + this.yCache;

      for (let [label, labelText] of Object.entries(content)){
        if (labelText){
          // width of label is a width of edge divided equally by non-empty labels amount
          const textMeasurement = {
            ctx: this.ctx,
            title: labelText,
            x: computedEdgeX + computedArrowWidth + labelWidth * step,
            y: computedTitleY,
            width: labelWidth,
            height: titleHeight,
            vAlign: titleVAlign
          }
          step++;

          // draw nothing if edge width to small
          if(labelWidth >= labelMinWidth){
            this._drawContentEdge(CanvasText, textMeasurement, LABEL_ALIGN[label.toUpperCase()])
          }

        }
      }

      this.ctx.restore();
      // set back default text drawing alignments
      CanvasText.vAlign = TEXT_POSITION.CENTER.VALUE;
      CanvasText.align = TEXT_POSITION.CENTER.VALUE;
    }

  }

  _contentParsed(content){
    const parsedContent = {
      start: null,
      middle: null,
      end: null
    };
    let amount = 0;
    for (let [type, value] of Object.entries(content) ){
      if (value?.trim()){
        parsedContent[type] = value;
        amount++;
      }
    }
    return {content: parsedContent, amount: amount};
  }

  _drawContentEdge(CanvasText, labelData, align){
    this.ctx.save();
    CanvasText.align = align;
    CanvasText.vAlign = labelData.vAlign;
    CanvasText.drawText(labelData);
    this.ctx.restore();
  }


  _radiansToDegrees(rad){
    if(rad < 0){
      // Correct the bottom error by adding the negative
      // angle to 360 to get the correct result around
      // the whole circle
      return (360.0 + (rad * (180 / Math.PI))).toFixed(2);
    } else {
      return (rad * (180 / Math.PI)).toFixed(2);
    }
  }
  _degreesToRadians(degrees){
    return degrees * (Math.PI / 180);
  }

  // BOUNDS

  _getEdgeCoordinates(){
    let x = this.edgeBounds.x;
    let y = this.edgeBounds.y;
    const shift = {x: 0, y: 0};
    const isReversed = this.endPosX - this.startPosX < 0;

    const {shadowShiftX, shadowShiftY, blurWidth: shadowBlurWidth} = this.shadowBounds;
    const {startArrowHeight, endArrowHeight} = this.arrowsBounds;
    const {y: titleY, shiftReversed: titleShiftReversed} = this.titleBounds;
    const editingHeight = this.editing ? this.borderWidth + this._HOVER_AREA : this.borderWidth;

    // shadow computed
    const shadowShiftXComputed = shadowShiftX - shadowBlurWidth;
    const shadowShiftYComputed = isReversed ? -(shadowShiftY + shadowBlurWidth + Math.max(startArrowHeight, endArrowHeight) / 2) : shadowShiftY - shadowBlurWidth;

    // title computed
    const titleComputed = isReversed ? titleShiftReversed : titleY;

    shift.x = Math.abs(Math.min(0, shadowShiftXComputed));
    shift.y = Math.abs(Math.min(0, -editingHeight / 2, shadowShiftYComputed, titleComputed, -(startArrowHeight + this.borderWidth) / 2, -(endArrowHeight + this.borderWidth) / 2));

    x += shift.x;
    y += shift.y;

    return {x, y}
  }


  // DEV

  _devDrawBorders(){
    const multiplier = this.ZOOM_MAX * this.pixelRatio;
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(this.ctx.canvas.width / multiplier, 0);
    this.ctx.lineTo(this.ctx.canvas.width / multiplier, this.ctx.canvas.height / multiplier);
    this.ctx.lineTo(0, this.ctx.canvas.height / multiplier);
    this.ctx.lineTo(0, 0);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = 'rgba(255,244,255,.6)';
    this.ctx.stroke();
  }

}
