import useMapArea from '@/services/canvas/map-area/MapArea';
import ToastUtils from '@/utils/ToastUtils';
import useI18nGlobal from '@/utils/i18n';
import ApiService from '@/utils/ApiService';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';

const {mapArea} = useMapArea();
const {createToastFromStore} = ToastUtils();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;

export default {
	namespaced: true,
	state: {
		saveHiddenTimeout: null,
		nodesHiddenOrder: {},
		saveLayerHiddenTimeout: null,
		layerHiddenOrder: {}
	},
	getters: {
		getNodesOriginal(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesOriginal'];
		},
		getNodes(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodes'];
		},
		getLayers(state, getters, rootState, rootGetters){
			return rootGetters['layers/getLayers']
		},
		getCurrentMapId(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getCurrentMapId']
		},
		getNodesURL(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesURL']
		},
		getMapsURL(state, getters, rootState, rootGetters){
			return rootGetters['maps/getMapsURL']
		},
		getElementsURL(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getElementsURL']
		},
	},
	mutations: {
		UPDATE_NODE_ORIGINAL_HIDDEN(state, {nodesOriginal, hiddenData}){
			for(let [id, status] of Object.entries(hiddenData)){
				const nodeOriginal = nodesOriginal.find(node => node.id === parseInt(id));
				nodeOriginal.visibility.hidden = status;
			}
		},
		UPDATE_NODE_HIDDEN(state, {nodes, hiddenData}){
			for(let [id, status] of Object.entries(hiddenData)){
				const node = nodes.find(node => node.id === parseInt(id));
				node.hidden = status;
			}
		},
		UPDATE_NODE_HIDDEN_LOCAL(state, {nodes, hiddenData}){
			for(let [id, status] of Object.entries(hiddenData)){
				const node = nodes.find(node => node.id === parseInt(id));
				node.hiddenLocal = status;
			}
		}
	},
	actions: {
		nodeUpdateHiddenLocal({getters, commit, dispatch}, hiddenData){
			// hiddenData: {[id]: hidden}
			const nodes = getters.getNodes;
			commit('UPDATE_NODE_HIDDEN_LOCAL', {nodes, hiddenData});
			dispatch('edges/edgeUpdateHiddenByNode', hiddenData, {root: true});
			const [[id, hidden]] = Object.entries(hiddenData);

			mapArea.updateEl(true, +id);
		},
		nodeUpdateHidden({state, getters, commit, dispatch}, hiddenData){
			// hiddenData: {[id]: hidden}
			const nodes = getters.getNodes;
			const nodesOriginal = getters.getNodesOriginal;
			commit('UPDATE_NODE_HIDDEN', {nodes, hiddenData});
			commit('UPDATE_NODE_ORIGINAL_HIDDEN', {nodesOriginal, hiddenData});

			// update hidden state on layer
			// dispatch('layers/hidden/nodeHiddenUpdate', hiddenData, {root: true})

			dispatch('edges/edgeUpdateHiddenByNode', hiddenData, {root: true});
			// set updated date of map changing
			dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});
			// update canvas
			for(let [id, hidden] of Object.entries(hiddenData)){
				mapArea.updateEl(true, +id);
			}
		},
		nodeSaveHidden({state, getters, commit, dispatch}, hiddenData){
			// save order as {id: status}
			Object.assign(state.nodesHiddenOrder, hiddenData);
			clearTimeout(state.saveHiddenTimeout);
			state.saveHiddenTimeout = setTimeout( async () => {
				const mapsURL = getters.getMapsURL;
				const elementsURL = getters.getElementsURL;
				const mapId = getters.getCurrentMapId;
				const nodesData = [];
				for(let [id, status] of Object.entries(state.nodesHiddenOrder)){
					const nodeHidden = {
						'id': id,
						'visibility': {'hidden': status}
					}
					nodesData.push(nodeHidden)
				}
				const data = {'nodes': nodesData}
				
				try{
					await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, data);
					const editsData = {
						type: SE_SYNC_TYPES.NODE_HIDDEN_UPDATED,
						data: state.nodesHiddenOrder
					}
					dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
					state.nodesHiddenOrder = {};
					
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeHiddenSuccess'), dispatch);
					clearTimeout(state.saveHiddenTimeout);
				} catch(err){
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeHiddenError'), dispatch);
					clearTimeout(state.saveHiddenTimeout);
				}
				
				
			}, 700);
		},
		layerUpdateHidden({getters, commit, dispatch}, data){
			const layers = getters.getLayers;
			const nodes = getters.getNodes;
			const nodesOriginal = getters.getNodesOriginal;
			
			// check if node on layer to change its hidden state
			const hiddenData = {}; // {id: status, id2: status2, ... }
			for(let [layerID, status] of Object.entries(data)){
				layers[layerID].nodes.forEach((node, nodeIndex) => {
					if (node.onLayer) {
						Object.assign(hiddenData, {[nodes[nodeIndex].id]: status})
					}
				})
			}
			
			// commit('UPDATE_NODE_ORIGINAL_HIDDEN', {nodesOriginal, hiddenData});
			commit('UPDATE_NODE_HIDDEN_LOCAL', {nodes, hiddenData});
			dispatch('edges/edgeUpdateHiddenByNode', hiddenData, {root: true});
			// update levels
			dispatch('layers/levelHiddenUpdate', data, {root: true});
			// update layers
			dispatch('layers/hidden/layerHiddenUpdate', data, {root: true});
			// set updated date of map changing
			dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});
			// update canvas
			for(let [id, hidden] of Object.entries(hiddenData)){
				mapArea.updateEl(true, +id);
			}
		},
		layerSaveHidden({state, getters, commit, dispatch}, hiddenData){
			Object.assign(state.layerHiddenOrder, hiddenData)
			clearTimeout(state.saveLayerHiddenTimeout);
			state.saveLayerHiddenTimeout = setTimeout(async () => {
				const mapsURL = getters.getMapsURL;
				const elementsURL = getters.getElementsURL;
				const mapId = getters.getCurrentMapId;
				const layers = getters.getLayers;
				const nodes = getters.getNodes;
				
				const layerData = [];
				const nodesData = [];
				for(let [id, status] of Object.entries(state.layerHiddenOrder)){
					layerData.push({'id': id,	'hidden': status});
					// collect nodes from current layer
					layers[id].nodes.forEach((node, nodeIndex) => {
						if(node.onLayer){
							nodesData.push({
								'id': nodes[nodeIndex].id,
								'editing': {'hidden': status}
							})
						}
					})
				}
				const levelsData = {'levels': layerData, 'nodes': nodesData}
				
				try{
					await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, levelsData);
					
					const editsData = {
						type: SE_SYNC_TYPES.LAYER_HIDDEN_UPDATED,
						data: state.layerHiddenOrder
					}
					dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
					state.layerHiddenOrder = {};
					
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.layerHiddenSuccess'), dispatch);
					clearTimeout(state.saveHiddenTimeout);
				} catch(err){
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.layerHiddenError'), dispatch);
					clearTimeout(state.saveHiddenTimeout);
				}
			}, 700)
		}
	}
}
