export default function (){
	const VISIBILITY_MAPPING = {
		visibilityMin: 'min_level',
		visibilityMax: 'max_level'
	}
	function mappingInverted(mapping){
		const invertedMapping = {}
		for(let [key,value] of Object.entries(mapping)){
			Object.assign(invertedMapping, {[value]: key})
		}
		return invertedMapping;
	}
	
	function convertVisibilityToOriginalProperty(visibilityProperty){
		const [entries] = Object.entries(visibilityProperty);
		const [key, value] = entries;
		
		return {[VISIBILITY_MAPPING[key]] : value}
	}
	
	function convertVisibilityToAdaptedProperty(visibilityProperty){
		const [entries] = Object.entries(visibilityProperty);
		const [key, value] = entries;
		const mapping = mappingInverted(VISIBILITY_MAPPING);
		return {[mapping[key]] : value}
	}
	
	return{
		convertVisibilityToOriginalProperty,
		convertVisibilityToAdaptedProperty
	}
}
