// Hair space character for precise justification
const SPACE = '\u200a'

export default class CanvasTxt {
	constructor(drawData, settings) {
    const {debug, align, vAlign, fontSize, fontWeight, fontStyle, fontVariant, font, lineHeight, justify} = settings;
    this.debug = debug || false;
    this.align = align || 'center';
    this.vAlign = vAlign || 'center';
    this.fontSize = fontSize;
    this.fontWeight = fontWeight || '';
    this.fontStyle = fontStyle || '';
    this.fontVariant = fontVariant || '';
    this.font = font || 'Arial';
    this.lineHeight = lineHeight || fontSize;
    this.justify = justify || false;
    this.drawData = drawData;
    this.style = `${this.fontStyle} ${this.fontVariant} ${this.fontWeight} ${this.fontSize}px ${this.font}`;
    this.textArray = drawData ? this.makeTextArray(drawData) : null;
    this.fontDimensions = drawData ? this.getTextHeight(drawData) : null;
	}
	
	makeTextArray(drawData) {
		let {ctx, title, width, height} = drawData;
		// Parse all to integers
		[width, height] = [width, height].map(el => parseInt(el))
		
		/*if (/!*width <= 0 || height <= 0 ||*!/ this.fontSize <= 0) {
			//width or height or font size cannot be 0
			return
		}*/
		
		// const {fontStyle, fontVariant, fontWeight, fontSize, font} = this
		ctx.font = this.style;
		
		//added one-line only auto linebreak feature
		let textarray = []
		let temptextarray = title.replaceAll('&shy;', ' -<br>').split('<br>')
		const spaceWidth = this.justify ? ctx.measureText(SPACE).width : 0
		// if no width specified it takes needed width to draw the text
		if(!width) return temptextarray;

		temptextarray.forEach(txtt => {
			let textwidth = ctx.measureText(txtt).width
			if (textwidth <= width) {
				textarray.push(txtt)
			} else {
				let temptext = txtt
				let linelen = width
				let textlen
				let textpixlen
				let texttoprint
				textwidth = ctx.measureText(temptext).width
				while (textwidth > linelen) {
					textlen = 0
					textpixlen = 0
					texttoprint = ''
					while (textpixlen < linelen) {
						textlen++
						texttoprint = temptext.substr(0, textlen)
						textpixlen = ctx.measureText(temptext.substr(0, textlen)).width
					}
					// Remove last character that was out of the box
					textlen > 1 ? textlen-- : null;
					texttoprint = texttoprint.substr(0, textlen)
					//if statement ensures a new line only happens at a space, and not amidst a word
					const backup = textlen
					if (temptext.substr(textlen, 1) !== ' ') {
						while (temptext.substr(textlen, 1) !== ' ' && textlen !== 0) {
							textlen--
						}
						if (textlen === 0) {
							textlen = backup
						}
						texttoprint = temptext.substr(0, textlen)
					}
					
					texttoprint = this.justify
						? this.justifyLine(ctx, texttoprint, spaceWidth, SPACE, width)
						: texttoprint
					
					temptext = temptext.substr(textlen)
					textwidth = ctx.measureText(temptext).width
					textarray.push(texttoprint)
				}
				if (textwidth > 0) {
					textarray.push(temptext)
				}
			}
			// end foreach temptextarray
		})
		return textarray;
	}
	
	getDrawnTextHeight(drawData) {
		const textArray = this.makeTextArray(drawData);
		const {fontContentArea} = this.drawData ? this.fontDimensions : this.getTextHeight(drawData);
		let lineHeightComputed = this.lineHeight === this.fontSize ? 0 : this.lineHeight;

		// if lineHeight isn't equal to 1
		if(this.lineHeight !== this.fontSize) {
			lineHeightComputed = parseFloat(((lineHeightComputed - this.fontSize) / 2).toFixed(2));
		}

		const charHeight = lineHeightComputed * 2 + fontContentArea;

		return charHeight * textArray.length;
	}

	getDrawnTextDimensions(drawData){
		const {ctx, title, width} = drawData;
		const textArray = this.makeTextArray({ctx, title, width});

		const rowsWidth = [];
		textArray.forEach(textRow => {
			const {width: textActualWidth} = ctx.measureText(textRow);
			rowsWidth.push(textActualWidth);
		})

		const textLongestLineWidth = Math.max(...rowsWidth);
		const textWidth = width || textLongestLineWidth;

		const {fontContentArea} = this.drawData ? this.fontDimensions : this.getTextHeight(drawData);
		let lineHeightComputed = this.lineHeight === this.fontSize ? 0 : this.lineHeight;

		// if lineHeight isn't equal to 1
		if(this.lineHeight !== this.fontSize) {
			lineHeightComputed = parseFloat(((lineHeightComputed - this.fontSize) / 2).toFixed(2));
		}

		const charHeight = lineHeightComputed * 2 + fontContentArea;
		return {
			textHeight: charHeight * textArray.length,
			textWidth: Math.ceil(textWidth)
		}
	}

	drawText(drawData) {
		/**
		 * width, height could be sizes of a node or computed size of text to draw
		 * */
		const {ctx, x, y, width, height} = drawData;
		const textArray = this.drawData ? this.textArray : this.makeTextArray(drawData);
		if (!textArray) return; // if width to drawn is smaller than single char

		const {fontContentArea, fontActualSize, baseline, descent, lineGap} = this.drawData ? this.fontDimensions : this.getTextHeight(drawData);
		let lineHeightComputed = this.lineHeight === this.fontSize ? 0 : this.lineHeight;

		// if lineHeight isn't equal to 1
		if(this.lineHeight !== this.fontSize) {
			lineHeightComputed = parseFloat(((lineHeightComputed - this.fontSize) / 2).toFixed(2));
		}

		const charHeight = lineHeightComputed * 2 + fontContentArea;
		const textHeight = charHeight * textArray.length;
		const vheight = charHeight * (textArray.length - 1)
		let textanchor;

		// End points
		const xEnd = x + width
		const yEnd = y + height
		
		if (this.align === 'right') {
			textanchor = xEnd
			ctx.textAlign = 'right'
		} else if (this.align === 'left') {
			textanchor = x
			ctx.textAlign = 'left'
		} else {
			textanchor = x + width / 2
			ctx.textAlign = 'center'
		}

		const charBaselineOffset = fontActualSize - descent + lineGap / 2 + lineHeightComputed;
		let txtY = y + charBaselineOffset;


		let debugY = y
		// Vertical Align
		if (this.vAlign === 'top') {
			txtY = y + charBaselineOffset
		} else if (this.vAlign === 'bottom') {
			txtY = yEnd - vheight
			debugY = yEnd
		} else {
			//defaults to center
			debugY = y + height / 2
			/**
			 * Y is coordinate of the baseline for the first line of text
			 * depends on height of text area and actual font size
			 * */
			txtY = y + (height - textHeight) / 2 + charBaselineOffset

		}
		//print all lines of text
		textArray.forEach(txtline => {
			// txtline = txtline.trim()
			ctx.fillText(txtline, textanchor, txtY)
			txtY += charBaselineOffset + descent + lineGap / 2 + lineHeightComputed;
		})
		
		if (this.debug) {
			// Text box
			ctx.lineWidth = 3
			ctx.strokeStyle = 'rgba(0, 144, 158, .5)'; // #00909e
			ctx.strokeRect(x, y, width, height)
			
			ctx.lineWidth = 2
			// Horizontal Center
			ctx.strokeStyle = '#f6d743'
			ctx.beginPath()
			ctx.moveTo(textanchor, y)
			ctx.lineTo(textanchor, yEnd)
			ctx.stroke()
			// Vertical Center
			ctx.strokeStyle = '#ff6363'
			ctx.beginPath()
			ctx.moveTo(x, debugY)
			ctx.lineTo(xEnd, debugY)
			ctx.stroke()
		}

		return {height: charHeight}
	}

	// Calculate Height of the font
	getTextHeight(drawData) {
		const {ctx, title} = drawData;
		const previousTextBaseline = ctx.textBaseline
		const previousFont = ctx.font
		
		ctx.textBaseline = 'bottom'
		ctx.font = this.style

		/**
		 * fontBoundingBoxAscent, fontBoundingBoxDescent isn't maintained by firefox for the moment of 27.10.2022, so
		 * const fontContentArea = fontBoundingBoxDescent + fontBoundingBoxAscent; is replaced by
		 * getting css measurements solution
		 * */
		const {actualBoundingBoxAscent, actualBoundingBoxDescent} = ctx.measureText('Mygtlf])');
		const {cssSize: {height: fontContentArea}} = this.getCSSMeasure('Mygtlf])');
		const xHeight = ctx.measureText('v');
		const descender = ctx.measureText('y');
		// const fontContentArea = fontBoundingBoxDescent + fontBoundingBoxAscent;
		const fontActualSize = actualBoundingBoxDescent + actualBoundingBoxAscent;
		const baseline = actualBoundingBoxAscent;
		const lineGap = fontContentArea - fontActualSize;
		const descent = descender.actualBoundingBoxAscent + descender.actualBoundingBoxDescent - (xHeight.actualBoundingBoxAscent + xHeight.actualBoundingBoxDescent)
		// Reset baseline
		ctx.textBaseline = previousTextBaseline
		ctx.font = previousFont

		return {fontContentArea, fontActualSize, baseline, descent, lineGap}
	}

	getCSSMeasure(title){
		// get the CSS metrics.
		// NB: NO CSS lineHeight value !
		const selector = document.querySelector('#__textMeasure');
		let div = selector || document.createElement('DIV');
		if(!div.id) div.id = '__textMeasure';
		if(div.innerHTML !== title) div.innerHTML = title;
		div.style.position = 'absolute';
		div.style.top = '-500px';
		div.style.left = '0';
		div.style.lineHeight = 'normal';
		div.style.fontFamily = this.font;
		div.style.fontWeight = this.fontWeight;
		div.style.fontSize = this.fontSize + 'px';
		if(!selector) document.body.appendChild(div);

		let cssSize = {width: div.offsetWidth, height: div.offsetHeight},
			cssInfo = window.getComputedStyle(div, null),
			fontSizePx = parseFloat(cssInfo['fontSize']),
			lineGap = cssSize.height - fontSizePx;

		// div.innerHTML = '';
		// document.body.removeChild(div);

		return {cssSize, fontSizePx, lineGap}
	}
 
	/**
	 * This function will insert spaces between words in a line in order
	 * to raise the line width to the box width.
	 * The spaces are evenly spread in the line, and extra spaces (if any) are inserted
	 * between the first words.
	 *
	 * It returns the justified text.
	 *
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {string} line
	 * @param {number} spaceWidth
	 * @param {string} spaceChar
	 * @param {number} width
	 */
	justifyLine(ctx, line, spaceWidth, spaceChar, width) {
		const text = line.trim()
		
		const lineWidth = ctx.measureText(text).width
		
		const nbSpaces = text.split(/\s+/).length - 1
		const nbSpacesToInsert = Math.floor((width - lineWidth) / spaceWidth)
		
		if (nbSpaces <= 0 || nbSpacesToInsert <= 0) return text
		
		// We insert at least nbSpacesMinimum and we add extraSpaces to the first words
		const nbSpacesMinimum = Math.floor(nbSpacesToInsert / nbSpaces)
		let extraSpaces = nbSpacesToInsert - nbSpaces * nbSpacesMinimum
		
		let spaces = []
		for (let i = 0; i < nbSpacesMinimum; i++) {
			spaces.push(spaceChar)
		}
		spaces = spaces.join('')
		
		const justifiedText = text.replace(/\s+/g, match => {
			const allSpaces = extraSpaces > 0 ? spaces + spaceChar : spaces
			extraSpaces--
			return match + allSpaces
		})
		
		return justifiedText
	}
}
