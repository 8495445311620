export const NODE_SHAPES = {
	CIRCLE: {VALUE: 'circle', ICON: 'icon--circle'},
	RECTANGLE: {VALUE: 'rectangle', ICON: 'icon--rectangle', disabled: false},
	TRIANGLE_TOP: {VALUE: 'triangle_top', ICON: 'icon--triangle-top', disabled: true},
	TRIANGLE_BOTTOM: {VALUE: 'triangle_bottom', ICON: 'icon--triangle-down', disabled: true},
	RHOMBUS: {VALUE: 'rhombus', ICON: 'icon--rhombus', disabled: true},
	PARALLELOGRAM: {VALUE: 'parallelogram', ICON: 'icon--parallelogram', disabled: true},
	HEXAGON_HOR: {VALUE: 'hexagon_hor', ICON: 'icon--hexagon-1', disabled: true},
	HEXAGON_VERT: {VALUE: 'hexagon_vert', ICON: 'icon--hexagon-2', disabled: true},
	PENTAGON: {VALUE: 'pentagon', ICON: 'icon--pentagon-1', disabled: true},
	STAR: {VALUE: 'star', ICON: 'icon--star', disabled: true},
	TRAPEZOID_TOP: {VALUE: 'trapezoid_top', ICON: 'icon--trapezoid-top', disabled: true},
	TRAPEZOID_BOTTOM: {VALUE: 'trapezoid_bottom', ICON: 'icon--trapezoid-bottom', disabled: true}
}
