export default function (){
	
	async function fontLoading(fontData){
		const {fontFamily, faces} = fontData;

		for(const face of faces){
			let font = new FontFace(fontFamily, `url(${face.url})`, {
				style: face.style,
				weight: face.weight
			});
			await font.load().then( (fontLoaded) => {
				document.fonts.add(fontLoaded);
				// console.log(document.fonts.check(`${face.style} ${face.weight} 16px ${fontFamily}`));
				return Promise.resolve(font);
			})
				.catch( error => {
					console.log(error);
					return Promise.reject();
				})
		}
	}
	
	function addFontToDOM(url){
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = url;
			document.getElementsByTagName('head')[0].appendChild(link);
	}
	
	function fontFamilyGetNameByUrl(url, fonts){
		if(!url) return null;
		const urlSplitted = url.split('/');
		const fontId = parseInt(urlSplitted[urlSplitted.length - 1]);
		const font = Object.values(fonts).find(font => font.id === fontId);
		
		return font?.family_name || null;
	}
	function fontFamilyGetUrlByName(name, fonts){
		const font = Object.values(fonts).find(font => font.family_name === name);
		return font._links.self;
	}
	function fontFamilyGetObjectByUrl(url, fonts){
		return Object.values(fonts).find(font => font._links.self === url) || null;
	}
	
	function getFontWeightOptions(fontName, fonts){
		const font = Object.values(fonts).find(font => font.family_name === fontName);
		return font ? Object.keys(font.weights) : [];
	}
	
	function getFontWeightAppropriate(fontName, fonts){
		if(!fontName) return null;
		const font = Object.values(fonts).find(font => font.family_name === fontName);
		const values = Object.values(font.weights);
		let weight = null;
		const weightCommon = 400;
		// if font weight has single option use it
		if(values.length === 1) {
			weight = values[0];
			// else look at regular weight equal 400
		} else if (values.includes(weightCommon)) {
			weight = weightCommon;
			// otherwise use first one value
		} else {
			weight = values[0];
		}
		return weight;
	}
	
	function convertFontWeightToName(fontName, fonts, weight){
		if(!fontName) return null;
		const font = Object.values(fonts).find(font => font.family_name === fontName);
		let weightName = null;
		for(let [key, value] of Object.entries(font.weights)){
			if(parseInt(weight) === parseInt(value)){
				weightName = key;
			}
		}
		return weightName;
	}
	
	function convertFontWeightToWeight(fontName, fonts, name){
		const font = Object.values(fonts).find(font => font.family_name === fontName);
		let weightNumber = null;
		for(let [key, value] of Object.entries(font.weights)){
			if(name === key){
				weightNumber = value;
			}
		}
		return weightNumber;
	}

	function fontsCollector(fonts){
		const collectedFonts = {};
		for(const font of fonts){
			const model = {
				value: font.family_name,
				name: font.name,
				url: font._links.self,
				properties: {},
			}
			const weights = font.weights; // {Name: value, name: value, ...}
			const revertedWeights = {};
			for(let [key, value] of Object.entries(weights)){
				revertedWeights[value] = key;
			}
			font.faces.forEach(face => {
				const property = {
					name: `${revertedWeights[face.weight]}${face.style === 'italic' ? ' italic' : ''}`, // name
					weight: face.weight, // value
					weightName: revertedWeights[face.weight],
					style: face.style,
				};
				if(!model.properties[property.name]) model.properties[property.name] = property;
			});

			collectedFonts[model.value] = model;
		}
		return collectedFonts;
	}
	function getFontProperty({fonts, fontValue}){
		const font = fonts.value[fontValue];
		const fontProperty = font.properties['Normal'] || Object.values(font.properties)[0];
		const fontWeight = fontProperty.weight;
		const fontStyle = fontProperty.style;
		return {fontWeight, fontStyle};
	}
	
	return {
		fontLoading,
		fontFamilyGetNameByUrl,
		fontFamilyGetUrlByName,
		addFontToDOM,
		fontsCollector,
		getFontProperty,
	}
}
