export default {
	state: {
		toasts: [],
		toastCounter: 0
	},
	getters: {
		getToasts(state){
			return state.toasts
		}
	},
	mutations: {
		ADD_TOAST(state, toastData){
			Object.assign(toastData, {id: state.toastCounter++})
			state.toasts.push(toastData);
		},
		REMOVE_TOAST(state, toastIndex){
			state.toasts.splice(toastIndex, 1);
		},
		RESET_COUNTER(state){
			state.toastCounter = 0;
		},
		RESET_TOASTS(state){
			state.toasts.splice(0, state.toasts.length);
		}
	},
	actions: {
		toastAdd({commit}, toastData){
			commit('ADD_TOAST', toastData);
		},
		toastRemove({commit}, toastIndex){
			commit('REMOVE_TOAST', toastIndex);
		},
		toastCounterReset({commit}){
			commit('RESET_COUNTER');
		},
		toastsReset({commit}) {
			commit('RESET_TOASTS');
			commit('RESET_COUNTER');
		}
	},
	namespaced: true,
}
