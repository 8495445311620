import io from "socket.io-client";
import {COLORS} from '@/models/EDITING_COLORS';
import {EDITING_ELEM_TYPES} from '@/models/EDITING_ELEM_TYPES';
import {EDITING_EXTENT_TYPES} from '@/models/EDITING_EXTENT_TYPES';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import emitter from "@/utils/emitter";

export default {
	state: {
		socket: null,
		userCurrent: null,
		visitors: [],
		blockedOptions: [],
		editedNode: null,
		editedEdge: null,
		editedTemplate: null
	},
	getters: {
		getSocket(state){
			return state.socket;
		},
		getCurrentUser(state){
			return state.userCurrent;
		},
		getVisitors(state){
			return state.visitors;
		},
		// BLOCKED OPTIONS
		getBlockedOptions(state){
			return state.blockedOptions;
		},
		getBlockedMap(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.MAP);
		},
		getBlockedGlobal(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.GLOBAL);
		},
		getBlockedCookieConsent(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.COOKIE_CONSENT);
		},
		getBlockedEditorsNote(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.EDITORS_NOTE);
		},
		getBlockedEmbed(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.EMBED);
		},
		getBlockedGlossary(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.GLOSSARY);
		},
		getBlockedHeaderBar(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.HEADER_BAR);
		},
		getBlockedMapAccess(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.MAP_ACCESS);
		},
		getBlockedMapUrl(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.MAP_URL);
		},
		getBlockedMediaAssets(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.MEDIA_ASSETS);
		},
		getBlockedSeo(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.SEO);
		},
		getBlockedSocialSharing(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.SOCIAL_SHARING);
		},
		getBlockedSources(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.SOURCES);
		},
		getBlockedQuiz(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.QUIZ)
		},
		getBlockedTableOfContent(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.TABLE_OF_CONTENT)
		},
		getBlockedLanguageSettings(state){
			return state.blockedOptions.find(blocked => blocked.elem_type === EDITING_ELEM_TYPES.MAP_LANGUAGES)
		},
		// edited elements
		getEditedNode(state){
			return state.editedNode;
		},
		getEditedEdge(state){
			return state.editedEdge;
		},
		getEditedTemplate(state){
			return state.editedTemplate;
		},
		// states from siblings modules
		getCurrentMapId(state, getters, rootState){
			return rootState.maps.currentMap.id;
		},
	},
	mutations: {
		CREATE_SOCKET(state, data){
			const {mapId, clientSlug} = data;
			state.socket = io(process.env.VUE_APP_ROOT_HOST,{
				withCredentials: true,
				path: '/socket.io2/',
				query: {
					map_id: mapId,
					client_slug: clientSlug,
				}
			})
		},
		RESET_SOCKET(state){
			state.socket = null;
			state.userCurrent = null;
			state.visitors = [];
			state.activeEdits = null;
			state.blockedOptions = [];
		},
		CURRENT_USER_SAVE(state, user){
			state.userCurrent = user;
		},
		VISITOR_ADD(state, users){
			const visitors = state.visitors;
			users.forEach( userNew => {
				// ensure that user is not already in the list and not current user
				if (!visitors.find(user => user.id === userNew.id ) && userNew.id !== state.userCurrent.id){
					// get color to attach
					const visitorColor = {color: null};
					if(visitors.length){
						const lastColorUsed = visitors[visitors.length - 1].color;
						const colorIndex = COLORS.findIndex(color => color.NAME === lastColorUsed);
						visitorColor.color = colorIndex === COLORS.length - 1 ?
							COLORS[0].NAME :
							COLORS[colorIndex + 1].NAME;
 					} else {
						visitorColor.color = COLORS[0].NAME;
					}
					
					// add pointer object with default values
					const pointer = {pointer: {x: 200, y: 300}}
					
					const visitor = Object.assign({}, userNew, visitorColor, pointer);
					visitors.push(visitor);
				}
			});
		},
		VISITOR_REMOVE(state, userRemoved){
			const userIndex = state.visitors.findIndex(user => user.id === userRemoved.id)
			state.visitors.splice(userIndex, 1);
		},
		VISITOR_POINTER_RECEIVED(state, UserState){
			/**
			 * @description UserState.editor(x,y)
			 * coords regarding window top left corner
			 * "cleared" from scaling
			 */
			const visitor = state.visitors.find(visitor => visitor.id === UserState.user.id);
			if(visitor){
				visitor.pointer.x = UserState.editor.x;
				visitor.pointer.y = UserState.editor.y;
			}
		},
		
		ADD_BLOCKED_OPTION(state, option){
			state.blockedOptions.push(option);
		},
		REMOVE_BLOCKED_OPTION(state, option){
			const blockedIndex = state.blockedOptions.findIndex(blocked => blocked.elem_type === option.elem_type);
			state.blockedOptions.splice(blockedIndex, 1);
		},
		
		SET_EDITED_NODE(state, node){
			state.editedNode = node;
		},
		SET_EDITED_EDGE(state, edge){
			state.editedEdge = edge;
		},
		SET_EDITED_TEMPLATE(state, template){
			state.editedTemplate = template;
		}
	},
	actions: {
		socketCreate({commit, dispatch}, data){
			commit('CREATE_SOCKET', data);
			dispatch('socketAttachEvents');
		},
		socketReset({getters, commit}){
			const socket = getters.getSocket;
			if(socket !== null){
				socket.emit('leave map editors', 'canvas_editor');
				commit('RESET_SOCKET');
			}
		},
		socketLeave({getters}){
			const socket = getters.getSocket;
			socket.emit('leave map editors', 'canvas_editor');
		},
		socketAttachEvents({getters, commit, dispatch}){
			const socket = getters.getSocket;
			const visitors = getters.getVisitors;
			
			socket.on('connect', event => {
				socket.emit('join map editors', 'canvas_editor');
			});
			
			socket.on('duplicate user error', error => {
				// router.push({name: 'duplicated-user'});
				dispatch('user/userDuplicatedSet', true, {root: true})
			});
			
			socket.on('requested invitation', users =>{
				dispatch('invitation/membersUpdate', users, {root: true})
			})
			
			socket.on('user connected', user => {
				commit('CURRENT_USER_SAVE', user);
			});
			
			socket.on('user joined map editors', joinLeaveEvent => {
				commit('VISITOR_ADD', joinLeaveEvent.users.canvas_editor); // joinLeaveEvent.users.canvas_editor
			});
			
			socket.on('user left map editors', joinLeaveEvent => {
				commit('VISITOR_REMOVE', joinLeaveEvent.user);
			});
			
			socket.on('update active edits', edits => {
				// blocking map settings
				const visitors = getters.getVisitors;
				
				// sort blocked options
				for(let [elem, edit] of Object.entries(edits)){
					if(elem !== EDITING_ELEM_TYPES.NODE && elem !== EDITING_ELEM_TYPES.EDGE && elem !== EDITING_ELEM_TYPES.TEMPLATE){
						for(let LockEvent of Object.values(edit)){
							const visitor = visitors.find(visitor => visitor.id === LockEvent[0].user_id);
							const blocked = Object.assign({}, ...LockEvent, {elem_type: elem}, {visitor});
							commit('ADD_BLOCKED_OPTION', blocked);
						}
					}
				}
				
				// sort blocked nodes
				for(let [nodeId, locks] of Object.entries(edits.nodes)){
					locks.forEach(lock => {
						const visitorColor = {color: null, colorName: null};
						// get color value for canvas
						const visitor = visitors.find(visitor => visitor.id === lock.user_id);
						visitorColor.color = COLORS.find(color => visitor.color === color.NAME).VALUE;
						visitorColor.colorName = visitor.color;
						
						const editingNode = Object.assign({}, lock, visitorColor, {elem_id: parseInt(nodeId)}, {initials: visitor.initials});
						dispatch('nodes/nodeSetEditing', editingNode, {root: true});
						if(lock.lock_extent === EDITING_EXTENT_TYPES.CONTENT){
							dispatch('nodes/nodeSetOccupied', editingNode, {root: true});
						}
					})
				}
				
				// sort blocked edges
				for(let [edgeId, LockEvent] of Object.entries(edits.edges)){
					const visitors = getters.getVisitors;
					// get color value for canvas
					// edge can be edited by one user at time
					const visitor = visitors.find(visitor => visitor.id === LockEvent[0].user_id);
					const visitorColor = {
						color: COLORS.find(color => visitor.color === color.NAME).VALUE,
						colorName: visitor.color
					};

					const editingEdge = Object.assign({},{elem_id: parseInt(edgeId)}, ...LockEvent, visitorColor, {initials: visitor.initials});
					dispatch('edges/edgeSetEditing', editingEdge, {root: true});
				}
				
				// sort blocked templates
				for(let [templateId, locks] of Object.entries(edits.object_templates)){
					locks.forEach(lock => {
						const visitorColor = {color: null};
						const visitors = getters.getVisitors;
						// get color value
						const visitor = visitors.find(visitor => visitor.id === lock.user_id);
						visitorColor.color = visitors.find(visitor => visitor.id === lock.user_id).color;
						visitorColor.colorName = visitor.color;
						
						const editingTemplate = Object.assign({}, lock, visitorColor, {elem_id: parseInt(templateId)},{initials: visitor.initials});
						dispatch('design/templateSetOccupied', editingTemplate, {root: true});
					})
				}
				
			});
			
			socket.on('updated user state', UserState => {
				// userPointerReceived(UserState);
				
				commit('VISITOR_POINTER_RECEIVED', UserState);
			});
			
			socket.on('block element', LockEvent => {
				/*console.log('block element');
				console.log(LockEvent);*/
				const userCurrent = getters.getCurrentUser;
				if(LockEvent.user_id === userCurrent.id) return;

				switch (LockEvent.elem_type){
					case EDITING_ELEM_TYPES.COOKIE_CONSENT:
					case EDITING_ELEM_TYPES.EDITORS_NOTE:
					case EDITING_ELEM_TYPES.EMBED:
					case EDITING_ELEM_TYPES.GLOSSARY:
					case EDITING_ELEM_TYPES.HEADER_BAR:
					case EDITING_ELEM_TYPES.MAP_ACCESS:
					case EDITING_ELEM_TYPES.MAP_URL:
					case EDITING_ELEM_TYPES.TABLE_OF_CONTENT:
					case EDITING_ELEM_TYPES.MEDIA_ASSETS:
					case EDITING_ELEM_TYPES.SEO:
					case EDITING_ELEM_TYPES.SOCIAL_SHARING:
					case EDITING_ELEM_TYPES.SOURCES:
					case EDITING_ELEM_TYPES.MAP:
					case EDITING_ELEM_TYPES.QUIZ:
					case EDITING_ELEM_TYPES.GLOBAL:
					case EDITING_ELEM_TYPES.MAP_LANGUAGES: {
						const visitor = visitors.find(visitor => visitor.id === LockEvent.user_id);
						Object.assign(LockEvent, {visitor});
						commit('ADD_BLOCKED_OPTION', LockEvent);
						break;
					}
					
					case EDITING_ELEM_TYPES.NODE: {
						const visitorColor = {color: null, colorName: null};
						const visitors = getters.getVisitors;
						// get color value for canvas
						const visitor = visitors.find(visitor => visitor.id === LockEvent.user_id);

						visitorColor.color = COLORS.find(color => visitor.color === color.NAME).VALUE;
						visitorColor.colorName = visitor.color;
						
						const editingNode = Object.assign({}, LockEvent, visitorColor, {initials: visitor.initials});
						dispatch('nodes/nodeSetEditing', editingNode, {root: true});
						if(LockEvent.lock_extent === EDITING_EXTENT_TYPES.CONTENT){
							dispatch('nodes/nodeSetOccupied', editingNode, {root: true});
						}
						break;
					}
					case EDITING_ELEM_TYPES.EDGE: {
						const visitorColor = {color: null, colorName: null};
						const visitors = getters.getVisitors;
						// get color value for canvas
						const visitor = visitors.find(visitor => visitor.id === LockEvent.user_id);
						visitorColor.colorName = visitors.find(visitor => visitor.id === LockEvent.user_id).color;
						visitorColor.color = COLORS.find(color => visitorColor.colorName === color.NAME).VALUE;
						
						const editingEdge = Object.assign({}, LockEvent, visitorColor, {initials: visitor.initials});
						dispatch('edges/edgeSetEditing', editingEdge, {root: true});
						break;
					}
					case EDITING_ELEM_TYPES.TEMPLATE: {
						const visitorColor = {color: null};
						const visitors = getters.getVisitors;
						// get color value
						const visitor = visitors.find(visitor => visitor.id === LockEvent.user_id);
						visitorColor.color = visitors.find(visitor => visitor.id === LockEvent.user_id).color;
						visitorColor.colorName = visitor.color;
						
						const editingTemplate = Object.assign({}, LockEvent, visitorColor, {initials: visitor.initials});
						dispatch('design/templateSetOccupied', editingTemplate, {root: true});
						break;
					}
				}
			});
			
			socket.on('unblock element', LockEvent => {
				/*console.log('UNBLOCK element');
				console.log(LockEvent);*/
				const userCurrent = getters.getCurrentUser;
				
				if(LockEvent.user_id === userCurrent?.id) return;
				
				switch (LockEvent.elem_type){
					case EDITING_ELEM_TYPES.COOKIE_CONSENT:
					case EDITING_ELEM_TYPES.EDITORS_NOTE:
					case EDITING_ELEM_TYPES.EMBED:
					case EDITING_ELEM_TYPES.GLOSSARY:
					case EDITING_ELEM_TYPES.HEADER_BAR:
					case EDITING_ELEM_TYPES.MAP_ACCESS:
					case EDITING_ELEM_TYPES.MAP_URL:
					case EDITING_ELEM_TYPES.TABLE_OF_CONTENT:
					case EDITING_ELEM_TYPES.MEDIA_ASSETS:
					case EDITING_ELEM_TYPES.SEO:
					case EDITING_ELEM_TYPES.SOCIAL_SHARING:
					case EDITING_ELEM_TYPES.SOURCES:
					case EDITING_ELEM_TYPES.MAP:
					case EDITING_ELEM_TYPES.QUIZ:
					case EDITING_ELEM_TYPES.GLOBAL:
					case EDITING_ELEM_TYPES.MAP_LANGUAGES: {
						commit('REMOVE_BLOCKED_OPTION', LockEvent);
						break;
					}
					
					case EDITING_ELEM_TYPES.NODE: {
						dispatch('nodes/nodeRemoveEditing', LockEvent, {root: true});
						if(LockEvent.lock_extent === EDITING_EXTENT_TYPES.CONTENT){
							dispatch('nodes/nodeRemoveOccupied', LockEvent, {root: true});
						}
						break;
					}
					case EDITING_ELEM_TYPES.EDGE: {
						dispatch('edges/edgeRemoveEditing', LockEvent, {root: true});
						break;
					}
					case EDITING_ELEM_TYPES.TEMPLATE: {
						dispatch('design/templateRemoveOccupied', LockEvent, {root: true});
						break;
					}
				}
			});
			
			socket.on('sync map edit', async diff => {
				/*console.log('sync map edit');
				console.log(diff);*/
				/**
				 * Assign changes to elements
				 * @param {Number} diff.userId - id of user who made a changes
				 * @param {String} diff.type - one of [...EDITING_ELEM_TYPES, ...SE_SYNC_TYPES]
				 * @param {Object} diff.data - data to be applied
				 */

				const userCurrent = getters.getCurrentUser;
				if(diff.userId === userCurrent?.id) return;
				
				switch (diff.type){
					case EDITING_ELEM_TYPES.MAP: {
						dispatch('mapSettings/mapDataUpdateSE', diff.data, {root: true});
						break;
					}
					// NODE
					case SE_SYNC_TYPES.NODE_POSITION: {
						dispatch('nodes/position/nodeUpdatePositionSE', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.NODE_REMOVED: {
						await dispatch('nodes/nodeRemove', diff.data, {root: true});
						emitter.emit('removedNodeTOC', diff.data);
						break;
					}
					case SE_SYNC_TYPES.NODE_CLONED: {
						dispatch('nodes/nodeClone', diff.data, {root: true});
						emitter.emit('addedNodeTOC', diff.data);
						break;
					}
					case SE_SYNC_TYPES.NODE_CREATED: {
						dispatch('nodes/nodeCreate', diff.data, {root: true});
						emitter.emit('addedNodeTOC', diff.data);
						break;
					}
					case SE_SYNC_TYPES.NODE_EDITED_DESIGN: {
						dispatch('nodes/design/nodeDesignUpdate', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.NODE_EDITED_CONTENT: {
						dispatch('nodes/content/nodeUpdateContent', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.NODE_VISIBILITY_UPDATED: {
						dispatch('nodes/visibility/nodeVisibilityUpdate', diff.data, {root: true})
						break;
					}
					case SE_SYNC_TYPES.NODE_HIDDEN_UPDATED: {
						dispatch('nodes/hidden/nodeUpdateHidden', diff.data, {root: true})
						break;
					}
					case SE_SYNC_TYPES.NODE_LOCKED_UPDATED: {
						dispatch('nodes/locked/nodeUpdateLocked', diff.data, {root: true})
						break;
					}
					case SE_SYNC_TYPES.NODES_ORDER_UPDATED: {
						dispatch('nodes/nodesUpdateOrder', diff.data, {root: true})
						break;
					}
					case SE_SYNC_TYPES.NODE_TEMPLATE_APPLIED: {
						const {nodeId, nodeData} = diff.data;
						const templateId = nodeData.object_template;
						const currentNode = await dispatch('nodes/templateApply', {nodeId, templateId}, {root: true})
						dispatch('nodes/nodesUpdateConvertedEdgesRelated', [currentNode], {root: true})
						break;
					}
					case SE_SYNC_TYPES.NODE_TEMPLATE_RESTORED: {
						dispatch('nodes/nodesApplyTemplateRestore', diff.data.nodeId, {root: true})
						break;
					}
					case SE_SYNC_TYPES.NODE_TEMPLATE_CREATED: {
						await dispatch('design/templateAddCreated', diff.data.template, {root: true});
						await dispatch('nodes/nodeTemplateCreatedPopulate', diff.data, {root: true});
						await dispatch('templates/objectTemplateCreate', {id: diff.data.template.id}, {root:true});
						break;
					}
					// EDGE
					case SE_SYNC_TYPES.EDGE_EDITED_DESIGN: {
						dispatch('edges/design/edgeDesignUpdate', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.EDGE_EDITED_CONTENT: {
						dispatch('edges/content/edgeContentUpdate', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.EDGE_CREATED: {
						dispatch('edges/create/edgeCreate', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.EDGE_REMOVED: {
						dispatch('edges/remove/edgesRemove', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.EDGE_ALL_REMOVED: {
						dispatch('edges/remove/edgesRemoveAll', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.EDGE_VISIBILITY_UPDATED: {
						dispatch('edges/visibility/edgeVisibilityUpdate', diff.data, {root: true})
						break;
					}
					case SE_SYNC_TYPES.EDGE_TEMPLATE_APPLIED: {
						const {edgeId, edgeData} = diff.data;
						const templateId = edgeData.object_template;
						await dispatch('edges/templateApply', {edgeId, templateId}, {root: true})
						break;
					}
					case SE_SYNC_TYPES.EDGE_TEMPLATE_RESTORED: {
						dispatch('edges/edgesApplyTemplateRestore', diff.data.edgeId, {root: true})
						break;
					}
					case SE_SYNC_TYPES.EDGE_TEMPLATE_CREATED: {
						await dispatch('design/templateAddCreated', diff.data.template, {root: true});
						await dispatch('edges/edgeTemplateCreatedPopulate', diff.data, {root: true});
						await dispatch('templates/objectTemplateCreate', {id: diff.data.template.id}, {root:true});
						break;
					}
					// GLOBAL
					case SE_SYNC_TYPES.GLOBAL_DESIGN_UPDATED: {
						await dispatch('design/globalUpdateSE', diff.data, {root: true})
						break;
					}
					// OBJECT TEMPLATE
					// : EDGE
					case SE_SYNC_TYPES.OBJECT_TEMPLATES_EDGE_UPDATED: {
						/**
						 * {id, data, edgeId?} = diff.data;
						 * */
						await dispatch('design/templateUpdate', diff.data, {root: true});
						await dispatch('templates/objectTemplateUpdateByData', diff.data, {root: true});
						await dispatch('edges/objectTemplatesUpdatedApplyToEdges', {templateId: diff.data.id}, {root: true})
						if(diff.data.edgeId !== undefined) dispatch('edges/edgeNullify', diff.data.edgeId, {root: true})
						break;
					}
					case SE_SYNC_TYPES.OBJECT_TEMPLATES_EDGE_REMOVED: {
						dispatch('design/templateDelete', diff.data, {root: true});
						dispatch('edges/objectTemplatesDeletedApply', diff.data, {root: true})
						break;
					}
					// :NODE
					case SE_SYNC_TYPES.OBJECT_TEMPLATES_NODE_UPDATED: {
						/**
						 * {id, data, nodeId?} = diff.data;
						 * */
						await dispatch('design/templateUpdate', diff.data, {root: true});
						await dispatch('templates/objectTemplateUpdateByData', diff.data, {root: true});
						await dispatch('nodes/objectTemplatesUpdatedApplyToNodes', {templateId: diff.data.id}, {root: true})
						if(diff.data.nodeId !== undefined) dispatch('nodes/nodeOriginalNullify', diff.data.nodeId, {root: true});
						break;
					}
					case SE_SYNC_TYPES.OBJECT_TEMPLATES_NODE_REMOVED: {
						dispatch('design/templateDelete', diff.data, {root: true});
						dispatch('nodes/objectTemplatesDeletedApply', diff.data, {root: true})
						break;
					}
					// LAYER
					case SE_SYNC_TYPES.LAYER_REMOVED: {
						dispatch('layers/layerDeleteSE', diff.data, {root: true})
						break;
					}
					case SE_SYNC_TYPES.LAYER_HIDDEN_UPDATED: {
						dispatch('nodes/hidden/layerUpdateHidden', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.LAYER_LOCKED_UPDATED: {
						dispatch('nodes/locked/layerUpdateLocked', diff.data, {root: true});
						break;
					}
					// MEDIA
					case SE_SYNC_TYPES.MEDIA_ASSET_REMOVED: {
						const mediaKey = diff.data.mediaKey;
						dispatch('design/checkDeletedMedia', mediaKey, {root: true});
						dispatch('nodes/checkDeletedMedia', mediaKey, {root: true});
						dispatch('mapSettings/checkDeletedMedia', mediaKey, {root: true});
						break;
					}
					// MAP
					case SE_SYNC_TYPES.MAP_UPDATED: {
						dispatch('maps/mapUpdateSE', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.MAP_RESET: {
						dispatch('maps/mapResetSE', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.MAP_PUBLISHED: {
						dispatch('maps/mapCurrentSave', diff.data, {root: true});
						break;
					}
					case SE_SYNC_TYPES.MAP_STATE_RESTORED: {
						window.location.reload();
						break;
					}
					case SE_SYNC_TYPES.TRANSLATED_STATUS: {
						emitter.emit('updateTranslated', diff.data);
						break;
					}
					case SE_SYNC_TYPES.LANGUAGE_UPDATED: {
						// force reload the page
						emitter.emit('languageUpdated');
						break;
					}

				}
			});
			
		},
		startEditingOption({state, getters}, type){
			const socket = getters.getSocket;
			const userCurrent = getters.getCurrentUser;
			const mapId = getters.getCurrentMapId;

			const LockEvent = {
				user_id: userCurrent.id,
				elem_id: mapId,
				elem_type: type,
				lock_extent: EDITING_EXTENT_TYPES.APPEARANCE
			}

			socket.emit('start editing element', LockEvent);
		},
		stopEditingOption({state, getters}, type){
			const socket = getters.getSocket;
			const userCurrent = getters.getCurrentUser;
			const mapId = getters.getCurrentMapId;
			
			const LockEvent = {
				user_id: userCurrent.id,
				elem_id: mapId,
				elem_type: type,
				lock_extent: EDITING_EXTENT_TYPES.APPEARANCE
			}
			socket.emit('stop editing element', LockEvent);
		},
		
		syncEditedMap({getters}, editedData){
			const socket = getters.getSocket;
			const userCurrent = getters.getCurrentUser;
			const payload = {
				userId: userCurrent.id,
				type: EDITING_ELEM_TYPES.MAP,
				data: editedData
			}
			
			socket.emit('submit map edit', payload);
		},
		
		startEditingNode({getters, commit, dispatch}, nodeData){
			/**
			 * @description called from
			 * //services/canvas/pointer/Pointer.js &&
			 * store/modules/editor/dashboard-options.js
			 */
			const {nodeId, lockExtent} = nodeData;
			const socket = getters.getSocket;
			const userCurrent = getters.getCurrentUser;
			const editedNode = getters.getEditedNode;
			
			const LockEvent = {
				user_id: userCurrent.id,
				elem_id: nodeId,
				elem_type: EDITING_ELEM_TYPES.NODE,
				lock_extent: lockExtent
			}
			
			if(editedNode){
				dispatch('stopEditingNode', editedNode);
			}
			
			socket.emit('start editing element', LockEvent);
			commit('SET_EDITED_NODE', LockEvent);
		},
		stopEditingNode({getters, commit}){
			const socket = getters.getSocket;
			
			const editedNode = getters.getEditedNode;
			socket.emit('stop editing element', editedNode);
			commit('SET_EDITED_NODE', null);
		},
		syncEdits({getters}, editsData){
			const socket = getters.getSocket;
			const userCurrent = getters.getCurrentUser;
			const {type, data} = editsData;
			const payload = {userId: userCurrent.id, type, data};

			socket.emit('submit map edit', payload);
		},
		startEditingEdge({getters, commit, dispatch}, edgeData){
			/**
			 * @description called from
			 * //services/canvas/pointer/Pointer.js &&
			 * store/modules/editor/dashboard-options.js
			 */
			const {edgeId, lockExtent} = edgeData;
			const socket = getters.getSocket;
			const userCurrent = getters.getCurrentUser;
			const editedEdge = getters.getEditedEdge;
			
			const LockEvent = {
				user_id: userCurrent.id,
				elem_id: edgeId,
				elem_type: EDITING_ELEM_TYPES.EDGE,
				lock_extent: lockExtent
			}
			
			if(editedEdge){
				dispatch('stopEditingEdge', LockEvent);
			}
			
			socket.emit('start editing element', LockEvent);
			commit('SET_EDITED_EDGE', LockEvent);
		},
		stopEditingEdge({getters, commit}){
			const socket = getters.getSocket;
			
			const editedEdge = getters.getEditedEdge;
			socket.emit('stop editing element', editedEdge);
			commit('SET_EDITED_EDGE', null);
		},
		startEditingTemplate({getters, commit, dispatch}, templateId){
			const socket = getters.getSocket;
			const userCurrent = getters.getCurrentUser;
			const editedTemplate = getters.getEditedTemplate;
			
			const LockEvent = {
				user_id: userCurrent.id,
				elem_id: templateId,
				elem_type: EDITING_ELEM_TYPES.TEMPLATE,
				lock_extent: EDITING_EXTENT_TYPES.APPEARANCE
			}

			if(editedTemplate){
				dispatch('stopEditingTemplate', LockEvent);
			}
			
			socket.emit('start editing element', LockEvent);
			commit('SET_EDITED_TEMPLATE', LockEvent);
		},
		stopEditingTemplate({getters, commit}){
			const socket = getters.getSocket;
			const editedTemplate = getters.getEditedTemplate;
			if(editedTemplate){
				socket.emit('stop editing element', editedTemplate);
				commit('SET_EDITED_TEMPLATE', null);
			}
		},
		// POINTER SEND
		sendPointerState({getters}, UserState){
			const socket = getters.getSocket;
			socket.emit('update user state', UserState);
		},
		// INVITE TEAM MEMBERS
		requestInvitation({getters}){
			const socket = getters.getSocket;
			socket.emit('request invitation');
		},
		inviteUsers({getters}, users){
			const socket = getters.getSocket;
			socket.emit('invite users', users);
		}
		
	},
	namespaced: true
}
