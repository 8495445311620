export default {
	state: {
		members: [],
	},
	getters: {
		getMembers(state){
			return state.members;
		}
	},
	mutations: {
		UPDATE_MEMBERS(state, members){
			state.members = members;
		}
	},
	actions: {
		membersUpdate({commit}, members){
			const adaptedMembers = members.map(member => {
				return {
					id: member.id,
					name: member.name,
					email: member.email,
					initials: member.initials,
					invite: false
				}
			})
			commit('UPDATE_MEMBERS', adaptedMembers);
		}
	},
	namespaced: true
}
