import ApiService from '@/utils/ApiService';
import {SE_SYNC_TYPES} from '@/models/SE_SYNC_TYPES';
import {TOASTS_TYPES} from '@/models/TOASTS_TYPES';
import useMapArea from '@/services/canvas/map-area/MapArea';
import ToastUtils from '@/utils/ToastUtils';
import useI18nGlobal from '@/utils/i18n';
import VisibilityUtils from '@/services/canvas/utils/VisibilityUtils';
const {mapArea} = useMapArea();
const {createToastFromStore} = ToastUtils();
const {i18n} = useI18nGlobal();
const {t} = i18n.global;
const { convertVisibilityToOriginalProperty } = VisibilityUtils();

export default {
	namespaced: true,
	state: {
		saveVisibilityTimeout: null,
		nodesVisibilityOrder: {}
	},
	getters: {
		getNodesOriginal(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodesOriginal'];
		},
		getNodes(state, getters, rootState, rootGetters){
			return rootGetters['nodes/getNodes'];
		},
		getCurrentMap(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getCurrentMap']
		},
		getMapsURL(state, getters, rootState, rootGetters){
			return rootGetters['maps/getMapsURL']
		},
		getElementsURL(state, getters, rootState, rootGetters) {
			return rootGetters['maps/getElementsURL']
		},
	},
	mutations: {
		UPDATE_NODE_ORIGINAL_VISIBILITY(state, {visibilityOriginal, nodeOriginal}){
			Object.assign(nodeOriginal.visibility, visibilityOriginal);
		},
	},
	actions: {
		nodeVisibilityUpdate({commit, getters, dispatch}, data){
			const {id, visibility} = data;
			const {visibilityMin, visibilityMax} = visibility;
			const nodeOriginal = getters.getNodesOriginal.find(node => node.id === parseInt(id));
			// convert properties to original ones
			const visibilityOriginal = {
				...convertVisibilityToOriginalProperty({visibilityMin}),
				...convertVisibilityToOriginalProperty({visibilityMax})
			};
			
			commit('nodes/UPDATE_NODE', {id, nodeData: visibility}, {root: true}); // update node
			commit('UPDATE_NODE_ORIGINAL_VISIBILITY', {visibilityOriginal, nodeOriginal}); // update node original
			
			const relatedData = {id, ...visibility};
			// update edges related visibility
			dispatch('edges/visibility/edgeChangeVisibilityByNode', relatedData, {root: true});
			// update node on layer settings
			dispatch('layers/layersNodeVisibilityUpdate', relatedData, {root: true});
			// set updated date of map changing
			dispatch('maps/mapCurrentSetUpdatedDate', new Date().toISOString(), {root: true});

			mapArea.updateEl(true, parseInt(id));
		},
		nodeVisibilitySave({state, getters, dispatch}, {id, visibility}){
			Object.assign(state.nodesVisibilityOrder, visibility);
			clearTimeout(state.saveVisibilityTimeout);
			state.saveVisibilityTimeout = setTimeout(async () => {
				const mapsURL = getters.getMapsURL;
				const elementsURL = getters.getElementsURL;
				const mapId = getters.getCurrentMap.id;
				const nodes = getters.getNodes;
				const node = nodes.find(node => node.id === parseInt(id));
				
				const {visibilityMin, visibilityMax} = state.nodesVisibilityOrder;
				const visibilityOriginal = {
					...convertVisibilityToOriginalProperty({visibilityMin}),
					...convertVisibilityToOriginalProperty({visibilityMax})
				};
				const data = {
					nodes: [{id, visibility: visibilityOriginal}]
				};
				
				try{
					state.nodesVisibilityOrder = {};
					await ApiService.patchRequest(`${mapsURL}${mapId}${elementsURL}`, data);
					// add SE to avoid saving related edges visibility twice
					const editsData = {
						type: SE_SYNC_TYPES.NODE_VISIBILITY_UPDATED,
						data: {id, visibility}
					}
					dispatch('simultaneousEditing/syncEdits', editsData, {root: true});
					// clearTimeout(state.saveVisibilityTimeout);
					await createToastFromStore(TOASTS_TYPES.SUCCESS, t('message.nodeVisibilitySuccess', {id}), dispatch);
					// send request to save edgesRelated visibility with null payload
					// since payload has been made by dispatch('edgeChangeVisibilityByNode')
					if(node.edgesFrom.length || node.edgesTo.length) {
						await dispatch('edges/visibility/edgeVisibilitySave', null, {root: true});
					}

				} catch(err){
					// clearTimeout(state.saveVisibilityTimeout);
					await createToastFromStore(TOASTS_TYPES.ERROR, t('message.nodeVisibilityError', {id}), dispatch);
				}
			}, 700)
		},
	}
}
